import GlobalDriveTypeForm from "@/modules/global/components/GlobalDriveTypeForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["global_drive_type_read"],
    sortable: true,
    actions: [
      {
        label: "Nowy typ napędu",
        type: "is-primary",
        route: () => ({
          name: "global_drive_types_create"
        }),
        permissions: ["global_drive_type_create"]
      }
    ],
    route: {
      path: "/global_drive_types/list",
      name: "global_drive_types_list"
    },
    breadcrumbs: [
      {
        label: () => "Typy napędów"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "global_drive_types",
    url: () =>
      `/global_drive_types?` +
      generateProperties([],["id", "name", "isEnabled", "position"]),
    rowActions: [
      {
        action: "edit",
        permissions: ["global_drive_type_update"]
      },
      {
        action: "remove",
        permissions: ["global_drive_type_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_drive_type_create"],
    route: {
      path: "/global_drive_types/create",
      name: "global_drive_types_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_drive_types_list" }),
        label: () => "Typy napędów"
      },
      {
        label: () => `Nowy typ napędu"`
      }
    ],
    formComponent: GlobalDriveTypeForm,
    listRoute: () => ({
      name: "global_drive_types_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_drive_types_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_drive_type_update"],
    route: {
      path: "/global_drive_types/:id/update",
      name: "global_drive_types_update"
    },
    resource: "global_drive_types",
    resourceProperties:
      generateProperties([], ["id", "name", "isEnabled", "position"]) +
      generateProperties(["productTypes"], ["id", "name", "code"], true),
    breadcrumbs: [
      {
        route: () => ({ name: "global_drive_types_list" }),
        label: () => "Typy napędów"
      },
      {
        label: ({ compData }) => `Edycja typu napędu "${compData.object.name}"`
      }
    ],
    formComponent: GlobalDriveTypeForm,
    listRoute: () => ({
      name: "global_drive_types_list"
    })
  }
};
