<template lang="pug">
  div.container.is-fullhd
    template
      table.table.is-bordered.has-cells-centered.is-narrow.is-fullwidth.print-table
        thead
          tr
            th(colspan=23)  Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
          tr
            th(rowspan=2) Lp.
            th(rowspan=2) Grupa
            th(rowspan=2) Wersja.
            th(rowspan=2) Kolek. tkan.
            th(rowspan=2) Tkan.
            th(rowspan=2) Kol. nici
            th(rowspan=2) Szer. tkaniny
            th(rowspan=2) Szer. belek
            th(rowspan=2) XL
            th(rowspan=2) Wys.
            th(rowspan=2) Ilość
            th(rowspan=2) Garb.
            th(rowspan=2) Taśma mask.
            th(rowspan=2) Dłg. linek
            th(rowspan=2) Rozstaw linek
            th(rowspan=2) Śr. belki
            th(rowspan=2) Osprz.
            th(rowspan=2) Prow.
            th(rowspan=2) Naciąg
            th(rowspan=2) Ster.
            th(rowspan=2) Moc.
            th(rowspan=2) Dod.
            th(rowspan=2) Miejsce
        tbody
          tr(v-for="(item, index) in orderItems(object)")
            td {{ index + 1 }}
            td
              p {{ prop(item.pleatGroup, 'position') }}
              p(v-if="item.pleatGroupBottom") {{ prop(item.pleatGroupBottom, "position") }}
            td {{ prop(item.pleat, 'name') }}
            td
              p {{ prop(item.pleatFabric, 'name') }}
              p(v-if="item.pleatFabricBottom") {{ prop(item.pleatFabricBottom, "name") }}
            td
              p {{ prop(item.pleatFabricColor, 'name') }}
              p(v-if="item.pleatFabricColorBottom") {{ prop(item.pleatFabricColorBottom, "name") }}
            td {{ prop(item.pleatFabricColor, "selectionOrder",  "threadColor") }}
            td {{ prop(item, 'width') }}
            td {{ getBalkWidth(item) }}
            td {{ item.width >= item.pleat.largePleatMargin ? 'XL' : '' }}
            td {{ item.height }}
            td {{ item.quantity }}
            td
              p {{ getTannersQuantity(item, item.pleatFabric) }}
              p(v-if="item.pleatFabricBottom") {{ getTannersQuantity(item, item.pleatFabricBottom) }}
            td {{ item.width - item.pleat.maskingTape }}
            td {{ getPleatWireLength(item) }}
            td {{ item.width + item.height }}
            td {{ (item.width - item.pleat.balkWidth)/2 }}
            td {{ item.pleatGlobalAccessoryEntry.globalAccessory.name }}
            td {{ item.pleatGlobalRunnerEntry && item.pleatGlobalRunnerEntry.globalRunner ? item.pleatGlobalRunnerEntry.globalRunner.name : '' }}
            td {{ item.pleatAdditionalString ? item.pleatAdditionalString.globalRunner.name : '' }}
            td {{ item.pleatPleatRemoteControlTypeEntry ? item.pleatPleatRemoteControlTypeEntry.pleatRemoteControlType.name : '' }}
            td {{ item.pleatPleatFixingTypeEntry.pleatFixingType.name }}
            td
              p(v-if="!item.pleatPleatAdditionalEntries || !item.pleatPleatAdditionalEntries.length") Brak
              p(v-for="(entry, indexProtection) in item.pleatPleatAdditionalEntries") {{ prop(entry, "name", "pleatAdditional") }}
            td {{ item.shortDescription }}
      .columns.is-multiline
        .column(v-if="getVersions(object).length > 0").is-narrow
          table.table.is-bordered.has-margin-bottom
            thead
              tr
                th Wersja
                th Ilość
            tbody
              tr(v-for="item in getVersions(object)")
                td {{ item.pleatName }}
                td {{ item.quantity }}

        .column(v-if="getGlobalAccessories(object).length > 0").is-narrow
          table.table.is-bordered.has-margin-bottom
            thead
              tr
                th Belka / kolor
                th Ilość
                th Mb
            tbody
              tr(v-for="item in getGlobalAccessories(object)")
                td {{ item.name }} {{ item.xl ? "XL" : "" }}
                td {{ item.quantity }}
                td {{ getDecimal(item.runningMetre / 10) }}

        .column(v-if="surfacePerFabricAndFabricColor(object).length > 0").is-narrow.is-3
          table.table.is-bordered.has-margin-bottom
            thead
              tr
                th Tkanina | Kolor
                th m2
            tbody
              tr(v-for="item in surfacePerFabricAndFabricColor(object)")
                td {{ `${item.fabricName} | ${item.fabricColorName}` }}
                td {{ getDecimal(item.surface /10000) }}
</template>

<script>
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import decimal from "../../../helpers/decimal";

export default {
  name: "PleatOrderItemPreviewTemplate",
  props: ['object'],
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
    getBalkWidth(item) {
      return item.width - item.pleat.balkWidth;
    },
    getTannersQuantity(item, pleatFabric) {
      let tannerCorrections = item.pleat.pleatTannerCorrections;

      tannerCorrections.sort((a, b) => {
        let aMargin = a.heightMargin;
        let bMargin = b.heightMargin;
        return aMargin - bMargin;
      });

      let foundNearestCorrection = null;

      // find nearest from heightMargin to height
      foundNearestCorrection = tannerCorrections.reduce((closest, currentCorrection) => (
          Math.abs(currentCorrection.heightMargin - item.height) < Math.abs(closest.heightMargin - item.height) ? currentCorrection : closest
      ));

      let tanner = null;

      if (foundNearestCorrection) {
          let tannersQuantity = item.height / (pleatFabric.tannerHeight/10000);
          tannersQuantity += foundNearestCorrection.tannerQuantityCorrection;
          tannersQuantity += pleatFabric.tannerCorrection;
          tannersQuantity = Math.ceil(tannersQuantity);
          tanner = tannersQuantity;
      }

      return tanner
    },
    getPleatWireLength(item) {
      let wireLengths = item.pleat.pleatWireLengths;

      wireLengths.sort((a, b) => {
        let aMargin = a.pleatWidthMargin;
        let bMargin = b.pleatWidthMargin;
        return aMargin - bMargin;
      });

      const foundNearestWireLength = wireLengths.reduce((closest, currentWire) => (
          Math.abs(currentWire.pleatWidthMargin - item.width) < Math.abs(closest.pleatWidthMargin - item.width) ? currentWire : closest
      ));

      let wireLength = 0;
      if (foundNearestWireLength) {
        wireLength =
            (foundNearestWireLength.pleatWidthRatio * item.width) +
            (foundNearestWireLength.pleatHeightRatio * item.height) +
            foundNearestWireLength.constantValue;
        wireLength = Math.round(wireLength * 100) / 100;
      }

      return wireLength;
    },
    getVersions(order) {
      const items = this.orderItems(order);
      let versions = [];

      for (let i = 0; i < items.length; i++) {
        const existingVersion = versions.find(v => v.pleatName === items[i].pleat.name);

        if (existingVersion) {
          existingVersion.quantity += items[i].quantity;
        } else {
          versions.push({
            pleatName: items[i].pleat.name,
            quantity: items[i].quantity
          });
        }
      }

      return versions;
    },

    getGlobalAccessories(order) {
      const items = this.orderItems(order);
      let globalAccessories = [];

      for (let i = 0; i < items.length; i++) {
        const pleatAccessory = items[i].pleatGlobalAccessoryEntry.globalAccessory
        const isXL = items[i].width >= items[i].pleat.largePleatMargin
        const existingVersion = globalAccessories.find(v => v.name === pleatAccessory.name && v.xl === isXL);

        if (existingVersion) {
          existingVersion.quantity += items[i].quantity;
          existingVersion.runningMetre += items[i].width * items[i].quantity
        } else {
          globalAccessories.push({
            name: pleatAccessory.name,
            xl: isXL,
            quantity: items[i].quantity,
            runningMetre: items[i].width * items[i].quantity
          });
        }
      }

      return globalAccessories;
    },
    surfacePerFabricAndFabricColor(order) {
      const items = this.orderItems(order);
      const surfaces = [];

      for (const item of items) {
        const {
          pleatFabric,
          pleatFabricColor,
          pleatFabricBottom,
          pleatFabricColorBottom,
          pleat,
          width,
          height,
          quantity
        } = item;

        if (pleatFabric && pleatFabricColor && pleat) {
          const addSurface = (fabric, fabricColor) => {
            const existingSurface = surfaces.find(surface =>
                surface.fabricName === fabric.name && surface.fabricColorName === fabricColor.name
            );

            if (existingSurface) {
              existingSurface.surface += width * height * quantity;
            } else {
              surfaces.push({
                fabricName: fabric.name,
                fabricColorName: fabricColor.name,
                surface: width * height * quantity
              });
            }
          };

          addSurface(pleatFabric, pleatFabricColor);

          // Check and add surface for pleatFabricBottom and pleatFabricColorBottom
          if (pleatFabricBottom && pleatFabricColorBottom) {
            addSurface(pleatFabricBottom, pleatFabricColorBottom);
          }
        }
      }

      return surfaces;
    }


  }
}
</script>
