import DownloadSubsectionForm from "@/modules/other/components/DownloadSubsectionForm";
import fetchDownloadSection from "@/modules/other/helpers/fetchDownloadSection";

export default {
  list: {
    permissions: ["download_subsection_read"],
    actions: [
      {
        label: "Nowe podsekcja",
        type: "is-primary",
        route: ({ route }) => ({
          name: "download_subsections_create",
          params: {
            downloadSectionId: route.params.downloadSectionId
          }
        }),
        permissions: ["download_subsection_create"]
      }
    ],
    route: {
      path: "/download_subsections/:downloadSectionId/list",
      name: "download_subsections_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne"
      },
      {
        label: ({ compData }) =>
          `Sekcje dodatkowe dla sekcji "${compData.downloadSection.name}"`
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Nazwa",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Pliki",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "download_files_list",
            params: { downloadSubsectionId: row.id }
          })
        }
      }
    ],
    resource: "download_subsections",
    url: ({ route }) =>
      `/download_sections/${route.params.downloadSectionId}/download_subsections?properties[]=id&properties[]=name&properties[]=isEnabled&properties[]=position`,
    onCreated: fetchDownloadSection,
    rowActions: [
      {
        action: "edit",
        permissions: ["download_subsection_update"]
      },
      {
        action: "remove",
        permissions: ["download_subsection_delete"]
      }
    ]
  },
  create: {
    permissions: ["download_subsection_create"],
    route: {
      path: "/download_subsections/:downloadSectionId/create",
      name: "download_subsections_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne"
      },
      {
        route: ({ compData }) => ({
          name: "download_subsections_list",
          params: { downloadSectionId: compData.downloadSection.id }
        }),
        label: ({ compData }) =>
          `Podsekcje sekcji "${compData.downloadSection.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowa podsekcja dla sekcji "${compData.downloadSection.name}"`
      }
    ],
    formComponent: DownloadSubsectionForm,
    onCreated: fetchDownloadSection,
    listRoute: ({ compData }) => ({
      name: "download_subsections_list",
      params: { downloadSectionId: compData.downloadSection.id }
    }),
    updateRoute: ({ object }) => ({
      name: "download_subsections_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["download_subsection_update"],
    route: {
      path: "/download_subsections/:id/update",
      name: "download_subsections_update"
    },
    resource: "download_subsections",
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne"
      },
      {
        route: function({ compData }) {
          return {
            name: "download_subsections_list",
            params: { downloadSectionId: compData.object.downloadSection.id }
          };
        },
        label: ({ compData }) =>
          `Podsekcje sekcji "${compData.object.downloadSection.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja podsekcji "${compData.object.name}" dla sekcji "${compData.object.downloadSection.name}"`
      }
    ],
    formComponent: DownloadSubsectionForm,
    onCreated: fetchDownloadSection,
    listRoute: ({ compData }) => ({
      name: "download_subsections_list",
      params: {
        downloadSectionId: compData.object.downloadSection.id
      }
    })
  }
};
