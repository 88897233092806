import moment from 'moment';
import OrderStatusToText from "./orderStatusToText";

export default function getStatusInfo(row) {
    const company = row.subCompany;

    let status;

    if (company && company?.hasShipmentToSubCompany) {
        //data odebrania, ustawiana po zatwierdzeniu w panelu logistyka
        if (row.shipmentDate && row.deliveryMethod) {
            status = row.deliveryMethod.name + " " + moment(row.shipmentDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")
        }
        //zaplanowana data odbioru, wyliczana na podstawie dni odbioru danej firmy
        else if (row.receptionDate) {
            status = 'Zaplanowano: ' + moment(row.receptionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
        }
        //zaplanowana data odbioru, wyliczana na podstawie dni odbioru danej firmy
        else if (row.productionDate) {
            if (company.receptionDays === null || company.receptionDays.length === 0) {
                status = moment(row.productionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY") + "Brak ustawionych dni odbioru!";
            } else { // Preferowane dni odbioru
                const preferredDaysOfWeek = new Set(company.receptionDays);
                const productionDate = moment(row.productionDate, "DD-MM-YYYY hh:mm:ss")
                let receptionDate = productionDate.clone().add(1, 'd'); // dodanie jednego dnia, ponieważ musi być to już kolejny dzień tygodnia

                // Szukanie najbliższego preferowanego dnia odbioru
                while (!preferredDaysOfWeek.has(receptionDate.format('ddd').toLowerCase())) {
                    receptionDate = receptionDate.add(1, 'd');
                }

                status =  "Zaplanowano na: " + moment(receptionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
            }
        } else {
            status = OrderStatusToText(row.status);
        }
    } else {
        if (row.productionStatus === "end") {
            status =  "Zakończono"
        } else {
            status = OrderStatusToText(row.status);
        }

    }

    if (row.orderInvoice) {
        status += '\n (Zafakturowano)';
    }

    return status || '';
}

