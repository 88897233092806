<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="retail_price_lists" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Typ produktu" field="productType" :errors="errors")
                                resource-select(
                                    v-model="data.productType"
                                    resource="product_types"
                                    url="product_types"
                                    placeholder="Typ produktu"
                                )

                            app-form-field(label="Marża detaliczna [%]" field="percentMargin" :errors="errors")
                                app-form-decimal(v-model="data.percentMargin")

                            app-form-field(label="Marża kwotowa detaliczna [pln]" field="amountMargin" :errors="errors")
                                app-form-decimal(v-model="data.amountMargin")

                            app-form-field(label="Rabat hurtowy [%]" field="percentDiscount" :errors="errors")
                                app-form-decimal(v-model="data.percentDiscount")

                            app-form-field(label="Rabat kwotowy hurtowy [pln]" field="amountDiscount" :errors="errors")
                                app-form-decimal(v-model="data.amountDiscount")
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";

export default {
  components: { ResourceSelect },
  mixins: [form],
  data() {
    return {
      model: {
        productType: null,
        percentMargin: 0,
        amountMargin: 0,
        percentDiscount: 0,
        amountDiscount: 0
      }
    };
  },
  methods: {
    transformToModel(data) {
      this.model.productType = data.productType["@id"];

      return data;
    }
  }
};
</script>
