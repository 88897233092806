import DownloadSectionForm from "@/modules/other/components/DownloadSectionForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["download_section_read"],
    actions: [
      {
        label: "Nowa sekcja główna",
        type: "is-primary",
        route: () => ({
          name: "download_sections_create"
        }),
        permissions: ["download_section_create"]
      }
    ],
    route: {
      path: "/download_sections/list",
      name: "download_sections_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Sekcje główne pobierania"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Podsekcje",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "download_subsections_list",
            params: { downloadSectionId: row.id }
          })
        }
      }
    ],
    resource: "download_sections",
    url: () =>
      `/download_sections?properties[]=id&properties[]=name&properties[]=isEnabled&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["download_section_update"]
      },
      {
        action: "remove",
        permissions: ["download_section_delete"]
      }
    ]
  },
  create: {
    permissions: ["download_section_create"],
    route: {
      path: "/download_sections/create",
      name: "download_sections_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne pobierania"
      },
      {
        label: () => `Nowa sekcja główna`
      }
    ],
    formComponent: DownloadSectionForm,
    listRoute: () => ({ name: "download_sections_list" }),
    updateRoute: ({ object }) => ({
      name: "download_sections_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["download_section_update"],
    route: {
      path: "/download_sections/:id/update",
      name: "download_sections_update"
    },
    resource: "download_sections",
    resourceProperties: generateProperties([], ["id", "name", "isEnabled"]),
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne pobierania"
      },
      {
        label: ({ compData }) =>
          `Edycja sekcji głównej "${compData.object.name}"`
      }
    ],
    formComponent: DownloadSectionForm,
    listRoute: () => ({ name: "download_sections_list" })
  }
};
