<template lang="pug">
  column
    app-view-breadcrumbs(:breadcrumbs="breadcrumbs" )
    div.box
      p.has-font-20 Nazwa skrócona: {{company.shortName ? company.shortName : '-'}}
      p.has-font-15 NIP: {{company.taxId ? company.taxId : '-' }}
    .panel
      .panel-heading Filtry
      .panel-block
        .columns
          .column
            b-field(label="Data od")
              DatePicker(v-model="beginFilterDate" type="day" value-type="format" format="YYYY-MM-DD" :clearable="false")
          .column
            b-field(label="Data do")
              DatePicker(v-model="endFilterDate" type="day" value-type="format" format="YYYY-MM-DD" :clearable="false")
          .column
            b-field(label="Pobierz dane")
              b-button(type="is-primary" @click="getMainStatisticsData") Filtruj

    table.table.is-bordered.is-fullwidth.is-narrow
      tbody
        template()
          tr
            th Nazwa produtku
            th Zamówień
            th Ilość sztuk
            th Kwota netto
          tr(v-for="(product, index) in mainStatisticsData")
            td {{productTypeToName(product.category) }}
            td {{product.orderCount }}
            td {{product.itemQuantity }}
            td {{ formatPriceValue(getDecimal(product.netTotal)) }}


</template>
<script>

import http from "../../../http";
import productTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import priceFormatter from "../../../helpers/priceFormatter";
import decimal from "../../../helpers/decimal";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import router from "../../../router";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      loading: false,
      mainStatisticsData: [],
      company: this.$route.params.company,
      beginFilterDate: moment().format("YYYY-MM-DD"),
      endFilterDate: moment()
          .add(5, "days")
          .format("YYYY-MM-DD")
      , breadcrumbs: [
        {
          label: () => "Wszyscy kontrahenci",
          route: () => ({name: "statistics_list"}),
        },
        {
          label: () => this.company ? this.company.shortName : '-'
        }
      ],
    };
  },
  mounted() {
    if (!this.company) router.push({name: "statistics_list"});
    this.getMainStatisticsData()
  },
  methods: {
    async getMainStatisticsData() {
      const {data} = await http.get(`statistics/company/${this.$route.params.id}?from=${this.beginFilterDate}&to=${this.endFilterDate}`);
      this.mainStatisticsData = data;

    },
    productTypeToName(productType) {
      return productTypeFullNameByOrderCategory[productType]
    },
    getDecimal(value) {
      return decimal(value)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
  }
};
</script>
