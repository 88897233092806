import simpleClone from "./simpleClone";
import orderCategoryByProductTypeCode from "./orderCategoryByProductTypeCode";

export default function orderItems(order) {

    switch (order.category) {
        case orderCategoryByProductTypeCode.RTK:
            return simpleClone(order.fabricShadeOrderItems);
        case orderCategoryByProductTypeCode.RDN:
            return simpleClone(order.fabricShadeDayNightOrderItems);
        case orderCategoryByProductTypeCode.RZW:
            return simpleClone(order.externalShutterOrderItems);
        case orderCategoryByProductTypeCode.PLI:
            return simpleClone(order.pleatOrderItems);
        case orderCategoryByProductTypeCode.AUT:
            return simpleClone(order.automationOrderItems);
        case orderCategoryByProductTypeCode.DET:
            return simpleClone(order.detailOrderItems);
        case orderCategoryByProductTypeCode.MSQ:
            return simpleClone(order.frameMosquitoNetOrderItems);
        case orderCategoryByProductTypeCode.MDR:
            return simpleClone(order.doorMosquitoNetOrderItems);
        case orderCategoryByProductTypeCode.MPL:
            return simpleClone(order.pleatMosquitoNetOrderItems);
        case orderCategoryByProductTypeCode.MZW:
            return simpleClone(order.rollMosquitoNetOrderItems);
        case orderCategoryByProductTypeCode.MPR:
            return simpleClone(order.slideMosquitoNetOrderItems);
        default:
            return [];
    }
}