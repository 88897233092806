<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_external_shutter_protection_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 W danej wersji to samo zabezpieczenie nie może występować więcej niż 1 raz
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Zabezpieczenia" field="externalShutterProtection" :errors="errors")
                resource-select(
                  v-model="data.externalShutterProtection"
                  resource="external_shutter_protections"
                  url="external_shutter_protections"
                  placeholder="Wybierz zabezpieczenie"
                  label="name"
                )

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  data() {
    return {
      externalShutterId: this.$route.params.externalShutterId,
      downloadedExternalShutterId: null
    };
  },
  computed: {
    model() {
      return {
        isEnabled: false,
        position: 0,
        surfaceSurcharge: 0,
        externalShutterProtection: null,
        externalShutter: `/external_shutters/${this.$route.params.externalShutterId}`
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.externalShutter) {
        data.externalShutter = data.externalShutter["@id"] || data.externalShutter;
      }
      if (data.externalShutterProtection) {
        data.externalShutterProtection = data.externalShutterProtection["@id"] || data.externalShutterProtection;
      }
      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.externalShutterId) {
      this.externalShutterId = this.$route.params.externalShutterId;
    } else if (this.$route.params.id) {
      const { data } = await http.get(
        `/external_shutter_external_shutter_runner_entries/${this.$route.params.id}?properties[externalShutter][]=id`
      );
      if (data.externalShutter) {
        this.downloadedExternalShutterId = data.externalShutter.id;
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
