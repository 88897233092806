<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="frame_mosquito_net_additional_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.heightSurcharge")

                            app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.widthSurcharge")
                                
                            app-form-field(label="Dopłata za sztukę" field="pieceSurcharge" :errors="errors")
                              app-form-decimal(v-model="data.pieceSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Dodatek" field="frameMosquitoNetAdditional" :errors="errors")
                                resource-select(
                                    v-model="data.frameMosquitoNetAdditional"
                                    resource="frame_mosquito_net_additionals"
                                    url="frame_mosquito_net_additionals"
                                    placeholder="Wybierz dodatek"
                                    extra-props="&order[name]=asc"
                                    :labels="['name']"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        pieceSurcharge: 0,
        isEnabled: true,
        frameMosquitoNet: `/frame_mosquito_nets/${this.$route.params.frameMosquitoNetId}`,
        frameMosquitoNetAdditional: null,
      },
      frameMosquitoNetId: this.$route.params.frameMosquitoNetId,
      downloadedFrameMosquitoNetId: null
    };
  },
  computed: {
    getFrameMosquitoNetId() {
      if (!isNaN(this.frameMosquitoNetId) && this.frameMosquitoNetId) {
        return this.frameMosquitoNetId;
      }
      return this.downloadedFrameMosquitoNetId;
    }
  },
  methods: {
    transformToModel(data) {
      if (data.frameMosquitoNet && data.frameMosquitoNet["@id"]) {
        data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      }
      if (data.frameMosquitoNetAdditional && data.frameMosquitoNetAdditional["@id"]) {
        data.frameMosquitoNetAdditional = data.frameMosquitoNetAdditional["@id"];
      }

      return data;
    },
    transformToSubmit(data) {
      if (data.frameMosquitoNet && data.frameMosquitoNet["@id"]) {
        data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      }
      if (data.frameMosquitoNetAdditional && data.frameMosquitoNetAdditional["@id"]) {
        data.frameMosquitoNetAdditional = data.frameMosquitoNetAdditional["@id"];
      }

      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.id) {
      const { data } = await http.get(
        `/frame_mosquito_net_additional_entries/${this.$route.params.id}?properties[frameMosquitoNet][]=id`
      );
      if (data.frameMosquitoNet) {
        this.downloadedFrameMosquitoNetId = data.frameMosquitoNet.id;
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
