import api from "./../api";

export function uploadMedia(value,key, formData, multiple) {
    let promises = [];

        if (multiple) {
            formData[key] = []
            if (value instanceof Array) {
                value.forEach((it) => {
                    promises.push(
                        new Promise(function (resolve, reject) {
                            api.postMediaObject(it).then(function ({data}) {
                                formData[key].push(data["@id"])
                                resolve(formData);
                            }, reject);
                        })
                    );
                })
            }
        } else {
            if (value instanceof File) {
                promises.push(
                    new Promise(function (resolve, reject) {
                        api.postMediaObject(value).then(function ({data}) {
                            formData[key] = data["@id"];
                            resolve(formData);
                        }, reject);
                    })
                );
            }
        }
        return promises;
}
