// table
import AppTable from "./table/components/AppTable";
import AppTableStore from "./table/components/AppTableStore";
import AppTableHead from "./table/components/AppTableHead";
import AppTableFilterText from "./table/components/AppTableFilterText";
import AppTableFilterBoolean from "./table/components/AppTableFilterBoolean";
import AppTableFilterSelect from "./table/components/AppTableFilterSelect";
import AppTableFilterExists from "./table/components/AppTableFilterExists";
import AppTableFilterDate from "./table/components/AppTableFilterDate";

// form
import AppForm from "./form/components/AppForm";
import AppFormField from "./form/components/AppFormField";
import AppFormFile from "./form/components/AppFormFile";
import AppFormDecimal from "./form/components/AppFormDecimal";
import AppFormLength from "./form/components/AppFormLength";
import AppFormDecimalInteger from "./form/components/AppFormDecimalInteger";
import AppFormDatePicker from "./form/components/AppFormDatePicker";
import AppFormEditor from "./form/components/AppFormEditor";
import AppFormTimeInteger from "./form/components/AppFormTimeInteger";

// button
import AppButtonRemove from "./button/components/AppButtonRemove";
import AppButtonEdit from "./button/components/AppButtonEdit";
import AppButtonToggle from "./button/components/AppButtonToggle";

// view
import AppViewCreate from "./view/components/AppViewCreate";
import AppViewUpdate from "./view/components/AppViewUpdate";
import AppViewList from "./view/components/AppViewList";
import AppViewLoadable from "./view/components/AppViewLoadable";
import AppViewBreadcrumbs from "./view/components/AppViewBreadcrumbs";
import AppViewShow from "./view/components/AppViewShow";
import AppViewCard from "./view/components/AppViewCard";

// layout
import AppCard from "./layout/components/AppCard";
import AppPanel from "./layout/components/AppPanel";
import AppPanelBlock from "./layout/components/AppPanelBlock";
import AppFormSurface from "./form/components/AppFormSurface";
import AppFormWeight from "./form/components/AppFormWeight";
import AppButtonToggleStatus from "./button/components/AppButtonToggleStatus";
import AppFormFiles from "./form/components/AppFormFiles";
import AppButtonCustom from "./button/components/AppButtonCustom";

export default {
  install(Vue) {
    // table
    Vue.component("app-table", AppTable);
    Vue.component("app-table-store", AppTableStore);
    Vue.component("app-table-head", AppTableHead);
    Vue.component("app-table-filter-text", AppTableFilterText);
    Vue.component("app-table-filter-boolean", AppTableFilterBoolean);
    Vue.component("app-table-filter-select", AppTableFilterSelect);
    Vue.component("app-table-filter-exists", AppTableFilterExists);
    Vue.component("app-table-filter-date", AppTableFilterDate);
    // form
    Vue.component("app-form", AppForm);
    Vue.component("app-form-field", AppFormField);
    Vue.component("app-form-file", AppFormFile);
    Vue.component("app-form-files", AppFormFiles);
    Vue.component("app-form-decimal", AppFormDecimal);
    Vue.component("app-form-decimal-integer", AppFormDecimalInteger);
    Vue.component("app-form-length", AppFormLength);
    Vue.component("app-form-datepicker", AppFormDatePicker);
    Vue.component("app-form-editor", AppFormEditor);
    Vue.component("app-form-time-integer", AppFormTimeInteger);
    Vue.component("app-form-surface", AppFormSurface);
    Vue.component("app-form-weight", AppFormWeight);
    // button
    Vue.component("app-button-remove", AppButtonRemove);
    Vue.component("app-button-edit", AppButtonEdit);
    Vue.component("app-button-toggle", AppButtonToggle);
    Vue.component("app-button-toggle-status", AppButtonToggleStatus);
    Vue.component("app-button-custom", AppButtonCustom);
    // view
    Vue.component("app-view-create", AppViewCreate);
    Vue.component("app-view-update", AppViewUpdate);
    Vue.component("app-view-list", AppViewList);
    Vue.component("app-view-show", AppViewShow);
    Vue.component("app-view-loadable", AppViewLoadable);
    Vue.component("app-view-breadcrumbs", AppViewBreadcrumbs);
    Vue.component("app-view-card", AppViewCard);
    // layout
    Vue.component("app-card", AppCard);
    Vue.component("app-panel", AppPanel);
    Vue.component("app-panel-block", AppPanelBlock);
  }
};
