<template lang="pug">
  .column(:class="{ 'dragging-disabled' : isEnded}").is-one-third.draggable-li(:style="{backgroundColor: isEnded ? 'gray' : '#99C68E'}")
    .columns.is-multiline
      .column.is-1.has-pointer
        .columns.draggable-col
          .column.has-text-weight-bold.has-low-padding-bottom
          .column.has-no-padding-left
      .column.is-12.has-no-padding-top
        .columns.draggable-col-2.has-font-8
          .column.is-three-fifths.has-no-padding-top.has-no-padding-bottom {{ companyShortName }}
          .column.has-no-padding-top.has-no-padding-bottom
            b-button(@click="editBooking").has-no-border.has-transparent-background.has-no-padding-left
              b-icon(icon="pencil" size="is-small")
</template>

<script>

export default {
  props: {
    booking: {type: Object, required: true}
  },
  methods: {
    editBooking() {
      this.$emit("edit", this.booking);
    }
  },
  computed: {
    companyShortName() {
      if(typeof this.booking === "object") {
        /**
         * Jakim ****** kod w warunku działa, a ten nie:
         * Object.prototype.hasOwnProperty.call(this.booking, "company") &&
         * Object.prototype.hasOwnProperty.call(this.booking.company, "shortName")
         * Rzuca jakimś błędem, że nie można wołać objektu na nullu lub undefined (patrz **** warunek wyżej, żeby nie było za kolorowo)
         * A w analogicznym przypadku w innym miejscu przechodzi Object.prototype.... a warunek jak niżej już nie %^#^&!@$#&!^@!!!!!!oneoneone
         * Fuck TS/JS
         */
        if(
          this.booking.company &&
          this.booking.company.shortName
          ) {
            return this.booking.company.shortName.substring(0, 7);
        }
      }

      return 'Brak'
    },
    isEnded() {
      if (this.booking && this.booking.productionStatus === 'end') {
        return true;
      }
      return false;
    },
  }
};
</script>

<style scoped>

</style>
