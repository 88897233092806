import crud from "../../crud";

import externalShutterArmorColors from "./crud/externalShutterArmorColors";
import externalShutters from "./crud/externalShutters";
import externalShutterDrillHoles from "./crud/externalShutterDrillHoles";
import externalShutterDriveExits from "./crud/externalShutterDriveExits";
import externalShutterArmors from "./crud/externalShutterArmors";
import externalShutterArmorExternalShutterArmorColorEntries from "./crud/externalShutterArmorExternalShutterArmorColorEntries";
import externalShutterArmorFabricShaderColorEntries from "./crud/externalShutterArmorFabricShaderColorEntries";
import externalShutterRunners from "./crud/externalShutterRunners";
import externalShutterProtections from "./crud/externalShutterProtections";
import externalShutterGlobalPipes from "./crud/externalShutterGlobalPipes";
import externalShutterPriceLists from "./crud/externalShutterPriceLists";
import externalShutterExternalShutterRunnerEntries from "./crud/externalShutterExternalShutterRunnerEntries";
import externalShutterRunnerFabricShaderColorEntries from "./crud/externalShutterRunnerFabricShaderColorEntries";
import externalShutterExternalShutterProtectionEntries from "./crud/externalShutterExternalShutterProtectionEntries";
import externalShutterBoxes from "./crud/externalShutterBoxes";
import externalShutterBoxExternalFabricShadeColorEntries from "./crud/externalShutterBoxExternalFabricShadeColorEntries";
import externalShutterBoxInternalFabricShadeColorEntries from "./crud/externalShutterBoxInternalFabricShadeColorEntries";
import externalShutterAdaptations from "./crud/externalShutterAdaptations";
import externalShutterGlobalDriveEntries from "./crud/externalShutterGlobalDriveEntries";

crud(externalShutters);
crud(externalShutterDrillHoles);
crud(externalShutterArmorColors);
crud(externalShutterDriveExits);
crud(externalShutterArmors);
crud(externalShutterArmorExternalShutterArmorColorEntries);
crud(externalShutterArmorFabricShaderColorEntries);
crud(externalShutterRunners);
crud(externalShutterProtections);
crud(externalShutterGlobalPipes);
crud(externalShutterPriceLists);
crud(externalShutterExternalShutterRunnerEntries);
crud(externalShutterRunnerFabricShaderColorEntries);
crud(externalShutterExternalShutterProtectionEntries);
crud(externalShutterBoxes);
crud(externalShutterBoxExternalFabricShadeColorEntries);
crud(externalShutterBoxInternalFabricShadeColorEntries);
crud(externalShutterAdaptations);
crud(externalShutterGlobalDriveEntries);
