import ExternalShutterProtectionForm from "../components/ExternalShutterProtectionForm";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_protection_read"],
    actions: [
      {
        label: "Nowe zabezpieczenie",
        type: "is-primary",
        route: () => ({
          name: "external_shutter_protections_create"
        }),
        permissions: ["external_shutter_protection_create"]
      }
    ],
    route: {
      path: "/external_shutter_protections/list",
      name: "external_shutter_protections_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Zabezpieczenia"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata do sztuki",
        format: decimal
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata pow. 1mb",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_protections",
    url: () =>
      `/external_shutter_protections?` +
      generateProperties(
        [],
        [
          "id",
          "name",
          "position",
          "isEnabled",
          "lengthSurcharge",
          "pieceSurcharge"
        ]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_protection_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_protection_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_protection_create"],
    route: {
      path: "/external_shutter_protections/create",
      name: "external_shutter_protections_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_protections_list" }),
        label: () => "Zabezpieczenia"
      },
      {
        label: () => `Nowe zabezpieczenie`
      }
    ],
    formComponent: ExternalShutterProtectionForm,
    listRoute: () => ({ name: "external_shutter_protections_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_protections_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_protection_update"],
    route: {
      path: "/external_shutter_protections/:id/update",
      name: "external_shutter_protections_update"
    },
    resource: "external_shutter_protections",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "position",
        "isEnabled",
        "lengthSurcharge",
        "pieceSurcharge",
        "description",
        "productionTime",
        "picture",
        "externalShutterProtectionConflicts"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_protections_list" }),
        label: () => "Zabezpieczenia"
      },
      {
        label: ({ compData }) => `Edycja zabezpieczenia "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterProtectionForm,
    listRoute: () => ({ name: "external_shutter_protections_list" })
  }
};
