import moment from "moment";
import priceFormatter from "../../../helpers/priceFormatter";
import decimal from "@/helpers/decimal";
import http from "../../../http";
import refreshStatisticsList from "../helpers/refreshStatisticsList";

export default {
    list: {
        permissions: ["statistics_read"],
        sortField: "shortName",
        sortOrder: "asc",
        narrow: true,
        actions: [
            {
                label: "Odśwież",
                type: "is-info",
                route: () => ({
                    name: "statistics_refresh"
                }),
                permissions: ["statistics_refresh"]
            },
        ],
        route: {
            path: "/statistics/list",
            name: "statistics_list"
        },
        breadcrumbs: [
            {
                label: () => "Statystyki menu",
                route: () => ({name: "statistics_menu"}),
            },
            {
                label: () => "Wszyscy kontrahenci"
            }
        ],
        fields: [
            {
                field: "shortName",
                type: "text",
                label: "Nazwa",
                filter: "text",
            },
            {
                field: "parentCompanyShortName",
                type: "text",
                label: "Pododbiorca dla",
            },
            {
                field: "firstLoginDate",
                type: "text",
                label: "Data pierwszego logowania",
                format: function (value, row) {
                    if (!value) return '-'
                    return moment(value, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY hh:mm");
                }
            },
            {
                field: "lastLoginDate",
                type: "text",
                label: "Data ostatniego logowania",
                format: function (value, row) {
                    if (!value) return '-'
                    return moment(value, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY hh:mm");
                }
            },
            {
                field: "lastValuationDate",
                type: "text",
                label: "Data ostatniej wyceny",
                format: function (value, row) {
                    if (!value) return '-'
                    return moment(value, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
                }
            },
            {
                field: "lastOrderDate",
                type: "text",
                label: "Data ostatniego zamówienia",
                format: function (value, row) {
                    if (!value) return '-'
                    return moment(value, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
                }
            },
            {
                field: "company",
                type: "virtual",
                label: "Wyceny",
                value: (h, row) => (row.company ? row.company.valuationCount : row.subCompany ? row.subCompany.valuationCount : '-')
            },
            {
                field: "company",
                type: "virtual",
                label: `Wyceny ${moment().format('Y')}`,
                value: (h, row) => (row.company ? row.company.valuationCountThisYear : row.subCompany ? row.subCompany.valuationCountThisYear : '-')
            },
            {
                field: "company",
                type: "virtual",
                label: `Wyceny ${moment().format('Y') - 1}`,
                value: (h, row) => (row.company ? row.company.valuationCountLastYear : row.subCompany ? row.subCompany.valuationCountLastYear : '-')
            },
            {
                field: "company",
                type: "virtual",
                label: "Zamówienia",
                value: (h, row) => (row.company ? row.company.orderCount : row.subCompany ? row.subCompany.orderCount : '-')
            },

            {
                field: "company",
                type: "virtual",
                label: `Zamówienia ${moment().format('Y')}`,
                value: (h, row) => (row.company ? row.company.orderCountThisYear : row.subCompany ? row.subCompany.orderCountThisYear : '-')
            },

            {
                field: "company",
                type: "virtual",
                label: `Zamówienia ${moment().format('Y') - 1}`,
                value: (h, row) => (row.company ? row.company.orderCountLastYear : row.subCompany ? row.subCompany.orderCountLastYear : '-')
            },
            {
                field: "company",
                type: "virtual",
                label: `Obrót ${moment().format('Y')} (netto)`,
                value: (h, row) => (row.company ? priceFormatter(decimal(row.company.turnoverThisYear)) : row.subCompany ? priceFormatter(decimal(row.subCompany.turnoverThisYear)) : '-')
            },
            {
                field: "company",
                type: "virtual",
                label: `Obrót ${moment().format('Y') - 1} (netto)`,
                value: (h, row) => (row.company ? priceFormatter(decimal(row.company.turnoverLastYear)) : row.subCompany ? priceFormatter(decimal(row.subCompany.turnoverLastYear)) : '-')
            },


            {
                field: "company.isEnabled",
                type: "virtual",
                label: "Aktywny",
                filter: "boolean",
                value: (h, row) => (row.company && row.company.isEnabled ? "Tak" : "Nie")
            },
            {
                type: "link",
                label: "Akcje",
                link: {
                    label: "Pokaż",
                    route: ({row}) => ({
                        name: "statistics_company",
                        params: {id: row.id, company: (row.company ? row.company: row.subCompany ? row.subCompany : {})}
                    })
                }
            },
        ],
        resource: "companies",
        onCreated: refreshStatisticsList,
        url: () =>
            `/statistics`,
        rowActions: [
            // {
            //   action: "edit",
            //   permissions: ["statistic_update"]
            // },
            // {
            //   action: "remove",
            //   permissions: ["statistic_delete"]
            // }
        ]
    },
    refresh: {
        permissions: ["statistics_refresh"],
        route: {
            path: "/statistics/refresh",
            name: "statistics_refresh"
        },
    }
};
