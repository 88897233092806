<template lang="pug">
  dynamic-update(v-bind="props")
</template>
<script>
import DynamicUpdate from "@/components/DynamicUpdate";
import ConfigurationForm from "@/modules/configuration/components/ConfigurationForm";

export default {
  components: { DynamicUpdate },
  computed: {
    props() {
      return {
        breadcrumbs: [],
        formComponent: ConfigurationForm,
        resource: "configurations",
        listRoute: () => ({}),
        actions: []
      };
    }
  }
};
</script>
