<template lang="pug" functional>
  b-field(:label="props.label")
    b-select(
      @input="props.filter({ field: `exists[${props.field}]`, value: $event })"
      :value="props.filters[props.field]"
    )
      option(:value="null") Wszystkie
      option(value="true") Posiada
      option(value="false") Nie posiada
</template>
<script>
import filterMixin from "../mixins/filterMixin";

export default {
  mixins: [filterMixin]
};
</script>
