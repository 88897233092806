import Vue from "vue";
import generateProperties from "../../../helpers/generateProperties";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "downloadSubsection", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `download_subsections/${route.params.downloadSubsectionId}?` +
        generateProperties([], ["id", "name"]) +
        generateProperties(["downloadSection"], ["id", "name"], true)
    );
    compData.downloadSubsection = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować podsekcji", "danger");
  }
};
