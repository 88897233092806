<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="sub_companies" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit" :custom-validation="validation")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-card(title="Dane firmy")
                app-form-field(label="NIP" field="taxId" :errors="errors")
                  b-field
                    b-input(v-model="data.taxId" @keydown.enter.prevent.native="fetchGus" expanded)
                    .control
                      b-button(type="is-primary" @click.prevent="fetchGus" :loading="gusFetching") Pobierz z GUS

                app-form-field(label="Nazwa" field="name" :errors="errors")
                  b-input(v-model="data.name")

                app-form-field(label="Nazwa skrócona" field="shortName" :errors="errors")
                  b-input(v-model="data.shortName")

                app-form-field(label="Fax" field="fax" :errors="errors")
                  b-input(v-model="data.fax")

                app-form-field(label="Kod pocztowy" field="zipCode" :errors="errors")
                  b-input(v-model="data.zipCode")

                app-form-field(label="Miejscowość" field="city" :errors="errors")
                  b-input(v-model="data.city")

                app-form-field(label="Ulica i numer" field="streetAndNumber" :errors="errors")
                  b-input(v-model="data.streetAndNumber")

                app-form-field(label="Telefon komórkowy" field="mobilePhone" :errors="errors")
                  b-input(v-model="data.mobilePhone")

                app-form-field(label="Telefon stacjonarny" field="phone" :errors="errors")
                  b-input(v-model="data.phone")

                app-form-field(label="Kraj" field="country" :errors="errors")
                  resource-select(
                    v-model="data.country"
                    resource="countries"
                    url="countries"
                    placeholder="Wybierz kraj"
                    :add-form="countryForm"
                    add-form-title="Dodawanie kraju"
                    @fetched="onFetchedCountries"
                  )
                app-form-field(label="Nazwa banku" field="bankName" :errors="errors")
                  b-input(v-model="data.bankName")

                app-form-field(label="Numer konta" field="bankAccountNumber" :errors="errors")
                  b-field
                    b-input(v-model="data.bankAccountNumber" @keydown.enter.prevent.native="checkBankAccount" expanded)
                    .control
                      b-button(type="is-primary" @click.prevent="checkBankAccount" :loading="bankAccountNumberChecking") Sprawdź

                app-form-field(label="E-mail" field="email" :errors="errors")
                  b-input(v-model="data.email")

                app-form-field(label="Strona www" field="website" :errors="errors")
                  b-input(v-model="data.website")

            .column
              app-card(title="Ustawienia")
                app-form-field(label="Jednostka miary" field="lengthUnit" :errors="errors")
                  b-select(v-model="data.lengthUnit")
                    option(v-for="({label, value}) in lengthUnits" :key="label" :value="value") {{ label }}

                app-form-field(label="Waluta" field="currency" :errors="errors")
                  resource-select(
                    v-model="data.currency"
                    resource="currencies"
                    url="currencies"
                    placeholder="Wybierz walutę"
                    label="code"
                    extra-props="&properties[]=code"
                  )

                app-form-field(label="Kurs waluty" field="currencyRate" :errors="errors")
                  b-input(v-model="data.currencyRate" @input="replaceCommaWithDotInCurrencyRate")

                app-form-field(label="Domyślna dostawa" field="defaultDeliveryMethod" :errors="errors")
                  resource-select(
                    v-model="data.defaultDeliveryMethod"
                    resource="delivery_methods"
                    url="delivery_methods"
                    placeholder="Wybierz domyślną dostawę"
                    :add-form="deliveryMethodForm"
                    add-form-title="Dodawanie metody dostawy"
                  )

                app-form-field(label="Sposoby płatności" field="paymentMethods" :errors="errors")
                  multiple-resource-select(
                    v-model="data.paymentMethods"
                    resource="payment_methods"
                    url="payment_methods"
                    placeholder="Wybierz sposoby płatności"
                    :add-form="paymentMethodForm"
                    add-form-title="Dodawanie metody płatności"
                    :disabled="paymentsMethodsAreDisabled"
                  )

                app-form-field(label="Dni odbioru" field="receptionDays" :errors="errors" expanded)
                  .columns.is-multiline.has-margin-top-5
                    .column.is-12.is-paddingless.has-margin-left-12(v-for="day in daysOfWeek")
                      b-checkbox(
                        :native-value="day.value"
                        v-model="data.receptionDays"
                      ) {{ day.field }}
                br
                br

                app-form-field(label="Stawka VAT zakupu" field="vatRate" :errors="errors")
                  resource-select(
                    v-model="data.vatRate"
                    resource="vat_rates"
                    url="vat_rates"
                    placeholder="Wybierz stawkę VAT"
                    :add-form="vatRateForm"
                    add-form-title="Dodawanie stawki VAT"
                  )

                app-form-field(field="isEnabled" :errors="errors")
                  b-checkbox(v-model="data.isEnabled") Aktywny

              app-card(title="Dane właściciela")
                app-form-field(label="Właściciel" field="owner" :errors="errors")
                  b-input(v-model="data.owner" type="text")

                app-form-field(label="Kontakt do właściciela" field="ownerPhone" :errors="errors")
                  b-input(v-model="data.ownerPhone")

        b-tab-item(label="Administrator")

          .columns
            .column
              app-card(title="Dane administratora")
                app-form-field(label="Imię" field="companyOwner.firstName" :errors="errors")
                  b-input(v-model="data.companyOwner.firstName")

                app-form-field(label="Nazwisko" field="companyOwner.lastName" :errors="errors")
                  b-input(v-model="data.companyOwner.lastName")

                app-form-field(label="Telefon" field="companyOwner.phone" :errors="errors")
                  b-input(v-model="data.companyOwner.phone")

                app-form-field(label="Telefon komórkowy" field="companyOwner.mobilePhone" :errors="errors")
                  b-input(v-model="data.companyOwner.mobilePhone")

            .column
              app-card(title="Dane użytkownika")
                app-form-field(field="companyOwner.isEnabled" :errors="errors")
                  b-checkbox(v-model="data.companyOwner.isEnabled") Aktywny

                app-form-field(label="Login" field="companyOwner.username" :errors="errors")
                  b-input(v-model="data.companyOwner.username" id="username" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off")

                app-form-field(label="E-mail" field="companyOwner.email" :errors="errors")
                  b-input(v-model="data.companyOwner.email")

                app-form-field(label="Hasło" field="companyOwner.password" :errors="errors")
                  b-input(v-model="data.companyOwner.password" id="password" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off" type="password")

        b-tab-item(label="Dostępne moduły / Typy produktów")

          app-card(title="Uprawnienia")
            app-form-field(label="Dostępne moduły" field="productTypes" :errors="errors")
              multiple-resource-select(
                v-model="data.productTypes"
                resource="product_types"
                url="product_types"
                placeholder="Wybierz dostępne moduły"
                @fetched="onFetchedProductTypes"
                :disabled="true"
              )
        b-tab-item(label="Ceny")
          b-tabs(type="is-boxed")
            b-tab-item(label="Marże i rodzaj cennika")
              table.table.is-fullwidth.is-bordered
                thead
                  th Typ produktu
                  th Marża procentowa
                tbody
                  tr(v-for="(margin, index) in productTypeMargins")
                    td {{ margin.productType }}
                    td
                      app-form-decimal(v-model="margin.margin" :precision="0" disabled)
            b-tab-item(label="Ustawienia cennika hurtowego")
              app-form-field( v-if="hasSuperAdminRole" label="Cennik hurtowy dla wszystkich" field="wholesale_price_list" :errors="errors")
                resource-select(
                  resource="wholesale_price_lists"
                  url="wholesale_price_lists"
                  placeholder="Wybierz cennik hurtowy"
                  @input="setAllPriceLists"
                  nullable
                  disabled
                )

              table.table.is-fullwidth.is-bordered
                thead
                  th Nazwa
                  th Cennik hurtowy
                tbody
                  tr(v-for="(entry, index) in model.companyPriceListEntries")
                    td {{ entry.productType.name }}
                    td
                      resource-select(
                        v-model="entry.wholesalePriceList"
                        resource="wholesale_price_lists"
                        url="wholesale_price_lists"
                        placeholder="Wybierz cennik hurtowy"
                        disabled
                      )
        b-tab-item(label="Regulamin")
          app-form-field(label="Treść regulaminu" field="regulationContent" :errors="errors")
            app-form-editor(v-model="data.regulationContent")
          p.has-font-20 Indiwidualny regulamin dla firmy, w przypadku braku uzupełnienia zostanie wykorzystany regulamin z Ustawienia->Konfiguracja .

        b-tab-item(label="Grafiki")
          app-form-field(label="Logo firmy" field="logo" :errors="errors")
            app-form-file(v-model="files.logo" :image="data.logo" @removed="data.logo = {}")
          p Potrzebna pomoc przy wstawieniu logo:
            b {{model.needSupportWithAddLogo ? 'Tak' : 'Nie'}}

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import CountryForm from "./CountryForm";
import PaymentMethodForm from "./PaymentMethodForm";
import DeliveryMethodForm from "./DeliveryMethodForm";
import VatRateForm from "./VatRateForm";
import http from "../../../http";
import {mapGetters} from "vuex";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      isReadOnly: true,
      wholesalePriceLists: [],
      productTypeMargins: [],
      productTypes: [],
      countries: [],
      gusFetching: false,
      bankAccountNumberChecking: false,
      countryForm: CountryForm,
      paymentMethodForm: PaymentMethodForm,
      deliveryMethodForm: DeliveryMethodForm,
      vatRateForm: VatRateForm,
      loading: false,
      files: {
        logo: {}
      },
      model: {
        name: "",
        shortName: "",
        taxId: "",
        isEnabled: true,
        owner: "",
        country: null,
        vatRate: null,
        defaultDeliveryMethod: null,
        paymentMethods: [],
        receptionDays: [],
        productTypes: [],
        companyOwner: {
          isEnabled: false,
          firstName: "",
          lastName: "",
          phone: "",
          mobilePhone: "",
          username: "",
          email: "",
          password: ""
        },
        ownerPhone: "",
        mobilePhone: "",
        phone: "",
        fax: "",
        zipCode: "",
        city: "",
        streetAndNumber: "",
        bankName: "",
        bankAccountNumber: "",
        email: "",
        website: "",
        companyPriceListEntries: [],
        currencyRate: "1.0000",
        currency: null,
        lengthUnit: "cm",
        logo: null,
        hasInvoicingByProducer: false,
        needSupportWithAddLogo: false,
        regulationContent: ""
      }
    };
  },
  computed: {
    lengthUnits() {
      return this.$store.getters.lengthUnits;
    },
    ...mapGetters("auth", ["hasSuperAdminRole"]),
    ...mapGetters(["daysOfWeek"]),
    paymentsMethodsAreDisabled() {
      return !this.model.hasInvoicingByProducer;
    }
  },
  methods: {
    replaceCommaWithDotInCurrencyRate() {
      this.model.currencyRate = this.model.currencyRate.replace(new RegExp(',', 'g'), '.');
    },
    transformToSubmit(data) {
      data.companyPriceListEntries.map(entry => {
        if (entry.productType) {
          entry.productType = entry.productType["@id"];
        }
        if (entry.wholesalePriceList && entry.wholesalePriceList["@id"]) {
          entry.wholesalePriceList = entry.wholesalePriceList["@id"];
        }
        return entry;
      });
      if (!data.companyOwner.password) {
        delete data.companyOwner.password;
      }
      if (data.defaultDeliveryMethod && data.defaultDeliveryMethod["@id"]) {
        data.defaultDeliveryMethod = data.defaultDeliveryMethod["@id"];
      }
      if (data.currency && data.currency["@id"]) {
        data.currency = data.currency["@id"];
      }
      delete data.hasInvoicingByProducer;
      return data;
    },
    transformToModel(object) {
      this.model.productTypes = object.productTypes.map(type => type["@id"]);

      return object;
    },
    // pobrano moduły - należy zbudować listę wpisów
    onFetchedProductTypes(data) {
      this.productTypes = data;

      // jeśli nie ma wpisu dla danego typu produktu to utwórz nowy
      this.productTypes.forEach(type => {
        let found = this.model.companyPriceListEntries.find(
            entry => entry.productType.id === type.id
        );
        if (!found) {
          this.model.companyPriceListEntries.push({
            productType: type,
            wholesalePriceList: null
          });
        }
      });
    },
    async fetchGus() {
      if (!this.model.taxId) return;
      this.gusFetching = true;
      let nip = this.clear(this.model.taxId);
      try {
        let {data} = await http.get(`/gus/nip/${nip}`);
        this.model.name = data.name;
        this.model.shortName = data.name;
        this.model.taxId = data.nip;
        this.model.owner = data.name;
        this.model.zipCode = data.zipCode;
        this.model.city = data.city;
        this.model.street = data.street;
        this.model.addressNumber = data.propertyNumber;
      } catch (e) {
        window.alert(
            "Wystąpił błąd podczas pobierania danych - nie działa serwis lub nie odnaleziono takiego podmiotu"
        );
      }
      this.gusFetching = false;
    },
    async checkBankAccount() {
      if (!this.model.taxId) {
        window.alert("Brak numeru NIP");
        return;
      }
      if (!this.model.bankAccountNumber) {
        window.alert("Brak numeru konta");
        return;
      }
      this.bankAccountNumberChecking = true;
      try {
        let nip = this.clear(this.model.taxId);
        let bankAccountNumber = this.clear(this.model.bankAccountNumber);
        let {data} = await http.get(
            `/check/bank/account/${nip}/${bankAccountNumber}`
        );
        if (data.result.accountAssigned === "TAK") {
          window.alert("Numer konta jest przypisany do tego podmiotu");
        }
        if (data.result.accountAssigned === "NIE") {
          window.alert("Numer konta NIE jest przypisany do tego podmiotu");
        }
      } catch (e) {
        window.alert(
            "Wystąpił błąd podczas sprawdzania numeru konta z białej listy podatników VAT"
        );
      }
      this.bankAccountNumberChecking = false;
    },
    clear(string) {
      let numb = string.match(/\d/g);
      return numb.join("");
    },
    onFetchedCountries(countries) {
      this.countries = countries;
      let poland = this.countries.find(country => country.name === "Polska");
      if (poland) {
        this.model.country = poland["@id"];
      }
    },
    setAllPriceLists(data) {
      this.model.companyPriceListEntries.forEach(entry => {
        entry.wholesalePriceList = data;
      });
    },
    validation(data, errors) {
      if (!data.shortName || data.shortName.split(" ").join("").length <= 0) {
        errors.push({
          propertyPath: "shortName",
          message: "Pole nie może być puste"
        });
      }
      if (!data.ownerPhone || data.ownerPhone.split(" ").join("").length <= 0) {
        errors.push({
          propertyPath: "ownerPhone",
          message: "Pole nie może być puste"
        });
      }
    }
  },
  async created() {
    //edycja
    if (this.$route.params.id) {
      let {data} = await this.$http.get("/sub_companies/" + this.$route.params.id + "/product_type_margins");
      this.productTypeMargins = data;
    }
  }
};
</script>
