import GlobalRemoteControlTypeForm from "@/modules/global/components/GlobalRemoteControlTypeForm";

export default {
  list: {
    permissions: ["global_remote_control_type_read"],
    actions: [
      {
        label: "Nowy typ pilotów",
        type: "is-primary",
        route: () => ({
          name: "global_remote_control_types_create"
        }),
        permissions: ["global_remote_control_type_create"]
      }
    ],
    route: {
      path: "/global_remote_control_types/list",
      name: "global_remote_control_types_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Typy pilotów"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" }
    ],
    resource: "global_remote_control_types",
    url: () =>
        `/global_remote_control_types`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_remote_control_type_update"]
      },
      {
        action: "remove",
        permissions: ["global_remote_control_type_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_remote_control_type_create"],
    route: {
      path: "/global_remote_control_types/create",
      name: "global_remote_control_types_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_remote_control_types_list" }),
        label: () => "Typy pilotów"
      },
      {
        label: () => `Nowy typ pilotów"`
      }
    ],
    formComponent: GlobalRemoteControlTypeForm,
    listRoute: () => ({
      name: "global_remote_control_types_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_remote_control_types_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_remote_control_type_update"],
    route: {
      path: "/global_remote_control_types/:id/update",
      name: "global_remote_control_types_update"
    },
    resource: "global_remote_control_types",
    breadcrumbs: [
      {
        route: () => ({ name: "global_remote_control_types_list" }),
        label: () => "Typy pilotów"
      },
      {
        label: ({ compData }) => `Edycja typu pilotów "${compData.object.name}"`
      }
    ],
    formComponent: GlobalRemoteControlTypeForm,
    listRoute: () => ({
      name: "global_remote_control_types_list"
    })
  }
};
