import AppErrorForm from "../componets/AppErrorForm"
import MemberShipForm from "../componets/memberShipForm";

export default {
    list: {
        permissions: ["member_ship"],
        pagination: true,
        actions:[{
            label: "Nowa rola w aplikacji",
            type: "is-primary",
            route: () => ({
                name: "member_ships_create"
            }),
            permissions: ["member_ships_create"]
        }],
        route: {
            path: "/member_ships",
            name: "member_ships_list"
        },
        sortable: false,
        breadcrumbs: [
            {
                route: () => ({name: "app_errors_list"}),
                label: () => "Role w aplikacji"
            }
        ],
        fields: [
            {
                field: "name",
                type: "text",
                label: "Nazwa",
                filter: "text"
            },
        ],
        resource: "member_ships",
        url: ({route}) =>
            `/member_ships`,
        rowActions: [
            {
                action: "edit",
                permissions: ["member_ship_update"]
            },
        ]
    },
    update: {
        permissions: ["member_ships_update"],
        route: {
            path: "/member_ships/:id/update",
            name: "member_ships_update"
        },
        resource: "member_ships",
        breadcrumbs: [
            {
                route: () => ({name: "member_ships_list"}),
                label: () => "Role aplikacji"
            },
            {
                label: ({compData}) =>
                    `Podgląd roli: "${compData.object.name}"`
            }
        ],
        formComponent: MemberShipForm,
        listRoute: ({compData}) => ({
            name: "member_ships_list"
        })
    },
    create: {
        permissions: ["member_ships_create"],
        route: {
            path: "/member_ships/:id/create",
            name: "member_ships_create"
        },
        resource: "member_ships",
        breadcrumbs: [
            {
                route: () => ({name: "member_ships_list"}),
                label: () => "Role aplikacji"
            },
            {
                label: ({compData}) =>
                    `Nowa rola`
            }
        ],
        formComponent: MemberShipForm,
        listRoute: ({compData}) => ({
            name: "member_ships_list"
        })
    }
};
