import GlobalRemoteControlChannelForm from "@/modules/global/components/GlobalRemoteControlChannelForm";

export default {
  list: {
    permissions: ["global_remote_control_channel_read"],
    actions: [
      {
        label: "Nowy kanał pilotów",
        type: "is-primary",
        route: () => ({
          name: "global_remote_control_channels_create"
        }),
        permissions: ["global_remote_control_channel_create"]
      }
    ],
    route: {
      path: "/global_remote_control_channels/list",
      name: "global_remote_control_channels_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Kanały pilotów"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" }
    ],
    resource: "global_remote_control_channels",
    url: () =>
        `/global_remote_control_channels`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_remote_control_channel_update"]
      },
      {
        action: "remove",
        permissions: ["global_remote_control_channel_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_remote_control_channel_create"],
    route: {
      path: "/global_remote_control_channels/create",
      name: "global_remote_control_channels_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_remote_control_channels_list" }),
        label: () => "Kanały pilotów"
      },
      {
        label: () => `Nowy kanał pilotów"`
      }
    ],
    formComponent: GlobalRemoteControlChannelForm,
    listRoute: () => ({
      name: "global_remote_control_channels_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_remote_control_channels_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_remote_control_channel_update"],
    route: {
      path: "/global_remote_control_channels/:id/update",
      name: "global_remote_control_channels_update"
    },
    resource: "global_remote_control_channels",
    breadcrumbs: [
      {
        route: () => ({ name: "global_remote_control_channels_list" }),
        label: () => "Kanały pilotów"
      },
      {
        label: ({ compData }) => `Edycja kanału pilotów "${compData.object.name}"`
      }
    ],
    formComponent: GlobalRemoteControlChannelForm,
    listRoute: () => ({
      name: "global_remote_control_channels_list"
    })
  }
};
