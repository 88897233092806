import PleatRemoteControlTypeForm from "../components/PleatRemoteControlTypeForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
    list: {
        permissions: ["pleat_remote_control_type_read"],
        actions: [
            {
                label: "Nowy typ sterowania",
                type: "is-primary",
                route: () => ({
                    name: "pleat_remote_control_types_create"
                }),
                permissions: ["pleat_remote_control_type_create"]
            }
        ],
        route: {
            path: "/pleat_remote_control_types/list",
            name: "pleat_remote_control_types_list"
        },
        sortable: false,
        breadcrumbs: [
            {
                label: () => "Typy sterowań plis"
            }
        ],
        fields: [
            { field: "name", type: "text", label: "Nazwa", filter: "text" },
            {
                field: "isEnabled",
                type: "boolean",
                label: "Widoczny",
                filter: "boolean"
            }
        ],
        resource: "pleat_remote_control_types",
        url: () =>
            `/pleat_remote_control_types?` +
            generateProperties(
                [],
                [
                    'id',
                    'name',
                    'isEnabled',
                    'position'
                ]
            ),
        rowActions: [
            {
                action: "edit",
                permissions: ["pleat_remote_control_type_update"]
            },
            {
                action: "remove",
                permissions: ["pleat_remote_control_type_delete"]
            }
        ]
    },
    create: {
        permissions: ["pleat_remote_control_type_create"],
        route: {
            path: "/pleat_remote_control_types/create",
            name: "pleat_remote_control_types_create"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "pleat_remote_control_types_list" }),
                label: () => "Typy sterowań plis"
            },
            {
                label: () => `Nowa typ sterowania`
            }
        ],
        formComponent: PleatRemoteControlTypeForm,
        listRoute: () => ({ name: "pleat_remote_control_types_list" }),
        updateRoute: ({ object }) => ({
            name: "pleat_remote_control_types_update",
            params: { id: object.id }
        })
    },
    update: {
        permissions: ["pleat_remote_control_type_update"],
        route: {
            path: "/pleat_remote_control_types/:id/update",
            name: "pleat_remote_control_types_update"
        },
        resource: "pleat_remote_control_types",
        resourceProperties: generateProperties(
            [],
            [
                "id",
                "name",
                "isEnabled",
                "picture",
                "description",
                "position"
            ]
        ),
        breadcrumbs: [
            {
                route: () => ({ name: "pleat_remote_control_types_list" }),
                label: () => "Typy sterowań plis"
            },
            {
                label: ({ compData }) => `Edycja typu sterowania "${compData.object.name}"`
            }
        ],
        formComponent: PleatRemoteControlTypeForm,
        listRoute: () => ({ name: "pleat_remote_control_types_list" })
    }
};
