import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "downloadSection", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `download_sections/${route.params.downloadSectionId}?properties[]=id&properties[]=name`
    );
    compData.downloadSection = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować sekcji głównej", "danger");
  }
};
