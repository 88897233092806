import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "pleatGroup", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `pleat_groups/${route.params.pleatGroupId}?properties[]=id&properties[]=name&properties[]=position`
    );
    compData.pleatGroup = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować grupy cenowej", "danger");
  }
};
