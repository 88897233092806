<template lang="pug">
  table.table.is-fullwidth.is-bordered
    FrameMosquitoNetOrderItemProductionTemplate(:object="order")

</template>

<script>
import FrameMosquitoNetOrderItemProductionTemplate from "../../orders/components/FrameMosquitoNetOrderItemProductionTemplate";

export default {
  components: {FrameMosquitoNetOrderItemProductionTemplate},
  name: "ProductionOrderPreviewFrameMosquitoNetTable",
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>