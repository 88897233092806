import PleatGlobalAccessoryEntryForm from "../components/PleatGlobalAccessoryEntryForm"; // Update import
import decimal from "../../../helpers/decimal";
import fetchPleat from "@/modules/pleats/helpers/fetchPleat"; // Update import

export default {
  list: {
    permissions: ["pleat_global_accessory_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_global_accessory_entries_create",
          params: {
            pleatId: route.params.pleatId // Update parameter name
          }
        }),
        permissions: ["pleat_global_accessory_entry_create"]
      }
    ],
    route: {
      path: "/pleat_global_accessory_entries/:pleatId/list", // Update path
      name: "pleat_global_accessory_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }), // Update route name
        label: () => "Plisy"
      },
      {
        label: ({ compData }) =>
            `Wiązania osprzętów dla wersji "${compData.pleat.name}"` // Update variable name
      }
    ],
    fields: [
      {
        field: "globalAccessory.symbol",
        type: "text",
        label: "Symbol osprzętu",
        filter: "text"
      },
      {
        field: "globalAccessory.name",
        type: "text",
        label: "Nazwa osprzętu",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "heightSurcharge",
        type: "text",
        label: "Dopłata wysokości [netto]",
        format: decimal
      }
    ],
    resource: "pleat_global_accessory_entries", // Update resource name
    url: ({ route }) =>
        `/pleats/${route.params.pleatId}/pleat_global_accessory_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=heightSurcharge&properties[globalAccessory][]=name&properties[globalAccessory][]=symbol`,
    onCreated: fetchPleat, // Update function name
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_global_accessory_entry_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_global_accessory_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_global_accessory_entry_create"],
    route: {
      path: "/pleat_global_accessory_entries/:pleatId/create",
      name: "pleat_global_accessory_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_global_accessory_entries_list",
          params: { pleatId: compData.pleat.id }
        }),
        label: ({ compData }) =>
            `Osprzęt dla wersji "${compData.pleat.name}"`
      },
      {
        label: ({ compData }) =>
            `Nowe wiązanie dla wersji "${compData.pleat.name}"`
      }
    ],
    formComponent: PleatGlobalAccessoryEntryForm, // Update component name
    onCreated: fetchPleat, // Update function name
    listRoute: ({ compData }) => ({
      name: "pleat_global_accessory_entries_list",
      params: { pleatId: compData.pleat.id }
    }),
    updateRoute: ({ object }) => ({
      name: "pleat_global_accessory_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["pleat_global_accessory_entry_update"],
    route: {
      path: "/pleat_global_accessory_entries/:id/update",
      name: "pleat_global_accessory_entries_update"
    },
    resource: "pleat_global_accessory_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_global_accessory_entries_list",
          params: { pleatId: compData.object.pleat.id }
        }),
        label: ({ compData }) =>
            `Osprzęt dla wersji "${compData.object.pleat.name}"`
      },
      {
        label: ({ compData }) =>
            `Edycja wiązania osprzętu "${compData.object.globalAccessory.name}" z wersją "${compData.object.pleat.name}"`
      }
    ],
    formComponent: PleatGlobalAccessoryEntryForm, // Update component name
    onCreated: fetchPleat, // Update function name
    listRoute: ({ compData }) => ({
      name: "pleat_global_accessory_entries_list",
      params: {
        pleatId: compData.object.pleat.id
      }
    })
  }
};
