import http from "../../../http";

export default async ({ compData, route, $notify, refs }) => {
    try {
        compData.loading = true;

        // Wywołaj endpoint do odświeżenia danych statystycznych
        await http.post('/statistics/refresh');

        // Wywołaj metodę fetch komponentu app-table do odświeżenia
        await refs.appTable.fetch();

        compData.loading = false;
    } catch (e) {
        $notify("Nie udało się pobrać statystyk", "danger");
    }
}
