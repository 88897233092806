import Vue from "vue";
import Vuex from "vuex";
import router from "./router";

import { sync } from "vuex-router-sync";
import http from "@/http";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    themeBackgroundColor: null
  },
  getters: {
    themeBackgroundColor(state) {
      return state.themeBackgroundColor;
    },
    languages: () => {
      return [
        { label: "Polski (PL)", value: "PL" },
        { label: "Czeski (CZ)", value: "CZ" },
        { label: "Angielski (EN)", value: "EN" },
        { label: "Niemiecki (DE)", value: "DE" },
        { label: "Francuski (FR)", value: "FR" },
        { label: "Szwedzki (SE)", value: "SE" },
        { label: "Włoski (IT)", value: "IT" },
        { label: "Słoweński (SI)", value: "SI" },
        { label: "Rosyjski (RU)", value: "RU" },
        { label: "Hiszpański (ES)", value: "ES" },
        { label: "Holenderski (NL)", value: "NL" }
      ];
    },
    lengthUnits: () => {
      return [
        { label: "Milimetr", value: "mm" },
        { label: "Centymetr", value: "cm" }
      ];
    },
    previewTypes: () => {
      return [
        { label: "Z prowadnicami", value: "with_runners" },
        { label: "Bez prowadnic", value: "without_runners" },
        { label: "Wolnowiszący", value: "free" }
      ];
    },
    daysOfWeek: () => {
      return [
        { value: "mon", field: "Poniedziałek" },
        { value: "tue", field: "Wtorek" },
        { value: "wed", field: "Środa" },
        { value: "thu", field: "Czwartek" },
        { value: "fri", field: "Piątek" },
        { value: "sat", field: "Sobota" },
        { value: "sun", field: "Niedziela" }
      ];
    }
  },
  mutations: {
    setThemeBackgroundColor(state, payload) {
      state.themeBackgroundColor = payload;
    }
  },
  actions: {
    async init({ commit }, id) {
      let { data } = await http.get(
        `/users/${id}?properties[]=backgroundColor`
      );
      commit("setThemeBackgroundColor", data.backgroundColor);
    },
    setThemeBackgroundColor({ commit }, payload) {
      commit("setThemeBackgroundColor", payload);
    }
  }
});

sync(store, router);

export default store;
