<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose")
    app-view-card(title="Wybierz linię produkcyjną dla zamówienia")
      template(slot="icon")
        b-button(
          @click="onClose"
        )
          b-icon(icon="close" size="is-small")
      resource-select(
        v-if="order && isActive"
        :value="order.productionLine"
        @input="handleInput"
        resource="production_lines"
        url="production_lines"
        placeholder="Wybierz linię produkcyjną"
        label="name"
        :extra-props="productionLineResourceSelectExtraProps"
        @fetched="onFetch"
      )
      b-button(type="is-primary" @click="onClose") Zatwierdź
</template>

<script>
import ResourceSelect from "./ResourceSelect";
import productTypeCodesByOrderCategory from "../../../helpers/productTypeCodesByOrderCategory";

export default {
  components: { ResourceSelect },
  props: {
    active: { type: Boolean, default: false, required: true },
    order: { default: null, required: true }
  },
  data() {
    return {
      isActive: this.active
    };
  },
  methods: {
    handleInput(event) {
      this.order.productionLine = event;
    },
    onClose() {
      this.isActive = false;
      this.$emit("close", this.order);
    },
    onFetch(data) {
      if (data.length > 0 && !this.order.productionLine) {
        this.order.productionLine = data[0]['@id'] || data[0];
      }
    }
  },
  computed: {
    productTypeCodeByOrderCategory() {
      if (this.order && this.order.category) {
        return productTypeCodesByOrderCategory[this.order.category];
      } else {
        return "RTK";
      }
    },
    productionLineResourceSelectExtraProps() {
      return `&properties[]=code&properties[]=lineColor&productType.code[]=${this.productTypeCodeByOrderCategory}`;
    }
  },
  watch: {
    active(value) {
      this.isActive = value;
    }
  }
};
</script>
