<template lang="pug">
.table-container.mt-2
  table.table.is-bordered.has-cells-centered.is-narrow.long-table.is-fullwidth
    thead
      tr
        th(colspan=17) Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
      tr
        th(rowspan=2) Lp.
        th(rowspan=2) Grupa
        th(rowspan=2) Wersja
        th(rowspan=2) Tkanina
        th(rowspan=2) Kolor
        th(rowspan=2) Szer. [mm]
        th(rowspan=2) Wys. [mm]
        th(rowspan=2) Szt.
        th(rowspan=2) Osprzęt
        th(rowspan=2) Moc.
        th(rowspan=2) Ster.
        th(rowspan=2) Prow.
        th(rowspan=2) Dod. naciąg
        th(rowspan=2) Dod.
        th(rowspan=2) Netto
    tbody
      tr(v-for="(item, index) in orderItems(object)")
        td {{ parseInt(index) + 1 }}
        td
          p {{ prop(item.pleatGroup, 'position') }}
          p(v-if="item.pleatGroupBottom") {{ prop(item.pleatGroupBottom, "position") }}
        td {{ prop(item.pleat, 'name') }}
        td
          p {{ prop(item.pleatFabric, 'name') }}
          p(v-if="item.pleatFabricBottom") {{ prop(item.pleatFabricBottom, "name") }}
        td
          p {{ prop(item.pleatFabricColor, 'name') }}
          p(v-if="item.pleatFabricColorBottom") {{ prop(item.pleatFabricColorBottom, "name") }}
        td {{ item.width  }}
        td {{ item.height  }}
        td {{ item.quantity }}
        td {{ prop(item.pleatGlobalAccessoryEntry, 'name', 'globalAccessory') }}
        td {{ prop(item.pleatPleatFixingTypeEntry, "name", "pleatFixingType") }}
        td {{ prop(item.pleatPleatRemoteControlTypeEntry, "name", "pleatRemoteControlType") }}
        td {{ prop(item.pleatGlobalRunnerEntry, 'name', 'globalRunner') }}
        td {{ prop(item.pleatAdditionalString, "symbol", "globalRunner") }}
        td
          p(v-if="!item.pleatPleatAdditionalEntries || !item.pleatPleatAdditionalEntries.length") Brak
          p(v-for="(entry, indexProtection) in item.pleatPleatAdditionalEntries") {{ prop(entry, "name", "pleatAdditional") }}
        td {{ formatPriceValue(getDecimal(item.netPurchaseValue)) }}
    tfoot
      tr
        td.has-text-right(colspan=7) Razem ilość:
        td {{ object.quantity }}
        td.has-text-right(colspan=6 ) Wartość razem netto:
        td {{ formatPriceValue(getDecimal(object.netPurchaseValueBeforeDiscount)) }}
</template>

<script>
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import decimal from "../../../helpers/decimal";

export default {
  name: "PleatOrderItemPreviewTemplate",
  props: ['object'],
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {

      return decimal(value);
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
  }
}
</script>

<style scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>