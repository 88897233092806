import ExternalShutterDriveExitForm from "../components/ExternalShutterDriveExitForm";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_drive_exit_read"],
    actions: [
      {
        label: "Nowe wyjście napędu",
        type: "is-primary",
        route: () => ({
          name: "external_shutter_drive_exits_create"
        }),
        permissions: ["external_shutter_drive_exit_create"]
      }
    ],
    route: {
      path: "/external_shutter_drive_exits/list",
      name: "external_shutter_drive_exits_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Wyjścia napędów"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_drive_exits",
    url: () =>
      `/external_shutter_drive_exits?` +
      generateProperties(
        [],
        ["id", "name", "isEnabled", "pieceSurcharge", "position"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_drive_exit_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_drive_exit_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_drive_exit_create"],
    route: {
      path: "/external_shutter_drive_exits/create",
      name: "external_shutter_drive_exits_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_drive_exits_list" }),
        label: () => "Wyjścia napędów"
      },
      {
        label: () => `Nowe wyjście napędów`
      }
    ],
    formComponent: ExternalShutterDriveExitForm,
    listRoute: () => ({ name: "external_shutter_drive_exits_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_drive_exits_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_drive_exit_update"],
    route: {
      path: "/external_shutter_drive_exits/:id/update",
      name: "external_shutter_drive_exits_update"
    },
    resource: "external_shutter_drive_exits",
    resourceProperties: generateProperties(
      [],
      ["id", "name", "isEnabled", "pieceSurcharge", "position", "picture", "sectionPicture"]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_drive_exits_list" }),
        label: () => "Wyjścia napędów"
      },
      {
        label: ({ compData }) => `Edycja wyjścia napędów "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterDriveExitForm,
    listRoute: () => ({ name: "external_shutter_drive_exits_list" })
  }
};
