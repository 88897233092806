<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="frame_mosquito_net_global_accessory_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            .has-text-centered.has-font-25 Metoda kalkulacji: "Szerokość/Wysokość w mm / 1000 * 2"
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.heightSurcharge")

                            app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.widthSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Osprzęt" field="globalAccessory" :errors="errors")
                                resource-select(
                                    v-model="data.globalAccessory"
                                    resource="global_accessories"
                                    url="global_accessories"
                                    placeholder="Wybierz osprzęt"
                                    extra-props="&properties[]=symbol&order[name]=asc"
                                    :labels="['symbol']"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
//TO JEST KOLOR
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        skipCalculations: false,
        isEnabled: true,
        frameMosquitoNet: `/frame_mosquito_nets/${this.$route.params.frameMosquitoNetId}`,
        globalAccessory: null,
      },
      frameMosquitoNetId: this.$route.params.frameMosquitoNetId,
    };
  },
  methods: {
    transformToModel(data) {
      if (data.frameMosquitoNet && data.frameMosquitoNet["@id"]) {
        data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      }
      if (data.globalAccessory && data.globalAccessory["@id"]) {
        data.globalAccessory = data.globalAccessory["@id"];
      }
      return data;
    },
    transformToSubmit(data) {
      if (data.frameMosquitoNet && data.frameMosquitoNet["@id"]) {
        data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      }
      if (data.globalAccessory && data.globalAccessory["@id"]) {
        data.globalAccessory = data.globalAccessory["@id"];
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
