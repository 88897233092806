import Vue from "vue";
import generateProperties from "../../../helpers/generateProperties";

export default async ({ compData, route, $notify, $http }) => {
    Vue.set(compData, "doorMosquitoNet", {});
    try {
        compData.loading = true;
        let { data } = await $http.get(`door_mosquito_nets/${route.params.doorMosquitoNetId}?` + generateProperties([],['id', 'name', 'code'],false));
        compData.doorMosquitoNet = data;
        compData.loading = false;
    } catch (e) {
        $notify("Nie udało się załadować moskitiery drzwiowej", "danger");
    }
};
