import SubCompanyForm from "../components/SubCompanyForm";
import fetchCompany from "@/modules/management/helpers/fetchCompany";

export default {
  list: {
    permissions: ["company_worker_read"],
    route: {
      path: "/company_workers/:companyId/list",
      name: "company_workers_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "companies_list" }),
        label: () => "Odbiorcy"
      },
      {
        label: ({ compData }) =>
          `Pracownicy dla odbiorcy: "${compData.company.name}"`
      }
    ],
    fields: [
      {
        field: "lastName",
        type: "text",
        label: "Nazwisko",
        filter: "text"
      },
      { field: "firstName", type: "text", label: "Imię", filter: "text" },
      {
        field: "username",
        type: "text",
        label: "Login",
        filter: "text"
      },
      { field: "phone", type: "text", label: "Telefon", filter: "text" },
      { field: "mobilePhone", type: "text", label: "Telefon komórkowy", filter: "text" },
      { field: "roles", type: "text", label: "Funkcja",
        format(value) {
          let rolesArray = "";
          if (value.length === 0) return '';

          rolesArray = value.join(', ');

          return rolesArray;
        }
      },
      {
        field: "email",
        type: "text",
        label: "Email",
      },
    ],
    resource: "company_workers",
    url: ({ route }) =>
      `/companies/${route.params.companyId}/company_workers?properties[]=id&properties[]=shortName&properties[]=firstName&properties[]=lastName&properties[]=phone&properties[]=isEnabled&properties[]=username&properties[]=roles`,
    onCreated: fetchCompany,
    rowActions: [

    ]
  },
  update: {
    permissions: ["company_worker_update"],
    route: {
      path: "/company_workers/:id/update",
      name: "company_workers_update"
    },
    resource: "company_workers",
    breadcrumbs: [
      {
        route: () => ({ name: "companies" }),
        label: () => "Odbiorcy"
      },
      {
        route: ({ compData }) => ({
          name: "company_workers_list",
          params: { companyId: compData.object.company.id }
        }),
        label: ({ compData }) =>
          `Pracownicy dla odbiorcy "${compData.object.company.name}"`
      },
      {
        label: ({ compData }) => `Edycja pracownika "${compData.object.name}"`
      }
    ],
    formComponent: SubCompanyForm,
    onCreated: fetchCompany,
    listRoute: ({ compData }) => ({
      name: "company_workers_list",
      params: {
        companyId: compData.object.company.id
      }
    })
  }
};
