<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_armor_external_shutter_armor_color_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 W danym pancerzu ten sam kolor pancerza nie może występować więcej niż 1 raz
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Dopłata za m2" field="lengthSurcharge" :errors="errors")
                app-form-decimal(v-model="data.lengthSurcharge")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(label="Przekrój" field="sectionPicture" :errors="errors")
                app-form-file(v-model="files.sectionPicture" :image="data.sectionPicture" @removed="data.sectionPicture = {}")


            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Kolor pancerza" field="externalShutterArmorColor" :errors="errors")
                resource-select(
                  v-model="data.externalShutterArmorColor"
                  resource="external_shutter_armor_colors"
                  url="external_shutter_armor_colors"
                  placeholder="Wybierz kolor pancerza"
                  extra-props="&properties[]=symbol"
                  :labels="['name', 'symbol']"
                )

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  data() {
    return {
      files: {
        sectionPicture: {}
      },
    };
  },
  computed: {
    model() {
      return {
        isEnabled: false,
        position: 0,
        lengthSurcharge: 0,
        externalShutterArmorColor: null,
        externalShutterArmor: `/external_shutter_armors/${this.$route.params.externalShutterArmorId}`,
        sectionPicture: {},
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.externalShutterArmor) {
        data.externalShutterArmor = data.externalShutterArmor["@id"] || data.externalShutterArmor;
      }
      if (data.externalShutterArmorColor) {
        data.externalShutterArmorColor = data.externalShutterArmorColor["@id"] || data.externalShutterArmorColor;
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
