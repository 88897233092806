<template lang="pug">
    .has-margin-top-small
        .panel
            .panel-heading
                span
                    b-icon(icon="filter" size="is-small")
                    | Filtry
                b-button(size="is-small" class="is-pulled-right" icon-left="filter-remove" @click.prevent="clearFilters")
                    | Usuń fltry
            .panel-block
                b-field(grouped)
                    slot(name="filters" :filter="filter" :filters="filters()")
        .is-table-responsive
            table.table.is-fullwidth.is-striped.is-hoverable.is-bordered(:class="classes")
                thead
                    tr
                        th(v-if="sortable" title="Przeciągnij i upuść by zmienić kolejność")
                            b-icon(icon="reorder-horizontal")
                        slot(name="headers" :sort="sort" :is-sorted="isSorted" :is-asc="isAsc" :is-desc="isDesc" :list="list")
                        th Operacje
                draggable(:value="list" tag="tbody" handle=".handle" :disabled="!sortable" @change="change" v-show="!loading")
                    tr(v-for="row in list" :key="row.id")
                        td(v-if="sortable" title="Przeciągnij by zmienić kolejność")
                            b-icon(class="handle" icon="reorder-horizontal" ref="handle" :style="{ cursor: 'move' }")
                        slot(:row="row" :reload="fetch")
            div(v-show="isEmpty && !loading" class="has-text-centered") Brak danych
            .is-relative(:class="classes")
                b-loading(:active="loading" :is-full-page="false")
            .is-clearfix
                .is-pulled-right
                    a.button(:disabled="!this.hasPrevPage" @click.prevent="prev") Poprzednia
                    a.button(:disabled="!this.hasNextPage" @click.prevent="next") Następna
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    sortable: Boolean,
    properties: { type: Array },
    storeNamespace: String
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.storeNamespace + "/fetch");
    },
    sort(args) {
      this.$store.dispatch(this.storeNamespace + "/sort", args);
    },
    clearFilters() {
      this.$store.dispatch(this.storeNamespace + "/clearFilters");
    },
    filter(args) {
      this.$store.dispatch(this.storeNamespace + "/filter", args);
    },
    next() {
      this.$store.dispatch(this.storeNamespace + "/next");
    },
    prev() {
      this.$store.dispatch(this.storeNamespace + "/prev");
    },
    isSorted(param) {
      this.$store.getters[this.storeNamespace + "/isSorted"](param);
    },
    async change({ moved }) {
      if (this.sortable) {
        await this.move({
          id: moved.element.id,
          position: moved.newIndex
        });
      }
    },
    async move({ id, position }) {
      try {
        this.loading = true;
        await this.$http.put(`${this.resource}/${id}`, {
          position
        });
        this.$notify("Zmieniono kolejność");
        this.fetch();
      } catch (e) {
        this.$notify(e.message, "danger");
      }
    }
  },
  computed: {
    list() {
      return this.$store.getters[this.storeNamespace + "/list"];
    },
    loading() {
      return this.$store.getters[this.storeNamespace + "/loading"];
    },
    params() {
      return this.$store.getters[this.storeNamespace + "/params"];
    },
    filters() {
      return this.$store.getters[this.storeNamespace + "/filters"];
    },
    classes() {
      return this.$store.getters[this.storeNamespace + "/classes"];
    },
    isEmpty() {
      return this.$store.getters[this.storeNamespace + "/isEmpty"];
    },
    isAsc() {
      return this.$store.getters[this.storeNamespace + "/isAsc"];
    },
    isDesc() {
      return this.$store.getters[this.storeNamespace + "/isDesc"];
    },
    currentPage() {
      return this.$store.getters[this.storeNamespace + "/currentPage"];
    },
    hasPrevPage() {
      return this.$store.getters[this.storeNamespace + "/hasPrevPage"];
    },
    hasNextPage() {
      return this.$store.getters[this.storeNamespace + "/hasNextPage"];
    }
  },
  watch: {
    "$store.state.route.query": "fetch"
  }
};
</script>
<style>
.sortable {
  cursor: pointer;
}
.is-taller {
  height: 100px;
}
.is-table-responsive {
  overflow-x: auto;
}
</style>
