<template lang="pug">
  table.table.is-fullwidth.is-bordered
    PleatOrderItemProductionTemplate(:object="order")

</template>

<script>
import PleatOrderItemProductionTemplate from "../../orders/components/PleatOrderItemProductionTemplate";

export default {
  components: {PleatOrderItemProductionTemplate},
  name: "ProductionOrderPreviewPleatTable",
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>