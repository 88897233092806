<template lang="pug">
  tr
    // liczba porządkowa
    td
      div
        .th-in {{ (index + 1 ) }}

    // pilot
    td(v-if="item.globalDrive") {{property(item.globalDrive, "name") }}
    td(v-if="item.globalDriveControl") {{property(item.globalDriveControl, "name") }}

    td {{ item.quantity }}
    // cena zakupu
    td {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}


</template>
<script>

import priceFormatter from "../../../helpers/priceFormatter";
import prop from "../../../helpers/propFunc";

export default {
  props: {
    index: Number,
    item: Object,
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    trueFalseToText(value){
      return (value)? "yes" : "no";
    },

    property: (object, field, extraField) => prop(object, field, extraField),
  },
  computed: {
    iteratorAlphabet(){
      if(this.item.externalShutterOrderItemCombinationsCount === 1)return " "
      return ["a","b","c"]
    },
    formatPrice: () => cents => {
      return (Math.round(cents) / 100)
          .toFixed(2)
          .toString()
          .replace(".", ",");
    },
  }
};
</script>
