import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "externalShutterArmor", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `external_shutter_armors/${route.params.externalShutterArmorId}?properties[]=id&properties[]=name`
    );
    compData.externalShutterArmor = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować pancerza", "danger");
  }
};
