<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="global_drives" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol")

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Dopłata zł / sztukę" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")

              .columns
                .column
                  app-form-field(label="Cena hurtowa" field="netWholesalePrice" :errors="errors")
                    app-form-decimal(v-model="data.netWholesalePrice")
                .column
                  app-form-field(label="Cena detaliczna" field="netRetailPrice" :errors="errors")
                    app-form-decimal(v-model="data.netRetailPrice")

              .columns
                .column
                  app-form-field(label="Minimalna szerokość (mm)" field="minWidth" :errors="errors")
                    app-form-length(v-model="data.minWidth" unit="mm")
                .column
                  app-form-field(label="Maksymalna szerokość (mm)" field="maxWidth" :errors="errors")
                    app-form-length(v-model="data.maxWidth" unit="mm")

              .columns
                .column
                  app-form-field(label="Udźwig (Nm.)" field="liftingCapacityNm" :errors="errors")
                    app-form-decimal(v-model="data.liftingCapacityNm")
                .column
                  app-form-field(label="Udźwig (kg, przy wysokości 2.5m)" field="liftingCapacity" :errors="errors")
                    app-form-decimal(v-model="data.liftingCapacity")

              app-form-field(label="Masa (kg/szt.)" field="weight" :errors="errors")
                app-form-weight(v-model="data.weight", unit="kg")

              app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                app-form-time-integer(v-model="data.productionTime")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(field="skipCalculations" :errors="errors")
                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

            .column
              app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                b-input(v-model="data.message" type="textarea")

              app-form-field(label="Opis" field="description" :errors="errors")
                b-input(v-model="data.description" type="textarea")

              .columns
                .column
                  app-form-field(label="Seria napędu" field="globalDriveSeries" :errors="errors")
                    resource-select(
                      v-model="data.globalDriveSeries"
                      resource="global_drive_series"
                      placeholder="Wybierz serie napędu"
                    )
                  app-form-field(label="Typ napędu" field="globalDriveType" :errors="errors")
                    resource-select(
                      v-model="data.globalDriveType"
                      resource="global_drive_types"
                      placeholder="Wybierz typ napędu"
                    )
                  app-form-field(label="Producent" field="manufacturer" :errors="errors")
                    resource-select(
                      v-model="data.manufacturer"
                      resource="manufacturers"
                      placeholder="Wybierz producenta"
                    )
                  br
                  app-form-field(label="Kompatybilne rury" field="globalPipes" :errors="errors")
                    multiple-resource-select(
                      v-model="data.globalPipes"
                      resource="global_pipes"
                      placeholder="Wybierz rury"
                    )

        b-tab-item(label="Sterowania i piloty")
          .columns
            .column
              app-form-field(label="Dostępne sterowania" field="globalDriveControls" :errors="errors")
                multiple-resource-select(
                  v-model="data.globalDriveControls"
                  resource="global_drive_controls"
                  url="global_drive_controls"
                  placeholder="Wybierz sterowania i piloty"
                )
            //.column
            //    app-form-field(label="Dostępne piloty (bezpośrednio związane z napędem)" field="globalRemoteControls" :errors="errors")
            //        multiple-resource-select(
            //            v-model="data.globalRemoteControls"
            //            resource="global_remote_controls"
            //            url="global_remote_controls"
            //            placeholder="Wybierz piloty"
            //        )
        b-tab-item(label="Zdjęcia")
          .columns
            .column
              app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

            .column
              app-form-field(label="Miniaturka (proporcja 4:3)" field="thumbnail" :errors="errors")
                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")
          .columns
            .column
              app-form-field(label="Przekrój" field="sectionPicture" :errors="errors")
                app-form-file(v-model="files.sectionPicture" :image="data.sectionPicture" @removed="data.sectionPicture = {}")

        b-tab-item(label="Dokumenty")
          app-form-field(label="Plik instrukcja montażu" field="measurementInstruction" :errors="errors")
            app-form-file(v-model="files.measurementInstruction" :image="data.measurementInstruction" @removed="data.measurementInstruction = {}" :downloadable="true")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect,
    ResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        symbol: "",
        pieceSurcharge: 0,
        description: "",
        disableChainLengthField: true,
        picture: "",
        thumbnail: "",
        sectionPicture: null,
        skipCalculations: false,
        isEnabled: true,
        message: "",
        globalDriveControls: [],
        //globalRemoteControls: [],
        globalDriveTypes: [],
        position: 0,
        liftingCapacity: 0,
        liftingCapacityNm: 0,
        netWholesalePrice: 0,
        netRetailPrice: 0,
        weight: 0,
        minWidth: 0,
        maxWidth: 0,
        globalDriveType: null,
        manufacturer: null,
        globalDriveSeries: null,
        productionTime: 0,
        globalPipes: [],
        measurementInstruction: null,
      },
      files: {
        measurementInstruction: null,
        picture: {},
        thumbnail: {},
        sectionPicture: {}
      },
      messageHelp:
          "Gdy użytkownik wybierze ten napęd, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToSubmit(data) {
      if (data.manufacturer && data.manufacturer["@id"]) {
        data.manufacturer = data.manufacturer["@id"];
      }
      if (data.globalDriveSeries && data.globalDriveSeries["@id"]) {
        data.globalDriveSeries = data.globalDriveSeries["@id"];
      }
      return data;
    },
    transformToModel(data) {
      if (!data.position) {
        data.position = 0;
      }
      if (!data.netRetailPrice) {
        data.netRetailPrice = 0
      }

      if (!data.netWholesalePrice) {
        data.netWholesalePrice = 0
      }

      this.model.globalDriveControls = data.globalDriveControls.map(
          globalDriveControl => globalDriveControl["@id"]
      );
      // this.model.globalRemoteControls = data.globalRemoteControls.map(
      //   globalRemoteControl => globalRemoteControl["@id"]
      // );
      this.model.globalPipes = data.globalPipes.map(
          globalPipe => globalPipe["@id"]
      );
      this.model.globalDriveType = data.globalDriveType["@id"];
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
