import AppErrorForm from "../componets/AppErrorForm"

export default {
  list: {
    permissions: ["app_error"],
    route: {
      path: "/app_errors",
      name: "app_errors_list"
    },
    sortable: false,
    breadcrumbs: [
      {
        route: () => ({ name: "app_errors_list" }),
        label: () => "Błędy aplikacji"
      }
    ],
    fields: [
        {
            field: "createdAt",
            type: "text",
            label: "Data wystąpienia"
        },
        {
            field: "userInfo",
            type: "virtual",
            label: "Użytkownik",
            value: (h, row) => (row.user ? ((row.user.firstName || "") + " " + (row.user.lastName || "") + " (id: "+row.user.id+")") : "brak")
        },
        {
            field: "status",
            type: "text",
            label: "Status"
        }
    ],
    resource: "app_errors",
    url: ({ route }) =>
      `/app_errors`,
    rowActions: [
      {
        action: "edit",
        permissions: ["app_error_update"]
      },
    ]
  },
  update: {
    permissions: ["app_error_update"],
    route: {
      path: "/app_errors/:id/update",
      name: "app_errors_update"
    },
    resource: "app_errors",
    breadcrumbs: [
      {
        route: () => ({ name: "app_errors_list" }),
        label: () => "Błędy aplikacji"
      },
      {
        label: ({ compData }) =>
          `Podgląd błędu numer: "${compData.object.id}" z dnia: ${compData.object.createdAt}`
      }
    ],
    formComponent: AppErrorForm,
    listRoute: ({ compData }) => ({
      name: "app_errors_list"
    })
  }
};
