<template lang="pug">
  div.container.is-fullhd
    template
      table.table.is-bordered.has-cells-centered.is-narrow.is-fullwidth.print-table
        thead
          tr
            th(colspan=11)  Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
          tr
            th(rowspan=2) Lp.
            th(rowspan=2) Wersja
            th(rowspan=2) Złożona
            th(rowspan=2) Kolor ramy
            th(rowspan=2) Siatka
            th(rowspan=2) Próg
            th(rowspan=2) Szer.
            th(rowspan=2) Wys.
            th(rowspan=2) Sztuk
            th(rowspan=2) Dod.
            th(rowspan=2) Netto
        tbody
          tr(v-for="(item, index) in orderItems(object)")
            td {{ index + 1 }}
            td {{ prop(item.pleatMosquitoNet, 'name') }}
            td {{ item.isAssembly ? 'TAK' : '-' }}
            td {{ prop(item.pleatMosquitoNetGlobalAccessoryEntry, "name", 'globalAccessory') }}
            td {{ prop(item.pleatMosquitoNetGlobalNetEntry, "name", 'globalNet') }}
            td {{ prop(item.pleatMosquitoNetSill, "name") }}
            td {{ item.width / 10 }}
            td {{ item.height / 10 }}
            td {{ item.quantity }}
            td
              p(v-if="!item.pleatMosquitoNetAdditionalEntries.length") Brak
              p(v-for="(entry, indexAdditional) in item.pleatMosquitoNetAdditionalEntries") {{ prop(entry, "name", "pleatMosquitoNetAdditional") }}
            td {{ formatPriceValue(getDecimal(item.netPurchaseValue)) }}

        tfoot
          tr
            td.has-text-right(colspan=8) Razem ilość:
            td {{ object.quantity }}
            td.has-text-right(colspan=1) Wartość razem netto:
            td {{ formatPriceValue(getDecimal(object.netPurchaseValueBeforeDiscount)) }}
</template>
<script>

import prop from "../../../helpers/propFunc";
import getOrderItems from "../../../helpers/orderItems";
import priceFormatter from "../../../helpers/priceFormatter";
import decimal from "../../../helpers/decimal";

export default {
  props: ['object'],
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
  }
};
</script>
