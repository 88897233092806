<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="fabric_colors" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Symbol" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Tkanina" field="fabric" :errors="errors")
                                resource-select(v-model="data.fabric" resource="fabrics" placeholder="Wybierz tkaninę")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                                b-input(v-model="data.message" type="textarea")

                            app-card(title="Cechy koloru")
                                app-form-field(label="Cechy koloru" field="traitColors" :errors="errors")
                                    multiple-resource-select(
                                      v-model="data.traitColors"
                                      resource="trait_colors"
                                      url="trait_colors"
                                      placeholder="Wybierz dostępne cechy koloru"
                                    )
                        .column
                            app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

                            app-form-field(label="Miniatura (proporcja 4:3)" field="thumbnail" :errors="errors")
                                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      files: {
        picture: {},
        thumbnail: {}
      },
      model: {
        symbol: "",
        name: "",
        message: "",
        fabric: `/fabrics/${this.$route.params.fabricId}`,
        isAvailableForShop: false,
        isEnabled: true,
        picture: null,
        thumbnail: null,
        traitColors: []
      },
      messageHelp:
        "Gdy użytkownik wybierze ten kolor tkaniny, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToModel(data) {
      this.model.fabric = data.fabric["@id"];

      return data;
    }
  }
};
</script>
