<template lang="pug">
  section.hero.is-fullheight
    .hero-body
      .container
        .columns.is-centered
          .column.is-5-tablet.is-4-desktop.is-3-widescreen
            form.box.is-radiusless(@submit.prevent="submit")
              b-message(v-show="error" type="is-danger")
                | {{ error }}
              b-message(v-show="info" type="is-info")
                | {{ info }}

              .field
                label.label Hasło
                .control.has-icons-left
                  input.input(v-model="data.password" type="password" placeholder="*******" required="")
                  span.icon.is-small.is-left
                    i.fa.fa-lock
              .field
                label.label Potwierdź hasło
                .control.has-icons-left
                  input.input(v-model="data.confirmPassword" type="password" placeholder="*******" required="")
                  span.icon.is-small.is-left
                    i.fa.fa-lock
              .field.has-text-centered
                b-button(native-type="submit" type="is-primary" :loading="loading" ) Zmień hasło

</template>
<script>
export default {
  data() {
    return {
      loading: false,
      error: null,
      info: null,
      data: {
        password: null,
        confirmPassword: null,
        token: null // Dodane pole do przechowywania tokenu z URL
      }
    };
  },
  mounted() {
    // Odczytanie tokenu z URL po załadowaniu komponentu
    this.data.token = this.$route.query.token;
  },
  methods: {
    async submit() {
      this.error = null;
      this.loading = true;

      if (this.data.password !== this.data.confirmPassword) {
        this.error = "Hasło i potwierdzenie hasła muszą się zgadzać.";
        this.loading = false;
        return;
      }

      // Password policy: Minimum 8 characters
      if (this.data.password.length < 8) {
        this.error = "Hasło musi mieć co najmniej 8 znaków.";
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch("auth/changePassword", this.data);
        this.info = "Zmiana hasła przebiegła pomyślnie."
        setTimeout(async () => {
          await this.$router.push("/")
        }, 5000);
      } catch (e) {
        this.error = "Wystąpił błąd, nie udało się zmienić hasła";
      }
      this.loading = false;
    }
  }
};
</script>
