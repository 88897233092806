import crud from "../../crud";

import companies from "./crud/companies";
import deliveryMethods from "./crud/deliveryMethods";
import paymentMethods from "./crud/paymentMethods";
import countries from "./crud/countries";
import vatRates from "./crud/vatRates";
import adminWorkers from "./crud/adminWorkers";
import productTypes from "./crud/productTypes";
import retailPriceLists from "./crud/retailPriceLists";
import wholesalePriceLists from "./crud/wholesalePriceLists";
import currencies from "./crud/currencies";
import traitColors from "./crud/traitColors";
import traitFabrics from "./crud/traitFabrics";
import subCompanies from "./crud/subCompanies";
import companyWorkers from "./crud/companyWorkers";
import subCompanyOrders from "./crud/subCompanyOrders";

crud(companies);
crud(deliveryMethods);
crud(paymentMethods);
crud(countries);
crud(vatRates);
crud(adminWorkers);
crud(productTypes);
crud(retailPriceLists);
crud(wholesalePriceLists);
crud(currencies);
crud(traitColors);
crud(traitFabrics);
crud(subCompanies);
crud(companyWorkers);
crud(subCompanyOrders);
