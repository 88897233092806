import PleatForm from "../components/PleatForm";
import generateProperties from "../../../helpers/generateProperties";
import centimeter from "../../../helpers/centimeter";

export default {
    list: {
        permissions: ["pleat_read"],
        actions: [
            {
                label: "Nowa wersja",
                type: "is-primary",
                route: () => ({
                    name: "pleats_create"
                }),
                permissions: ["pleat_create"]
            }
        ],
        route: {
            path: "/pleats/list",
            name: "pleats_list"
        },
        sortable: true,
        breadcrumbs: [
            {
                label: () => "Plisy"
            }
        ],
        fields: [
            { field: "symbol", type: "text", label: "Symbol", filter: "text" },
            { field: "name", type: "text", label: "Nazwa", filter: "text" },
            {
                field: "isEnabled",
                type: "boolean",
                label: "Widoczny",
                filter: "boolean"
            },
            {
                field: "minWidth",
                type: "text",
                label: "Min. szer. (mm)",
            },
            {
                field: "maxWidth",
                type: "text",
                label: "Maks. szer. (mm)",
            },
            {
                type: "link",
                label: "Cennik bazowy",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_price_lists",
                        params: { pleatId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Osprzęt",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_global_accessory_entries_list",
                        params: { pleatId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Prowadnice",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_global_runner_entries_list",
                        params: { pleatId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Mocowania",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_pleat_fixing_type_entries_list",
                        params: { pleatId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Sterowania",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_pleat_remote_control_type_entries_list",
                        params: { pleatId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Dodatkowe naciągi",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_additional_strings_list",
                        params: { pleatId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Dodatki",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "pleat_pleat_additional_entries_list",
                        params: { pleatId: row.id }
                    })
                }
            }
        ],
        resource: "pleats",
        url: () =>
            `/pleats?` +
            generateProperties(
                [],
                [
                    'id',
                    'name',
                    'position',
                    'symbol',
                    'isEnabled',
                    'priceListType',
                    'minWidth',
                    'maxWidth',
                ]
            ),
        rowActions: [
            {
                action: "edit",
                permissions: ["pleat_update"]
            },
            {
                action: "remove",
                permissions: ["pleat_delete"]
            }
        ]
    },
    create: {
        permissions: ["pleat_create"],
        route: {
            path: "/pleats/create",
            name: "pleats_create"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Plisy"
            },
            {
                label: () => `Nowa wersja`
            }
        ],
        formComponent: PleatForm,
        listRoute: () => ({ name: "pleats_list" }),
        updateRoute: ({ object }) => ({
            name: "pleats_update",
            params: { id: object.id }
        })
    },
    update: {
        permissions: ["pleat_update"],
        route: {
            path: "/pleats/:id/update",
            name: "pleats_update"
        },
        resource: "pleats",
        resourceProperties: generateProperties(
            [],
            [
                "id",
                "name",
                "position",
                "symbol",
                "isEnabled",
                "picture",
                "notesForPriceListPL",
                "notesForPriceListCZ",
                "notesForPriceListEN",
                "notesForPriceListDE",
                "notesForPriceListFR",
                "notesForPriceListSE",
                "notesForPriceListIT",
                "notesForPriceListSI",
                "notesForPriceListRU",
                "notesForPriceListES",
                "notesForPriceListNL",
                "priceListType",
                "minWidth",
                "maxWidth",
                "minHeight",
                "maxHeight",
                "previewType",
                "surveyDescriptionPL",
                "surveyDescriptionCZ",
                "surveyDescriptionEN",
                "surveyDescriptionDE",
                "surveyDescriptionFR",
                "surveyDescriptionSE",
                "surveyDescriptionIT",
                "surveyDescriptionSI",
                "surveyDescriptionRU",
                "surveyDescriptionES",
                "surveyDescriptionNL",
                "extendedTitlePL",
                "extendedTitleCZ",
                "extendedTitleEN",
                "extendedTitleDE",
                "extendedTitleFR",
                "extendedTitleSE",
                "extendedTitleIT",
                "extendedTitleSI",
                "extendedTitleRU",
                "extendedTitleES",
                "extendedTitleNL",
                "descriptionForShopPL",
                "descriptionForShopCZ",
                "descriptionForShopEN",
                "descriptionForShopDE",
                "descriptionForShopFR",
                "descriptionForShopSE",
                "descriptionForShopIT",
                "descriptionForShopSI",
                "descriptionForShopRU",
                "descriptionForShopES",
                "descriptionForShopNL",
                "surveyPicture",
                "priceListCols",
                "priceListRows",
                "measurementInstruction",
                "productionTime",
                "defaultInstallationValue",
                "message",
                "fabricCount",
                "balkCount",
                "balkWidth",
                "productionRunner",
                "largePleatMargin",
                "maskingTape",
                "profilePictureForShop",
                "isRequiredAdditionalStrain",
                "pieceSurcharge"
            ]
            ) + generateProperties(
            ['pleatTannerCorrections'],
            [
                'id',
                'pleat',
                'heightMargin',
                'tannerQuantityCorrection'
            ],
            true
            ) + generateProperties(
            ['pleatWireLengths'],
            [
                'id',
                'pleat',
                'pleatWidthMargin',
                'pleatWidthRatio',
                'pleatHeightRatio',
                'constantValue'
            ],
            true
        ),
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Plisy"
            },
            {
                label: ({ compData }) => `Edycja wersji "${compData.object.name}"`
            }
        ],
        formComponent: PleatForm,
        listRoute: () => ({ name: "pleats_list" })
    }
};
