import FabricShadeGlobalAccessoryEntryForm from "../components/FabricShadeGlobalAccessoryEntryForm";
import decimal from "../../../helpers/decimal";
import fetchFabricShade from "@/modules/fabric_shades/helpers/fetchFabricShade";

export default {
  list: {
    permissions: ["fabric_shade_global_accessory_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_shade_global_accessory_entries_create",
          params: {
            fabricShadeId: route.params.fabricShadeId
          }
        }),
        permissions: ["fabric_shade_global_accessory_entry_create"]
      }
    ],
    route: {
      path: "/fabric_shade_global_accessory_entries/:fabricShadeId/list",
      name: "fabric_shade_global_accessory_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        label: ({ compData }) =>
          `Wiązania osprzętów dla wersji "${compData.fabricShade.name}"`
      }
    ],
    fields: [
      {
        field: "globalAccessory.symbol",
        type: "text",
        label: "Symbol osprzętu",
        filter: "text"
      },
      {
        field: "globalAccessory.name",
        type: "text",
        label: "Nazwa osprzętu",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "heightSurcharge",
        type: "text",
        label: "Dopłata wysokości [netto]",
        format: decimal
      }
    ],
    resource: "fabric_shade_global_accessory_entries",
    url: ({ route }) =>
      `/fabric_shades/${route.params.fabricShadeId}/fabric_shade_global_accessory_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=heightSurcharge&properties[globalAccessory][]=name&properties[globalAccessory][]=symbol`,
    onCreated: fetchFabricShade,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_global_accessory_entry_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_global_accessory_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_global_accessory_entry_create"],
    route: {
      path: "/fabric_shade_global_accessory_entries/:fabricShadeId/create",
      name: "fabric_shade_global_accessory_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_global_accessory_entries_list",
          params: { fabricShadeId: compData.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Osprzęt dla wersji "${compData.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadeGlobalAccessoryEntryForm,
    onCreated: fetchFabricShade,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_global_accessory_entries_list",
      params: { fabricShadeId: compData.fabricShade.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_global_accessory_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_global_accessory_entry_update"],
    route: {
      path: "/fabric_shade_global_accessory_entries/:id/update",
      name: "fabric_shade_global_accessory_entries_update"
    },
    resource: "fabric_shade_global_accessory_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_global_accessory_entries_list",
          params: { fabricShadeId: compData.object.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Osprzęt dla wersji "${compData.object.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania osprzętu "${compData.object.globalAccessory.name}" z wersją "${compData.object.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadeGlobalAccessoryEntryForm,
    onCreated: fetchFabricShade,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_global_accessory_entries_list",
      params: {
        fabricShadeId: compData.object.fabricShade.id
      }
    })
  }
};
