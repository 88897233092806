<template lang="pug">
    app-view-loadable(:loading="loading")
        .columns
            .column
                slot(name="breadcrumb" :object="object")
                b-button(class="is-pulled-left" v-if="$listeners['to-list']" @click.prevent="$listeners['to-list']") Wróć do listy
            .column.has-text-right.buttons.has-addons.is-narrow
                slot(name="actions")
        slot(:object="object" v-if="object")
        .buttons
            b-button(v-if="$listeners['to-list']" @click.prevent="$listeners['to-list']") Wróć do listy
            slot(name="buttons" :object="object")
</template>
<script>
export default {
  props: {
    resource: { type: String, required: true }
  },
  data() {
    return {
      loading: false,
      object: null
    };
  },
  computed: {
    url() {
      return `${this.resource}/${this.$route.params.id}`;
    }
  },
  async created() {
    try {
      this.loading = true;
      let { data } = await this.$http.get(this.url);
      this.object = data;
      this.$emit("fetched", data);
      this.loading = false;
    } catch (e) {
      this.$notify(
        `Nie udało się pobrać obiektu pod adresem ${this.url}`,
        "danger"
      );
    }
  }
};
</script>
<style>
.is-taller {
  height: 100px;
}
</style>
