<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="thread_colors" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 Nici numerujemy od najjaśniejszej do najciemniejszej
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Kolejność doboru" field="selectionOrder" :errors="errors")
                b-input(v-model="data.selectionOrder" type="number")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  data() {
    return {
    };
  },
  computed: {
    model() {
      return {
        name: "",
        isEnabled: false,
        selectionOrder: 0,
        position: 0,
      };
    },
  },
  methods: {
    transformToSubmit(data) {
      if(data.selectionOrder)data.selectionOrder = parseInt(data.selectionOrder)
      return data;
    },
    transformToModel(data) {
      return data;
    },
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
