import fetchPleatFabric from "../helpers/fetchPleatFabric";
import PleatFabricColorForm from "../components/PleatFabricColorForm.vue";

export default {
  list: {
    permissions: ["pleat_fabric_color_read"],
    actions: [
      {
        label: "Nowy kolor tkaniny",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_fabric_colors_create",
          params: {
            pleatFabricId: route.params.pleatFabricId
          }
        }),
        permissions: ["pleat_fabric_color_create"]
      }
    ],
    route: {
      path: "/pleat_fabric_colors/:pleatFabricId/list",
      name: "pleat_fabric_colors_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_fabrics_list",
          params: { pleatGroupId: {...compData}.pleatFabric?.pleatGroup.id}
        }),
        label: ({ compData }) =>  `Kolekcje tkanin dla grupy "${{...compData}.pleatFabric?.pleatGroup?.name}"`

      },
      {
        label: ({ compData }) => `Kolory dla tkaniny "${compData?.pleatFabric?.name}"`
      }
    ],
    fields: [
      { field: "picture", type: "image", label: "Zdjęcie" },
      { field: "thumbnail", type: "image", label: "Miniatura" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "pleat_fabric_colors",
    url: ({ route }) =>
      `/pleat_fabrics/${route.params.pleatFabricId}/pleat_fabric_colors?properties[]=id&properties[]=name&properties[]=picture&properties[]=isEnabled&properties[]=symbol&properties[]=thumbnail&properties[]=pleatFabric`,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_fabric_color_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_fabric_color_delete"]
      }
    ],
    onCreated: fetchPleatFabric
  },
  create: {
    permissions: ["pleat_fabric_color_create"],
    route: {
      path: "/pleat_fabric_colors/:pleatFabricId/create",
      name: "pleat_fabric_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_fabrics_list",
          params: { pleatGroupId: {...compData}.pleatFabric?.pleatGroup.id}
        }),
        label: ({ compData }) =>  `Kolekcje tkanin dla grupy "${{...compData}.pleatFabric?.pleatGroup?.name}"`

      },
      {
        label: ({ compData }) => `Kolory dla tkaniny "${compData?.pleatFabric?.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy kolor dla tkaniny "${compData.pleatFabric.name}"`
      }
    ],
    formComponent: PleatFabricColorForm,
    listRoute: ({ compData }) => ({
      name: "pleat_fabric_colors_list",
      params: { pleatFabricId: compData.pleatFabric.id }
    }),
    updateRoute: ({ object }) => ({
      name: "pleat_fabric_colors_update",
      params: { id: object.id }
    }),
    onCreated: fetchPleatFabric
  },
  update: {
    permissions: ["pleat_fabric_color_update"],
    route: {
      path: "/pleat_fabric_colors/:id/update",
      name: "pleat_fabric_colors_update"
    },
    resource: "pleat_fabric_colors",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_fabrics_list",
          params: { pleatGroupId: {...compData}.object.pleatFabric?.pleatGroup.id}
        }),
        label: ({ compData }) =>  `Kolekcje tkanin dla grupy "${{...compData}.object.pleatFabric?.pleatGroup?.name}"`

      },
      {
        label: ({ compData }) => `Kolory dla tkaniny "${compData?.object.pleatFabric?.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru "${compData.object.name}" dla tkaniny "${compData.object.pleatFabric.name}"`
      }
    ],
    formComponent: PleatFabricColorForm,
    listRoute: ({ compData }) => ({
      name: "pleat_fabric_colors_list",
      params: { pleatFabricId: compData.object.pleatFabric.id }
    })
  }
};
