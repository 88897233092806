import PleatGroupForm from "../components/PleatGroupForm";

export default {
  list: {
    permissions: ["pleat_group_read"],
    properties: ["id", "position", "name", "isEnabled"],
    actions: [
      {
        label: "Nowa grupa",
        type: "is-primary",
        route: () => ({
          name: "pleat_groups_create"
        }),
        permissions: ["pleat_group_create"]
      }
    ],
    route: {
      path: "/pleat_groups/list",
      name: "pleat_groups_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        label: () => "Grupy cenowe"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Tkaniny",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "pleat_fabrics_list",
            params: { pleatGroupId: row.id }
          })
        }
      }
    ],
    resource: "pleat_groups",
    url: () =>
        `/pleat_groups?properties[]=id&properties[]=name&properties[]=number&properties[]=isEnabled&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_group_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_group_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_group_create"],
    route: {
      path: "/pleat_groups/create",
      name: "pleat_groups_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: () => `Nowa grupa`
      }
    ],
    formComponent: PleatGroupForm,
    listRoute: () => ({ name: "pleat_groups_list" }),
    updateRoute: ({ object }) => ({
      name: "pleat_groups_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["pleat_group_update"],
    route: {
      path: "/pleat_groups/:id/update",
      name: "pleat_groups_update"
    },
    resource: "pleat_groups",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) => `Edycja grupy "${compData.object.name}"`
      }
    ],
    formComponent: PleatGroupForm,
    listRoute: () => ({ name: "pleat_groups_list" })
  }
};
