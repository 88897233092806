<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="wholesale_price_lists")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Marża hurtowa [%]" field="percentMargin" :errors="errors")
                                app-form-decimal(v-model="data.percentMargin")

                            app-form-field(label="Marża kwotowa hurtowa [pln]" field="amountMargin" :errors="errors")
                                app-form-decimal(v-model="data.amountMargin")

                            app-form-field(label="Rabat hurtowy [%]" field="percentDiscount" :errors="errors")
                                app-form-decimal(v-model="data.percentDiscount")

                            app-form-field(label="Rabat kwotowy hurtowy [pln]" field="amountDiscount" :errors="errors")
                                app-form-decimal(v-model="data.amountDiscount")
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        percentMargin: 0,
        amountMargin: 0,
        percentDiscount: 0,
        amountDiscount: 0
      }
    };
  }
};
</script>
