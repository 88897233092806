import FabricForm from "../components/FabricForm";
import fetchFabricShadeGroup from "../helpers/fetchFabricShadeGroup";
import centimeter from "../../../helpers/centimeter";
import decimal from "../../../helpers/decimal";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["fabric_read"],
    actions: [
      {
        label: "Nowa kolekcja",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabrics_create",
          params: {
            fabricShadeGroupId: route.params.fabricShadeGroupId
          }
        }),
        permissions: ["fabric_create"]
      }
    ],
    route: {
      path: "/fabrics/:fabricShadeGroupId/list",
      name: "fabrics_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: () => ({ name: "fabric_shade_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy ${compData.fabricShadeGroup.name}`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      { field: "description", type: "text", label: "Opis", filter: "text" },
      {
        field: "rollWidth",
        type: "text",
        label: "Szerokość rolki [cm]",
        format: decimal
      },
      {
        field: "rollLength",
        type: "text",
        label: "Długość rolki [cm]",
        format: decimal
      },
      {
        field: "hasPattern",
        type: "boolean",
        label: "Wzór",
        filter: "boolean"
      },
      {
        field: "isTurnable",
        type: "boolean",
        label: "Obracanie",
        filter: "boolean"
      },
      {
        field: "hasTurnablePattern",
        type: "boolean",
        label: "Obracanie wzoru",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Kolory",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_colors_list",
            params: { fabricId: row.id }
          })
        }
      }
    ],
    resource: "fabrics",
    url: ({ route }) =>
        `/fabric_shade_groups/${route.params.fabricShadeGroupId}/fabrics?${generateProperties(
            ["id", "name", "symbol", "isEnabled", "number", "description", "hasTurnablePattern", "isTurnable", "hasPattern", "rollWidth", "rollLength", "position", "measurementInstructions"],
            [],
            true
        )}`,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_delete"]
      }
    ],
    onCreated: fetchFabricShadeGroup
  },
  create: {
    permissions: ["fabric_create"],
    route: {
      path: "/fabrics/:fabricShadeGroupId/create",
      name: "fabrics_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: () => ({ name: "fabric_shade_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) =>
          `Nowa kolekcja dla grupy "${compData.fabricShadeGroup.name}"`
      }
    ],
    formComponent: FabricForm,
    listRoute: ({ compData }) => ({
      name: "fabrics_list",
      params: { fabricShadeGroupId: compData.fabricShadeGroup.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabrics_update",
      params: { id: object.id }
    }),
    onCreated: fetchFabricShadeGroup
  },
  update: {
    permissions: ["fabric_update"],
    route: {
      path: "/fabrics/:id/update",
      name: "fabrics_update"
    },
    resource: "fabrics",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: () => ({ name: "fabric_shade_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabrics_list",
          params: { fabricShadeGroupId: compData.object.group.id }
        }),
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy "${compData.object.group.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja tkaniny "${compData.object.name}" dla grupy "${compData.object.group.name}"`
      }
    ],
    formComponent: FabricForm,
    listRoute: ({ compData }) => ({
      name: "fabrics_list",
      params: { fabricShadeGroupId: compData.object.group.id }
    })
  }
};
