<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="global_balks" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Magazyn (symbol)" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                app-form-time-integer(v-model="data.productionTime")

                            app-form-field(label="Kolor belki dolnej" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

                            app-form-field(label="Miniaturka belki dolnej (proporcja 4:3)" field="thumbnail" :errors="errors")
                                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")
                        .column

                            app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                                b-input(v-model="data.message" type="textarea")

                            app-form-field(label="Dostępne kolory tej belki" field="fabricShadeColors" :errors="errors")
                                multiple-resource-select(
                                    v-model="data.fabricShadeColors"
                                    resource="fabric_shade_colors"
                                    url="fabric_shade_colors"
                                    placeholder="Wybierz dostępne kolory"
                                )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        symbol: "",
        name: "",
        picture: "",
        thumbnail: "",
        message: "",
        productionTime: 0,
        fabricShadeColors: []
      },
      files: {
        picture: {},
        thumbnail: {}
      },
      messageHelp:
        "Gdy użytkownik wybierze tą belkę, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToModel(data) {
      data.fabricShadeColors = data.fabricShadeColors.map(
        color => color["@id"]
      );
      return data;
    }
  }
};
</script>
