import AdminWorkerForm from "../components/AdminWorkerForm";

export default {
    list: {
        hasPagination: false,
        permissions: ["admin_worker_read"],
        actions: [
            {
                label: "Nowy pracownik",
                type: "is-primary",
                route: () => ({
                    name: "admin_workers_create"
                }),
                permissions: ["fabric_shade_create"]
            }
        ],
        route: {
            path: "/admin-workers/list",
            name: "admin_workers_list"
        },
        breadcrumbs: [
            {
                label: () => "Pracownicy"
            }
        ],
        fields: [
            {
                field: "firstName",
                type: "text",
                label: "Imię",
                filter: "text"
            },
            {
                field: "lastName",
                type: "text",
                label: "Nazwisko",
                filter: "text"
            },
            {field: "username", type: "text", label: "Login", filter: "text"},
            {field: "email", type: "text", label: "E-mail", filter: "text"},
            {
                field: "isEnabled",
                type: "boolean",
                label: "Aktywny",
                filter: "boolean"
            }
        ],
        resource: "admin_workers",
        url: () =>
            `/admin_workers?properties[]=id&properties[]=firstName&properties[]=lastName&properties[]=username&properties[]=email&properties[]=isEnabled`,
        rowActions: [
            {
                action: "edit",
                permissions: ["admin_worker_update"]
            },
            {
                action: "remove",
                permissions: ["admin_worker_delete"]
            }
        ]
    },
    create: {
        permissions: ["admin_worker_create"],
        route: {
            path: "/admin-workers/create",
            name: "admin_workers_create"
        },
        breadcrumbs: [
            {
                route: () => ({name: "admin_workers_list"}),
                label: () => "Pracownicy"
            },
            {
                label: () => `Nowy pracownik`
            }
        ],
        formComponent: AdminWorkerForm,
        listRoute: () => ({
            name: "admin_workers_list"
        }),
        updateRoute: ({object}) => ({
            name: "admin_workers_update",
            params: {id: object.id}
        })
    },
    update: {
        permissions: ["admin_worker_update"],
        route: {
            path: "/admin-workers/:id/update",
            name: "admin_workers_update"
        },
        resource: "admin_workers",
        breadcrumbs: [
            {
                route: () => ({name: "admin_workers_list"}),
                label: () => "Pracownicy"
            },
            {
                label: ({compData}) =>
                    `Edycja pracownika "${compData.object.username}"`
            }
        ],
        formComponent: AdminWorkerForm,
        listRoute: () => ({name: "admin_workers_list"})
    }
};
