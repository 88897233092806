<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="pleat_fabrics" @object-changed="change" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Symbol" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Opis" field="description" :errors="errors")
                                b-input(v-model="data.description" type="textarea")

                            app-form-field(label="Szerokość rolki (mm)" field="rollWidth" :errors="errors")
                                app-form-length(v-model="data.rollWidth" unit="mm")

                            app-form-field(label="Długość rolki (mm)" field="rollLength" :errors="errors")
                                app-form-length(v-model="data.rollLength" unit="mm")

                            app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                app-form-time-integer(v-model="data.productionTime")

                            app-form-field(label="Korekta garbików" field="tannerCorrection" :errors="errors")
                                b-input(v-model="data.tannerCorrection" type="number")

                            app-form-field(label="Wysokość garbików (mm)" field="tannerHeight" :errors="errors")
                                app-form-decimal(v-model="data.tannerHeight" unit="mm" :precision="4" )


                        .column
                            app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                                b-input(v-model="data.message" type="textarea")

                            app-form-field(label="Grupa" field="pleatGroup" :errors="errors")
                                resource-select(
                                    v-model="data.pleatGroup"
                                    resource="pleat_groups"
                                    url="pleat_groups"
                                    placeholder="Wybierz grupę"
                                    )

                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Cechy tkaniny" field="traitFabrics" :errors="errors")
                                multiple-resource-select(
                                    v-model="data.traitFabrics"
                                    resource="trait_fabrics"
                                    url="trait_fabrics"
                                    placeholder="Wybierz cechy"
                                )

                            app-form-field(field="isEnabledForShop" :errors="errors")
                                b-checkbox(v-model="data.isEnabledForShop") Dostępne dla sklepu

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            div(v-if="!isCreating && data.pleatFabricColors.length > 0")
                                app-form-field(label="Kolor domyślny" field="defaultPleatFabricColor" :errors="errors")
                                      resource-select(
                                        v-model="data.defaultPleatFabricColor"
                                        resource="pleat_fabric_colors"
                                        :url="colors"
                                        nullable
                                      )

                    p.subtitle Maksymalne wysokości dla wersji
                    .is-relative(:class="{ 'is-taller': loading }")
                        b-loading(:active="loading" :is-full-page="false")
                    div(v-show="!loading")
                        app-form-field(
                            v-for="(height, i) in data.pleatFabricHeights"
                            :key="height.pleat.name"
                            horizontal
                            :label="height.pleat.name"
                            field="field"
                            :errors="errors"
                            )
                            app-form-length(v-model="height.height" unit="cm")
                b-tab-item(label="Dokumenty")
                  app-form-field(label="Atesty i certyfikaty" field="measurementInstructions" :errors="errors")
                    app-form-files(v-model="files.measurementInstructions" :image="data.measurementInstructions" @removed="(id)=>{data.measurementInstructions = afterRemovedFiles(id,data.measurementInstructions)}"  :downloadable="true" :multiple='true' accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps')

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      loading: false,
      pleats: [],
      files: {
        measurementinstructions: null
      },
      model: {
        name: "",
        symbol: "",
        description: "",
        message: "",
        rollWidth: 0,
        rollLength: 0,
        tannerCorrection: 0,
        tannerHeight:0,
        position: 0,
        traitFabrics: [],
        measurementInstructions: [],
        isEnabledForShop: false,
        isEnabled: true,
        pleatFabricHeights: [],
        pleatFabricColors: [],
        defaultPleatFabricColor: null,
        productionTime: 0,
        pleatGroup: `/pleat_groups/${this.$route.params.pleatGroupId}`
      },
      colors: `/pleat_fabrics/${this.$route.params.id}/pleat_fabric_colors`,
      isCreating: this.$route.name.includes("create"),
      messageHelp:
        "Gdy użytkownik wybierze tą tkaninę, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToSubmit(data) {
      data.pleatFabricHeights.map(pleatFabricHeight => {
        pleatFabricHeight.pleat = pleatFabricHeight.pleat["@id"];
        return pleatFabricHeight;
      });
      if (data.defaulPleatFabricColor && data.defaulPleatFabricColor["@id"]) {
        data.defaulPleatFabricColor = data.defaulPleatFabricColor["@id"];
      }
      if(data.tannerCorrection){
        data.tannerCorrection= parseInt(data.tannerCorrection)
      }

      return data;
    },
    change(formData) {
      this.pleats.forEach(
        this.addPleatFabricHeightFor(formData.pleatFabricHeights)
      );
    },
    afterRemovedFiles(id, array) {
      if(array.length === 0)return [];
      return array.filter((obj)=> obj.id !== id);
    },
    addPleatFabricHeightFor(collection) {
      return pleat => {
        const found = collection.find(fh => {
          return fh.pleat.id === pleat.id;
        });
        if (!found) {
          collection.push({
            pleat: {
              id: pleat.id,
              ["@id"]: pleat["@id"],
              name: pleat.name
            },
            height: 0
          });
        }
      };
    }
  },
  async created() {
    try {
      this.loading = true;
      let { data } = await this.$http.get("pleats", {
        params: {
          pagination: false,
          properties: ["id", "name"]
        }
      });
      this.pleats = data["hydra:member"];
      this.pleats.forEach(
        this.addPleatFabricHeightFor(this.model.pleatFabricHeights)
      );
    } catch ({ message, response }) {
      if (!response) {
        throw new Error(message);
      }
      if (response.status !== 401) {
        this.$notify(message, "danger");
      }
    }
    this.model.position = await checkLastPosition(this.$router);
    this.loading = false;
  }
};
</script>
