<template lang="pug">
  b-field(:label="label")
    date-picker(
      :value="filters[field]"
      @input="filter({ field: field, value: $event })"
      type="day"
      value-type="format"
      format="YYYY-MM-DD"
      :placeholder="internalPlaceholder"
      :disabled-date="disabledDate"
      )
</template>
<script>
import filterMixin from "../mixins/filterMixin";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker
  },
  mixins: [filterMixin],
  props: {
    placeholder: { type: String },
    disabledDate: { type: Function, default: date => date > new Date() }
  },
  computed: {
    internalPlaceholder() {
      return this.placeholder || this.label.toLowerCase() + "...";
    }
  }
};
</script>
