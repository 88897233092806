import PleatPleatFixingTypeEntryForm from "../components/PleatPleatFixingTypeEntryForm";
import fetchPleat from "../helpers/fetchPleat";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
    list: {
        permissions: ["pleat_pleat_fixing_type_entry_read"],
        actions: [
            {
                label: "Nowe wiązanie",
                type: "is-primary",
                route: ({ route }) => ({
                    name: "pleat_pleat_fixing_type_entries_create",
                    params: {
                        pleatId: route.params.pleatId
                    }
                }),
                permissions: ["pleat_pleat_fixing_type_entry_create"]
            }
        ],
        route: {
            path:
                "/pleat_pleat_fixing_type_entries/:pleatId/list",
            name: "pleat_pleat_fixing_type_entries_list"
        },
        sortable: true,
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Wersje"
            },
            {
                label: ({ compData }) =>
                    `Wiązania typów mocowań dla wersji "${compData.pleat.name}"`
            }
        ],
        fields: [
            {
                field: "pleatFixingType.name",
                type: "text",
                label: "Nazwa typu mocowania",
                filter: "text"
            },
            {
                field: "widthSurcharge",
                type: "text",
                label: "Dopłata szerokości",
                format: decimal
            },
            {
                field: "pieceSurcharge",
                type: "text",
                label: "Dopłata od sztuki",
                format: decimal
            },
            {
                field: "isEnabled",
                type: "boolean",
                label: "Aktywny",
                filter: "boolean"
            },
        ],
        resource: "pleat_pleat_fixing_type_entries",
        url: ({ route }) =>
            `/pleats/${route.params.pleatId}/pleat_pleat_fixing_type_entries?` +
            generateProperties(
                [],
                ["id", "position", "isEnabled", "widthSurcharge", "pieceSurcharge"]
            ) +
            generateProperties(["pleatFixingType"], ["name"], true)
        ,
        onCreated: fetchPleat,
        rowActions: [
            {
                action: "edit",
                permissions: ["pleat_pleat_fixing_type_entry_update"]
            },
            {
                action: "remove",
                permissions: ["pleat_pleat_fixing_type_entry_delete"]
            }
        ]
    },
    create: {
        permissions: ["pleat_pleat_fixing_type_entry_create"],
        route: {
            path:
                "/pleat_pleat_fixing_type_entries/:pleatId/create",
            name: "pleat_pleat_fixing_type_entries_create"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Wersje"
            },
            {
                route: ({ compData }) => ({
                    name: "pleat_pleat_fixing_type_entries_list",
                    params: { pleatId: compData.pleat.id }
                }),
                label: ({ compData }) =>
                    `Wiązania typów mocowań dla wersji "${compData.pleat.name}"`
            },
            {
                label: ({ compData }) =>
                    `Nowe wiązanie dla wersji "${compData.pleat.name}"`
            }
        ],
        formComponent: PleatPleatFixingTypeEntryForm,
        onCreated: fetchPleat,
        listRoute: ({ compData }) => ({
            name: "pleat_pleat_fixing_type_entries_list",
            params: { pleatId: compData.pleat.id }
        }),
        updateRoute: ({ object }) => ({
            name: "pleat_pleat_fixing_type_entries_update",
            params: {
                id: object.id
            }
        })
    },
    update: {
        permissions: ["pleat_pleat_fixing_type_entry_update"],
        route: {
            path: "/pleat_pleat_fixing_type_entries/:id/update",
            name: "pleat_pleat_fixing_type_entries_update"
        },
        resource: "pleat_pleat_fixing_type_entries",
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Wersje"
            },
            {
                route: ({ compData }) => ({
                    name: "pleat_pleat_fixing_type_entries_list",
                    params: {
                        pleatId: compData.object.pleat.id
                    }
                }),
                label: ({ compData }) =>
                    `Wiązania typów mocowań dla wersji "${compData.object.pleat.name}"`
            },
            {
                label: ({ compData }) =>
                    `Edycja wiązania typu mocowania "${compData.object.pleatFixingType.name}" z wersją "${compData.object.pleat.name}"`
            }
        ],
        formComponent: PleatPleatFixingTypeEntryForm,
        onCreated: fetchPleat,
        listRoute: ({ compData }) => ({
            name: "pleat_pleat_fixing_type_entries_list",
            params: {
                pleatId: compData.object.pleat.id
            }
        })
    }
};
