<template lang="pug">
  app-form(resource="external_shutter_price_lists" v-bind="props" v-on="listeners" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              resource-select(
                v-model="data.externalShutterArmor"
                resource="external_shutter_armors"
                url="external_shutter_armors"
                placeholder="Wybierz pancerz"
              )
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position= +$event" :value="data.position")
            .column
            .column
        b-tab-item(label="Cennik")
         .has-text-centered.has-font-25 Pamiętaj, jednostka w cennikach to milimetry
          .is-clearfix.has-margin-bottom
             button.button.is-pulled-right(@click.prevent="clear(data)") Wyczyść
          table.table.price-list-table
            tbody
              tr(v-for="(_, row) in rows" :key="row")
                td(v-for="(_, col) in cols" :key="col")
                  div(v-if="row === 0 && col === 0")
                    b-input(size="is-small" @paste.native.prevent="onPaste($event, data)" placeholder="Wklej tutaj treść z Excela")
                  div(v-else)
                    app-form-length(
                      classes="has-text-weight-bold has-background-gray"
                      v-if="row === 0 || col === 0"
                      size="is-small"
                      :value="getValueFor(row, col, data)"
                      @input="setValueFor(row, col, data, $event)"
                      unit="cm"
                    )
                    app-form-decimal-integer(
                      v-else
                      size="is-small"
                      :value="getValueFor(row, col, data)"
                      @input="setValueFor(row, col, data, $event)"
                    )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import onPaste from "../../../helpers/onPaste";
import http from "../../../http";
import ResourceSelect from "../../common/components/ResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  methods: {
    getValueFor(row, col, data) {
      const item = data.externalShutterPriceListEntries.find(
        item => item.row === row && item.col === col
      );
      if (item) {
        return item.value;
      }
      return null;
    },
    setValueFor(row, col, data, value) {
      const item = data.externalShutterPriceListEntries.find(
        item => item.row === row && item.col === col
      );
      if (item) {
        item.value = value;
      } else {
        data.externalShutterPriceListEntries.push({
          row,
          col,
          value
        });
      }
    },
    transformToSubmit(data) {
      data.externalShutter = data.externalShutter["@id"] || data.externalShutter;
      data.externalShutterArmor = data.externalShutterArmor["@id"] || data.externalShutterArmor ;
      data.externalShutterPriceListEntries = data.externalShutterPriceListEntries.filter(
        entry =>
          entry.row !== null && entry.col !== null && entry.value !== null
      );
      return data;
    },
    clear(data) {
      if (confirm("Czy na pewno wyczyścić wszystkie pola?")) {
        data.externalShutterPriceListEntries = [];
      }
    },
    onPaste(event, data) {
      const value = event.clipboardData.getData("text");
      data.externalShutterPriceListEntries = onPaste(value);
    }
  },
  computed: {
    model() {
      return {
        externalShutter: {},
        externalShutterArmor: null,
        externalShutterPriceListEntries: [],
        isEnabled: false,
        position: 0
      };
    },
    externalShutter() {
      return this.object && this.object.externalShutter || this.model.externalShutter || {};
    },
    rows() {
      return this.externalShutter.priceListRows;
    },
    cols() {
      return this.externalShutter.priceListCols;
    }
  },
  async created() {
    if (this.$route.params.externalShutterId) {
      const { data } = await http.get(`/external_shutters/${this.$route.params.externalShutterId}?properties[]=priceListCols&properties[]=priceListRows`);
      this.model.externalShutter = data;
      this.model.position = await checkLastPosition(this.$router);

    }
  }
};
</script>
