<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="countries" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywna

                            app-form-field(label="Dostępne stawki vat dla tego kraju" field="vatRates" :errors="errors")
                                multiple-resource-select(
                                    v-model="data.vatRates"
                                    resource="vat_rates"
                                    url="vat_rates"
                                    placeholder="Wybierz dostępne stawki VAT"
                                )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        isEnabled: true,
        vatRates: []
      }
    };
  },
  methods: {
    transformToModel(object) {
      this.model.vatRates = object.vatRates.map(vatRate => vatRate["@id"]);

      return object;
    }
  }
};
</script>
