import SupportInstructionForm from "../components/SupportInstructionForm";

export default {
  list: {
    permissions: ["support_instructions_read"],
    actions: [
      {
        label: "Nowa instrukcja",
        type: "is-primary",
        route: () => ({
          name: "support_instructions_create"
        }),
        permissions: ["support_instructions_create"]
      }
    ],
    route: {
      path: "/support_instructions/list",
      name: "support_instructions_list"
    },
    countable: true,
    breadcrumbs: [
      {
        label: () => "Instrukcje"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "isEnabled", type: "boolean", label: "Aktywny" }
    ],
    resource: "support_instructions",
    url: () => `/support_instructions`,
    rowActions: [
      {
        action: "edit",
        permissions: ["support_instructions_update"]
      },
      {
        action: "remove",
        permissions: ["support_instructions_delete"]
      }
    ]
  },
  create: {
    permissions: ["support_instructions_create"],
    route: {
      path: "/support_instructions/create",
      name: "support_instructions_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "support_instructions_list" }),
        label: () => "Instrukcje"
      },
      {
        label: () => `Nowa Instrukcja`
      }
    ],
    formComponent: SupportInstructionForm,
    listRoute: () => ({ name: "support_instructions" }),
    updateRoute: ({ object }) => ({
      name: "support_instructions_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["support_instructions_update"],
    route: {
      path: "/support_instructions/:id/update",
      name: "support_instructions_update"
    },
    resource: "support_instructions",
    breadcrumbs: [
      {
        route: () => ({ name: "support_instructions_list" }),
        label: () => "Instrukcje"
      },
      {
        label: ({ compData }) => `Edycja instrukcji "${compData.object.name}"`
      }
    ],
    formComponent: SupportInstructionForm,
    listRoute: () => ({ name: "support_instructions_list" })
  }
};
