<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_external_shutter_runner_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 W danej wersji ta sama prowadnica nie może występować więcej niż 1 raz
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Prowadnica" field="externalShutterRunner" :errors="errors")
                resource-select(
                  v-model="data.externalShutterRunner"
                  resource="external_shutter_runners"
                  url="external_shutter_runners"
                  placeholder="Wybierz prowadnicę"
                  label="name"
                )

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  computed: {
    model() {
      return {
        isEnabled: false,
        position: 0,
        surfaceSurcharge: 0,
        externalShutterRunner: null,
        externalShutter: `/external_shutters/${this.$route.params.externalShutterId}`
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.externalShutter) {
        data.externalShutter = data.externalShutter["@id"] || data.externalShutter;
      }
      if (data.externalShutterRunner) {
        data.externalShutterRunner = data.externalShutterRunner["@id"] || data.externalShutterRunner;
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
