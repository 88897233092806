import fetchFabricShadeDayNight from "../helpers/fetchFabricShadeDayNight";
import FabricShadeDayNightPriceListForm from "../components/FabricShadeDayNightPriceListForm";

export default {
  list: {
    permissions: ["fabric_shade_day_night_price_list_read"],
    sortField:"groupDayNight.position",
    sortOrder:'asc',
    route: {
      path: "/fabric_shade_day_night_price_lists/:fabricShadeDayNightId/list",
      name: "fabric_shade_day_night_price_lists_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        label: ({ compData }) =>
          `Cennik bazowy dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    fields: [
      {
        field: "groupDayNight.position",
        type: "text",
        label: "Pozycja",
        filter: "text"
      },
      {
        field: "groupDayNight.name",
        type: "text",
        label: "Nazwa grupy",
        filter: "text"
      }
    ],
    resource: "fabric_shade_day_night_price_lists",
    url: ({ route }) =>
      `/fabric_shade_day_nights/${route.params.fabricShadeDayNightId}/fabric_shade_day_night_price_lists?properties[]=groupDayNight&properties[]=id`,
    onCreated: fetchFabricShadeDayNight,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_day_night_price_list_update"]
      }
    ]
  },
  update: {
    permissions: ["fabric_shade_day_night_price_list_update"],
    route: {
      path: "/fabric_shade_day_night_price_lists/:id/update",
      name: "fabric_shade_day_night_price_lists_update"
    },
    resource: "fabric_shade_day_night_price_lists",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_price_lists_list",
          params: { fabricShadeDayNightId: compData.object.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Cennik bazowy dla wersji "${compData.object.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja cennika bazowego grupy "${compData.object.groupDayNight.name}" dla wersji "${compData.object.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightPriceListForm,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_price_lists_list",
      params: {
        fabricShadeDayNightId: compData.object.fabricShadeDayNight.id
      }
    })
  }
};
