import FabricShadeColorForm from "../components/FabricShadeColorForm";

export default {
  list: {
    permissions: ["fabric_shade_color_read"],
    actions: [
      {
        label: "Nowy kolor",
        type: "is-primary",
        route: () => ({
          name: "fabric_shade_colors_create"
        }),
        permissions: ["fabric_shade_color_create"]
      }
    ],
    route: {
      path: "/fabric_shade_colors/list",
      name: "fabric_shade_colors_list"
    },
    sortable: false,
    sortField:"name",
    sortOrder:'asc',
    breadcrumbs: [
      {
        label: () => `Kolory osprzętu`
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "fabric_shade_colors",
    url: () =>
      `/fabric_shade_colors?properties[]=id&properties[]=name&properties[]=isEnabled`,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_color_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_color_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_color_create"],
    route: {
      path: "/fabric_shade_colors/create",
      name: "fabric_shade_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "fabric_shade_colors_list"
        }),
        label: () => `Kolory osprzętu`
      },
      {
        label: () => `Nowy kolor`
      }
    ],
    formComponent: FabricShadeColorForm,
    listRoute: () => ({
      name: "fabric_shade_colors_list"
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_colors_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_color_update"],
    route: {
      path: "/fabric_shade_colors/:id/update",
      name: "fabric_shade_colors_update"
    },
    resource: "fabric_shade_colors",
    breadcrumbs: [
      {
        route: () => ({
          name: "fabric_shade_colors_list"
        }),
        label: () => `Kolory osprzętu`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru osprzętu "${compData.object.name}"`
      }
    ],
    formComponent: FabricShadeColorForm,
    listRoute: () => ({
      name: "fabric_shade_colors_list"
    })
  }
};
