<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="news")
        template(#default="{ data, errors }")

            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                  b-tabs(type="is-boxed")
                    b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
                      app-form-field(:label="`Tytuł (${value})`" :field="`title${value}`" :errors="errors")
                        b-input(v-model="data[`title${value}`]")
                      app-form-field(:label="`Treść (${value})`" :field="`content${value}`" :errors="errors")
                        app-form-editor(v-model="data[`content${value}`]")

                  app-form-field(label="Typy produktów" field="productTypes" :errors="errors")
                    multiple-resource-select(
                      v-model="data.productTypes"
                      resource="product_types"
                      url="product_types"
                      placeholder="Wybierz typy produktów"
                      extra-props="&properties[]=name"
                      :labels="['name']"
                    )

                  .columns
                    .column
                      app-form-field(label="Data ważności od" field="dateFrom" :errors="errors")
                        app-form-datepicker(v-model="data.dateFrom")
                    .column
                      app-form-field(label="Data ważności do" field="dateTo" :errors="errors")
                        app-form-datepicker(v-model="data.dateTo")

                  .columns
                    .column.is-narrow
                      app-form-field(label="Priorytet" field="priority" :errors="errors")
                        b-select(placeholder="Priorytet" v-model="data.priority")
                          option(value="niski") Niski
                          option(value="sredni") Średni
                          option(value="wysoki") Wysoki
                          option(value="zgoda") Zgoda
                    .column
                      | Status Niski: zwykłe wyświetlenie newsa <br>
                      | Status Średni: dodanie przycisku 'Potwierdź przeczytanie newsa' <br>
                      | Status Wysoki: wymuszenie przeczytania news'a poprzez zablokowanie menu <br>
                      | Status Zgoda: wyrażenie zbgody na warunki zaprezentowane w wiadomości

                  app-form-field(field="isAttached" :errors="errors")
                    b-checkbox(v-model="data.isAttached") Przyczepiony

                  app-form-field(field="isEnabled" :errors="errors")
                    b-checkbox(v-model="data.isEnabled") Aktywny

                  app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                    app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

                  app-form-field(label="Zdjęcie 2 (proporcja 4:3)" field="secondPicture" :errors="errors")
                    app-form-file(v-model="files.secondPicture" :image="data.secondPicture" @removed="data.secondPicture = {}")
</template>
<script>
import { mapGetters } from "vuex";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import moment from "moment";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      files: {
        picture: {},
        secondPicture: {}
      },
      model: {
        titlePL: "",
        titleCZ: "",
        titleEN: "",
        titleDE: "",
        titleFR: "",
        titleSE: "",
        titleIT: "",
        titleSI: "",
        titleRU: "",
        titleES: "",
        titleNL: "",
        contentPL: "",
        contentCZ: "",
        contentEN: "",
        contentDE: "",
        contentFR: "",
        contentSE: "",
        contentIT: "",
        contentSI: "",
        contentRU: "",
        contentES: "",
        contentNL: "",
        isEnabled: true,
        isAttached: true,
        picture: null,
        secondPicture: null,
        productTypes: [],
        dateFrom: null,
        dateTo: null,
        priority: "niski"
      }
    };
  },
  computed: {
    ...mapGetters(["languages"])
  }
};
</script>
