import ProductionLineForm from "../components/ProductionLineForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    sortable: false,
    hasPagination: false,
    permissions: ["production_line_read"],
    route: {
      path: "/production_lines/list",
      name: "production_lines_list"
    },
    breadcrumbs: [
      {
        label: () => "Linie produkcyjne"
      }
    ],
    fields: [
      {
        field: "productType.name",
        type: "text",
        label: "Typ produktu",
        filter: "text"
      },
      {
        field: "name",
        type: "text",
        label: "Linia produkcyjna"
      },
      {
        field: "fittersCount",
        type: "text",
        label: "Monterzy"
      },
    ],
    resource: "production_lines",
    url: () =>
      `/production_lines?` +
      generateProperties(
        [],
        ["id", "name", "date", "fittersCount"]
      ) +
      generateProperties(
        ['productType'],
        ['name'],
        true
        ),
    rowActions: [
      {
        action: "edit",
        permissions: ["production_lines_update"]
      },
    ]
  },
  update: {
    permissions: ["production_line_update"],
    route: {
      path: "/production_lines/:id/update",
      name: "production_lines_update"
    },
    resource: "production_lines",
    breadcrumbs: [
      {
        route: () => ({ name: "production_lines_list" }),
        label: () => "Linie produkcyjne"
      },
      {
        label: ({ compData }) =>
          `Edycja Lini produkcyjnej "${compData.object.name}" dla typu produktu "${compData.object.productType.name}"`
      }
    ],
    formComponent: ProductionLineForm,
    listRoute: () => ({ name: "production_lines_list" })
  }
};
