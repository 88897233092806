export default function calculatePieces(item, lengthUnit) {
    const {detailUnit, pieces} = item;

    switch (detailUnit?.symbol) {
        case 'mb':
            return lengthUnit === 'cm' ? pieces / 10 : pieces ;
        case 'rozpak':
            return  pieces / 100;
        case 'opak':
            return pieces / 100;
        default:
            return 1;
    }
}