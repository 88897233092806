<template lang="pug">
  div Produkcja
    br
    br
    .panel
      .panel-heading Eksport do csv
      .panel-block
        .columns
          .column
            b-field(label="Data od")
              DatePicker(v-model="beginReportDate" type="day" value-type="format" format="YYYY-MM-DD" :disabled-date="(date) => compareStartExportDate(date)" :clearable="false")
          .column
            b-field(label="Data do")
              DatePicker(v-model="endReportDate" type="day" value-type="format" format="YYYY-MM-DD" :disabled-date="(date) => compareEndExportDate(date)" :clearable="false")
          .column
            b-field(label="Pobierz dane")
              b-button(type="is-primary" @click="generateCsv") Generuj
    app-table-store(store-namespace="productionOrdersList")
      template(#filters="props")
        .columns
          .column
            app-table-filter-date(
              label="Data produkcji"
              field="productionDate"
              v-bind="props"
            )
          .column
            app-table-filter-text(
              label="Numer zamówienia"
              field="number"
              v-bind="props"
              :time="1000"
              :value-changer-to-filter="numberFlatter"
              :value-changer-to-input="numberCreator"
            )
          .column
            app-table-filter-text(
              label="Odbiorca"
              field="companiesShortNames"
              v-bind="props"
            )

      template(#headers="props")
        th.has-max-width-20
          b-checkbox(@input="checkAll($event, props.list)" :value="selectedOrders.length === props.list.length")
        app-table-head(v-bind="props" label="Data Produkcji")
        app-table-head(v-bind="props" label="Nr zamówienia")
        app-table-head(v-bind="props" label="Kategoria")
        app-table-head(v-bind="props" label="Sztuk")
        app-table-head(v-bind="props" label="Produkcja")
        app-table-head(v-bind="props" label="Firma")
        app-table-head(v-bind="props" label="Klient")

      template(#default="{ row, perPage, total }")
        td.has-max-width-20
          b-checkbox(v-model="selectedOrders" :native-value="row.id")
        td {{ getOrderDate(row) }}
        td {{ orderNumber(row.number) }}
        td {{ getOrderCategory(row) }}
        td {{ countOrderItems(row) }}
        td {{ getProductionWorkersInitials(row) }}
        td {{ getCompanyInfo(row) }}
        td {{ getCustomerInfo(row) }}
        td
          b-button(size="is-small" type="is-info" @click="showOrder(row)")
            b-icon(size="is-small" icon="eye")
          b-button(size="is-small" type="is-primary" @click="editOrder(row)")
            b-icon(size="is-small" icon="pencil")
    br
    .divider
    br
    .columns.is-multiline
      .column
        b-checkbox(v-model="ordersAreConfirmed" required) Zatwierdzam wyprodukowanie zaznaczonych zamówień
      .column.is-12
        b-button(type="is-primary" @click="confirmOrders") Zatwierdź
    br
    .divider
    br
    ProductionOrderPreview(:active="isModalActive" :order-id="orderId" @close="isModalActive=false")
    ProductionOrderEdit(:active="isEditModalActive" :order-id="editOrderId" @close="onEditOrderClose")
</template>
<script>
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import moment from "moment";
import orderNumberFlatter from "../../../helpers/orderNumberFlatter";
import orderItemGetter from "../../../helpers/orderItemGetter";
import ProductionOrderPreview from "../../common/components/ProductionOrderPreview";
import ProductionOrderEdit from "../../common/components/ProductionOrderEdit";
import notify from "../../../helpers/notify";
import http from "../../../http";
import AppTableFilterDate from "../../../plugins/app/table/components/AppTableFilterDate";
import productTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    AppTableFilterDate,
    ProductionOrderPreview,
    ProductionOrderEdit,
    DatePicker
  },
  data() {
    return {
      selectedOrders: [],
      ordersAreConfirmed: false,
      isModalActive: false,
      orderId: null,
      isEditModalActive: false,
      editOrderId: null,
      beginReportDate: moment().format("YYYY-MM-DD"),
      endReportDate: moment()
        .add(5, "days")
        .format("YYYY-MM-DD")
    };
  },
  methods: {
    compareStartExportDate(date) {
      return moment(date).isAfter(moment(this.endReportDate));
    },
    compareEndExportDate(date) {
      return moment(date).isBefore(moment(this.beginReportDate));
    },
    onEditOrderClose() {
      this.isEditModalActive = false;
      this.$store.dispatch("productionOrdersList/fetch").then(() => {
        for (const idx in this.selectedOrders) {
          let found = false;
          this.$store.getters["productionOrdersList/list"].forEach(el => {
            if (el.id === this.selectedOrders[idx]) {
              this.selectedOrders[idx] = el.id;
              found = true;
            }
          });
          if (!found) {
            this.selectedOrders.slice(idx, 1);
          }
        }
      });
    },
    editOrder(row) {
      this.editOrderId = row.id;
      this.isEditModalActive = true;
    },
    showOrder(row) {
      this.orderId = row.id;
      this.isModalActive = true;
    },
    async confirmOrders() {
      if (this.selectedOrders.length < 1) {
        notify("Nie zaznaczono żadnego zamówienia!", "warning");
        return;
      }

      let atLestOneOrderHasNoWorkers = false;

      for (const idx in this.selectedOrders) {
        const order = this.$store.getters["productionOrdersList/list"][
              this.$store.getters["productionOrdersList/list"].findIndex(
                  item => item.id === this.selectedOrders[idx]
              )
            ];
        if (
          order['@type'] !== 'FixedOrder' &&
          order.sawyerProductionWorkers.length < 1
        ) {
          atLestOneOrderHasNoWorkers = true;
          notify(
            "Conajmniej jedno zaznaczone zamówienie nie posiada przypisanego pilarza!",
            "warning"
          );
        }
        if (
         order.fitterProductionWorkers.length < 1
        ) {
          atLestOneOrderHasNoWorkers = true;
          notify(
            "Conajmniej jedno zaznaczone zamówienie nie posiada przypisanego montera!",
            "warning"
          );
        }
        if (atLestOneOrderHasNoWorkers) {
          break;
        }
      }

      if (atLestOneOrderHasNoWorkers) {
        return;
      }

      
      if (!this.ordersAreConfirmed) {
        notify(
          "Nie zatwierdzono wyprodukowania zaznaczonych zamówień!",
          "warning"
        );
        return;
      }

      const body = { orders: this.selectedOrders };

      await http
        .post("order_ends", body)
        .then(() => {
          notify("Zamknięto zamówienia.");
          this.selectedOrders = [];
          this.ordersAreConfirmed = false;
          this.$store.dispatch("productionOrdersList/fetch");
        })
        .catch(exception => {
          notify("Podczas zamykania zamówień wystąpił błąd.", "warning");
        });
    },
    checkAll(event, list) {
      if (event) {
        this.selectedOrders = list.map(item => item.id);
      } else {
        this.selectedOrders = [];
      }
    },
    numberFlatter(value) {
      return orderNumberFlatter(value);
    },
    numberCreator(value) {
      return orderNumberCreator(value);
    },
    orderNumber(number) {
      return orderNumberCreator(number);
    },
    getOrderDate(row) {
      return moment(row.productionDate, "DD-MM-YYYY hh:mm:ss").format(
        "YYYY-MM-DD"
      );
    },
    getOrderCategory(row) {
      let cat = '';
      
      if (row['@type'] === "FixedOrder") {
        cat = "(FX) ";
      }
      return cat + productTypeFullNameByOrderCategory[row.category];
    },
    countOrderItems(row) {
      if ('FixedOrder' === row['@type']) {
          return row.quantity;
      }
      const items = this.getOrderItems(row);
      if (!items) {
        return 0;
      }
      let counter = 0;
      for (const idx in items) {
        counter += items[idx].quantity;
      }
      return counter;
    },
    getOrderItems(order) {
      return orderItemGetter(order);
    },
    getCustomerInfo(order) {
      if (!order || !order.customer) {
        return "";
      }
      const customer = order.customer;
      if (customer.type === "person") {
        return (
          (customer.lastName ? customer.lastName : "") +
          (customer.city ? ` (${customer.city})` : "")
        );
      }
      if (customer.type === "company") {
        return (
          (customer.companyName ? customer.companyName : "") +
          (customer.taxId ? ` (${customer.taxId})` : "")
        );
      }
      return "";
    },
    getCompanyInfo(order) {
      const company = order.company || order.subCompany;
      if (company) {
        return company.shortName;
      } else {
        return 'Brak';
      }
    },
    getProductionWorkersInitials(order) {
      if (!order) {
        return "";
      }
      const productionWorkers = order.fitterProductionWorkers.concat(
        order.sawyerProductionWorkers
      );
      let initials = "";
      for (const idx in productionWorkers) {
        if (parseInt(idx) === productionWorkers.length - 1) {
          if (!initials.includes(productionWorkers[idx].initials)) {
            initials += productionWorkers[idx].initials;
          }
        } else {
          if (!initials.includes(productionWorkers[idx].initials)) {
            initials += productionWorkers[idx].initials + ", ";
          }
        }
      }
      return initials;
    },
    generateCsv() {
      http
        .get(
          `/api/generate/csv?beginDate=${this.beginReportDate}&endDate=${this.endReportDate}`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `raport-${this.beginReportDate}-${this.endReportDate}.csv`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          notify("Nie udało się pobrać pliku csv", "warning");
        });
    }
  },
  computed: {
    list() {
      return this.$store.getters["productionOrderList/list"];
    }
  },
  watch: {
    "$store.state.route.query": function() {
      this.selectedOrders = [];
    }
  },
  created() {
    this.$store.dispatch("productionOrdersList/fetch");
  }
};
</script>
