<template lang="pug">
    b-field
        b-upload(:value="value" @input="$emit('input', $event)" :disabled="disabled")
            a(class="button is-primary" :disabled="disabled")
                b-icon(icon="upload")
                span Wskaż plik
        span(class="image-name" v-if="!image && value && value.name") {{ value.name }}
        span(class="" v-if="image && image.contentUrl")
            .card
                .card-image
                    figure.image(v-if="!downloadable")
                        img(:src="image.contentUrl")
                .card-content(v-if="downloadable")
                    span {{ downloadText }}
                .card-footer
                    p.card-footer-item
                        b-button(
                            type="is-danger"
                            icon-left="mdi mdi-delete"
                            @click.prevent="remove"
                            :loading="removing"
                            :disabled="disabled"
                            ) Usuń
                    p.card-footer-item(v-if="downloadable")
                        a(
                            class="button is-primary"
                            :href="this.image.contentUrl"
                            target="_blank"
                            download
                        ) Pobierz/Wydrukuj plik

</template>
<script>
export default {
  props: {
    value: { type: [File, Object] },
    image: { type: [Object, String] },
    downloadable: { type: Boolean, default: false },
    downloadText: { type: String, default: "Instrukcja montażu" },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      removing: false
    };
  },
  methods: {
    async remove() {
      try {
        this.removing = true;
        await this.$http.delete(`media_objects/${this.image.id}`);
        this.$emit("input", {});
        this.$emit("removed");
        this.removing = false;
      } catch (e) {
        this.removing = false;
      }
    }
  }
};
</script>
