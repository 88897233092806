<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="global_nets" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol")

            .column
              app-form-field(label="Komunikat" field="message" :errors="errors")
                b-input(v-model="data.message" type="textarea")
        b-tab-item(label="Pliki")
          .columns
            .column
              app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
            .column
              app-form-field(label="Zdjęcie (proporcja 4:3)" field="thumbnail" :errors="errors")
                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")
          .columns
            .column
              app-form-field(label="Instrukcje" field="pictures" :errors="errors")
                app-form-files(v-model="files.instructions" :image="data.instructions" @removed="(id)=>{data.instructions = afterRemovedFiles(id,data.instructions)}" :multiple='true' accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps')


</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        symbol: "",
        message: "",
        instructions: [],
        thumbnail: "",
        picture: "",
      },
      files: {
        picture: {},
        instructions: null,
        thumbnail: null,
      }
    };
  },
  methods: {
    afterRemovedFiles(id, array) {
      if (array.length === 0) return [];
      return array.filter((obj) => obj.id !== id);
    },
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      return data;
    }
  }
};
</script>
