<template lang="pug">
  div.container.is-fullhd
    template
      table.table.is-bordered.has-cells-centered.is-narrow.is-fullwidth.print-table
        thead
          tr
            th(colspan=10)  Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
          tr
            th(rowspan=2) Lp.
            th(rowspan=2) Wersja
            th(rowspan=2) Kolor ramy
            th(rowspan=2) Siatka
            th(rowspan=2) Szer.
            th(rowspan=2) Wys.
            th(rowspan=2) Sztuk
            th(rowspan=2) Dod.
            th(rowspan=2) Miejsce
        tbody
          tr(v-for="(item, index) in orderItems(object)")
            td {{ index + 1 }}
            td {{ prop(item.rollMosquitoNet, 'name') }}
            td {{ prop(item.rollMosquitoNetGlobalAccessoryEntry, "name", 'globalAccessory') }}
            td {{ prop(item.rollMosquitoNetGlobalNetEntry, "name", 'globalNet') }}
            td {{ item.width / 10 }}
            td {{ item.height / 10 }}
            td {{ item.quantity }}
            td
              p(v-if="!item.rollMosquitoNetAdditionalEntries.length") Brak
              p(v-for="(entry, indexAdditional) in item.rollMosquitoNetAdditionalEntries") {{ prop(entry, "name", "rollMosquitoNetAdditional") }}
            td {{ item.shortDescription }}
</template>
<script>

import prop from "../../../helpers/propFunc";
import getOrderItems from "../../../helpers/orderItems";

export default {
  props: ['object'],
  methods: {
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
  }
};
</script>
