<template lang="pug">
  app-view-list(:loading="data.loading")
    template(#breadcrumb)
      app-view-breadcrumbs(:breadcrumbs="breadcrumbs" :data="data")

    template(#actions)
      b-button(
        v-for="(action,id) in allowedActions"
        :type="action.type"
        tag="router-link"
        :to="getActionRoute(action)"
        :key="id"
      ).has-margin-left-12 {{ action.label }}

    app-table(
      ref="appTable"
      :url="getUrl()"
      :resource="resource"
      :sortable="sortable"
      :countable="countable"
      :properties="properties"
      :hide-filters="hideFilters"
      :row-actions="rowActions"
      :has-pagination="hasPagination"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :items-per-page="itemsPerPage"
      :rowColorFunction="rowColorFunction"
      :narrow="narrow"
    )
      template(#filters="props")
        .columns
          template(v-for="{filter, label, field, filterOptions, filterExtraField} in filters")
            .column(v-if="filter === 'text'")
              app-table-filter-text(
                :label="label"
                :field='field + (filterExtraField ? "["+filterExtraField + "]" : ``)'
                v-bind="props"
              )
            .column(v-if="filter === 'exists'")
              app-table-filter-exists(
                :label="label"
                :field="field"
                v-bind="props"
              )
            .column(v-if="filter === 'boolean'")
              app-table-filter-boolean(
                :label="label"
                :field="field"
                v-bind="props"
              )
            .column(v-if="filter === 'select'")
              app-table-filter-select(
                :label="label"
                :field="field"
                v-bind="props"
              )
                option(v-for="{label, value} in filterOptions" :value="value") {{ label }}
            .column(v-if="filter === 'datepicker'")
              app-table-filter-date(
                :label="label"
                :field="field"
                v-bind="props"
              )

      template(#headers="props")
        app-table-head(
          v-for="({label, sortable, disabledHead}, id) in headers"
          v-bind="props"
          :label="label"
          :disabled-head="disabledHead"
          :sortable="sortable"
          :key="id"
        )

      template(#default="{ row, perPage, total, reload }")
        template(v-for="header in headers")

          td(v-if="header.type === 'virtual'") {{ header.value(header, row) }}

          td(v-if="header.type === 'text'") {{ getValue(header, row) }}

          td(v-if="header.type === 'simplebool'") {{ getValue(header, row) ? "TAK" : "NIE" }}

          td(v-if="header.type === 'boolean'")
            app-button-toggle(
              :resource="resource"
              :object="row"
              :field="header.field"
            )

          td(v-if="header.type === 'booleanStatus'")
            app-button-toggle-status(
              :resource="resource"
              :object="row"
              :field="header.field"
              :status_true="header.status_true"
              :status_false="header.status_false"
            )

          td(v-if="header.type === 'image'")
            figure(v-if="getImage(header.field, row)" class="image is-4by3")
              img(:src="getImage(header.field, row)")
            template(v-else) Brak

          td(v-if="header.type === 'link'")
            router-link(:to="header.link.route({row})") {{ header.link.label }}

          td(v-if="header.type === 'links'")
            template(v-for="link in header.links")
              router-link(:to="link.route({row})") {{ link.label }}
              template(v-if="header.links.indexOf(link) !== header.links.length - 1") |

          td(v-if="header.type === 'buttons'")
            .buttons.has-addons
              template(v-for="button in header.buttons")
                button(v-if="button.isExecuteFunction && button.boolStatement({row})" @click="button.execute({row, reload, showModal})"  class="button is-small") {{ button.label }}
                router-link(v-else-if="!button.boolStatement" :to="button.route({row})" class="button is-small") {{ button.label }}
                router-link(v-else-if="button.boolStatement({row})" :to="button.route({row})" class="button is-small") {{ button.label }}

      template(#actions="{ row, reload }" v-if="rowActions && rowActions.length")
        template(v-for="action in rowActions")
          app-button-custom(v-if="action.action === 'custom'" :icon="action.icon" :title="action.title" :to="action.route({row})")
          app-button-edit(v-if="canEdit(action)" :to="updateRoute(row, action)" :icon="getUpdateIcon(action)")
          app-button-remove(v-if="canRemove(action)" :resource="resource" :object="row" size="is-small" @removed="reload")
    OrderListChangeData(:active="isModalActive" :title="titleModal" :field="fieldModal" :order="order" @close="isModalActive=false")

</template>
<script>
import {mapGetters} from "vuex";
import OrderListChangeData from "../plugins/app/modal/OrderListChangeData";

export default {
  components: {OrderListChangeData},
  props: {
    breadcrumbs: Array,
    sortable: Boolean,
    sortField: {type: String, default: "position"},
    sortOrder: {type: String, default: "asc"},
    countable: Boolean,
    fields: Array,
    hideFilters: Boolean,
    narrow: Boolean,
    actions: {
      type: Array,
      default: () => []
    },
    url: Function,
    resource: String,
    onCreated: Function,
    rowActions: Array,
    properties: Array,
    hasPagination: {type: Boolean, default: true},
    itemsPerPage: {type: Number, default: 50},
    rowColorFunction: {
      type: Function, default: () => {}
    },
  },
  data() {
    return {
      isModalActive: false,
      titleModal: null,
      fieldModal: null,
      order: null,
      data: {
        loading: false,
      }
    };
  },
  computed: {
    allowedActions() {
      return this.actions.filter(action => this.isAllowed(action.permissions));
    },
    ...mapGetters("auth", ["isAllowed"]),
    filters() {
      const filters = this.fields.filter(field => field.filter);
      return filters.map(field => {
        if (field.filterOptions && typeof field.filterOptions === "function") {
          field.filterOptions = field.filterOptions();
        }
        return field;
      });
    },
    headers() {
      return this.fields.filter(field => !field.isOnlyFilter);
    }
  },
  methods: {
    showModal(title, field, order) {
      this.order = order
      this.titleModal = title
      this.fieldModal = field
      this.isModalActive = true;
    },
    canEdit(action) {
      return action.action === "edit" && this.isAllowed(action.permissions);
    },
    canRemove(action) {
      return action.action === "remove" && this.isAllowed(action.permissions);
    },
    getUpdateIcon(action) {
      return action.icon || "pencil";
    },
    getImage(field, row) {
      const value = this.getValue({field}, row);
      if (value) {
        return value.contentUrl;
      }
      return null;
    },
    getValue(header, obj) {
      const value = header.field
          .split(".")
          .reduce(
              (o, i) => (typeof o == "undefined" || o === null ? o : o[i]),
              obj
          );
      if (header.format) {
        return header.format(value);
      }
      return value;
    },
    getActionRoute(action) {
      return action.route({route: this.$route, compData: this.data});
    },
    getUrl() {
      return this.url({route: this.$route, compData: this.$data});
    },
    updateRoute(row, action) {
      return {
        name: action.pathName || `${this.resource}_update`,
        params: {id: row.id}
      };
    }
  },
  created() {

    if (this.onCreated) {
      this.onCreated({
        compData: this.data,
        route: this.$route,
        $notify: this.$notify,
        $http: this.$http,
        refs: this.$refs
      });
    }
  }
};
</script>
