export default function (row) {
    if (!row || !row.customer) {
        return '';
    }

    const customer = row.customer;

    if (customer.type === 'person') {
        return (customer.firstName || '') + " " + (customer.lastName || '');
    } else {
        return (customer.taxId || '') + " " + (customer.companyName || '');
    }
}
