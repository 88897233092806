export default function onPaste(value) {
  const arr = value.split("\n").map(i => i.split(/\s+/));
  let collection = [];
  arr.forEach((rowValue, row) => {
    rowValue.forEach((value, col) => {
      if (row === 0 && col === 0) {
        return;
      }
      let transformedValue = null;
      // if centimeter make millimeter else make gross
      if (row === 0 || col === 0) {
        transformedValue = value * 10;
      } else {
        transformedValue = value * 100;
      }
      collection.push({
        row,
        col,
        value: transformedValue
      });
    });
  });
  return collection;
}
