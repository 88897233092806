import FabricDayNightForm from "../components/FabricDayNightForm";
import fetchFabricShadeDayNightGroup from "../helpers/fetchFabricShadeDayNightGroup";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["fabric_day_night_read"],
    actions: [
      {
        label: "Nowa kolekcja",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_day_nights_create",
          params: {
            fabricShadeDayNightGroupId: route.params.fabricShadeDayNightGroupId
          }
        }),
        permissions: ["fabri_day_nightc_create"]
      }
    ],
    route: {
      path: "/fabric_day_nights/:fabricShadeDayNightGroupId/list",
      name: "fabric_day_nights_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: () => ({ name: "fabric_shade_day_night_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy ${compData.fabricShadeDayNightGroup.name}`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz.", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },

      { field: "description", type: "text", label: "Opis", filter: "text" },
      {
        field: "rollWidth",
        type: "text",
        label: "Szerokość rolki [cm]",
        format: decimal
      },
      {
        field: "rollLength",
        type: "text",
        label: "Długość rolki [cm]",
        format: decimal
      },
      {
        field: "hasPattern",
        type: "boolean",
        label: "Wzór",
        filter: "boolean"
      },
      {
        field: "isTurnable",
        type: "boolean",
        label: "Obracanie",
        filter: "boolean"
      },
      {
        field: "hasTurnablePattern",
        type: "boolean",
        label: "Obracanie wzoru",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Kolory",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_color_day_nights_list",
            params: { fabricDayNightId: row.id }
          })
        }
      }
    ],
    resource: "fabric_day_nights",
    url: ({ route }) =>
      `/fabric_shade_day_night_groups/${route.params.fabricShadeDayNightGroupId}/fabric_day_nights?properties[]=id&properties[]=name&properties[]=symbol&properties[]=isEnabled&properties[]=number&properties[]=description&properties[]=hasTurnablePattern&properties[]=isTurnable&properties[]=hasPattern&properties[]=rollWidth&properties[]=rollLength&properties[]=position&properties[]=measurementInstructions`,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_day_night_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_day_night_delete"]
      }
    ],
    onCreated: fetchFabricShadeDayNightGroup  
  },
  create: {
    permissions: ["fabric_day_night_create"],
    route: {
      path: "/fabric_day_nights/:fabricShadeDayNightGroupId/create",
      name: "fabric_day_nights_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: () => ({ name: "fabric_shade_day_night_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) =>
          `Nowa kolekcja dla grupy "${compData.fabricShadeDayNightGroup.name}"`
      }
    ],
    formComponent: FabricDayNightForm,
    listRoute: ({ compData }) => ({
      name: "fabric_day_nights_list",
      params: { fabricShadeDayNightGroupId: compData.fabricShadeDayNightGroup.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_day_nights_update",
      params: { id: object.id }
    }),
    onCreated: fetchFabricShadeDayNightGroup
  },
  update: {
    permissions: ["fabric_day_night_update"],
    route: {
      path: "/fabric_day_nights/:id/update",
      name: "fabric_day_nights_update"
    },
    resource: "fabric_day_nights",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: () => ({ name: "fabric_shade_day_night_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_day_nights_list",
          params: { fabricShadeDayNightGroupId: compData.object.groupDayNight.id }
        }),
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy "${compData.object.groupDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja tkaniny "${compData.object.name}" dla grupy "${compData.object.groupDayNight.name}"`
      }
    ],
    formComponent: FabricDayNightForm,
    listRoute: ({ compData }) => ({
      name: "fabric_day_nights_list",
      params: { fabricShadeDayNightGroupId: compData.object.groupDayNight.id }
    })
  }
};
