<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutters" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column.is-2
              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol")

              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny

              app-form-field(field="hasCombinations" :errors="errors")
                b-checkbox(v-model="data.hasCombinations") Możliwość kombinacji

            .column
              .columns
                .column
                  app-form-field(label="Minimalna szerokość (mm)" field="minWidth" :errors="errors")
                    app-form-length(v-model="data.minWidth")
                .column
                  app-form-field(label="Maksymalna szerokość (mm)" field="maxWidth" :errors="errors")
                    app-form-length(v-model="data.maxWidth")
              .columns
                .column
                  app-form-field(label="Liczba KOLUMN cennika" field="priceListCols" :errors="errors")
                    b-input(type="number" v-model.number="data.priceListCols")
                .column
                  app-form-field(label="Liczba WIERSZY cennika" field="priceListRows" :errors="errors")
                    b-input(type="number" v-model.number="data.priceListRows")
            .column.is-2
              app-form-field(label="Kompatybilne rury" field="globalPipes" :errors="errors")
                multiple-resource-select(
                  v-model="data.globalPipes"
                  resource="global_pipes"
                  url="global_pipes"
                  extra-props="&isEnabledForExternalShutter=true"
                  placeholder="Wybierz rury"
                )
            .column.is-2
              app-form-field(label="Wyjścia napędów" field="externalShutterDriveExits" :errors="errors")
                multiple-resource-select(
                  v-model="data.externalShutterDriveExits"
                  resource="external_shutter_drive_exits"
                  url="external_shutter_drive_exits"
                  placeholder="Wybierz wyjścia napędów"
                )

        b-tab-item(label="Produkcja")
          .columns
            .column
              .columns
                .column
                  app-form-field(label="Skrzynka (mm)" field="productionBox" :errors="errors")
                    app-form-length(v-model="data.productionBox")
                .column
                  app-form-field(label="Rura (mm)" field="productionPipe" :errors="errors")
                    app-form-length(v-model="data.productionPipe")
                .column
                  app-form-field(label="Listwa dolna (mm)" field="productionBalk" :errors="errors")
                    app-form-length(v-model="data.productionBalk")
                .column
                  app-form-field(label="Prowadnica (mm)" field="productionRunner" :errors="errors")
                    app-form-length(v-model="data.productionRunner")
              .columns
                .column
                  app-form-field(label="Pancerz 1 (mm)" field="productionArmor1" :errors="errors")
                    app-form-length(v-model="data.productionArmor1")
                .column
                  app-form-field(label="Pancerz 2 (boczny w H, mm)" field="productionArmor2" :errors="errors")
                    app-form-length(v-model="data.productionArmor2")
                .column
                  app-form-field(label="Pancerz 3 (środkowy w H, mm)" field="productionArmor3" :errors="errors")
                    app-form-length(v-model="data.productionArmor3")
                .column
              .columns
                .column
                  app-form-field(label="Siatka moskitiery (mm)" field="productionMosquitoNet" :errors="errors")
                    app-form-length(v-model="data.productionMosquitoNet")
                .column
                  app-form-field(label="Listwa moskitiery (mm)" field="productionMosquitoSlat" :errors="errors")
                    app-form-length(v-model="data.productionMosquitoSlat")
                .column
                  app-form-field(label="Kasetka moskitiery (mm)" field="productionMosquitoCas" :errors="errors")
                    app-form-length(v-model="data.productionMosquitoCase")
                .column
                  app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                    app-form-time-integer(v-model="data.productionTime")

        b-tab-item(label="Zdjęcia")
          app-form-field(label="Zdjęcie pomiaru" field="surveyPicture" :errors="errors")
            app-form-file(v-model="files.surveyPicture" :image="data.surveyPicture" @removed="data.surveyPicture = {}")

        b-tab-item(label="Opisy pomiarów")
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Krótki opis pomiaru (${value})`" :field="`surveyDescription${value}`" :errors="errors")
                app-form-editor(v-model="data[`surveyDescription${value}`]")

        b-tab-item(label="Dla sklepu")
          app-form-field(field="isEnabledForShop" :errors="errors")
            b-checkbox(v-model="data.isEnabledForShop") Włącz wersję dla sklepu
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Rozszerzony tytuł (${value})`" :field="`extendedTitle${value}`" :errors="errors")
                b-input(v-model="data[`extendedTitle${value}`]")
              app-form-field(:label="`Opis wersji dla sklepu (${value})`" :field="`descriptionForShop${value}`" :errors="errors")
                app-form-editor(v-model="data[`descriptionForShop${value}`]")

        b-tab-item(label="Dokumenty")
          app-form-field(label="Pliki instrukcji montażu" field="measurementInstructions" :errors="errors")
            app-form-files(v-model="files.measurementInstructions" :image="data.measurementInstructions" @removed="(id)=>{data.measurementInstructions = afterRemovedFiles(id,data.measurementInstructions)}"  :downloadable="true" :multiple='true' accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps')

</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
import {mapGetters} from "vuex";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {MultipleResourceSelect},
  data() {
    return {
      files: {
        measurementinstructions: null,
        surveyPicture: {}
      }
    };
  },
  computed: {
    ...mapGetters(["languages", "previewTypes"]),
    model() {
      return {
        symbol: "",
        name: "",
        priceListType: "tabela",
        isEnabled: true,
        minWidth: 0,
        maxWidth: 0,
        productionTime: 0,
        priceListCols: 0,
        priceListRows: 0,
        productionBox: 0,
        productionRunner: 0,
        productionPipe: 0,
        productionBalk: 0,
        productionArmor1: 0,
        productionArmor2: 0,
        productionArmor3: 0,
        productionMosquitoNet: 0,
        productionMosquitoSlat: 0,
        productionMosquitoCase: 0,
        notesForPriceListPL: "",
        notesForPriceListCZ: "",
        notesForPriceListEN: "",
        notesForPriceListDE: "",
        notesForPriceListFR: "",
        notesForPriceListSE: "",
        notesForPriceListIT: "",
        notesForPriceListSI: "",
        notesForPriceListRU: "",
        notesForPriceListES: "",
        notesForPriceListNL: "",
        surveyDescriptionPL: "",
        surveyDescriptionCZ: "",
        surveyDescriptionEN: "",
        surveyDescriptionDE: "",
        surveyDescriptionFR: "",
        surveyDescriptionSE: "",
        surveyDescriptionIT: "",
        surveyDescriptionSI: "",
        surveyDescriptionRU: "",
        surveyDescriptionES: "",
        surveyDescriptionNL: "",
        extendedTitlePL: "",
        descriptionForShopPL: "",
        extendedTitleCZ: "",
        descriptionForShopCZ: "",
        extendedTitleEN: "",
        descriptionForShopEN: "",
        extendedTitleDE: "",
        descriptionForShopDE: "",
        extendedTitleFR: "",
        descriptionForShopFR: "",
        extendedTitleSE: "",
        descriptionForShopSE: "",
        extendedTitleIT: "",
        descriptionForShopIT: "",
        extendedTitleSI: "",
        descriptionForShopSI: "",
        extendedTitleRU: "",
        descriptionForShopRU: "",
        extendedTitleES: "",
        descriptionForShopES: "",
        extendedTitleNL: "",
        descriptionForShopNL: "",
        isEnabledForShop: false,
        measurementinstructions: null,
        surveyPicture: null,
        hasCombinations: false,
        globalPipes: [],
        externalShutterDriveExits: []
      };
    }
  },
  methods: {
    transformToSubmit(data) {
      data.globalPipes = data.globalPipes.map(pipe => pipe["@id"] || pipe);
      data.externalShutterDriveExits = data.externalShutterDriveExits.map(exit => exit["@id"] || exit);

      return data;
    },
    transformToModel(data) {
      return data;
    },
    afterRemovedFiles(id, array) {
      if(array.length === 0)return [];
      return array.filter((obj)=> obj.id !== id);
    }
  }
};
</script>
