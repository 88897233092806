<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose")
    app-view-card(title="Zamówienie FX")
      template(slot="icon")
        b-button(
          @click="onClose"
        )
          b-icon(icon="close" size="is-small")
      template
        app-form(v-bind="props" @close="onClose" resource="fixed_orders", :showSave="false" :transformToSubmit="transformToSubmit" :transformToModel="transformToModel" :customValidation="validation")
          template(#default="{ data, errors }")
            .columns
              .column
                app-form-field(label="Data produkcji" field="productionDate" :errors="errors")
                    date-picker(
                        v-model="data.productionDate"
                        type="date"
                        value-type="format"
                        format="DD-MM-YYYY"
                        :disabled-date="(date) => !date.getDay()"
                    )
                app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                    app-form-time-integer(v-model="data.productionTime")
                app-form-field(label="Numer zamówienia" field="number" :errors="errors")
                    b-input(type="number" @input="data.number = +$event" :value="data.number")
                app-form-field(label="Typ produktu" field="productType" :errors="errors")
                    resource-select(
                        v-if="data && isActive"
                        v-model="data.productType"
                        resource="product_types"
                        url="product_types"
                        placeholder="Wybierz typ produktu"
                        :extra-props="productTypeResourceSelectProps"
                        label="name"
                        @input="onProductTypeChange(data, $event)"
                        full-object
                    )
                app-form-field(label="Linia produkcyjna" field="productionLine" :errors="errors")
                  resource-select(
                      v-if="data && isActive && data.productType"
                      v-model="data.productionLine"
                      resource="production_lines"
                      :url="productionLineResourceSelectProps"
                      placeholder="Wybierz linię produkcyjną"
                      label="name"
                      :addDefaultProp="false"
                  )
              .column
                app-form-field(label="Notatka" field="comments" :errors="errors")
                  b-input(v-model="data.comments" type="textarea")
                app-form-field(label="Odbiorca" field="company" :errors="errors")
                  resource-select(
                    v-if="data && isActive"
                    v-model="data.company"
                    resource="companies"
                    :url="companyResourceSelectProps"
                    placeholder="Wybierz odbiorcę"
                    label="shortName"
                    :addDefaultProp="false"
                  )
                app-form-field(label="Czynności" field="fixedOrderActivities" :errors="errors")
                  multiple-resource-select(
                      v-if="data && isActive"
                      v-model="data.fixedOrderActivities"
                      resource="fixed_order_activities"
                      :url="fixedOrderActivitiesSelectProps"
                      placeholder="Wybierz czynności"
                      :addDefaultProp="false"
                      extraProps="&order[name]=asc"
                      @fetched="onFixedOrderActivitiesFetched"
                  )
                app-form-field(v-if="isRequiredQuantity" label="Ilość" field="quantity" :errors="errors")
                  b-input(type="number" @input="data.quantity = +$event" :value="data.quantity")
</template>

<script>
import form from "@/plugins/app/form/mixins/form";
import ResourceSelect from "./ResourceSelect";
import AppForm from '../../../plugins/app/form/components/AppForm.vue';
import generateProperties from "../../../helpers/generateProperties";
import DatePicker from "vue2-datepicker";
import http from "../../../http";
import MultipleResourceSelect from "./MultipleResourceSelect.vue";
import orderCategoryByProductTypeCode from "../../../helpers/orderCategoryByProductTypeCode";

export default {
  mixins: [form],
  components: { MultipleResourceSelect, ResourceSelect, AppForm, DatePicker },
  props: {
    active: { type: Boolean, default: false, required: true },
    fixedOrder: { default: null, required: true },
  },
  data() {
    return {
      isActive: this.active,
      fixedOrderActivities: []
    };
  },
  methods: {
    onProductTypeChange(data) {
      data.productionLine = null;
    },
    onClose() {
      this.isActive = false;
      this.$emit("close", this.order);
    },
    transformToSubmit(data) {
      if ( !data.id && !data['@id'] )
      {
          data.status = 'production';
          data.productionStatus = 'in_progress';
          if (data.productType && data.productType.code) {
              data.category = orderCategoryByProductTypeCode[data.productType.code];
          } else {
              data.category = null;
          }
      }
      if(data.productType && data.productType['@id']) {
          data.productType = data.productType['@id'];
      }
      if(data.productionLine && data.productionLine['@id']) {
          data.productionLine = data.productionLine['@id'];
      }
      if(data.company && data.company['@id']) {
          data.company = data.company['@id'];
      }

      return data;
    },
    transformToModel(data) {
      return data;
    },
    onFixedOrderActivitiesFetched(data) {
        this.fixedOrderActivities = data;
    },
    async validation(formData, errors) {
      if (this.isRequiredQuantity && formData.quantity < 1) {
          errors.push({
              propertyPath: 'quantity',
              message: 'Ilość jest wymagana'
          });
      }
      if (!formData.company) {
          errors.push({
              propertyPath: 'company',
              message: 'Pole jest wymagane'
          });
      }
      if (!formData.productionDate) {
          errors.push({
              propertyPath: 'productionDate',
              message: 'Pole jest wymagane'
          });
      }
      if (!formData.productionLine) {
          errors.push({
              propertyPath: 'productionLine',
              message: 'Pole jest wymagane'
          });
      }
      if (!formData.productType) {
          errors.push({
              propertyPath: 'productType',
              message: 'Pole jest wymagane'
          });
      }
      if (!formData.number) {
          errors.push({
              propertyPath: 'number',
              message: 'Pole jest wymagane'
          });
      }
      if (!formData.category) {
          this.$notify(
              `Wystąpił problem z ustawianiem kategorii FX, skontaktuj się z administratorem.`,
              "danger"
          );
      }

      if (errors.length > 0) {
          return;
      }

      let validationData = new FormData();
      validationData.append('productionTime', formData.productionTime);
      validationData.append('productType', parseInt(formData.productType.split('/')[2]));
      validationData.append('productionLine', parseInt(formData.productionLine.split('/')[2]));
      validationData.append('productionDate', formData.productionDate);

      let { data } = await http.post(
        '/api/validate/production/time',
        validationData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/ld+json"
          }
        }
      );

      if(!data.status) {
        if(data.errors) {
          for(let i = 0; i < data.errors.length; i++) {
            errors.push({
              propertyPath: data.errors[i].propertyPath,
              message: data.errors[i].message
            });
          }
        }
      }
    }
  },
  computed: {
    model() {
      if(!this.fixedOrder) {
        return {
            fixedOrderActivities: [],
            productionTime: 0,
            productionDate: null,
            productionLine: null,
            productType: null,
            company: null,
            status: null,
            quantity: 0,
            number: '',
            comments: ''
        }
      }
      return this.fixedOrder;
    },
    productionLineResourceSelectProps() {
      if(Object.prototype.hasOwnProperty.call(this.props.model, 'productType') && Object.prototype.hasOwnProperty.call(this.props.model.productType, 'id')) {
        return 'production_lines?' + generateProperties([],['id', 'name']) + `&productType.id[]=${this.props.model.productType.id}`;
      } else {
        return 'production_lines?' + generateProperties([],['id', 'name']);
      }
    },
    isRequiredQuantity() {
      return this.props.model.fixedOrderActivities.filter(
          (activity) => {
              const objectActivity = this.fixedOrderActivities.find((obj) => String(activity) === String(obj['@id']));
              return objectActivity.requireQuantity;
          }
      ).length > 0;
    },
    companyResourceSelectProps() {
      return `companies?` + generateProperties([],['id', 'shortName']) + '&isEnabled=true';
    },
    productTypeResourceSelectProps() {
      return generateProperties([],['id', 'name', 'code'],true);
    },
    fixedOrderActivitiesSelectProps() {
      return `fixed_order_activities?` +generateProperties([],['id', 'name', 'requireQuantity'],true) + '&isEnabled=true';
    },
    url() {
      let address = `fixed_orders/${this.fixedOrder.id}`;
      return address;
    }
  },
  watch: {
    async active(value) {
      if(!value) {
        this.props.model = {
          fixedOrderActivities: [],
          productionTime: 0,
          productionDate: null,
          productionLine: null,
          productType: null,
          company: null,
          status: 'new',
          quantity: 0,
          number: '',
          comments: ''
        };
      }
      this.isActive = value;
      if(value && this.fixedOrder && this.fixedOrder.id) {
        try {
          let { data } = await this.$http.get(this.url);
          // this.fixedOrder = data;
          this.props.model = data;
          this.$emit("fetched", data);
        } catch (e) {
          this.$notify(
            `Nie udało się pobrać obiektu pod adresem ${this.url}`,
            "danger"
          );
        }
      }

    }
  },
};
</script>
