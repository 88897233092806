<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="member_ships" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column.is-6
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")
            .column.is-6
              app-form-field(label="Uprawnienia" field="permissions" :errors="errors")
                multiple-resource-select(
                  v-model="data.permissions"
                  resource="permissions"
                  extra-props="?itemsPerPage=1000"
                  url="permissions"
                  placeholder="Wybierz uprawnienia"
                  :label="item => `${item.code}`"
                )


</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {MultipleResourceSelect},
  data() {
    return {
      model: {
        name: null,
        permissions: []
      }
    };
  },
  computed: {},
  methods: {

    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      return data;
    },

  }
};
</script>
