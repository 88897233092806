<template lang="pug">
  app-view-show(resource="orders")
    template(#default="{ object }")
      #order.container
        .has-text-centered.has-font-15
          .has-margin-bottom {{object.status === 'valuation'? "Wycena" : "Reklamacja"}} nr
            .has-text-weight-bold {{ orderNumberCreator(object.number) }}
        .columns
          .column.mt-5
            app-panel(title="Krótki opis")
              app-panel-block {{ shortDescription ? shortDescription : "Brak" }}
        .columns
          .column
            app-panel(title="Zamawiający")
              app-panel-block {{ getValueFromCompanyType(object, "shortName") }}
              template(v-if="'preview'===printType")
                app-panel-block {{ getValueFromCompanyType(object, "zipCode") }} {{ getValueFromCompanyType(object, "city") }}
                app-panel-block {{ getValueFromCompanyType(object, "streetAndNumber") }}
                app-panel-block Tel.: {{ getValueFromCompanyType(object, "phone") }}, {{ getValueFromCompanyType(object, "ownerPhone") }}
          .column
          .column
          .column
            app-panel(v-if="'preview'===printType" title="Sprzedający")
              app-panel-block AMIDEX Alina i Ireneusz Cieśla Sp. J
        .has-text-centered.has-margin-top-small.has-margin-bottom {{ positionsTitle(object) }}
        table.table.is-fullwidth.is-bordered.order
          fabric-shade-order-item-preview-template(:object="object" v-if="'preview'===printType && (object.category === 'fabric_shade')")
          fabric-shade-order-item-production-template(:object="object" v-if="'production'===printType && (object.category === 'fabric_shade')")
          fabric-shade-day-night-order-item-preview-template(:object="object" v-if="'preview'===printType && (object.category === 'fabric_shade_day_night')")
          fabric-shade-day-night-order-item-production-template(:object="object" v-if="'production'===printType && (object.category === 'fabric_shade_day_night')")
          external-shutter-order-items-after-sum(:items="orderAfterSum(object)" v-if="object.category === 'external_shutter'" )
          external-shutter-order-item-preview-template(:object="object" v-if="'preview'===printType && (object.category === 'external_shutter')")
          external-shutter-order-item-production-template(:object="object" v-if="'production'===printType && (object.category === 'external_shutter')")
          pleat-order-item-production-template(:object="object" v-if="'production'===printType && (object.category === 'pleat')")
          detail-order-item-production-template(:object="object" v-if="'production'===printType && (object.category === 'detail')")

        .columns(style="justify-content:space-between;")
          .column.is-4
            table.table.is-bordered.is-fullwidth
              thead
                tr
                  th(colspan=2) Informacje o {{object.status === 'valuation'? "wycenie" : "zamówieniu"}}
              tbody.print-width-250.real-width-tables-td-250
                template(v-if="'preview'===printType")
                  tr
                    th Data wyceny
                    td {{ prop(object, "valuationDate") || "Brak" }}
                  tr
                    th Data zamówienia
                    td {{ prop(object, "orderDate") || "Brak"}}
                tr(v-if="'preview'===printType")
                  th Osoba zamawiajaca
                  td {{ getOrdererInfo(object) }}
                tr
                  th Sposób dostawy
                  td {{ prop(object.deliveryMethod, "name") }}
                tr
                  th Opis paczki
                  td {{ prop(object, "packageNote") }}
                tr
                  th Uwagi
                  td {{ prop(object, "notes") }}
                tr
                  th(colspan="2") Komentarz  {{ prop(object && object.adminNotesUserChanger, "firstName" ) }} {{ prop(object && object.adminNotesUserChanger, "lastName" ) }}
                tr
                  td(colspan="2") {{ prop(object, "adminNotes") }}
          .column.is-4(v-if="'preview'===printType")
            template
              .column
                table.table.is-bordered.is-fullwidth(v-if="object.sawyerProductionWorkers.length > 0 || object.fitterProductionWorkers.length > 0")
                  thead
                    tr
                      th(colspan=3) Obsługa zamówienia
                  tbody
                    tr(v-for="sawyer in object.sawyerProductionWorkers")
                      td Pilarz
                      td(colspan=2) {{ sawyer.firstName }} {{ sawyer.lastName }}
                    tr(v-for="fitter in object.fitterProductionWorkers")
                      td Monter
                      td(colspan=2) {{ fitter.firstName }} {{ fitter.lastName }}
              .column
                table.table.is-bordered.is-fullwidth
                  thead
                    tr
                      th(colspan=3) Zestawienie wartości {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
                  tbody
                    tr(v-if="!object.category === 'external_shutter'")
                      th(colspan=2) Wartość netto
                      td.has-text-right {{ formatPriceValue(getDecimal(object.netPurchaseValueBeforeDiscount))}}
                    tr(v-if="object.category === 'external_shutter'")
                      th(colspan=2) Wartość netto
                      td.has-text-right {{ formatPriceValue(getDecimal(object.netPurchaseValueBeforeDiscount))}}
                    tr(v-if="object.category === 'external_shutter'")
                      th(colspan=2) Wartość netto automatyki
                      td.has-text-right {{ formatPriceValue(getDecimal(object.netPurchaseAutomationValueBeforeDiscount))}}
                    tr(v-if="object.category === 'external_shutter'")
                      th(colspan=2) Wartość netto razem
                      td.has-text-right {{ formatPriceValue(getDecimal(object.totalNetPurchaseValueBeforeDiscount))}}
                    tr
                      th VAT
                      td.has-text-right {{ prop(object.vatRate, 'value')}} %
                      td.has-text-right {{ formatPriceValue(getDecimal(object.vatRateBeforeDiscountValue))}}
                    tr
                      th(colspan=2) Wartość brutto
                      td.has-text-right {{ formatPriceValue(getDecimal(object.grossPurchaseValueBeforeDiscount)) }}
                    tr
                      th(colspan=2) Wartość brutto po rabacie
                      td.has-text-right {{ formatPriceValue(getDecimal(object.totalPurchaseGrossValue)) }}
                    tr
                      th Wartość zmieniona
                      th {{ prop(object && object.customPurchaseGrossValueUserChanger, "firstName" ) }} {{ prop(object && object.customPurchaseGrossValueUserChanger, "lastName" ) }}
                      td.has-text-right {{ formatPriceValue(getDecimal(object.customPurchaseGrossValue)) }}

                table.table.is-bordered.is-fullwidth
                  thead
                    tr
                      th Sposób zapłaty
                  tbody
                    tr
                      td {{ prop(object.paymentMethod, 'name') }}
          .column(v-if="'production'===printType").is-7
            .columns.is-multline
              .column.is-6
                table.signature-table
                  tbody
                    tr
                      td Pilarze:
                    tr
                      td .............................................................................
                    tr
                      td .............................................................................
              .column.is-6
                table.signature-table
                  tbody
                    tr
                      td Monterzy:
                    tr
                      td .............................................................................
                    tr
                      td .............................................................................


      div.has-text-centered
        b-button(@click.prevent="print(object)") Drukuj
</template>
<script>
import http from "@/http";
import notify from "@/helpers/notify";
import decimal from "@/helpers/decimal";
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import simpleClone from "../../../helpers/simpleClone";
import ExternalShutterOrderItemProductionTemplate
  from "../../orders/components/ExternalShutterOrderItemProductionTemplate";
import FabricShadeDayNightOrderItemPreviewTemplate
  from "../../orders/components/FabricShadeDayNightOrderItemPreviewTemplate";
import FabricShadeDayNightOrderItemProductionTemplate
  from "../../orders/components/FabricShadeDayNightOrderItemProductionTemplate";
import FabricShadeOrderItemProductionTemplate from "../../orders/components/FabricShadeOrderItemProductionTemplate";
import ExternalShutterOrderItemsAfterSum from "../../orders/components/ExternalShutterOrderItemsAfterSum";
import AutomationOrderItems from "../../orders/components/AutomationOrderItems";
import ExternalShutterOrderItemPreviewTemplate from "../../orders/components/ExternalShutterOrderItemPreviewTemplate";
import FabricShadeOrderItemPreviewTemplate from "../../orders/components/FabricShadeOrderItemPreviewTemplate";
import PleatOrderItemProductionTemplate from "../../orders/components/PleatOrderItemProductionTemplate.vue";
import DetailOrderItemProductionTemplate from "../../orders/components/DetailOrderItemProductionTemplate.vue";

export default {
  props: ["printType", "shortDescription"],
  components: {
    ExternalShutterOrderItemProductionTemplate,
    FabricShadeDayNightOrderItemPreviewTemplate,
    FabricShadeOrderItemProductionTemplate,
    FabricShadeDayNightOrderItemProductionTemplate,
    PleatOrderItemProductionTemplate,
    DetailOrderItemProductionTemplate,
    FabricShadeOrderItemPreviewTemplate, ExternalShutterOrderItemsAfterSum, AutomationOrderItems, ExternalShutterOrderItemPreviewTemplate},
  data() {
    return {
      updated: false,
      newStatus: null,
      newProductionStatus: null
    };
  },

  methods: {
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField = null) => prop(object, field, extraField = null),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getValueFromCompanyType(object, field) {
      if (object.company) {
        return object.company[field];
      } else if (object.subCompany) {
        return object.subCompany[field];
      }
    },
    positionsTitle(order) {
      if (order.category === "fabric_shade") {
        return "Roletki tkaninowe";
      }
      if (order.category === "fabric_shade_day_night") {
        return "Roletki dzień-noc";
      }
      if (order.category === "external_shutter") {
        return "Rolety zewnętrzne";
      }
    },
    orderAfterSum: function(items) {
      const orderItems = simpleClone(this.orderItems(items))
      return orderItems.filter((item, index, selfArray) =>
              index === selfArray.findIndex((p) => (
                  p.externalShutter['@id'] === item.externalShutter['@id'] &&
                  p.externalShutterArmorFabricShadeColorEntry['@id'] === item.externalShutterArmorFabricShadeColorEntry['@id'] &&
                  p.temporaryExternalBoxColor['@id'] === item.temporaryExternalBoxColor['@id'] &&
                  p.temporaryInternalBoxColor['@id'] === item.temporaryInternalBoxColor['@id'] &&
                  p.externalShutterArmorExternalShutterArmorColorEntry['@id'] === item.externalShutterArmorExternalShutterArmorColorEntry['@id'] &&
                  p.externalShutterPriceList["externalShutterArmor"]['@id'] === item.externalShutterPriceList["externalShutterArmor"]['@id'] &&
                  p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['@id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['@id']

              ))
      );
    },

    print(order) {
      this.$htmlToPaper("order");
      const printType = this.printType;
      if ("preview" === printType && "new" === order.status) {
        this.newStatus = "accepted";
      } else if ("production" === printType && "accepted" === order.status) {
        this.newStatus = "production";
      }
      if ((this.newStatus || this.newProductionStatus) && !this.updated) {
        let timeout = null;
        const interval = setInterval(() => {
          if (document.hasFocus()) {
            if (!timeout) {
              timeout = setTimeout(() => {
                try {
                  const data =
                      this.newStatus && this.newProductionStatus
                          ? {
                            status: this.newStatus,
                            productionStatus: this.newProductionStatus
                          }
                          : this.newProductionStatus
                              ? { productionStatus: this.newProductionStatus }
                              : this.newStatus
                                  ? { status: this.newStatus }
                                  : null;
                  http.put(`/orders/${order.id}`, data);
                  const notification = ` ${
                      this.newStatus
                          ? `Zmieniono status zamówienia na: \"${this.translate(
                              this.newStatus
                          )}\".\n`
                          : ""
                  } ${
                      this.newProductionStatus
                          ? `Zmieniono status produkcji na: \"${this.translate(
                              this.newProductionStatus
                          )}\".`
                          : ""
                  }`;
                  notify(notification);
                  this.updated = true;
                } catch {
                  notify("Nie udało się zmienic statusu zamówienia", "warning");
                } finally {
                  clearInterval(interval);
                  clearTimeout(timeout);
                }
              }, 1000);
            }
          }
        }, 500);
      }
    },
    translate(value) {
      switch (value) {
        case "accepted":
          return "Przyjęte";
        case "production":
          return "Produkcja";
        case "in_progress":
          return "W trakcie";
        case "waiting":
          return "Oczekuje";
      }
    },
    orderNumberCreator(val) {
      return orderNumberCreator(val);
    },

    getDecimal(val) {
      return decimal(val);
    },
    trueFalseToText(value){
      return (value)? 'Tak' : 'Nie'
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
    formatDecimal(val) {
      return (Math.round((val + Number.EPSILON) * 100) / 100).toFixed(2);
    },
    getOrdererInfo(order) {
      const orderer = order.orderer;
      if (!orderer) {
        return "Brak";
      }
      return `${orderer.firstName || ""} ${orderer.lastName || ""}`;
    }
  }
};
</script>
