import ExternalShutterArmorExternalShutterArmorColorEntryForm from "../components/ExternalShutterArmorExternalShutterArmorColorEntryForm";
import fetchExternalShutterArmor from "../helpers/fetchExternalShutterArmor";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_armor_external_shutter_armor_color_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "external_shutter_armor_external_shutter_armor_color_entries_create",
          params: {
            externalShutterArmorId: route.params.externalShutterArmorId
          }
        }),
        permissions: ["external_shutter_armor_external_shutter_armor_color_entry_create"]
      }
    ],
    route: {
      path: "/external_shutter_armor_external_shutter_armor_color_entries/:externalShutterArmorId/list",
      name: "external_shutter_armor_external_shutter_armor_color_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armors_list" }),
        label: () => "Pancerze"
      },
      {
        label: ({ compData }) =>
          `Wiązania kolorów pancerza dla pancerza "${compData.externalShutterArmor.name}"`
      }
    ],
    fields: [
      {
        field: "externalShutterArmorColor.name",
        type: "text",
        label: "Kolor pancerza",
        filter: "text"
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata za m2",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_armor_external_shutter_armor_color_entries",
    url: ({ route }) =>
      `/external_shutter_armors/${route.params.externalShutterArmorId}/external_shutter_armor_external_shutter_armor_color_entries?` +
      generateProperties([], ["id", "position", "isEnabled", "lengthSurcharge"]) +
      generateProperties(["externalShutterArmor"], ["name"], true) +
      generateProperties(["externalShutterArmorColor"], ["name"], true),
    onCreated: fetchExternalShutterArmor,
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_armor_external_shutter_armor_color_entry_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_armor_external_shutter_armor_color_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_armor_external_shutter_armor_color_entry_create"],
    route: {
      path: "/external_shutter_armor_external_shutter_armor_color_entries/:externalShutterArmorId/create",
      name: "external_shutter_armor_external_shutter_armor_color_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armors_list" }),
        label: () => "Pancerze"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_armor_external_shutter_armor_color_entries_list",
          params: { ExternalShutterArmorId: compData.externalShutterArmor.id }
        }),
        label: ({ compData }) =>
          `Kolory pancerza "${compData.externalShutterArmor.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla pancerza "${compData.externalShutterArmor.name}"`
      }
    ],
    formComponent: ExternalShutterArmorExternalShutterArmorColorEntryForm,
    onCreated: fetchExternalShutterArmor,
    listRoute: ({ compData }) => ({
      name: "external_shutter_armor_external_shutter_armor_color_entries_list",
      params: { externalShutterArmorId: compData.externalShutterArmor.id }
    }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_armor_external_shutter_armor_color_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["external_shutter_armor_external_shutter_armor_color_entry_update"],
    route: {
      path: "/external_shutter_armor_external_shutter_armor_color_entries/:id/update",
      name: "external_shutter_armor_external_shutter_armor_color_entries_update"
    },
    resource: "external_shutter_armor_external_shutter_armor_color_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armors_list" }),
        label: () => "Pancerze"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_armor_external_shutter_armor_color_entries_list",
          params: { externalShutterArmorId: compData.object.externalShutterArmor.id }
        }),
        label: ({ compData }) =>
          `Kolory pancerza dla pancerza "${compData.object.externalShutterArmor.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania koloru pancerza "${compData.object.externalShutterArmorColor.name}" z pancerzem "${compData.object.externalShutterArmor.name}"`
      }
    ],
    formComponent: ExternalShutterArmorExternalShutterArmorColorEntryForm,
    onCreated: fetchExternalShutterArmor,
    listRoute: ({ compData }) => ({
      name: "external_shutter_armor_external_shutter_armor_color_entries_list",
      params: {
        externalShutterArmorId: compData.object.externalShutterArmor.id
      }
    })
  }
};
