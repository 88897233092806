import orderCategoryByProductTypeCode from "./orderCategoryByProductTypeCode";

export default function orderItems(order) {

    switch (order.category) {
        case orderCategoryByProductTypeCode.RTK:
            return order.fabricShadeOrderItems;
        case orderCategoryByProductTypeCode.RDN:
            return order.fabricShadeOrderItems;
        case orderCategoryByProductTypeCode.RZW:
            return order.externalShutterOrderItems;
        case orderCategoryByProductTypeCode.PLI:
            return order.pleatOrderItems;
        case orderCategoryByProductTypeCode.AUT:
            return order.automationOrderItems;
        case orderCategoryByProductTypeCode.DET:
            return order.detailOrderItems;
        case orderCategoryByProductTypeCode.MSQ:
            return order.frameMosquitoNetOrderItems;
        case orderCategoryByProductTypeCode.MDR:
            return order.doorMosquitoNetOrderItems;
        case orderCategoryByProductTypeCode.MPL:
            return order.pleatMosquitoNetOrderItems;
        case orderCategoryByProductTypeCode.MZW:
            return order.rollMosquitoNetOrderItems;
        case orderCategoryByProductTypeCode.MPR:
            return order.slideMosquitoNetOrderItems;
        default:
            return [];
    }
}