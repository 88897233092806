<template lang="pug">
  .column(:class="{ 'dragging-disabled' : isEnded}").is-one-third.draggable-li(:style="{backgroundColor: isEnded ? 'gray' : orderBackgroundColor}")
    .columns.is-multiline
      .column.is-1.has-pointer
        .columns.draggable-col(@click="showOrder")
          .column.has-text-weight-bold.has-low-padding-bottom {{ orderNumber }}
          .column.has-no-padding-left ({{ countOfOrderItems }})

      .column.is-12.has-no-padding-top
        .columns.draggable-col-2.has-font-8
          .column.is-three-fifths.has-no-padding-top.has-no-padding-bottom {{ companyShortName }}
          .column.has-no-padding-top.has-no-padding-bottom
            b-button(@click="editOrder" :disabled="isDisabled").has-no-border.has-transparent-background.has-no-padding-left
              b-icon(icon="pencil" size="is-small")
</template>

<script>
import moment from "moment";

export default {
  props: {
    orderNumber: String,
    countOfOrderItems: Number,
    companyShortName: String,
    order: Object
  },
  methods: {
    showOrder() {
      this.$emit("show", this.order);
    },
    editOrder() {
      this.$emit("edit", this.order);
    }
  },
  computed: {
    //Nie pozwala na wykonywanie akcji jeśli data produkcji jest przed data dzisiejsza
    isDisabled() {
      if (this.order && this.order.productionDate) {
        return moment(this.order.productionDate, "DD-MM-YYYY").isBefore(
          moment(),
          "day"
        );
      }
      return false;
    },
    isEnded() {
      if (this.order && this.order.productionStatus === 'end') {
        return true;
      }
      return false;
    },
    orderBackgroundColor() {
      if (this.order && this.order.productionLine && this.order.productionLine.lineColor) {
        return this.order.productionLine.lineColor;
      }
      return "#dedede";
    }
  }
};
</script>

<style scoped>
button:disabled,
button[disabled] {
  top: -8px;
  background-color: transparent;
  border-color: transparent;
  cursor: default;
}
</style>
