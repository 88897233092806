<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_drill_holes" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")
              app-form-field(label="Dopłata za sztukę prowadnicy" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")
              app-form-field(label="Przekrój lewy" field="sectionLeftPicture" :errors="errors")
                app-form-file(v-model="files.sectionLeftPicture" :image="data.sectionLeftPicture" @removed="data.sectionLeftPicture = {}")
            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny
              app-form-field(label="Przekrój prawy" field="sectionRightPicture" :errors="errors")
                app-form-file(v-model="files.sectionRightPicture" :image="data.sectionRightPicture" @removed="data.sectionRightPicture = {}")
</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
    data() {
      return {
        files: {
          sectionLeftPicture: {},
          sectionRightPicture: {}
        }
      };
  },
  computed: {
    model() {
      return {
        name: "",
        pieceSurcharge: 0,
        isEnabled: false,
        position: 0,
        sectionLeftPicture: null,
        sectionRightPicture: null
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
