import SlideMosquitoNetFrameForm from "../components/SlideMosquitoNetFrameForm.vue";

export default {
    list: {
        permissions: ["slide_mosquito_net_frame_read"],
        actions: [
            {
                label: "Nowa rama",
                type: "is-primary",
                route: () => ({
                    name: "slide_mosquito_net_frames_create"
                }),
                permissions: ["slide_mosquito_net_frame_create"]
            }
        ],
        route: {
            path: "/slide_mosquito_net_frames/list",
            name: "slide_mosquito_net_frames_list"
        },
        sortable: false,
        breadcrumbs: [
            {
                label: () => `Ramy`
            }
        ],
        fields: [
            { field: "name", type: "text", label: "Nazwa", filter: "text" },
            { field: "symbol", type: "text", label: "Symbol", filter: "text" },
            { field: "isEnabled", type: "boolean", label: "Aktywny", filter: "boolean" }
        ],
        resource: "slide_mosquito_net_frames",
        url: () => `/slide_mosquito_net_frames`,
        rowActions: [
            {
                action: "edit",
                permissions: ["slide_mosquito_net_frame_update"]
            },
            {
                action: "remove",
                permissions: ["slide_mosquito_net_frame_delete"]
            }
        ]
    },
    create: {
        permissions: ["slide_mosquito_net_frame_create"],
        route: {
            path: "/slide_mosquito_net_frames/create",
            name: "slide_mosquito_net_frames_create"
        },
        breadcrumbs: [
            {
                route: () => ({
                    name: "slide_mosquito_net_frames_list"
                }),
                label: () => `Ramy`
            },
            {
                label: () => `Nowa rama`
            }
        ],
        formComponent: SlideMosquitoNetFrameForm,
        listRoute: () => ({
            name: "slide_mosquito_net_frames_list"
        }),
        updateRoute: ({ object }) => ({
            name: "slide_mosquito_net_frames_update",
            params: {
                id: object.id
            }
        })
    },
    update: {
        permissions: ["slide_mosquito_net_frame_update"],
        route: {
            path: "/slide_mosquito_net_frames/:id/update",
            name: "slide_mosquito_net_frames_update"
        },
        resource: "slide_mosquito_net_frames",
        breadcrumbs: [
            {
                route: () => ({
                    name: "slide_mosquito_net_frames_list"
                }),
                label: () => `Ramy`
            },
            {
                label: ({ compData }) => `Edycja ramy "${compData.object.name}"`
            }
        ],
        formComponent: SlideMosquitoNetFrameForm,
        listRoute: () => ({
            name: "slide_mosquito_net_frames_list"
        })
    }
};
