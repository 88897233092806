import ProductionFreeDayForm from "../components/ProductionFreeDayForm";
import generateProperties from "../../../helpers/generateProperties";
import moment from "moment";

export default {
  list: {
    sortable: false,
    hasPagination: true,
    permissions: ["production_free_day_read"],
    actions: [
      {
        label: "Nowy dzień wolny",
        type: "is-primary",
        route: () => ({
          name: "production_free_days_create"
        }),
        permissions: ["production_free_day_create"]
      }
    ],
    route: {
      path: "/production_free_days/list",
      name: "production_free_days_list"
    },
    breadcrumbs: [
      {
        label: () => "Dni wolne"
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Nazwa Robocza",
        filter: "text"
      },
      {
        field: "date",
        type: "virtual",
        label: "Data",
        value: (h, row) => (moment(row.date, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")),
        filter: "text"
      },
    ],
    resource: "production_free_days",
    url: () =>
      `/production_free_days?` +
      generateProperties(
        [],
        ["id", "name", "date"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["production_free_days_update"]
      },
      {
        action: "remove",
        permissions: ["production_free_day_delete"]
      }
    ]
  },
  create: {
    permissions: ["production_free_day_create"],
    route: {
      path: "/production_free_days/create",
      name: "production_free_days_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "production_free_days_list" }),
        label: () => "Dni wolne"
      },
      {
        label: () => `Nowy dzień wolny`
      }
    ],
    formComponent: ProductionFreeDayForm,
    listRoute: () => ({
      name: "production_free_days_list"
    }),
    updateRoute: ({ object }) => ({
      name: "production_free_days_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["production_free_day_update"],
    route: {
      path: "/production_free_days/:id/update",
      name: "production_free_days_update"
    },
    resource: "production_free_days",
    breadcrumbs: [
      {
        route: () => ({ name: "production_free_days_list" }),
        label: () => "Dni wolne"
      },
      {
        label: ({ compData }) =>
          `Edycja dnia wolnego "${compData.object.name} ${moment(compData.object.date, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY')}"`
      }
    ],
    formComponent: ProductionFreeDayForm,
    listRoute: () => ({ name: "production_free_days_list" })
  }
};
