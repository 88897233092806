<template lang="pug">
    b-input(
        :custom-class="classes"
        :size="size"
        :value="formatted"
        @input="onInput"
        @focus="$event.target.select()"
    )
</template>
<script>
export default {
  props: {
    value: Number,
    size: String,
    classes: String
  },
  computed: {
    formatted() {
      return this.value / 100;
    }
  },
  methods: {
    onInput(event) {
      const previousNumber = this.value;
      const number = Math.round(parseFloat(event.replace(",", ".")));
      const value = parseInt(number * 100);
      if (isNaN(number)) {
        this.$emit("input", previousNumber);
      } else {
        this.$emit("input", value);
      }
    }
  }
};
</script>
