<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="fabric_shade_day_night_additionals" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                app-form-time-integer(v-model="data.productionTime")
                                
                            app-form-field(label="Opis" field="description" :errors="errors")
                                b-input(v-model="data.description" type="textarea")
                        .column
                            app-form-field(label="Zdjęcie" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")



</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        picture: "",
        description: "",
        productionTime: 0,
      },
      files: {
        picture: {},
      }
    };
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {

      return data;
    }
  }
};
</script>
