import { SnackbarProgrammatic as Snackbar } from "buefy";

export default function(message, type = "success", duration = 6000, position = "is-bottom-right", action = ()=>{}) {
  Snackbar.open({
    message,
    type: `is-${type}`,
    position: position,
    duration: duration,
    onAction: action
  });
}
