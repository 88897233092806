<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="roll_mosquito_net_additional_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.heightSurcharge")

                            app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.widthSurcharge")
                                
                            app-form-field(label="Dopłata za sztukę" field="pieceSurcharge" :errors="errors")
                              app-form-decimal(v-model="data.pieceSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Dodatek" field="rollMosquitoNetAdditional" :errors="errors")
                                resource-select(
                                    v-model="data.rollMosquitoNetAdditional"
                                    resource="roll_mosquito_net_additionals"
                                    url="roll_mosquito_net_additionals"
                                    placeholder="Wybierz dodatek"
                                    extra-props="&order[name]=asc"
                                    :labels="['name']"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        pieceSurcharge: 0,
        isEnabled: true,
        rollMosquitoNet: `/roll_mosquito_nets/${this.$route.params.rollMosquitoNetId}`,
        rollMosquitoNetAdditional: null,
      },
      rollMosquitoNetId: this.$route.params.rollMosquitoNetId,
      downloadedRollMosquitoNetId: null
    };
  },
  computed: {
    getRollMosquitoNetId() {
      if (!isNaN(this.rollMosquitoNetId) && this.rollMosquitoNetId) {
        return this.rollMosquitoNetId;
      }
      return this.downloadedRollMosquitoNetId;
    }
  },
  methods: {
    transformToModel(data) {
      if (data.rollMosquitoNet && data.rollMosquitoNet["@id"]) {
        data.rollMosquitoNet = data.rollMosquitoNet["@id"];
      }
      if (data.rollMosquitoNetAdditional && data.rollMosquitoNetAdditional["@id"]) {
        data.rollMosquitoNetAdditional = data.rollMosquitoNetAdditional["@id"];
      }

      return data;
    },
    transformToSubmit(data) {
      if (data.rollMosquitoNet && data.rollMosquitoNet["@id"]) {
        data.rollMosquitoNet = data.rollMosquitoNet["@id"];
      }
      if (data.rollMosquitoNetAdditional && data.rollMosquitoNetAdditional["@id"]) {
        data.rollMosquitoNetAdditional = data.rollMosquitoNetAdditional["@id"];
      }

      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.id) {
      const { data } = await http.get(
        `/roll_mosquito_net_additional_entries/${this.$route.params.id}?properties[rollMosquitoNet][]=id`
      );
      if (data.rollMosquitoNet) {
        this.downloadedRollMosquitoNetId = data.rollMosquitoNet.id;
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
