import FabricShadeGlobalRunnerEntryForm from "../components/FabricShadeGlobalRunnerEntryForm";
import decimal from "../../../helpers/decimal";
import fetchFabricShade from "@/modules/fabric_shades/helpers/fetchFabricShade";

export default {
  list: {
    permissions: ["fabric_shade_global_runner_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_shade_global_runner_entries_create",
          params: {
            fabricShadeId: route.params.fabricShadeId
          }
        }),
        permissions: ["fabric_shade_global_runner_entry_create"]
      }
    ],
    route: {
      path: "/fabric_shade_global_runner_entries/:fabricShadeId/list",
      name: "fabric_shade_global_runner_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        label: ({ compData }) =>
          `Wiązania prowadnic dla wersji "${compData.fabricShade.name}"`
      }
    ],
    fields: [
      {
        field: "globalRunner.symbol",
        type: "text",
        label: "Symbol prowadnicy",
        filter: "text"
      },
      {
        field: "globalRunner.name",
        type: "text",
        label: "Nazwa prowadnicy",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata długości [netto]",
        format: decimal
      }
    ],
    resource: "fabric_shade_global_runner_entries",
    url: ({ route }) =>
      `/fabric_shades/${route.params.fabricShadeId}/fabric_shade_global_runner_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=lengthSurcharge&properties[globalRunner][]=name&properties[globalRunner][]=symbol`,
    onCreated: fetchFabricShade,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_global_runner_entry_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_global_runner_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_global_runner_entry_create"],
    route: {
      path: "/fabric_shade_global_runner_entries/:fabricShadeId/create",
      name: "fabric_shade_global_runner_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_global_runner_entries_list",
          params: { fabricShadeId: compData.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Prowadnice dla wersji "${compData.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadeGlobalRunnerEntryForm,
    onCreated: fetchFabricShade,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_global_runner_entries_list",
      params: { fabricShadeId: compData.fabricShade.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_global_runner_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_global_runner_entry_update"],
    route: {
      path: "/fabric_shade_global_runner_entries/:id/update",
      name: "fabric_shade_global_runner_entries_update"
    },
    resource: "fabric_shade_global_runner_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_global_runner_entries_list",
          params: { fabricShadeId: compData.object.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Prowadnice dla wersji "${compData.object.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania prowadnicy "${compData.object.globalRunner.name}" z wersją "${compData.object.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadeGlobalRunnerEntryForm,
    onCreated: fetchFabricShade,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_global_runner_entries_list",
      params: {
        fabricShadeId: compData.object.fabricShade.id
      }
    })
  }
};
