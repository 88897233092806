import ExternalShutterForm from "../components/ExternalShutterForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["external_shutter_read"],
    actions: [
      {
        label: "Nowa roleta zewnętrzna",
        type: "is-primary",
        route: () => ({
          name: "external_shutters_create"
        }),
        permissions: ["external_shutter_create"]
      }
    ],
    route: {
      path: "/external_shutters/list",
      name: "external_shutters_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Rolety zewnętrzne"
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: 'Symbol', filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      {
        field: "minWidth",
        type: "text",
        label: "Min szer(mm)"
      },
      {
        field: "maxWidth",
        type: "text",
        label: "Max szer(mm)"
      },
      {
        type: "link",
        label: "Pancerze (cenniki)",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_price_lists_list",
            params: { externalShutterId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Prowadnice",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_external_shutter_runner_entries_list",
            params: { externalShutterId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Zabezpieczenia",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_external_shutter_protection_entries_list",
            params: { externalShutterId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Skrzynki",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_boxes_list",
            params: { externalShutterId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Adaptacje",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_adaptations_list",
            params: { externalShutterId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Napędy",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_global_drive_entries_list",
            params: { externalShutterId: row.id }
          })
        }
      }
    ],
    resource: "external_shutters",
    url: () =>
      `/external_shutters?` +
      generateProperties(
        [],
        ["id", "name", "symbol", "minWidth", "maxWidth", "isEnabled"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_create"],
    route: {
      path: "/external_shutters/create",
      name: "external_shutters_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Relety zewnętrzne"
      },
      {
        label: () => `Nowa wersja`
      }
    ],
    formComponent: ExternalShutterForm,
    listRoute: () => ({ name: "external_shutters_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutters_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_update"],
    route: {
      path: "/external_shutters/:id/update",
      name: "external_shutters_update"
    },
    resource: "external_shutters",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "position",
        "symbol",
        "isEnabled",
        "minWidth",
        "maxWidth",
        "priceListCols",
        "priceListRows",
        "productionTime",
        "productionBox",
        "productionPipe",
        "productionBalk",
        "productionRunner",
        "productionArmor1",
        "productionArmor2",
        "productionArmor3",
        "productionMosquitoNet",
        "productionMosquitoSlat",
        "productionMosquitoCase",
        "surveyDescriptionPL",
        "surveyDescriptionCZ",
        "surveyDescriptionEN",
        "surveyDescriptionDE",
        "surveyDescriptionFR",
        "surveyDescriptionSE",
        "surveyDescriptionIT",
        "surveyDescriptionSI",
        "surveyDescriptionRU",
        "surveyDescriptionES",
        "surveyDescriptionNL",
        "notesForPriceListPL",
        "notesForPriceListCZ",
        "notesForPriceListEN",
        "notesForPriceListDE",
        "notesForPriceListFR",
        "notesForPriceListSE",
        "notesForPriceListIT",
        "notesForPriceListSI",
        "notesForPriceListRU",
        "notesForPriceListES",
        "notesForPriceListNL",
        "extendedTitlePL",
        "extendedTitleCZ",
        "extendedTitleEN",
        "extendedTitleDE",
        "extendedTitleFR",
        "extendedTitleSE",
        "extendedTitleIT",
        "extendedTitleSI",
        "extendedTitleRU",
        "extendedTitleES",
        "extendedTitleNL",
        "descriptionForShopPL",
        "descriptionForShopCZ",
        "descriptionForShopEN",
        "descriptionForShopDE",
        "descriptionForShopFR",
        "descriptionForShopSE",
        "descriptionForShopIT",
        "descriptionForShopSI",
        "descriptionForShopRU",
        "descriptionForShopES",
        "descriptionForShopNL",
        "measurementInstructions",
        "surveyPicture",
        "isEnabledForShop",
        "hasCombinations",
        "globalPipes",
        "externalShutterDriveExits"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        label: ({ compData }) => `Edycja wersji "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterForm,
    listRoute: () => ({ name: "external_shutters_list" })
  }
};
