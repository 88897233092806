import fetchSubCompany from "../helpers/fetchSubCompany";
import generateProperties from "../../../helpers/generateProperties";
import getOrderDate from "../../../helpers/orderDate";
import getStatusInfo from "../../../helpers/subCompanyOrderStatusInfo";
import productTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import formatPrice from "../../../helpers/formatPrice";
import priceFormatter from "../../../helpers/priceFormatter";
import customer from "../../../helpers/customer";

export default {
    list: {
        permissions: ["sub_company_read"],
        // sortOrder: "asc",
        sortable: false,
        route: {
            path: "/sub_companies/:subCompanyId/orders",
            name: "sub_company_orders"
        },
        //dlaczego w ten sposób? ponieważ genialność reaktywności sprawia, że w którymś momencie brakuje danych, które na pewno tam się znajdują
        //więc bawimy się w tą głupią grę :)
        breadcrumbs: [
            {
                route: function({ compData }) {
                    if (compData.subCompany.company) {
                        return {
                            name: "sub_companies_list",
                            params: {
                                companyId: compData.subCompany.company.id
                            }
                        }
                    }
                    return {
                        name: "sub_companies_list",
                        params: {
                            companyId: null
                        }
                    }
                },
                label: function ({compData}) {
                    if (compData.subCompany.company) {
                        return `Pododbiorcy dla odbiorcy: "${compData.subCompany.company.name}"`
                    }
                    return `Pododbiorcy dla odbiorcy: `
                }
            },
            {
                label: ({compData}) =>
                    `Zamówienia dla pododbiorcy: "${compData.subCompany.name}"`
            }
        ],
        fields: [
            {
                field: "search",
                label: "Odbiorca",
                filter: "text",
                type: "virtual",
                value: (h, row) => (customer(row))
            },
            {
                field: "number",
                type: "text",
                label: "Numer",
                filter: "text"
            },
            {
                field: "name",
                type: "text",
                label: "Nazwa",
                filter: "text"
            },
            {
                field: "orderDate",
                type: "virtual",
                label: "Data zamówienia",
                value: (h, row) => (getOrderDate(row))
            },
            {
                field: "category",
                type: "virtual",
                label: "Kategoria",
                value: (h, row) => (productTypeFullNameByOrderCategory[row.category])
            },
            {
                field: "in",
                type: "virtual",
                label: "Status",
                value: (h, row) => (getStatusInfo(row)),
                filter: 'select',
                filterOptions: () => [
                    {label: "Wszystkie", value: null},
                    {label: "Zamówienia", value: "new accepted production", selected: true},
                    {label: "Wycena", value: "valuation"},
                    {label: "Nowy", value: "new"},
                    {label: "Przyjęte", value: "accepted"},
                    {label: "Produkcja", value: "production"},
                    {label: "Niedokończone", value: "cleared"},
                ],
            },
            {
                field: "net_purchase_price",
                type: "virtual",
                label: "Kwota zakupu netto",
                value: (h, row) => (priceFormatter(formatPrice(row.totalNetPurchaseValueBeforeDiscount - row.companyMarginProfit)))
            },
            {
                field: "net_sales_price",
                type: "virtual",
                label: "Kwota sprzedaży netto",
                value: (h, row) => (priceFormatter(formatPrice(row.totalNetPurchaseValueBeforeDiscount)))
            },
            {
                field: "net_margin_profit",
                type: "virtual",
                label: "Marża netto",
                value: (h, row) => (priceFormatter(formatPrice(row.companyMarginProfit)))
            },
            {
                field: "settled_order",
                type: "virtual",
                label: "Rozlicz",
                value: (h, row) => ((!row.isSettled) ? 'Nie' : 'Tak')
            },
            {
                field: "orderDate[strictly_after]",
                type: "date",
                label: "Data początkowa",
                filter: 'datepicker',
                disabledHead: true
            },
            {
                field: "orderDate[strictly_before]",
                type: "date",
                label: "Data końcowa",
                filter: 'datepicker',
                disabledHead: true
            },
        ],
        resource: "sub_companies",
        url: ({route}) =>
            '/sub_companies/' + route.params.subCompanyId + '/orders?subCompany.id=route.params.subCompanyId&in=new accepted production valuation complete&order[orderDate]=desc' +
            generateProperties(['subCompany'], ["receptionDays", "hasInvoicingByProducer", "hasShipmentToSubCompany"], true) +
            generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true) +
            generateProperties([], ["id", "name", "number", "orderDate", "productionStatus", "category", "status", "receptionDate", "productionDate", "shipmentDate", "totalPurchaseGrossValue", "customPurchaseGrossValue", "deliveryMethod", "companyMarginProfit", "totalNetPurchaseValueBeforeDiscount", "orderInvoice", "isSettled", "subCompany"], true) +
            generateProperties(["deliveryMethod"], ['name'], true),
        onCreated: fetchSubCompany,
    },
};
