<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="global_remote_controls" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Symbol" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            .columns
                                .column
                                    app-form-field(label="Kolejność" field="position" :errors="errors")
                                        b-input(type="number" @input="data.position = +$event" :value="data.position")
                                .column
                                    app-form-field(label="Dopłata zł / sztukę" field="pieceSurcharge" :errors="errors")
                                        app-form-decimal(v-model="data.pieceSurcharge")

                            .columns
                                .column
                                    app-form-field(label="Cena hurtowa" field="netWholesalePrice" :errors="errors")
                                        app-form-decimal(v-model="data.netWholesalePrice")
                                .column
                                    app-form-field(label="Cena detaliczna" field="netRetailPrice" :errors="errors")
                                        app-form-decimal(v-model="data.netRetailPrice")

                            app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                app-form-time-integer(v-model="data.productionTime")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                        .column
                            app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                                b-input(v-model="data.message" type="textarea")

                            app-form-field(label="Producent" field="manufacturer" :errors="errors")
                                resource-select(
                                  v-model="data.manufacturer"
                                  resource="manufacturers"
                                  placeholder="Wybierz producenta"
                                )
                            app-form-field(label="Typ pilota" field="globalRemoteControlType" :errors="errors")
                                resource-select(
                                    v-model="data.globalRemoteControlType"
                                    resource="global_remote_control_types"
                                    placeholder="Wybierz typ"
                                )
                            app-form-field(label="Kanał" field="globalRemoteControlChannel" :errors="errors")
                                resource-select(
                                    v-model="data.globalRemoteControlChannel"
                                    resource="global_remote_control_channels"
                                    placeholder="Wybierz kanał"
                                )
                            br

                            app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

                            app-form-field(label="Miniaturka (proporcja 4:3)" field="thumbnail" :errors="errors")
                                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  components: { ResourceSelect },
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        symbol: "",
        position: 0,
        pieceSurcharge: 0,
        picture: "",
        thumbnail: "",
        message: "",
        skipCalculations: false,
        productionTime: 0,
        isEnabled: true,
        manufacturer: null,
        globalRemoteControlType: null,
        globalRemoteControlChannel: null,
        netRetailPrice: 0,
        netWholesalePrice: 0
      },
      files: {
        picture: {},
        thumbnail: {}
      },
      messageHelp:
        "Gdy użytkownik wybierze ten pilot, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToModel(data) {
      if (!data.netRetailPrice) {
        data.netRetailPrice = 0;
      }
      if (!data.netWholesalePrice) {
        data.netWholesalePrice = 0;
      }

      if (data.manufacturer && data.manufacturer["@id"]) {
        data.manufacturer = data.manufacturer["@id"];
      }

      if (data.globalRemoteControlType && data.globalRemoteControlType["@id"]) {
        data.globalRemoteControlType = data.globalRemoteControlType["@id"];
      }

      if (data.globalRemoteControlChannel && data.globalRemoteControlChannel["@id"]) {
        data.globalRemoteControlChannel = data.globalRemoteControlChannel["@id"];
      }

      return data;
    },
    transformToSubmit(data) {
      if (!data.position) {
        data.position = 0;
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
