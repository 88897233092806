import OrderReclamationForm from "../components/OrderReclamationForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
    list: {
        permissions: ["order_reclamation_read"],
        actions: [
            {
                label: "Nowa reklamacja",
                type: "is-primary",
                route: () => ({
                    name: "order_reclamation_create"
                }),
                permissions: ["order_reclamations_create"]
            }
        ],
        countable: true,
        route: {
            path: "/order_reclamations/list",
            name: "order_reclamations_list"
        },
        breadcrumbs: [
            {
                label: () => "Lista zgłoszeń"
            }
        ],
        fields: [
            {field: "name", type: "text", label: "Nazwa", filter: "text"},
            {
                field: "createdAt",
                type: "text",
                label: "Data zgłoszenia"
            },
            {
                field: "company",
                type: "virtual",
                label: "Zgłaszający",
                filter: "text",
                value: (h, row) => {
                    return row && row.company && row.company.shortName ? row.company.shortName : '-'
                },
            },
            {
                field: "order.category",
                type: "virtual",
                label: "Produkt",
                filter: "select",
                filterOptions: () => [
                    {label: "Wszystkie", value: null},
                    {label: "Roletki tkaninowe", value: "fabric_shade"},
                    {label: "Roletki dzień-noc", value: "fabric_shade_day_night"},
                    {label: "Rolety zewnętrzne", value: "external_shutter"},
                ],
                value: (h, row) => {
                    const value = row && row.order ? row.order.category : '-'

                    if (value === "fabric_shade") {
                        return "Roletki tkaninowe";
                    } else if (value === "fabric_shade_day_night") {
                        return "Roletki dzień-noc"
                    } else if (value === "external_shutter") {
                        return "Rolety zewnętrzne"
                    }
                },

            },
            {
                field: "updatedAt",
                type: "text",
                label: "Data ostatniej aktualizacji"
            },
            {
                field: "decision",
                type: "text",
                label: "Decyzja",
                filter: "select",
                filterOptions: () => [
                    {label: "Wszystkie", value: null},
                    {label: "Uznana", value: "accepted"},
                    {label: "Odrzucona", value: "rejected"}
                ],
                format: val => {
                    switch (val) {
                        case "rejected":
                            return "Odrzucona";
                        case "accepted":
                            return "Uznana";
                        default :
                            return 'brak'
                    }
                }
            },
            {
                field: "status",
                type: "text",
                label: "Status",
                filter: "select",
                filterOptions: () => [
                    {label: "Wszystkie", value: null},
                    {label: "Nowy", value: "new"},
                    {label: "Przyjęty", value: "accepted"},
                    {label: "Zakończony", value: "closed"}
                ],
                format: val => {
                    switch (val) {
                        case "new":
                            return "Nowy";
                        case "accepted":
                            return "Przyjęty";
                        case "closed":
                            return "Zakończony";
                    }
                }
            },
            {
                field: "isReadByAdmin",
                type: "simplebool",
                label: "Odczytano",
                filter: "boolean"
            },
            {
                field: "status",
                type: "booleanStatus",
                label: "Zamknięta",
                status_true: "closed",
                status_false: "new"
            },
        ],
        resource: "order_reclamations",
        url: () =>
            `/order_reclamations?` +
            generateProperties(
                [],
                ["id", "name", "createdAt", "updatedAt", "status", "decision", "reportedBy", "reason", "isReadByAdmin", "company", "order", "month", "orderNumberFromOutside", "orderNumber"]
            ) +
            generateProperties(
                ["company"],
                ["shortName", "id"], true)
            +
            generateProperties(
                ["order"],
                ["category", "number"], true),
        rowActions: [
            {
                action: "edit",
                icon: "eye",
                permissions: ["order_reclamation_update"]
            }
        ]
    },
    create: {
        permissions: ["order_reclamation_create"],
        route: {
            path: "/order_reclamation/create",
            name: "order_reclamation_create"
        },
        breadcrumbs: [
            {
                route: () => ({name: "order_reclamations_list"}),
                label: () => "Reklamacje"
            },
            {
                label: () => `Nowa reklamacja`
            }
        ],
        formComponent: OrderReclamationForm,
        listRoute: () => ({name: "order_reclamations_list"}),
        updateRoute: ({object}) => ({
            name: "order_reclamations_update",
            params: {id: object.id}
        }),
    },
    update: {
        permissions: ["order_reclamation_update"],
        route: {
            path: "/order_reclamations/:id/update",
            name: "order_reclamations_update"
        },
        resource: "order_reclamations",
        breadcrumbs: [
            {
                route: () => ({name: "order_reclamations_list"}),
                label: () => "Lista zgłoszeń reklamacyjnych"
            },
            {
                label: ({compData}) =>
                    `"Edycja zgłoszenia reklamacyjnego": "${compData.object.name}"`
            }
        ],
        formComponent: OrderReclamationForm,
        listRoute: () => ({name: "order_reclamations_list"})
    }
};
