<template lang="pug">
  span(title="Kliknij by przełączyć")
    a(
      class="button is-small"
      :class="classes"
      href=""
      @click.prevent="toggle"
    ) {{ value === status_true ? "TAK" : "NIE" }}
</template>
<script>
export default {
  props: {
    resource: {type: String, required: true},
    object: {type: Object, required: true},
    field: {type: String, required: true},
    status_true: {type: String, required: true},
    status_false: {type: String, required: true},
  },
  computed: {
    value() {
      return this.object[this.field];
    },
    classes() {
      return {"is-success": this.value === this.status_true, "is-danger": this.value !== this.status_true};
    },
    url() {
      return `${this.resource}/${this.object.id}`;
    }
  },
  methods: {
    async toggle() {
      const value = this.object[this.field] === this.status_true ? this.status_false : this.status_true;
      this.object[this.field] = value;
      try {
        let {data} = await this.$http.put(this.url, {
          [this.field]: value
        });
        this.$emit("success", data);
        this.$notify("Przełączono element");
      } catch (e) {
        this.$notify("Nie udało się przełączyć elementu", "danger");
        this.object[this.field] = !this.object[this.field];
      }
    }
  }
};
</script>
