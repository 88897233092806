import ExternalShutterBoxExternalFabricShadeColorEntryForm from "../components/ExternalShutterBoxExternalFabricShadeColorEntryForm";
import fetchExternalShutterBox from "../helpers/fetchExternalShutterBox";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";
import fetchExternalShutterAndExternalShutterBox from "../helpers/fetchExternalShutterAndExternalShutterBox";

export default {
  list: {
    permissions: [
      "external_shutter_box_external_fabric_shade_color_entry_read"
    ],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name:
            "external_shutter_box_external_fabric_shade_color_entries_create",
          params: {
            externalShutterBoxId: route.params.externalShutterBoxId
          }
        }),
        permissions: [
          "external_shutter_box_external_fabric_shade_color_entry_create"
        ]
      }
    ],
    route: {
      path:
        "/external_shutter_box_external_fabric_shade_color_entries/:externalShutterBoxId/list",
      name: "external_shutter_box_external_fabric_shade_color_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        route: ({ compData }) => ({ name: "external_shutter_boxes_list", params: {externalShutterId: compData.externalShutter && compData.externalShutter.id} }),
        label: ({ compData }) => `Skrzynki dla wersji "${compData.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Kolory zewnętrzne skrzynki "${compData.externalShutterBox.name}"`
      }
    ],
    fields: [
      {
        field: "fabricShadeColor.name",
        type: "text",
        label: "Kolor zewnętrzny",
        filter: "text"
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata za mb",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_box_external_fabric_shade_color_entries",
    url: ({ route }) =>
      `/external_shutter_boxes/${route.params.externalShutterBoxId}/external_shutter_box_external_fabric_shade_color_entries?` +
      generateProperties(
        [],
        ["id", "position", "isEnabled", "lengthSurcharge"]
      ) +
      generateProperties(["externalShutterBox"], ["name", "id"], true) +
      generateProperties(["fabricShadeColor"], ["name", "id"], true),
    onCreated: fetchExternalShutterAndExternalShutterBox,
    rowActions: [
      {
        action: "edit",
        permissions: [
          "external_shutter_box_external_fabric_shade_color_entry_update"
        ]
      },
      {
        action: "remove",
        permissions: [
          "external_shutter_box_external_fabric_shade_color_entry_delete"
        ]
      }
    ]
  },
  create: {
    permissions: [
      "external_shutter_box_external_fabric_shade_color_entry_create"
    ],
    route: {
      path:
        "/external_shutter_box_external_fabric_shade_color_entries/:externalShutterBoxId/create",
      name: "external_shutter_box_external_fabric_shade_color_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        route: ({ compData }) => ({ name: "external_shutter_boxes_list", params: {externalShutterId: compData.externalShutter.id} }),
        label: ({ compData }) => `Skrzynki dla wersji "${compData.externalShutter.name}"`
      },
      {
        route: ({ compData }) => ({ name: "external_shutter_box_external_fabric_shade_color_entries_list", params: {externalShutterBoxId: compData.externalShutterBox.id} }),
        label: ({ compData }) =>
          `Kolory zewnętrzne skrzynki "${compData.externalShutterBox.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla skrzynki "${compData.externalShutterBox.name}"`
      }
    ],
    formComponent: ExternalShutterBoxExternalFabricShadeColorEntryForm,
    onCreated: fetchExternalShutterAndExternalShutterBox,
    listRoute: ({ compData }) => ({
      name: "external_shutter_box_external_fabric_shade_color_entries_list",
      params: { externalShutterBoxId: compData.externalShutterBox.id }
    }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_box_external_fabric_shade_color_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: [
      "external_shutter_box_external_fabric_shade_color_entry_update"
    ],
    route: {
      path:
        "/external_shutter_box_external_fabric_shade_color_entries/:id/update",
      name: "external_shutter_box_external_fabric_shade_color_entries_update"
    },
    resource: "external_shutter_box_external_fabric_shade_color_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        route: ({ compData }) => ({ name: "external_shutter_boxes_list", params: {externalShutterId: compData.object.externalShutter && compData.object.externalShutter.id || undefined} }),
        label: ({ compData }) => `Skrzynki dla wersji "${compData.object.externalShutter && compData.object.externalShutter.name || ""}"`
      },
      {
        route: ({ compData }) => ({ name: "external_shutter_box_external_fabric_shade_color_entries_list", params: {externalShutterBoxId: compData.object.externalShutterBox && compData.object.externalShutterBox.id || undefined} }),
        label: ({ compData }) =>
          `Kolory zewnętrzne skrzynki "${compData.object.externalShutterBox && compData.object.externalShutterBox.name || ""}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania koloru zewnętrznego "${compData.object.fabricShadeColor && compData.object.fabricShadeColor.name || ""}" ze skrzynką "${compData.object.externalShutterBox && compData.object.externalShutterBox.name || ""}"`
      }
    ],
    formComponent: ExternalShutterBoxExternalFabricShadeColorEntryForm,
    onCreated: fetchExternalShutterAndExternalShutterBox,
    listRoute: ({ compData }) => ({
      name: "external_shutter_box_external_fabric_shade_color_entries_list",
      params: {
        externalShutterBoxId: compData.object.externalShutterBox.id
      }
    })
  }
};
