import moment from 'moment';

export default function getOrderDate(order) {
    return order.orderDate
        ? moment(order.orderDate, "DD-MM-YYYY hh:mm:ss").format(
            "YYYY-MM-DD hh:mm:ss"
        )
        : "";
}

