import GlobalDriveControlForm from "../components/GlobalDriveControlForm";
import decimal from "../../../helpers/decimal";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["global_drive_control_read"],
    sortable: true,
    actions: [
      {
        label: "Nowe sterowanie",
        type: "is-primary",
        route: () => ({
          name: "global_drive_controls_create"
        }),
        permissions: ["global_drive_control_create"]
      },
      {
        label: "Export",
        type: "is-info",
        route: () => ({
          name: "global_drive_controls_export"
        }),
        permissions: ["global_drive_controls_export"]
      },
      {
        label: "Import",
        type: "is-info",
        route: () => ({
          name: "global_drive_controls_import"
        }),
        permissions: ["global_drive_controls_import"]
      }
    ],
    route: {
      path: "/global_drive_controls/list",
      name: "global_drive_controls_list"
    },
    breadcrumbs: [
      {
        label: () => `Globalne sterowania`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "isRemoteControl", type: "boolean", label: "Pilot", filter: "boolean" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za szt. [netto]",
        format: decimal
      }
    ],
    resource: "global_drive_controls",
    url: () =>
      `/global_drive_controls?` +
      generateProperties(
        [],
        ["id", "name", "isEnabled", "symbol", "pieceSurcharge", "position", 'isRemoteControl']
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["global_drive_control_update"]
      },
      {
        action: "remove",
        permissions: ["global_drive_control_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_drive_control_create"],
    route: {
      path: "/global_drive_controls/create",
      name: "global_drive_controls_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_drive_controls_list" }),
        label: () => "Globalne sterowania"
      },
      {
        label: () => `Nowe globalne sterowanie`
      }
    ],
    formComponent: GlobalDriveControlForm,
    listRoute: () => ({
      name: "global_drive_controls_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_drive_controls_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["global_drive_control_create"],
    route: {
      path: "/global_drive_controls/:id/update",
      name: "global_drive_controls_update"
    },
    resource: "global_drive_controls",
    breadcrumbs: [
      {
        route: () => ({ name: "global_drive_controls_list" }),
        label: () => "Globalne sterowania"
      },
      {
        label: ({ compData }) =>
          `Edycja globalnego sterowania "${compData.object.name}"`
      }
    ],
    formComponent: GlobalDriveControlForm,
    listRoute: () => ({
      name: "global_drive_controls_list"
    })
  },
  export: {
    permissions: ["global_drive_controls_export"],
    route: {
      path: "/global_drive_controls/export",
      name: "global_drive_controls_export"
    },
  },
  import: {
    permissions: ["global_drive_controls_import"],
    route: {
      path: "/global_drive_controls/import",
      name: "global_drive_controls_import"
    },
  },
};
