import crud from "../../crud";
import slideMosquitoNets from "./crud/slideMosquitoNets";
import slideMosquitoNetGlobalAccessoryEntries from "./crud/slideMosquitoNetGlobalAccessoryEntries";
import slideMosquitoNetGlobalNetEntries from "./crud/slideMosquitoNetGlobalNetEntries";
import slideMosquitoNetSlideMosquitoNetAdditionalEntries from "./crud/slideMosquitoNetSlideMosquitoNetAdditionalEntries";
import slideMosquitoNetPriceLists from "./crud/slideMosquitoNetPriceLists";
import slideMosquitoNetAdditionals from "./crud/slideMosquitoNetAdditionals";
import slideMosquitoNetFrames from "./crud/slideMosquitoNetFrames";
import slideMosquitoNetBottomRails from "./crud/slideMosquitoNetBottomRails";

crud(slideMosquitoNets);
crud(slideMosquitoNetGlobalAccessoryEntries);
crud(slideMosquitoNetGlobalNetEntries);
crud(slideMosquitoNetSlideMosquitoNetAdditionalEntries);
crud(slideMosquitoNetPriceLists);
crud(slideMosquitoNetAdditionals);
crud(slideMosquitoNetFrames);
crud(slideMosquitoNetBottomRails);
