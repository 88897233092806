<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="fixed_order_activities" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
                app-form-field(label="Nazwa" field="name" :errors="errors")
                  b-input(v-model="data.name")

                app-form-field(label="Kolejność" field="position" :errors="errors")
                  b-input(type="number" @input="data.position = +$event" :value="data.position")

                app-form-field(field="isEnabled" :errors="errors")
                  b-checkbox(v-model="data.isEnabled") Aktywny

                app-form-field(field="requireQuantity" :errors="errors")
                  b-checkbox(v-model="data.requireQuantity") Czy wymaga ilości sztuk w zamówieniu

            .column

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
    mixins: [form],
    data() {
        return {
            model: {
                name: "",
                isEnabled: false,
                position: 0,
                requireQuantity: false,
            }
        };
    },
    methods: {
        transformToSubmit(data) {
            if (data.fixedOrders) {
                delete data.fixedOrders;
            }

            return data;
        },
        transformToModel(data) {
            if (data.fixedOrders) {
                delete data.fixedOrders;
            }

            return data;
        }
    },
    async created() {
        this.model.position = await checkLastPosition(this.$router);
    }
};
</script>
