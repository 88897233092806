/**
 * INPUT:
 * {
 *     name: "test",
 *     fabricShades: [
 *         {
 *             @id: "/fabric_shades/1"
 *         },
 *         {
 *             @id: "/fabric_shades/2",
 *             "fabricShadeColors": [
 *                  {
 *                      @id: "/fabric_shade_colors/1"
 *                  }
 *             ]
 *         }
 *     ]
 * }
 *
 * OUTPUT:
 * {
 *     name: "test",
 *     fabricShades: [
 *         "/fabric_shades/1",
 *         "/fabric_shades/2",
 *     ]
 * }
 */
export default function flatter(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] && obj[key]["@id"]) {
      obj[key] = obj[key]["@id"];
    }
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map(item => {
        if (item["@id"]) {
          return item["@id"];
        }
        return flatter(item);
      });
    }
  });
  return obj;
}
