import router from "./../../router";
import UserSettings from "@/modules/settings/components/UserSettings";

router.addRoute(
  {
    path: "/settings/user",
    name: "user_settings",
    component: UserSettings
  }
);
