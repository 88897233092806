import fetchFabricShade from "../helpers/fetchFabricShade";
import FabricShadePriceListForm from "../components/FabricShadePriceListForm";

export default {
  list: {
    permissions: ["fabric_shade_price_list_read"],
    sortField:"group.position",
    sortOrder:'asc',
    route: {
      path: "/fabric_shade_price_lists/:fabricShadeId/list",
      name: "fabric_shade_price_lists_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        label: ({ compData }) =>
          `Cennik bazowy dla wersji "${compData.fabricShade.name}"`
      }
    ],
    fields: [
      {
        field: "group.position",
        type: "text",
        label: "Pozycja",
      },
      {
        field: "group.name",
        type: "text",
        label: "Nazwa grupy",
        filter: "text"
      }
    ],
    resource: "fabric_shade_price_lists",
    url: ({ route }) =>
      `/fabric_shades/${route.params.fabricShadeId}/fabric_shade_price_lists?properties[]=group&properties[]=id`,
    onCreated: fetchFabricShade,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_price_list_update"]
      }
    ]
  },
  update: {
    permissions: ["fabric_shade_price_list_update"],
    route: {
      path: "/fabric_shade_price_lists/:id/update",
      name: "fabric_shade_price_lists_update"
    },
    resource: "fabric_shade_price_lists",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_price_lists_list",
          params: { fabricShadeId: compData.object.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Cennik bazowy dla wersji "${compData.object.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja cennika bazowego grupy "${compData.object.group.name}" dla wersji "${compData.object.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadePriceListForm,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_price_lists_list",
      params: {
        fabricShadeId: compData.object.fabricShade.id
      }
    })
  }
};
