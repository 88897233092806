<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose" full-screen)
    app-view-loadable(:loading="loading")
      app-view-card(:title="'Podgląd zamówienia ' + orderNumber")
        template(slot="icon")
          b-button(
            @click="onClose"
          )
            b-icon(icon="close" size="is-small")
        .is-centered(v-if="!order || (order && orderItems.length < 1) && order['@type'] !== 'FixedOrder'") Brak
        template(v-else)
          production-order-preview-table(v-if="(order && orderItems.length > 0)" :order="order")
          br
          .columns
            .column.is-1
            .column
              table.table.is-bordered.is-fullwidth
                thead
                  tr
                    th(colspan=2) Informacje o zamówieniu
                tbody.print-width-250.real-width-tables-td-250
                  tr
                    th Numer
                    td {{ orderNumber }}
                  tr
                    th Firma
                    td {{ companyShortName }}
                  tr
                    th Klient
                    td {{ ordererInfo }}
                  tr
                    th Produkt
                    td {{ getProductCategoryName(order.category) }}
                  tr
                    th Sztuk
                    td {{ orderItemsCount }}
            .column
            .column
              table.table.is-bordered.is-fullwidth
                thead
                  tr
                    th(colspan=2) Statusy
                tbody.print-width-250.real-width-tables-td-250
                  tr
                    th Wycena
                    td {{ getDate(order.valuationDate) }}
                  tr
                    th Zamówienie
                    td {{ getDate(order.orderDate) }}
                  tr
                    th Produkcja
                    td {{ getDate(order.productionDate, "DD-MM-YYYY") }}
                  tr
                    th Status
                    td {{ getStatus(order.productionStatus) }}
            .column.is-1
</template>

<script>
import decimal from "../../../helpers/decimal";
import http from "../../../http";
import notify from "../../../helpers/notify";
import generateProperties from "../../../helpers/generateProperties";
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import moment from "moment";
import ProductionOrderPreviewTable from "../subcomponents/ProductionOrderPreviewTable";
import ProductTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import orderItemsGetter from "../../../helpers/orderItemGetter";

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {ProductionOrderPreviewTable},
  props: {
    active: { type: Boolean, default: false, required: true },
    orderId: { default: null, required: true }
  },
  data() {
    return {
      order: null,
      isActive: this.active,
      loading: false
    };
  },
  methods: {
    downloadOrder: debounce(async function(activator, value) {
      if (
        !this.order ||
        (this.order && this.order.id !== parseInt(this.orderId))
      ) {
        try {
          this.loading = true;
          let { data } = await http.get(`/api/production/orders/${this.orderId}/view/preview`);
          this.order = data;
          if (activator === "active") {
            if (this.isActive !== !!value) {
              this.isActive = !!value;
            }
          }
        } catch {
          this.order = null;
          notify(
            "Nie udało się załadować danych do podglądu zamówienia",
            "danger"
          );
          this.$emit("close");
        } finally {
          this.loading = false;
        }
      } else if (activator === "active") {
        if (this.isActive !== !!value) {
          this.isActive = !!value;
        }
      }
    }, 100),
    onClose() {
      this.isActive = false;
      this.$emit("close");
    },
    prop(object, field, extraField = null) {
      if (extraField) {
        return (object && object[extraField][field]) || "Brak";
      }
      return (object && object[field]) || "Brak";
    },
    getDecimalValue(value) {
      return decimal(value);
    },
    getDate(date, format = "DD-MM-YYYY hh:mm:ss") {
      if (!date) {
        return "Nie ustawione";
      }
      return moment(date, "DD-MM-YYYY hh:mm:ss").format(format);
    },
    getStatus(status) {
      switch (status) {
        case "waiting":
          return "Oczekuje";
        case "in_progress":
          return "W trakcie";
        case "end":
          return "Zakończony";
        default:
          return "";
      }
    },
    getProductCategoryName(type) {
      return ProductTypeFullNameByOrderCategory[type] || '';
    }
  },
  computed: {
    ordererInfo() {
      if (!this.order || !this.order.orderer) {
        return "Brak";
      }
      const orderer = this.order.orderer;
      return `${orderer.firstName || ""} ${orderer.lastName || ""}`;
    },
    companyShortName() {
      if (!this.order) {
        return "";
      }
      if (this.order.company) {
        return this.order.company.shortName;
      }
      if (this.order.subCompany) {
        return this.order.subCompany.shortName;
      }
      return "";
    },
    orderItems() {
      if (!this.order) {
        return [];
      }

      return orderItemsGetter(this.order);
    },
    orderNumber() {
      if (this.order && this.order.number) {
        return orderNumberCreator(this.order.number);
      } else {
        return "";
      }
    },
    orderItemsCount() {
      if (this.order['@type'] === 'FixedOrder') {
          return this.order.quantity;
      }
      let count = 0;
      if (this.orderItems.length < 1) {
        return count;
      }
      for (let item in this.orderItems) {
        count += this.orderItems[item].quantity;
      }
      return count;
    }
  },
  watch: {
    orderId(value) {
      if (value && !isNaN(value) && this.active) {
        this.downloadOrder("id");
      }
    },
    active(value) {
      if (value && !isNaN(value) && this.active) {
        this.downloadOrder("active", value);
      }
    }
  }
};
</script>
