import FabricShadeDayNightForm from "../components/FabricShadeDayNightForm";
import generateProperties from "../../../helpers/generateProperties";
import centimeter from "../../../helpers/centimeter";

export default {
  list: {
    permissions: ["fabric_shade_day_night_read"],
    actions: [
      {
        label: "Nowa wersja",
        type: "is-primary",
        route: () => ({
          name: "fabric_shade_day_nights_create"
        }),
        permissions: ["fabric_shade_day_night_create"]
      }
    ],
    route: {
      path: "/fabric_shade_day_nights/list",
      name: "fabric_shade_day_nights_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Roletki dzień-noc"
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      {
        field: "isWithGlassSizes",
        type: "boolean",
        label: "Rozmiary szyby"
      },
      {
        field: "priceListType",
        type: "text",
        label: "Rodzaj cennika",
        filter: "select",
        filterOptions: [
          { label: "Wszystkie", value: null },
          { label: "Tabela", value: "tabela" },
          { label: "Cena za m2", value: "m2" }
        ]
      },
      {
        field: "minWidth",
        type: "text",
        label: "Minimalna szerokość (cm)",
        format: centimeter
      },
      {
        field: "maxWidth",
        type: "text",
        label: "Maksymalna szerokość (cm)",
        format: centimeter
      },
      {
        type: "link",
        label: "Cennik bazowy",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_shade_day_night_price_lists_list",
            params: { fabricShadeDayNightId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Osprzęt",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_shade_day_night_global_accessory_entries_list",
            params: { fabricShadeDayNightId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Prowadnice",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_shade_day_night_global_runner_entries_list",
            params: { fabricShadeDayNightId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Belki dolne",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_shade_day_night_global_balk_entries_list",
            params: { fabricShadeDayNightId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Mocowania",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "fabric_shade_day_night_global_fixing_entries_list",
            params: { fabricShadeDayNightId: row.id }
          })
        }
      },
        {
            type: "link",
            label: "Dodatki",
            link: {
                label: "Pokaż",
                route: ({ row }) => ({
                    name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_list",
                    params: { fabricShadeDayNightId: row.id }
                })
            }
        }
    ],
    resource: "fabric_shade_day_nights",
    url: () =>
      `/fabric_shade_day_nights?` + generateProperties([], ['id', 'name', 'symbol', 'isEnabled', 'priceListType', 'minWidth', 'maxWidth', 'isWithGlassSizes']),
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_day_night_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_day_night_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_day_night_create"],
    route: {
      path: "/fabric_shade_day_nights/create",
      name: "fabric_shade_day_nights_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        label: () => `Nowa wersja`
      }
    ],
    formComponent: FabricShadeDayNightForm,
    listRoute: () => ({ name: "fabric_shade_day_nights_list" }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_day_nights_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["fabric_shade_day_night_update"],
    route: {
      path: "/fabric_shade_day_nights/:id/update",
      name: "fabric_shade_day_nights_update"
    },
    resource: "fabric_shade_day_nights",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "position",
        "symbol",
        "isEnabled",
        "isEnabledForShop",
        "picture",
        "notesForPriceListPL",
        "notesForPriceListCZ",
        "notesForPriceListEN",
        "notesForPriceListDE",
        "notesForPriceListFR",
        "notesForPriceListSE",
        "notesForPriceListIT",
        "notesForPriceListSI",
        "notesForPriceListRU",
        "notesForPriceListES",
        "notesForPriceListNL",
        "fabricWidth",
        "fabricWidthOperator",
        "priceListType",
        "minWidth",
        "maxWidth",
        "minHeight",
        "maxHeight",
        "productionCase",
        "productionPipe",
        "productionWeight",
        "productionRunner",
        "productionFabricWidth",
        "productionFabricHeight",
        "hasRunner",
        "hasCase",
        "hasFlounces",
        "hasSideDivision",
        "previewType",
        "surveyDescriptionPL",
        "surveyDescriptionCZ",
        "surveyDescriptionEN",
        "surveyDescriptionDE",
        "surveyDescriptionFR",
        "surveyDescriptionSE",
        "surveyDescriptionIT",
        "surveyDescriptionSI",
        "surveyDescriptionRU",
        "surveyDescriptionES",
        "surveyDescriptionNL",
        "extendedTitlePL",
        "extendedTitleCZ",
        "extendedTitleEN",
        "extendedTitleDE",
        "extendedTitleFR",
        "extendedTitleSE",
        "extendedTitleIT",
        "extendedTitleSI",
        "extendedTitleRU",
        "extendedTitleES",
        "extendedTitleNL",
        "descriptionForShopPL",
        "descriptionForShopCZ",
        "descriptionForShopEN",
        "descriptionForShopDE",
        "descriptionForShopFR",
        "descriptionForShopSE",
        "descriptionForShopIT",
        "descriptionForShopSI",
        "descriptionForShopRU",
        "descriptionForShopES",
        "descriptionForShopNL",
        "fabricHeights",
        "mechanismPicture",
        "thumbnailForPriceList",
        "surveyPicture",
        "profilePictureForShop",
        "heightFieldPictureForShop",
        "widthFieldPictureForShop",
        "priceListCols",
        "priceListRows",
        "measurementInstruction",
        "globalPipes",
        "productionTime",
        "defaultInstallationValue",
        "message",
        'isWithGlassSizes'
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        label: ({ compData }) => `Edycja wersji "${compData.object.name}"`
      }
    ],
    formComponent: FabricShadeDayNightForm,
    listRoute: () => ({ name: "fabric_shade_day_nights_list" })
  }
};
