<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="global_drive_controls" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Symbol" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            .columns
                                .column
                                    app-form-field(label="Kolejność" field="position" :errors="errors")
                                        b-input(type="number" @input="data.position = +$event" :value="data.position")
                                .column
                                    app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                        app-form-time-integer(v-model="data.productionTime")

                            app-form-field(label="Dopłata zł / sztukę" field="pieceSurcharge" :errors="errors")
                               app-form-decimal(v-model="data.pieceSurcharge")

                            .columns
                                .column
                                    app-form-field(label="Cena hurtowa" field="netWholesalePrice" :errors="errors")
                                        app-form-decimal(v-model="data.netWholesalePrice")
                                .column
                                    app-form-field(label="Cena detaliczna" field="netRetailPrice" :errors="errors")
                                        app-form-decimal(v-model="data.netRetailPrice")


                            app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

                            app-form-field(label="Miniaturka (proporcja 4:3)" field="thumbnail" :errors="errors")
                                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")

                        .column
                            app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                                b-input(v-model="data.message" type="textarea")

                            app-form-field(label="Opis" field="description" :errors="errors")
                                b-input(v-model="data.description" type="textarea")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                            app-form-field(label="Producent" field="manufacturer" :errors="errors")
                                resource-select(
                                  v-model="data.manufacturer"
                                  resource="manufacturers"
                                  placeholder="Wybierz producenta"
                                )
                            app-form-field(label="Typ sterowania" field="globalDriveControlType" :errors="errors")
                                resource-select(
                                  v-model="data.globalDriveControlType"
                                  resource="global_drive_control_types"
                                  placeholder="Wybierz typ sterowania"
                                )
                            app-form-field(field="isRemoteControl" :errors="errors")
                              b-checkbox(v-model="data.isRemoteControl") Pilot

                            app-form-field(label="Kanał" field="globalRemoteControlChannel" :errors="errors")
                              resource-select(
                                v-model="data.globalRemoteControlChannel"
                                resource="global_remote_control_channels"
                                placeholder="Wybierz kanał"
                              )
                b-tab-item(label="Dokumenty")
                  app-form-field(label="Plik instrukcja montażu" field="measurementInstruction" :errors="errors")
                    app-form-file(v-model="files.measurementInstruction" :image="data.measurementInstruction" @removed="data.measurementInstruction = {}" :downloadable="true")
                b-tab-item(label="Sterowania i piloty")
                  app-form-field(label="Sterowania i piloty" field="assignedGlobalDriveControls" :errors="errors")
                    multiple-resource-select(
                    v-model="data.assignedGlobalDriveControls"
                    resource="assignedGlobalDriveControls"
                    url="global_drive_controls"
                    placeholder="Wybierz starowania i piloty"
                    :excludeId="data.id"
                  )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import ResourceSelect from "../../common/components/ResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  components: { MultipleResourceSelect, ResourceSelect },
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        symbol: "",
        position: 0,
        pieceSurcharge: 0,
        description: "",
        picture: "",
        thumbnail: "",
        message: "",
        skipCalculations: false,
        isEnabled: true,
        manufacturer: null,
        globalDriveControlType: null,
        productionTime: 0,
        isRemoteControl:false,
        globalRemoteControlChannel:null,
        globalRemoteControls: [],
        assignedGlobalDriveControls:[],
        netRetailPrice: 0,
        netWholesalePrice: 0,
        measurementInstruction: null,
      },
      files: {
        picture: {},
        thumbnail: {},
        measurementInstruction: null,
      },
      messageHelp:
        "Gdy użytkownik wybierze to sterowanie, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToSubmit(data) {
      if (data.manufacturer && data.manufacturer["@id"]) {
        data.manufacturer = data.manufacturer["@id"];
      }
      if (!data.position) {
        data.position = 0;
      }
      if (data.globalDriveControlType && data.globalDriveControlType["@id"]) {
        data.globalDriveControlType = data.globalDriveControlType["@id"];
      }

      data.assignedGlobalDriveControls = data.assignedGlobalDriveControls.map(
          assignedDriveControl => assignedDriveControl["@id"] || assignedDriveControl
      );
      if (data.id) {
        const idx = data.assignedGlobalDriveControls.findIndex(
            assignedDriveControl => assignedDriveControl === `/global_drive_control/${data.id}`
        );
        if (idx > -1) {
          data.assignedGlobalDriveControls.splice(idx, 1);
        }
      }
      return data;
    },
    transformToModel(data) {
      if (!data.netRetailPrice) {
        data.netRetailPrice = 0;
      }
      if (!data.netWholesalePrice) {
        data.netWholesalePrice = 0;
      }
      if (data.globalRemoteControlChannel && data.globalRemoteControlChannel["@id"]) {
        data.globalRemoteControlChannel = data.globalRemoteControlChannel["@id"];
      }

      /*this.model.globalRemoteControls = data.globalRemoteControls.map(
        globalRemoteControls => globalRemoteControls["@id"]
      );*/
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
