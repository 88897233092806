import ManufacturerForm from "../components/ManufacturerForm";

export default {
  list: {
    permissions: ["manufacturer_read"],
    actions: [
      {
        label: "Nowy producent",
        type: "is-primary",
        route: () => ({
          name: "manufacturers_create"
        }),
        permissions: ["manufacturer_create"]
      }
    ],
    route: {
      path: "/manufacturers/list",
      name: "manufacturers_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => `Producenci`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" }],
    resource: "manufacturers",
    url: () => `/manufacturers`,
    rowActions: [
      {
        action: "edit",
        permissions: ["manufacturer_update"]
      },
      {
        action: "remove",
        permissions: ["manufacturer_delete"]
      }
    ]
  },
  create: {
    permissions: ["manufacturer_create"],
    route: {
      path: "/manufacturers/create",
      name: "manufacturers_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "manufacturers_list"
        }),
        label: () => `Producenci`
      },
      {
        label: () => `Nowy producent`
      }
    ],
    formComponent: ManufacturerForm,
    listRoute: () => ({
      name: "manufacturers_list"
    }),
    updateRoute: ({ object }) => ({
      name: "manufacturers_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["manufacturer_update"],
    route: {
      path: "/manufacturers/:id/update",
      name: "manufacturers_update"
    },
    resource: "manufacturers",
    breadcrumbs: [
      {
        route: () => ({
          name: "manufacturers_list"
        }),
        label: () => `Producenci`
      },
      {
        label: ({ compData }) => `Edycja producenta "${compData.object.name}"`
      }
    ],
    formComponent: ManufacturerForm,
    listRoute: () => ({
      name: "manufacturers_list"
    })
  }
};
