import NewsForm from "../components/NewsForm";

export default {
  list: {
    permissions: ["news_read"],
    actions: [
      {
        label: "Nowa aktualność",
        type: "is-primary",
        route: () => ({
          name: "news_create"
        }),
        permissions: ["news_create"]
      }
    ],
    route: {
      path: "/news/list",
      name: "news_list"
    },
    countable: true,
    breadcrumbs: [
      {
        label: () => "Aktualności"
      }
    ],
    fields: [
      {field: "titlePL", type: "text", label: "Tytuł", filter: "text"},
      {field: "author.email", type: "text", label: "Autor"},
      {field: "createdAt", type: "text", label: "Data dodania"},
      {
        field: "productTypes",
        type: "virtual", label: "Moduł",
        value: (h, row) => row.productTypes.map(pt => pt.name).join(', ')
      },
      {field: "priority", type: "text", label: "Priorytet"},
      {field: "isEnabled", type: "boolean", label: "Aktywny"},
      {field: "dateFrom", type: "text", label: "Data ważności od"},
      {field: "dateTo", type: "text", label: "Data waśności do"},
      {type: "link", label: "Potwierdzenia", link:
          {
            label: "Podglad",
            route: ({row}) => ({name: "news_reads_list", params: {id: row.id}})
          }
      },
      {field: "isAttached", type: "boolean", label: "Przypięty"},
    ],
    resource: "news",
    url: () => `/news`,
    rowActions: [
      {
        action: "edit",
        permissions: ["news_update"]
      },
      {
        action: "remove",
        permissions: ["news_delete"]
      }
    ]
  },
  create: {
    permissions: ["news_create"],
    route: {
      path: "/news/create",
      name: "news_create"
    },
    breadcrumbs: [
      {
        route: () => ({name: "news_list"}),
        label: () => "Aktualności"
      },
      {
        label: () => `Nowa aktualność`
      }
    ],
    formComponent: NewsForm,
    listRoute: () => ({name: "news_list"}),
    updateRoute: ({object}) => ({
      name: "news_update",
      params: {id: object.id}
    })
  },
  update: {
    permissions: ["news_update"],
    route: {
      path: "/news/:id/update",
      name: "news_update"
    },
    resource: "news",
    breadcrumbs: [
      {
        route: () => ({name: "news_list"}),
        label: () => "Aktualności"
      },
      {
        label: ({compData}) => `Edycja aktualności "${compData.object.titlePL}"`
      }
    ],
    formComponent: NewsForm,
    listRoute: () => ({name: "news_list"})
  }
};
