import FabricColorForm from "../components/FabricColorForm";
import fetchFabric from "../helpers/fetchFabric";

export default {
  list: {
    permissions: ["fabric_color_read"],
    actions: [
      {
        label: "Nowy kolor tkaniny",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_colors_create",
          params: {
            fabricId: route.params.fabricId
          }
        }),
        permissions: ["fabric_color_create"]
      }
    ],
    route: {
      path: "/fabric_colors/:fabricId/list",
      name: "fabric_colors_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_colors_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: () => ({ name: "fabric_shade_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => {
          if (compData.fabric.group) {
            return {
              name: "fabrics_list",
              params: { fabricShadeGroupId: compData.fabric.group.id }
            };
          }
          return {};
        },
        label: ({ compData }) => {
          if (compData.fabric.group) {
            return `Kolekcje tkanin dla grupy "${compData.fabric.group.name}"`;
          }
          return null;
        }
      },
      {
        label: ({ compData }) => `Kolory dla tkaniny "${compData.fabric.name}"`
      }
    ],
    fields: [
      { field: "picture", type: "image", label: "Zdjęcie" },
      { field: "thumbnail", type: "image", label: "Miniatura" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "fabric_colors",
    url: ({ route }) =>
      `/fabrics/${route.params.fabricId}/fabric_colors?properties[]=id&properties[]=name&properties[]=picture&properties[]=isEnabled&properties[]=symbol&properties[]=thumbnail`,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_color_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_color_delete"]
      }
    ],
    onCreated: fetchFabric
  },
  create: {
    permissions: ["fabric_color_create"],
    route: {
      path: "/fabric_colors/:fabricId/create",
      name: "fabric_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_colors_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: () => ({ name: "fabric_shade_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabrics_list",
          params: { fabricShadeGroupId: compData.fabric.group.id }
        }),
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy "${compData.fabric.group.name}"`
      },
      {
        route: ({ compData }) => ({
          name: "fabric_colors_list",
          params: { fabricId: compData.fabric.id }
        }),
        label: ({ compData }) => `Kolory dla tkaniny "${compData.fabric.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy kolor dla tkaniny "${compData.fabric.name}"`
      }
    ],
    formComponent: FabricColorForm,
    listRoute: ({ compData }) => ({
      name: "fabric_colors_list",
      params: { fabricId: compData.fabric.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_colors_update",
      params: { id: object.id }
    }),
    onCreated: fetchFabric
  },
  update: {
    permissions: ["fabric_color_update"],
    route: {
      path: "/fabric_colors/:id/update",
      name: "fabric_colors_update"
    },
    resource: "fabric_colors",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: () => ({ name: "fabric_shade_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabrics_list",
          params: { fabricShadeGroupId: compData.object.fabric.group.id }
        }),
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy "${compData.object.fabric.group.name}"`
      },
      {
        route: ({ compData }) => ({
          name: "fabric_colors_list",
          params: { fabricId: compData.object.fabric.id }
        }),
        label: ({ compData }) =>
          `Kolory dla tkaniny "${compData.object.fabric.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru "${compData.object.name}" dla tkaniny "${compData.object.fabric.name}"`
      }
    ],
    formComponent: FabricColorForm,
    listRoute: ({ compData }) => ({
      name: "fabric_colors_list",
      params: { fabricId: compData.object.fabric.id }
    })
  }
};
