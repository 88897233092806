import WindowFrameForm from "@/modules/fabric_shades/components/WindowFrameForm";

export default {
  list: {
    sortable: true,
    permissions: ["window_frame_read"],
    actions: [
      {
        label: "Nowa kolor okna",
        type: "is-primary",
        route: () => ({
          name: "window_frames_create"
        }),
        permissions: ["window_frame_create"]
      }
    ],
    route: {
      path: "/window_frames/list",
      name: "window_frames_list"
    },
    breadcrumbs: [
      {
        label: () => "Kolory okien"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "picture", type: "image", label: "Zdjęcie" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }
    ],
    resource: "window_frames",
    url: () =>
      "/window_frames?properties[]=id&properties[]=name&properties[]=isEnabled&properties[]=picture",
    rowActions: [
      {
        action: "edit",
        permissions: ["window_frame_update"]
      },
      {
        action: "remove",
        permissions: ["window_frame_delete"]
      }
    ]
  },
  create: {
    permissions: ["window_frame_create"],
    route: {
      path: "/window_frames/create",
      name: "window_frames_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "window_frames_list" }),
        label: () => "Kolory okien"
      },
      {
        label: () => "Nowa kolor okna"
      }
    ],
    formComponent: WindowFrameForm,
    listRoute: () => ({
      name: "window_frames_list"
    }),
    updateRoute: ({ object }) => ({
      name: "window_frames_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["window_frame_update"],
    route: {
      path: "/window_frames/:id/update",
      name: "window_frames_update"
    },
    resource: "window_frames",
    breadcrumbs: [
      {
        route: () => ({ name: "window_frames_list" }),
        label: () => "Kolory okien"
      },
      {
        label: ({ compData }) => `Edycja koloru okna "${compData.object.name}"`
      }
    ],
    formComponent: WindowFrameForm,
    listRoute: () => ({
      name: "window_frames_list"
    })
  }
};
