<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="details" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Automatyka" field="globalDriveControl" :errors="errors")
                b-autocomplete(
                  v-model="searchGlobalElements"
                  :data="globalElements"
                  placeholder="Wybierz globalny podzespoł"
                  field="shortName"
                  :loading="isFetching"
                  @typing="getAsyncData"
                  @select="option => {onGlobalElementChanged(option, data);}"
                )
                  template(#default="{ option }")
                    span(v-if="option.symbol") {{ option.symbol }}
                    span(v-else) {{ option.name }}
                //resource-select(v-model="data.globalDriveControl" resource="global_drive_controls" placeholder="Wybierz automatykę" extra-props="&isEnabled=1" )
              app-form-field(label="Nazwa" field="name" :errors="errors" )
                b-input(v-model="data.name" :disabled="isDetailFromAutomation")
              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol" :disabled="isDetailFromAutomation")
              app-form-field(label="Numer EAN" field="EANNumber" :errors="errors")
                b-input(v-model="data.EANNumber")
              app-form-field(label="Jednostki" field="detailUnits" :errors="errors")
                multiple-resource-select(
                  v-model="data.detailUnits"
                  resource="detail_units"
                  placeholder="Wybierz jednostki"
                )
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled" ) Aktywny
              app-form-field(label="Jednostka metryczna" field="metricUnit" :errors="errors")
                b-select(v-model="data.metricUnit")
                  option(v-for="unit in metricUnits" :key="unit.id" :value="unit.name === 'brak' ? null : unit.name ") {{unit.name}}
              .columns
                .column
                  app-form-field(label="Cena jednostkowa hurt" field="netWholesalePriceForUnit" :errors="errors")
                    app-form-decimal(v-model="data.netWholesalePriceForUnit" :disabled="isDetailFromAutomation")
                .column
                  app-form-field(label="Cena jednostkowa detal" field="netRetailPriceForUnit" :errors="errors")
                    app-form-decimal(v-model="data.netRetailPriceForUnit" :disabled="isDetailFromAutomation")
              .columns
                .column
                  app-form-field(label="Cena cięcia hurt" field="netWholesalePriceForCut" :errors="errors")
                    app-form-decimal(v-model="data.netWholesalePriceForCut")
                .column
                  app-form-field(label="Cena cięcia detal" field="netRetailPriceForCut" :errors="errors")
                    app-form-decimal(v-model="data.netRetailPriceForCut")
              .columns
                .column
                  app-form-field(label="Cena za opakowanie hurt" field="netWholesalePriceForPackage" :errors="errors")
                    app-form-decimal(v-model="data.netWholesalePriceForPackage")
                .column
                  app-form-field(label="Cena za opakowanie detal" field="netRetailPriceForPackage" :errors="errors")
                    app-form-decimal(v-model="data.netRetailPriceForPackage")

              app-form-field(label="Minimalna ilość zamówieniowa (dotyczy sztuk)" field="minOrderQuantity" :errors="errors")
                b-input(type="number" v-model.number="data.minOrderQuantity")

              app-form-field(label="Maksymalna ilość zamówieniowa jednostkowa (dotyczy sztuk)" field="maxUnitOrderQuantity" :errors="errors")
                b-input(type="number" v-model.number="data.maxUnitOrderQuantity")

              app-form-field(label="Krok zmiany ilości (dotyczy sztuk)" field="quantityChangeStep" :errors="errors")
                b-input(type="number" v-model.number="data.quantityChangeStep")

              app-form-field(label="Ilość w opakowaniu" field="packageQuantity" :errors="errors")
                b-input(type="number" v-model.number="data.packageQuantity")

              app-form-field(label="Waga jednostkowa" field="weight" :errors="errors")
                app-form-decimal(v-model="data.weight")

            .column
              app-form-field(label="Opis" field="description" :errors="errors")
                b-input(v-model="data.description" type="textarea" :disabled="isDetailFromAutomation")
              app-form-field(label="Zdjęcie (proporcja 4:3) pierwsze" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}" :disabled="isDetailFromAutomation")
              app-form-field(label="Zdjęcia (proporcja 4:3) dodatkowe" field="pictures" :errors="errors")
                app-form-files(v-model="files.pictures" :image="data.pictures" @removed="(id)=>{data.pictures = afterRemovedFiles(id,data.pictures)}" :multiple='true' accept='image/jpeg,image/gif,image/png')
        b-tab-item(label="Produkty / System")
          app-form-field(label="Systemy" field="detailSystems" :errors="errors")
            multiple-resource-select(
              v-model="data.detailSystems"
              resource="detail_systems"
              placeholder="Wybierz systemy"
            )
        b-tab-item(label="Kategorie")
          app-form-field(label="Kategorie" field="detailCategories" :errors="errors")
            multiple-resource-select(
              v-model="data.detailCategories"
              resource="detail_categories"
              extra-props="&isEnabled=1"
              placeholder="Wybierz kategorie"
            )
        b-tab-item(label="Kolory")
          app-form-field(label="Kolory" field="detailColors" :errors="errors")
            multiple-resource-select(
              v-model="data.detailColors"
              extra-props="&isEnabled=1"
              resource="detail_colors"
              placeholder="Wybierz Kolor"
            )
        b-tab-item(label="Dokumenty")
          app-form-field(label="Pliki instrukcji montażu" field="measurementInstructions" :errors="errors")
            app-form-files(v-model="files.measurementInstructions" :image="data.measurementInstructions" @removed="(id)=>{data.measurementInstructions = afterRemovedFiles(id,data.measurementInstructions)}"  :downloadable="true" :multiple='true' accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps')

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import generateProperties from "../../../helpers/generateProperties";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      searchGlobalElements: "",
      globalElements: [],
      isFetching: false,
      metricUnits: [
        {id: 0, name: 'brak'},
        {id: 1, name: 'mm'},
        {id: 2, name: 'cm'},
        {id: 3, name: 'm'},
        {id: 4, name: 'mb'},
        {id: 5, name: 'kg'},
        {id: 6, name: 'g'},
        {id: 7, name: 'szt'},
        {id: 8, name: 'opak'},
        {id: 9, name: 'rozpak'},
      ],
      files: {
        picture: {},
        pictures: null,
        measurementInstructions: null,
        thumbnail: {}
      },
      model: {
        symbol: "",
        name: "",
        EANNumber: "",
        description: "",
        detailUnits: [],
        detailCategories: [],
        detailSystems: [],
        detailColors: [],
        isEnabled: true,
        netWholesalePriceForUnit: 0,
        netRetailPriceForUnit: 0,
        netWholesalePriceForCut: 0,
        netRetailPriceForCut: 0,
        netWholesalePriceForPackage: 0,
        netRetailPriceForPackage: 0,
        minOrderQuantity: 0,
        maxUnitOrderQuantity: 0,
        quantityChangeStep: 0,
        packageQuantity: 0,
        weight: 0,
        picture: null,
        pictures: [],
        measurementinstructions: null,
        globalDriveControl: null,
        globalDrive: null,
        metricUnit: ""
      },
    };
  },
  watch: {
    "model.globalDriveControl": {
      handler: "onChangeGlobalDriveControl",
      immediate: true
    },
    "model.globalDrive": {
      handler: "onChangeGlobalDrive",
      immediate: true
    }
  },
  computed: {
    isDetailFromAutomation() {
      return this.model.globalDriveControl !== null || this.model.globalDrive !== null;
    }
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(data) {

      this.model.detailCategories = data.detailCategories.map(
          detailCategories => detailCategories["@id"]
      );

      this.model.detailColors = data.detailColors.map(
          detailColors => detailColors["@id"]
      );

      this.model.detailSystems = data.detailSystems.map(
          detailSystems => detailSystems["@id"]
      );

      this.model.detailUnits = data.detailUnits.map(
          detailUnits => detailUnits["@id"]
      );

      return data;
    },
    afterRemovedFiles(id, array) {
      if (array.length === 0) return [];
      return array.filter((obj) => obj.id !== id);
    },
    async onChangeGlobalDriveControl(change) {
      if (this.model.globalDriveControl !== null) {
        const {data} = await http.get(change);
        this.model.name = data.name;
        this.model.symbol = data.symbol;
        this.model.description = data.description;
        this.model.netRetailPriceForUnit = data.netRetailPrice;
        this.model.netWholesalePriceForUnit = data.netWholesalePrice;
        this.model.picture = data.picture;
      }
    },
    async onChangeGlobalDrive(change) {
      if (this.model.globalDrive !== null) {
        const {data} = await http.get(change);
        this.model.name = data.name;
        this.model.symbol = data.symbol;
        this.model.description = data.description;
        this.model.netRetailPriceForUnit = data.netRetailPrice;
        this.model.netWholesalePriceForUnit = data.netWholesalePrice;
        this.model.picture = data.picture;
      }
    },
    onGlobalElementChanged(option, data) {
      if (option['@type'] === 'GlobalDriveControl') {
        this.model.globalDriveControl = option["@id"];
      } else {
        this.model.globalDrive = option["@id"];
      }
    },

    getAsyncData: debounce(async function (name) {
      if (!name.length) {
        this.customers = [];
        return;
      }
      this.isFetching = true;

      const fetchResource = async (resource, properties) => {
        try {
          const {data} = await http.get(`/${resource}?` + generateProperties([], properties) + `&search=${name}&isEnabled=1`);
          return data["hydra:member"];
        } catch (error) {
          return [];
        }
      };

      const [globalDrives, globalDriveControls] = await Promise.all([
        fetchResource('global_drives', ["id", "name", "symbol"]),
        fetchResource('global_drive_controls', ["id", "name", "symbol"])
      ]);

      this.globalElements = globalDrives.concat(globalDriveControls);
      this.isFetching = false;
    }, 500),
  }
};
</script>
