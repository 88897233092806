<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_protections" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              .columns
                .column
                  app-form-field(label="Nazwa" field="name" :errors="errors")
                    b-input(v-model="data.name")
                  app-form-field(field="isEnabled" :errors="errors")
                    b-checkbox(v-model="data.isEnabled") Widoczny
                .column
                  app-form-field(label="Kolejność" field="position" :errors="errors")
                    b-input(type="number" @input="data.position = +$event" :value="data.position")
              .columns
                .column
                  app-form-field(label="Dopłata za 1m szerokości" field="lengthSurcharge" :errors="errors")
                    app-form-decimal(v-model="data.lengthSurcharge")
                .column
                  app-form-field(label="Dopłata za sztukę" field="pieceSurcharge" :errors="errors")
                    app-form-decimal(v-model="data.pieceSurcharge")
                .column
                  app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                    app-form-time-integer(v-model="data.productionTime")
              app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
            .column
              app-form-field(label="Opis" field="description" :errors="errors")
                app-form-editor(v-model="data.description")

              app-form-field(label="Konflikty" field="externalShutterProtectionConflicts" :errors="errors")
                multiple-resource-select(
                  v-model="data.externalShutterProtectionConflicts"
                  resource="external_shutter_protections"
                  url="external_shutter_protections"
                  placeholder="Wybierz konflikty"
                  :exclude-id="data.id"
                )

</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import simpleClone from "../../../helpers/simpleClone";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { MultipleResourceSelect },
  data() {
    return {
      files: {
        picture: {}
      }
    };
  },
  computed: {
    model() {
      return {
        name: "",
        position: 0,
        isEnabled: false,
        lengthSurcharge: 0,
        pieceSurcharge: 0,
        description: "",
        productionTime: 0,
        externalShutterProtectionConflicts: [],
        picture: null
      };
    }
  },
  methods: {
    transformToSubmit(data) {
      data.externalShutterProtectionConflicts = data.externalShutterProtectionConflicts.map(
        conflict => conflict["@id"] || conflict
      );
      if (data.id) {
        const idx = data.externalShutterProtectionConflicts.findIndex(
          conflict => conflict === `/external_shutter_protections/${data.id}`
        );
        if (idx > -1) {
          data.externalShutterProtectionConflicts.splice(idx, 1);
        }
      }
      return data;
    },
    transformToModel(data) {
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
