<template lang="pug">
  div
    app-view-card(:title="`Lista obecności na dzień ${getCurrentFormattedDate()}`")
      app-view-card(title="Instrukcja")
        ul
          li Zaznacz każdemu z pracowników czy jest obecny
          li Pracownikom produkcji zaznacz stanowisko pracy pilarza/montera na dzisiejszy dzień
          li Wybierz każdemu pracownikowi linię przy produkcie, na którym będzie pracował
          li Jeśli przy zaznaczonym produkcie nie będzie wybranej linii produkcyjnej, ustawienie zostanie pominięte
          li Jeśli edytujesz tę listę po raz drugi, to wszystkie wybrane wcześniej informacje zostaną połączone z poprzednimi.
      br
      .columns.is-multiline
        .column.is-3(v-for="(worker, id) in workers")
          app-view-card(:title="`${worker.firstName} ${worker.lastName}`")
            template(slot="icon")
              b-button(@click="toggleWorkerVisible(worker)")
                b-icon(v-if="workerIsVisible(worker)" icon="arrow-up" size="is-small")
                b-icon(v-if="!workerIsVisible(worker)" icon="arrow-down" size="is-small")
            template
              .columns.is-multiline(v-if="workerIsVisible(worker)")
                .column.is-12
                  b-select(:value="getWorkerPresentValue(worker)" @input="handleWorkerPresentInput(worker, $event)")
                    option(:value="true") Obecny
                    option(:value="false") Nieobecny
                .column
                  b-field.has-text-weight-bold.has-text-centered Monter
                  div(v-for="(type, typeId) in productTypes" :key="'fitterType'+typeId")
                    br
                    b-checkbox(
                      :value="getFitterProductTypeValueByWorker(worker, type)"
                      @input ="handleFitterProductTypeInput(worker, type, $event)"
                    ) {{ type.name }}
                    .has-margin-left-30(v-for="(line, lineId) in type.productionLines" v-if="checkFitterProductType(worker, type)")
                      br
                      b-checkbox(
                        :value="getFitterProductionLineValueByWorkerAndProductType(worker, type, line)"
                        @input="handleFitterProductionLineInput(worker, type, line, $event)"
                      ) {{ line.name }}
                .divider.is-vertical
                .column
                  b-field.has-text-weight-bold.has-text-centered Pilarz
                  div(v-for="(type, typeId) in productTypes" :key="'sawyerType'+typeId")
                    br
                    b-checkbox(
                      :value="getSawyerProductTypeValueByWorker(worker, type)"
                      @input ="handleSawyerProductTypeInput(worker, type, $event)"
                    ) {{ type.name }}
      .has-text-centered
        b-button(@click="postAttendanceRegister" type="is-primary") Zapisz
</template>

<script>
import http from "../../../http";
import generateProperties from "../../../helpers/generateProperties";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import simpleClone from "../../../helpers/simpleClone";
import Vue from "vue";
import notify from "../../../helpers/notify";
import moment from "moment";

export default {
  components: { MultipleResourceSelect },
  data() {
    return {
      //downloaded
      workers: [],
      //downloaded
      productTypes: [],
      model: {
        workers: []
      },
      workersVisible: []
    };
  },
  methods: {
    getCurrentFormattedDate() {
      return moment().format("DD-MM-YYYY");
    },
    toggleWorkerVisible(worker) {
      const val = !this.workersVisible[worker.id];
      Vue.set(this.workersVisible, worker.id, val);
    },
    workerIsVisible(worker) {
      return this.workersVisible[worker.id];
    },
    checkFitterProductType(worker, type) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      const typeId = this.model.workers[workerId].fitterProductTypes.findIndex(el => el.id === type.id);
      return typeId > -1;
    },
    handleWorkerPresentInput(worker, data) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      this.model.workers[workerId].isPresent = !!data;
    },
    getWorkerPresentValue(worker) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      return this.model.workers[workerId].isPresent;
    },
    getFitterProductTypeValueByWorker(worker, type) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      return this.model.workers[workerId].fitterProductTypes.findIndex(el => el.id === type.id) > -1;
    },
    handleFitterProductTypeInput(worker, type, data) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      const typeId = this.model.workers[workerId].fitterProductTypes.findIndex( el => el.id === type.id);
      if (data) {
        let productType = simpleClone(type);
        productType.productionLines = [];
        this.model.workers[workerId].fitterProductTypes.push(productType);
      } else {
        if (typeId > -1) {
          Vue.delete(this.model.workers[workerId].fitterProductTypes, typeId);
        }
      }
    },
    getFitterProductionLineValueByWorkerAndProductType(worker, type, line) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      const typeId = this.model.workers[workerId].fitterProductTypes.findIndex(el => el.id === type.id);
      const lineId = this.model.workers[workerId].fitterProductTypes[typeId].productionLines.findIndex(el => el.id === line.id);

      return lineId > -1;
    },
    handleFitterProductionLineInput(worker, type, line, data) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      const typeId = this.model.workers[workerId].fitterProductTypes.findIndex( el => el.id === type.id);
      const lineId = this.model.workers[workerId].fitterProductTypes[typeId].productionLines.findIndex(el => el.id === line.id);
      if (data) {
        let productionLine = simpleClone(line);
        this.model.workers[workerId].fitterProductTypes[typeId].productionLines.push(productionLine);
      } else {
        if (lineId > -1) {
          Vue.delete(this.model.workers[workerId].fitterProductTypes[typeId].productionLines, lineId);
        }
      }
    },
    checkSawyerProductType(worker, type) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      const typeId = this.model.workers[workerId].sawyerProductTypes.findIndex(el => el.id === type.id);
      return typeId > -1;
    },
    getSawyerProductTypeValueByWorker(worker, type) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      return this.model.workers[workerId].sawyerProductTypes.findIndex(el => el.id === type.id) > -1;
    },
    handleSawyerProductTypeInput(worker, type, data) {
      const workerId = this.model.workers.findIndex(el => el.worker.id === worker.id);
      const typeId = this.model.workers[workerId].sawyerProductTypes.findIndex( el => el.id === type.id);
      if (data) {
        let productType = simpleClone(type);
        productType.productionLines = [];
        this.model.workers[workerId].sawyerProductTypes.push(productType);
      } else {
        if (typeId > -1) {
          Vue.delete(this.model.workers[workerId].sawyerProductTypes, typeId);
        }
      }
    },
    async downloadUsers() {
      let { data } = await http.get(
        "/production_workers?isEnabled[]=true" +
          generateProperties([], ["id", "firstName", "lastName"], true) +
          generateProperties(["fitterProductTypes"], ["id"], true) +
          generateProperties(["sawyerProductTypes"], ["id"], true)
      );
      const workers = data["hydra:member"];

      for (const idx in workers) {
        this.workersVisible[workers[idx].id] = true;
        let fitterProductTypes = simpleClone(workers[idx].fitterProductTypes);
        for (const ptIdx in fitterProductTypes) {
          fitterProductTypes[ptIdx].productionLines = [];
        }
        let sawyerProductTypes = simpleClone(workers[idx].sawyerProductTypes);
        for (const ptIdx in sawyerProductTypes) {
          sawyerProductTypes[ptIdx].productionLines = [];
        }
        this.model.workers.push({
          isPresent: true,
          worker: workers[idx],
          fitterProductTypes: fitterProductTypes,
          sawyerProductTypes: sawyerProductTypes
        });
      }
      this.workers = workers;
    },
    async downloadProductTypes() {
      let { data } = await http.get(
        "/product_types?" +
          generateProperties([], ["id", "name"]) +
          generateProperties(
            ["productionLines"],
            ["id", "name", "number"],
            true
          )
      );
      this.productTypes = data["hydra:member"];
    },
    async postAttendanceRegister() {
      let data = simpleClone(this.model);
      for (const idx in data.workers) {
        data.workers[idx]["@id"] = data.workers[idx].worker["@id"];
        data.workers[idx]["id"] = data.workers[idx].worker["id"];
        data.workers[idx]["@type"] = data.workers[idx].worker["@type"];
        delete data.workers[idx].worker;
      }
      await http.post("/attendance_registers", data);
      notify("Lista obecności została przetworzona");
    }
  },
  async created() {
    await this.downloadUsers().then(() => this.downloadProductTypes());
  }
};
</script>
