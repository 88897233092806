import fetchPleatMosquitoNet from "../helpers/fetchPleatMosquitoNet";
import PleatMosquitoNetPriceListForm from "../components/PleatMosquitoNetPriceListForm.vue";
import generateProperties from "../../../helpers/generateProperties";

export default {
    list: {
        permissions: ["pleat_mosquito_net_price_list_read"],
        route: {
            path: "/pleat_mosquito_net_price_lists/:pleatMosquitoNetId/list",
            name: "pleat_mosquito_net_price_lists_list"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "pleat_mosquito_nets_list" }),
                label: () => "Moskitiery plisowane"
            },
            {
                route: ({ compData }) =>  compData && compData.object && compData.object.pleatMosquitoNet ? {
                    name: "pleat_mosquito_net_price_lists_list",
                    params: { pleatMosquitoNetId: compData.object.pleatMosquitoNet.id }
                } : {
                    name: "pleat_mosquito_net_price_lists_list",
                    params: { pleatMosquitoNetId: 1}
                },
                label: ({ compData }) =>
                    compData && compData.object && compData.object.pleatMosquitoNet ? `Cennik bazowy dla wersji "${compData.object.pleatMosquitoNet.name}"` : ''
            },
        ],
        fields: [
            {
                field: "pleatMosquitoNet.name",
                type: "text",
                label: "Nazwa moskitiery",
            },
            {
                field: "pleatMosquitoNet.symbol",
                type: "text",
                label: "Kod moskitiety",
            }
        ],
        resource: "pleat_mosquito_net_price_lists",
        url: ({ route }) =>
            `/pleat_mosquito_nets/${route.params.pleatMosquitoNetId}/pleat_mosquito_net_price_lists?`
        +generateProperties([],['id'],false)
        +generateProperties(['pleatMosquitoNet'],['id', 'name', 'symbol'],true)
        ,
        onCreated: fetchPleatMosquitoNet,
        rowActions: [
            {
                action: "edit",
                permissions: ["pleat_mosquito_net_price_list_update"]
            }
        ]
    },
    update: {
        permissions: ["pleat_mosquito_net_price_list_update"],
        route: {
            path: "/pleat_mosquito_net_price_lists/:id/update",
            name: "pleat_mosquito_net_price_lists_update"
        },
        resource: "pleat_mosquito_net_price_lists",
        breadcrumbs: [
            {
                route: () => ({ name: "pleat_mosquito_nets_list" }),
                label: () => "Moskitiery Plisowane"
            },
            {
                route: ({ compData }) => ({
                    name: "pleat_mosquito_net_price_lists_list",
                    params: { pleatMosquitoNetId: compData.object.pleatMosquitoNet.id }
                }),
                label: ({ compData }) =>
                    `Cennik bazowy dla wersji "${compData.object.pleatMosquitoNet.name}"`
            },
        ],
        onCreated: fetchPleatMosquitoNet,
        formComponent: PleatMosquitoNetPriceListForm,
        listRoute: ({ compData }) => ({
            name: "pleat_mosquito_net_price_lists_list",
            params: {
                pleatMosquitoNetId: compData.object.pleatMosquitoNet.id
            }
        })
    }
};
