<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="frame_mosquito_net_gaskets" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Dopłata za sztukę" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(field="skipCalculations" :errors="errors")
                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe
            .column
              app-form-field(label="Komunikat" field="message" :errors="errors")
                b-input(v-model="data.message" type="textarea")

              app-form-field(label="Zdjęcie" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        description: "",
        picture: null,
        position: null,
        isEnabled: true,
        pieceSurcharge: 0,
        skipCalculations: false,
        frameMosquitoNet: `/frame_mosquito_nets/${this.$route.params.frameMosquitoNetId}`,
      },
      files: {
        picture: null
      }
    };
  },
  methods: {
    transformToModel(data) {
      if (data.frameMosquitoNet && data.frameMosquitoNet["@id"]) {
        data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      }
      return data;
    },
    transformToSubmit(data) {
      if (data.frameMosquitoNet && data.frameMosquitoNet["@id"]) {
        data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
