<template lang="pug">
  .table-container.mt-2
    table.table.is-bordered.has-cells-centered.is-narrow.long-table
      thead
        tr
          th(colspan=20)  Pozycje zamówienia
        tr
          th(rowspan=2) Lp.
          th(rowspan=2) Wer.
          th(rowspan=2) Mosk
          th(rowspan=2) Komb.
          th(rowspan=3) Szer. [mm]
          th(colspan=3).has-text-centered Wys.
          th(rowspan=2) Pow. [m&#178;]
          th(rowspan=2) Masa [kg]
          th(rowspan=2) Napęd
          th(rowspan=2) Str nap.
          th(rowspan=2) Prow. L
          th(rowspan=2) Prow. P
          th(rowspan=2) Naw. L
          th(rowspan=2) Naw. P
          th(rowspan=2) Adap.
          th(rowspan=2) Zab.
          th(rowspan=2) Szt
          th(rowspan=2) Netto
        tr
          th Prow.[mm]
          th Całk.[mm]
          th Skrz.[mm]
      tbody
        template(v-for="(item, index) in orderItems(object)")
          tr(v-for="(itemCombination, indexCombination) in item.externalShutterOrderItemCombinations")
            // liczba porządkowa
            td
              div
                .th-in {{ (index + 1 + iteratorAlphabet(item)[indexCombination]) }}
            //nazwa wersji (z drugiej tabelki)
            td {{ checkVirtualVersionNumberAfterSum(orderAfterSum(object), item) +1 }}
            // Moskitiera
            td {{ trueFalseToText(prop(itemCombination.hasMosquitoNet)) }}
            // wybrana kombinacja
            td {{ (item.selectedCombination === "single") ? "-" : item.selectedCombination   }}
            //szerokość kombinacji
            td {{ prop(itemCombination, "width") }}
            // wysokość prowadnic
            td {{ prop(itemCombination, "runnersHeight") }}
            // wysokość całkowita
            td {{ prop(itemCombination, "fullHeight") }}
            // nazwa skrzynki
            td {{ prop(itemCombination.externalShutterBox, "name") }}
            // powierzchnia w m^2
            td {{ getDecimal(Math.round((prop(itemCombination, "fullHeight") * prop(itemCombination, "width")) / 10000)) }}
            // masa zestawu
            td {{ formatWeight(itemCombination.weight) }}
            // napęd
            td {{ prop(itemCombination.globalDrive, "symbol") }}
            // strona napędu
            td {{ prop(itemCombination.externalShutterDriveExit, "name") }}
            // Prowadnica lewa
            td {{ prop(itemCombination.externalShutterExternalShutterRunnerEntryLeft, "name",  "externalShutterRunner") }}
            // Prowadnica prawa
            td {{ prop(itemCombination.externalShutterExternalShutterRunnerEntryRight, "name",  "externalShutterRunner") }}
            // Nawiert lewy
            td {{ prop(itemCombination.externalShutterDrillHoleLeft, "name") }}
            // Nawiert prawy
            td {{ prop(itemCombination.externalShutterDrillHoleRight, "name") }}
            //Adaptacja
            td {{ prop(itemCombination.externalShutterAdaptation, "name") }}
            // Zabezpieczenia
            td
              p(v-if="!itemCombination.externalShutterExternalShutterProtectionEntries.length") Brak
              p(v-for="(itemProtection, indexProtection) in itemCombination.externalShutterExternalShutterProtectionEntries") {{ prop(itemProtection, "name", "externalShutterProtection") }}
            // ilość szt. przy kombinacji
            td( v-if="indexCombination === 0" :rowspan="item.externalShutterOrderItemCombinationsCount") {{ item.quantity }}
            //Wartość netto
            td {{ formatPriceValue(getDecimal(itemCombination.netPurchaseValue)) }}
      tfoot
        tr
          td(colspan=4).has-text-right Razem :
          td.has-text-weight-bold {{getDecimal(sumParameterInExternalShutterOrderItems(orderItems(object), 'width', true) /10)  }}m
          td(colspan=3)
          td.has-text-weight-bold {{ getDecimal(sumParameterInExternalShutterOrderItems(orderItems(object), 'sumSurfacesAllCombinations') / 10000)}}
          td.has-text-weight-bold {{ formatWeight(object.sumWeight) }}
          td(colspan=9)
          td.has-text-weight-bold {{ formatPriceValue(getDecimal(object.netPurchaseValueBeforeDiscount)) }}
    automation-order-items(:items='object.automationOrderItems')

</template>
<script>

import decimal from "@/helpers/decimal";
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import AutomationOrderItems from "./AutomationOrderItems";
import ExternalShutterOrderItemsAfterSum from "./ExternalShutterOrderItemsAfterSum";
import simpleClone from "../../../helpers/simpleClone";
import weight from "../../../helpers/weight";

export default {
  props: ['object'],
  components:{AutomationOrderItems, ExternalShutterOrderItemsAfterSum },
  computed:{
    formatWeight: () => grams => weight(grams),
    sumParameterInExternalShutterOrderItems: () => (items,parameter, combination=false) =>  {
      if(combination)return items.reduce((total, externalShutterOrderItem) =>{
        let combinationTotal = 0;
        externalShutterOrderItem.externalShutterOrderItemCombinations.forEach(combination=>{
          combinationTotal += combination[parameter]
        })
        return combinationTotal + total
      } ,0)

      return Object.values(items).reduce((total, externalShutterOrderItem) => (externalShutterOrderItem[parameter]) + total,0)
    },
    iteratorAlphabet: () => item =>{
      if(item.externalShutterOrderItemCombinationsCount === 1)return " "
      return ["a","b","c"]
    },
  },
  methods: {
    checkVirtualVersionNumberAfterSum: function (orderAfterSum, item) {
      return orderAfterSum.findIndex((p) => (
          p.externalShutter['id'] === item.externalShutter['id'] &&
          p.externalShutterArmorFabricShadeColorEntry['id'] === item.externalShutterArmorFabricShadeColorEntry['id'] &&
          p.temporaryExternalBoxColor['id'] === item.temporaryExternalBoxColor['id'] &&
          p.temporaryInternalBoxColor['id'] === item.temporaryInternalBoxColor['id'] &&
          p.externalShutterArmorExternalShutterArmorColorEntry['id'] === item.externalShutterArmorExternalShutterArmorColorEntry['id'] &&
          p.externalShutterPriceList["externalShutterArmor"]['id'] === item.externalShutterPriceList["externalShutterArmor"]['id'] &&
          p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id']

    ))
    },
    orderAfterSum: function(items) {
      const orderItems = simpleClone(this.orderItems(items))
      return orderItems.filter((item, index, selfArray) =>
              index === selfArray.findIndex((p) => (
                  p.externalShutter['id'] === item.externalShutter['id'] &&
                  p.externalShutterArmorFabricShadeColorEntry['id'] === item.externalShutterArmorFabricShadeColorEntry['id'] &&
                  p.temporaryExternalBoxColor['id'] === item.temporaryExternalBoxColor['id'] &&
                  p.temporaryInternalBoxColor['id'] === item.temporaryInternalBoxColor['id'] &&
                  p.externalShutterArmorExternalShutterArmorColorEntry['id'] === item.externalShutterArmorExternalShutterArmorColorEntry['id'] &&
                  p.externalShutterPriceList["externalShutterArmor"]['id'] === item.externalShutterPriceList["externalShutterArmor"]['id'] &&
                  p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id']
    ))
      );
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    trueFalseToText(value){
      return (value)? 'Tak' : 'Nie'
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
  }
};
</script>
