<template lang="pug" functional>
    .card
        .card-header
            .card-header-title {{ props.title }}
            .card-header-icon
                slot(name="icon")
        .card-content(:class="{ 'is-paddingless': props.paddingless }")
            slot
</template>
<script>
export default {
  props: {
    title: String,
    paddingless: Boolean
  }
};
</script>
