import crud from "../../crud";
import rollMosquitoNets from "./crud/rollMosquitoNets";
import rollMosquitoNetGlobalAccessoryEntries from "./crud/rollMosquitoNetGlobalAccessoryEntries";
import rollMosquitoNetGlobalNetEntries from "./crud/rollMosquitoNetGlobalNetEntries";
import rollMosquitoNetRollMosquitoNetAdditionalEntries from "./crud/rollMosquitoNetRollMosquitoNetAdditionalEntries";
import RollMosquitoNetPriceLists from "./crud/rollMosquitoNetPriceLists";
import rollMosquitoNetAdditionals from "./crud/rollMosquitoNetAdditionals";

crud(rollMosquitoNets);
crud(rollMosquitoNetGlobalAccessoryEntries);
crud(rollMosquitoNetGlobalNetEntries);
crud(rollMosquitoNetRollMosquitoNetAdditionalEntries);
crud(RollMosquitoNetPriceLists);
crud(rollMosquitoNetAdditionals);
