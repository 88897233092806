<template lang="pug">
    currency-input(
        :class="classes"
        :value="parseFloat(value)"
        @input="$emit('input', $event)"
        @keydown.enter.prevent.native
        @focus="$event.target.select()"
        :currency="null"
        :precision="precision"
        value-as-integer
    )
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    precision: {
      type: Number,
      default: 2
    },
    size: {
      type: String,
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000000
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  // props: ["value", "min", "max", "readOnly", "size", "precision"],
  computed: {
    classes() {
      return {
        input: true,
        [`${this.size}`]: true
      };
    }
  }
};
</script>
