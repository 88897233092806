<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="frame_mosquito_nets" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol")

              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Rodzaj cennika" field="name" :errors="errors")
                b-select(placeholder="Wybierz rodzaj cennika" v-model="data.priceListType")
                  option(value="tabela")  Tabela - ceny w zależności od rozmiarów
                  option(value="m2") Ceny za m2 powierzchni

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny

              .columns
                .column
                  app-form-field(label="Liczba KOLUMN cennika" field="priceListCols" :errors="errors")
                    b-input(type="number" v-model.number="data.priceListCols")
                .column
                  app-form-field(label="Liczba WIERSZY cennika" field="priceListRows" :errors="errors")
                    b-input(type="number" v-model.number="data.priceListRows")
              .columns
                .column
                .column
                  app-form-field(label="Domyślna cena montażu" field="defaultInstallationValue" :errors="errors")
                    app-form-decimal(v-model="data.defaultInstallationValue")

                  app-form-field(label="Dopłata do złożenia" field="assemblySurcharge" :errors="errors")
                    app-form-decimal(v-model="data.assemblySurcharge")
              .columns
                .column
                  app-form-field(label="Minimalna szerokość (cm)" field="minWidth" :errors="errors")
                    app-form-length(v-model="data.minWidth" unit="cm")
                .column
                  app-form-field(label="Maksymalna szerokość (cm)" field="maxWidth" :errors="errors")
                    app-form-length(v-model="data.maxWidth" unit="cm")

              .columns
                .column
                  app-form-field(label="Minimalna wysokość (cm)" field="minHeight" :errors="errors")
                    app-form-length(v-model="data.minHeight" unit="cm")
                .column
                  app-form-field(label="Maksymalna wysokość (cm)" field="maxHeight" :errors="errors")
                    app-form-length(v-model="data.maxHeight" unit="cm")
            .column
              app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                b-input(v-model="data.message" type="textarea")

        b-tab-item(label="Produkcja")
          .columns
            .column
              app-form-field(label="Wysokość cięcia (mm)" field="productionHeightFrameCut" :errors="errors")
                app-form-length(v-model="data.productionHeightFrameCut" unit="mm")

              app-form-field(label="Szerokość cięcia (mm)" field="productionWidthFrameCut" :errors="errors")
                app-form-length(v-model="data.productionWidthFrameCut" unit="mm")

              app-form-field(label="Dodaj poprzeczkę od wysokości (mm)" field="crossbarHeight" :errors="errors")
                app-form-length(v-model="data.crossbarHeight" unit="mm")

              app-form-field(field="hasCrossbar" :errors="errors")
                b-checkbox(v-model="data.hasCrossbar") Posiada poprzeczkę
            .column
              app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                app-form-time-integer(v-model="data.productionTime")
              app-form-field(field="canBeSentInParts" :errors="errors")
                b-checkbox(v-model="data.canBeSentInParts") Może być wysłany w częściach

        b-tab-item(label="Uwagi do cennika")
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Uwagi do cennika (${value})`" :field="`notesForPriceList${value}`" :errors="errors")
                app-form-editor(v-model="data[`notesForPriceList${value}`]")

        b-tab-item(label="Zdjęcia")
          app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
            app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

          app-form-field(label="Zdjęcie mechanizmu" field="mechanismPicture" :errors="errors")
            app-form-file(v-model="files.mechanismPicture" :image="data.mechanismPicture" @removed="data.mechanismPicture = {}")

          app-form-field(label="Miniaturka dla cennika" field="thumbnailForPriceList" :errors="errors")
            app-form-file(v-model="files.thumbnailForPriceList" :image="data.thumbnailForPriceList" @removed="data.thumbnailForPriceList = {}")

          app-form-field(label="Zdjęcie pomiaru" field="surveyPicture" :errors="errors")
            app-form-file(v-model="files.surveyPicture" :image="data.surveyPicture" @removed="data.surveyPicture = {}")

        b-tab-item(label="Opisy pomiarów")
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Krótki opis pomiaru (${value})`" :field="`surveyDescription${value}`" :errors="errors")
                app-form-editor(v-model="data[`surveyDescription${value}`]")

        b-tab-item(label="Dla sklepu")
          app-form-field(field="isEnabledForShop" :errors="errors")
            b-checkbox(v-model="data.isEnabledForShop") Włącz wersję dla sklepu
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Rozszerzony tytuł (${value})`" :field="`extendedTitle${value}`" :errors="errors")
                b-input(v-model="data[`extendedTitle${value}`]")
              app-form-field(:label="`Opis wersji dla sklepu (${value})`" :field="`descriptionForShop${value}`" :errors="errors")
                app-form-editor(v-model="data[`descriptionForShop${value}`]")
          p.subtitle Zdjęcia
          hr

          app-form-field(label="Zdjęcie wersji bok profil dla sklepu" field="profilePictureForShop" :errors="errors")
            app-form-file(v-model="files.profilePictureForShop" :image="data.profilePictureForShop" @removed="data.profilePictureForShop = {}")

          app-form-field(label="Zdjęcie pole wysokość dla sklepu" field="heightFieldPictureForShop" :errors="errors")
            app-form-file(v-model="files.heightFieldPictureForShop" :image="data.heightFieldPictureForShop" @removed="data.heightFieldPictureForShop = {}")

          app-form-field(label="Zdjęcie pole szerokość dla sklepu" field="widthFieldPictureForShop" :errors="errors")
            app-form-file(v-model="files.widthFieldPictureForShop" :image="data.widthFieldPictureForShop" @removed="data.widthFieldPictureForShop = {}")

        b-tab-item(label="Dokumenty")
          app-form-field(label="Plik instrukcja montażu" field="measurementInstruction" :errors="errors")
            app-form-file(v-model="files.measurementInstruction" :image="data.measurementInstruction" @removed="data.measurementInstruction = {}" :downloadable="true")

</template>
<script>
import { mapGetters } from "vuex";
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      files: {
        picture: {},
        mechanismPicture: {},
        thumbnailForPriceList: {},
        surveyPicture: {},
        profilePictureForShop: {},
        heightFieldPictureForShop: {},
        widthFieldPictureForShop: {},
        measurementInstruction: {}
      },
      messageHelp:
          "Gdy użytkownik wybierze tą wersję, zostanie mu wyświetlony podany komunikat."
    };
  },
  computed: {
    ...mapGetters(["languages"]),
    model() {
      return {
        symbol: "",
        name: "",
        priceListType: "tabela",
        isEnabled: true,
        position: 0,
        message: "",
        productionWeight: 0,
        productionHeightFrameCut: 0,
        productionWidthFrameCut: 0,
        crossbarHeight: 0,
        hasCrossbar: false,
        canBeSentInParts: false,
        assemblySurcharge: 0,
        minWidth: 0,
        maxWidth: 0,
        minHeight: 0,
        maxHeight: 0,
        defaultInstallationValue: 0,
        productionTime: 0,
        priceListCols: 0,
        priceListRows: 0,
        notesForPriceListPL: "",
        notesForPriceListCZ: "",
        notesForPriceListEN: "",
        notesForPriceListDE: "",
        notesForPriceListFR: "",
        notesForPriceListSE: "",
        notesForPriceListIT: "",
        notesForPriceListSI: "",
        notesForPriceListRU: "",
        notesForPriceListES: "",
        notesForPriceListNL: "",
        surveyDescriptionPL: "",
        surveyDescriptionCZ: "",
        surveyDescriptionEN: "",
        surveyDescriptionDE: "",
        surveyDescriptionFR: "",
        surveyDescriptionSE: "",
        surveyDescriptionIT: "",
        surveyDescriptionSI: "",
        surveyDescriptionRU: "",
        surveyDescriptionES: "",
        surveyDescriptionNL: "",
        picture: null,
        mechanismPicture: null,
        thumbnailForPriceList: null,
        surveyPicture: null,
        isEnabledForShop: false,
        extendedTitlePL: "",
        descriptionForShopPL: "",
        extendedTitleCZ: "",
        descriptionForShopCZ: "",
        extendedTitleEN: "",
        descriptionForShopEN: "",
        extendedTitleDE: "",
        descriptionForShopDE: "",
        extendedTitleFR: "",
        descriptionForShopFR: "",
        extendedTitleSE: "",
        descriptionForShopSE: "",
        extendedTitleIT: "",
        descriptionForShopIT: "",
        extendedTitleSI: "",
        descriptionForShopSI: "",
        extendedTitleRU: "",
        descriptionForShopRU: "",
        extendedTitleES: "",
        descriptionForShopES: "",
        extendedTitleNL: "",
        descriptionForShopNL: "",
        profilePictureForShop: null,
        heightFieldPictureForShop: null,
        widthFieldPictureForShop: null,
        measurementInstruction: null
      };
    }
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(data) {
      return data;
    }
  }
};
</script>
