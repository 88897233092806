<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="door_mosquito_net_bottom_rails" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")
            .column
              app-form-field(label="Dopłata za sztukę zł" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")

              app-form-field(label="Dopłata wysokości zł / m (liczona wg Wysokość moskitiery w mm / 1000 * 2)" field="heightSurcharge" :errors="errors")
                app-form-decimal(v-model="data.heightSurcharge")

              app-form-field(label="Dopłata szerokości zł / m (liczona wg Szerokość moskitiery w mm / 1000 * 2)" field="widthSurcharge" :errors="errors")
                app-form-decimal(v-model="data.widthSurcharge")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        symbol: "",
        position: 0,
        isEnabled: false,
        pieceSurcharge: 0,
        heightSurcharge: 0,
        widthSurcharge: 0
      },
    };
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      return data;
    }
  }
};
</script>
