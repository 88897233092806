<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_global_drive_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny
              app-form-field(label="Korekta cięcią rury" field="pipeCutCorrection" :errors="errors")
                b-input(type="number" @input="data.pipeCutCorrection = +$event" :value="data.pipeCutCorrection")
              app-form-field(label="Dopłata grupowania" field="groupingSurcharge" :errors="errors")
                app-form-decimal(v-model="data.groupingSurcharge")
              app-form-field(v-if="externalShutterId" label="Domyślne zabezpieczenie" field="externalShutterProtection" :errors="errors")
                resource-select(
                  v-model="data.externalShutterProtection"
                  :resource="`external_shutter_external_shutter_protection_entries`"
                  :url="`external_shutters/${externalShutterId}/external_shutter_external_shutter_protection_entries`"
                  extra-props="properties[]=id&properties[externalShutterProtection][]=name"
                  placeholder="Wybierz Zabezpieczenie"
                  :labels="[['externalShutterProtection', 'name']]"
                  :add-default-prop="false"
                  :full-object="true"
                )
            .column

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "../../../http";

export default {
  mixins: [form],
  components: { ResourceSelect },
  data() {
    return {
      externalShutterId: null
    };
  },
  computed: {
    model() {
      return {
        isEnabled: false,
        groupingSurcharge: 0,
        pipeCutCorrection: 0,
        externalShutterProtection: null,
        externalShutter: null
      };
    }
  },
  methods: {
    transformToModel(oriData) {
      if (oriData.externalShutter) {
        let shutter = oriData.externalShutter['@id'] || oriData.externalShutter;
        shutter = shutter.split("/");
        shutter = shutter[shutter.length - 1];
        if (parseInt(shutter)) {
          this.externalShutterId = shutter;
        }
      }

      if (oriData.externalShutterProtection && this.externalShutterId) {
        let shutter = oriData.externalShutterProtection['@id'] || oriData.externalShutterProtection;
        shutter = shutter.split("/");
        shutter = shutter[shutter.length - 1];
        http
          .get(
            `external_shutters/${this.externalShutterId}/external_shutter_external_shutter_protection_entries?properties[]=id&properties[externalShutterProtection][]=name&properties[externalShutterProtection][]=id&externalShutterProtection.id=${shutter}`
          )
          .then(result => {
            oriData.externalShutterProtection =
              (result.data["hydra:member"] && result.data["hydra:member"][0]) ||
              null;
          });
      }
      return oriData;
    },
    transformToSubmit(data) {
      if (data.externalShutterProtection) {
        data.externalShutterProtection = data.externalShutterProtection['externalShutterProtection'] && data.externalShutterProtection['externalShutterProtection']['@id'] || data.externalShutterProtection['externalShutterProtection'] && data.externalShutterProtection['externalShutterProtection'] ||null;
      }
      if (data.externalShutter) {
        delete data.externalShutter;
      }
      if (data.globalDrive) {
        data.globalDrive = data.globalDrive["@id"] || data.globalDrive;
      }
      if (data.externalShutterProtection) {
        data.externalShutterProtection = data.externalShutterProtection["@id"] || data.externalShutterProtection;
      }
      return data;
    }
  }
};
</script>
