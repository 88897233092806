<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="pleat_remote_control_types" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Opis" field="description" :errors="errors")
                b-input(v-model="data.description" type="textarea")
            .column
              app-form-field(label="Zdjęcie" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  data() {
    return {
      files: {
        picture: {},
      },
    };
  },
  computed: {
    model() {
      return {
        name: "",
        isEnabled: false,
        description: "",
        position: 0,
        picture: {}
      };
    },
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(data) {
      return data;
    },
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
