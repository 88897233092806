import fetchPleat from "../helpers/fetchPleat";
import PleatPriceListForm from "../components/PleatPriceListForm";

export default {
  list: {
    permissions: ["pleat_price_list_read"],
    sortField: "group.position",
    sortOrder: "asc",
    route: {
      path: "/pleat_price_lists/:pleatId/list",
      name: "pleat_price_lists"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy" // Adjust this label as needed
      },
      {
        label: ({ compData }) =>
            `Cennik bazowy dla wersji "${compData.pleat.name}"`
      }
    ],
    fields: [
      {
        field: "group.position",
        type: "text",
        label: "Pozycja"
      },
      {
        field: "group.name",
        type: "text",
        label: "Nazwa grupy",
        filter: "text"
      }
    ],
    resource: "pleat_price_lists",
    url: ({ route }) =>
        `/pleats/${route.params.pleatId}/pleat_price_lists?properties[]=group&properties[]=id`,
    onCreated: fetchPleat,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_price_list_update"]
      }
    ]
  },
  update: {
    permissions: ["pleat_price_list_update"],
    route: {
      path: "/pleat_price_lists/:id/update",
      name: "pleat_price_lists_update"
    },
    resource: "pleat_price_lists",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy" // Adjust this label as needed
      },
      {
        route: ({ compData }) => ({
          name: "pleat_price_lists_list",
          params: { pleatId: compData.object.pleat.id }
        }),
        label: ({ compData }) =>
            `Cennik bazowy dla wersji "${compData.object.pleat.name}"`
      },
      {
        label: ({ compData }) =>
            `Edycja cennika bazowego grupy "${compData.object.group.name}" dla wersji "${compData.object.pleat.name}"`
      }
    ],
    formComponent: PleatPriceListForm,
    listRoute: ({ compData }) => ({
      name: "pleat_price_lists",
      params: {
        pleatId: compData.object.pleat.id
      }
    })
  }
};
