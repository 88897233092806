import fetchExternalShutter from "../helpers/fetchExternalShutter";
import generateProperties from "../../../helpers/generateProperties";
import ExternalShutterPriceListForm from "../components/ExternalShutterPriceListForm";

export default {
  list: {
    permissions: ["external_shutter_price_list_read"],
    actions: [
      {
        label: "Nowy cennik",
        type: "is-primary",
        route: ({ route }) => ({
          name: "external_shutter_price_lists_create",
          params: {
            externalShutterId: route.params.externalShutterId
          }
        }),
        permissions: ["external_shutter_price_list_create"]
      }
    ],
    route: {
      path: "/external_shutter_price_lists/:externalShutterId/list",
      name: "external_shutter_price_lists_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        label: ({ compData }) =>
          `Cenniki pancerzy dla wersji "${compData.externalShutter.name}"`
      }
    ],
    fields: [
      {
        field: "externalShutterArmor.name",
        type: "text",
        label: "Pancerz",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_price_lists",
    url: ({ route }) =>
      `/external_shutters/${route.params.externalShutterId}/external_shutter_price_lists?` +
      generateProperties([], ["id", "position", "isEnabled"]) +
      generateProperties(["externalShutterArmor"], ["name"], true),
    onCreated: fetchExternalShutter,
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_price_list_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_price_list_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_price_list_create"],
    route: {
      path: "/external_shutter_price_lists/:externalShutterId/create",
      name: "external_shutter_price_lists_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_price_lists_list",
          params: { ExternalShutterId: compData.externalShutter.id }
        }),
        label: ({ compData }) =>
          `Cenniki dla wersji "${compData.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy cennik dla wersji "${compData.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterPriceListForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_price_lists_list",
      params: { externalShutterId: compData.externalShutter.id }
    }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_price_lists_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["external_shutter_price_list_update"],
    route: {
      path: "/external_shutter_price_lists/:id/update",
      name: "external_shutter_price_lists_update"
    },
    resource: "external_shutter_price_lists",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Rolety zewnętrzne"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_price_lists_list",
          params: { externalShutterId: compData.object.externalShutter.id }
        }),
        label: ({ compData }) =>
          `Cenniki dla wesji "${compData.object.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja cennika pancerza "${compData.object.externalShutterArmor.name}" dla wersji "${compData.object.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterPriceListForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_price_lists_list",
      params: {
        externalShutterId: compData.object.externalShutter.id
      }
    })
  }
};
