<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="pleat_pleat_fixing_type_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 W danej wersji ten sam typ mocowania nie może występować więcej niż 1 raz
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Typ mocowania" field="pleatFixingType" :errors="errors")
                resource-select(
                  v-model="data.pleatFixingType"
                  resource="pleat_fixing_types"
                  url="pleat_fixing_types"
                  placeholder="Wybierz typ mocowania"
                  label="name"
                )

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")
            .column
              app-form-field(label="Dopłata szerokości" field="widthSurcharge" :errors="errors")
                app-form-decimal(v-model="data.widthSurcharge")

              app-form-field(label="Dopłata od sztuki" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")
        b-tab-item(label="Zdjęcia")
          .columns
            .column
              app-form-field(label="Zdjęcie" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

              app-form-field(label="Zdjęcie - pole wysokość" field="heightFieldPicture" :errors="errors")
                app-form-file(v-model="files.heightFieldPicture" :image="data.heightFieldPicture" @removed="data.heightFieldPicture = {}")

              app-form-field(label="Zdjęcie - pole szerokość" field="widthFieldPicture" :errors="errors")
                app-form-file(v-model="files.widthFieldPicture" :image="data.widthFieldPicture" @removed="data.widthFieldPicture = {}")

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  data() {
    return {
      files: {
        heightFieldPicture: {},
        widthFieldPicture: {},
        picture: {},
      }
    };
  },
  computed: {
    model() {
      return {
        isEnabled: false,
        position: 0,
        widthSurcharge: 0,
        pieceSurcharge: 0,
        pleatFixingType: null,
        heightFieldPicture: {},
        widthFieldPicture: {},
        picture: {},
        pleat: `/pleats/${this.$route.params.pleatId}`
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.pleat) {
        data.pleat = data.pleat["@id"] || data.pleat;
      }
      if (data.pleatFixingType) {
        data.pleatFixingType = data.pleatFixingType["@id"] || data.pleatFixingType;
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
