import FabricColorDayNightForm from "../components/FabricColorDayNightForm";
import fetchFabricDayNight from "../helpers/fetchFabricDayNight";

export default {
  list: {
    permissions: ["fabric_color_day_night_read"],
    actions: [
      {
        label: "Nowy kolor tkaniny",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_color_day_nights_create",
          params: {
            fabricDayNightId: route.params.fabricDayNightId
          }
        }),
        permissions: ["fabric_color_day_night_create"]
      }
    ],
    route: {
      path: "/fabric_color_day_nights/:fabricDayNightId/list",
      name: "fabric_color_day_nights_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: () => ({ name: "fabric_shade_day_night_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => {
          if (compData.fabricDayNight.groupDayNight) {
            return {
              name: "fabric_day_nights_list",
              params: { fabricShadeDayNightGroupId: compData.fabricDayNight.groupDayNight.id }
            };
          }
          return {};
        },
        label: ({ compData }) => {
          if (compData.fabricDayNight.groupDayNight) {
            return `Kolekcje tkanin dla grupy "${compData.fabricDayNight.groupDayNight.name}"`;
          }
          return null;
        }
      },
      {
        label: ({ compData }) => `Kolory dla tkaniny "${compData.fabricDayNight.name}"`
      }
    ],
    fields: [
      { field: "picture", type: "image", label: "Zdjęcie" },
      { field: "thumbnail", type: "image", label: "Miniatura" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "fabric_color_day_nights",
    url: ({ route }) =>
      `/fabric_day_nights/${route.params.fabricDayNightId}/fabric_color_day_nights?properties[]=id&properties[]=name&properties[]=picture&properties[]=isEnabled&properties[]=symbol&properties[]=thumbnail`,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_color_day_night_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_color_day_night_delete"]
      }
    ],
    onCreated: fetchFabricDayNight
  },
  create: {
    permissions: ["fabric_color_day_night_create"],
    route: {
      path: "/fabric_color_day_nights/:fabricDayNightId/create",
      name: "fabric_color_day_nights_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_color_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: () => ({ name: "fabric_shade_day_night_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_day_nights_list",
          params: { fabricShadeDayNightGroupId: compData.fabricDayNight.groupDayNight.id }
        }),
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy "${compData.fabricDayNight.groupDayNight.name}"`
      },
      {
        route: ({ compData }) => ({
          name: "fabric_color_day_nights_list",
          params: { fabricDayNightId: compData.fabricDayNight.id }
        }),
        label: ({ compData }) => `Kolory dla tkaniny "${compData.fabricDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy kolor dla tkaniny "${compData.fabricDayNight.name}"`
      }
    ],
    formComponent: FabricColorDayNightForm,
    listRoute: ({ compData }) => ({
      name: "fabric_color_day_nights_list",
      params: { fabricId: compData.fabricDayNight.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_color_day_nights_update",
      params: { id: object.id }
    }),
    onCreated: fetchFabricDayNight
  },
  update: {
    permissions: ["fabric_colo_day_nightr_update"],
    route: {
      path: "/fabric_color_day_nights/:id/update",
      name: "fabric_color_day_nights_update"
    },
    resource: "fabric_color_day_nights",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: () => ({ name: "fabric_shade_day_night_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_day_nights_list",
          params: { fabricShadeDayNightGroupId: compData.object.fabricDayNight.groupDayNight.id }
        }),
        label: ({ compData }) =>
          `Kolekcje tkanin dla grupy "${compData.object.fabricDayNight.groupDayNight.name}"`
      },
      {
        route: ({ compData }) => ({
          name: "fabric_color_day_nights_list",
          params: { fabricDayNightId: compData.object.fabricDayNight.id }
        }),
        label: ({ compData }) =>
          `Kolory dla tkaniny "${compData.object.fabricDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru "${compData.object.name}" dla tkaniny "${compData.object.fabricDayNight.name}"`
      }
    ],
    formComponent: FabricColorDayNightForm,
    listRoute: ({ compData }) => ({
      name: "fabric_color_day_nights_list",
      params: { fabricDayNightId: compData.object.fabricDayNight.id }
    })
  }
};
