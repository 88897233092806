import PleatMosquitoNetGlobalNetEntryForm from "../../pleat_mosquito_nets/components/PleatMosquitoNetGlobalNetEntryForm.vue";
import fetchPleatMosquitoNet from "../helpers/fetchPleatMosquitoNet";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["pleat_mosquito_net_global_net_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_mosquito_net_global_net_entries_create",
          params: {
            pleatMosquitoNetId: route.params.pleatMosquitoNetId
          }
        }),
        permissions: ["pleat_mosquito_net_global_net_create"]
      }
    ],
    route: {
      path: "/pleat_mosquito_net_global_net_entries/:pleatMosquitoNetId/list",
      name: "pleat_mosquito_net_global_net_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleat_mosquito_nets_list" }),
        label: () => "Moskitiery plisowane"
      },
      {
        label: ({ compData }) =>
          `Wiązania kolorów siatek dla wersji "${compData.pleatMosquitoNet.name}"`
      }
    ],
    fields: [
      {
        field: "globalNet.name",
        type: "text",
        label: "Nazwa siatki",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "skipCalculations",
        type: "boolean",
        label: "Pomiń kalkulacje cennikowe",
        filter: "boolean"
      },
    ],
    resource: "pleat_mosquito_net_global_net_entries",
    url: ({ route }) =>
      `/pleat_mosquito_nets/${route.params.pleatMosquitoNetId}/pleat_mosquito_net_global_net_entries?` +
        generateProperties([],['id', 'position', 'isEnabled', 'skipCalculations']) +
        generateProperties(['pleatMosquitoNet'],['id', 'name', 'symbol'], true) +
        generateProperties(['globalNet'],['id','name','symbol'],true),
    onCreated: fetchPleatMosquitoNet,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_mosquito_net_global_net_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_mosquito_net_global_net_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_mosquito_net_global_net_create"],
    route: {
      path: "/pleat_mosquito_net_global_net_entries/:pleatMosquitoNetId/create",
      name: "pleat_mosquito_net_global_net_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleat_mosquito_nets_list" }),
        label: () => "Moskitiery plisowane"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_mosquito_net_global_net_entries_list",
          params: { pleatMosquitoNetId: compData.pleatMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `kolory siatek dla wersji "${compData.pleatMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy kolor siatki dla wersji "${compData.pleatMosquitoNet.name}"`
      }
    ],
    formComponent: PleatMosquitoNetGlobalNetEntryForm,
    onCreated: fetchPleatMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "pleat_mosquito_net_global_net_entries_list",
      params: { pleatMosquitoNetId: compData.pleatMosquitoNet.id }
    }),
    updateRoute: ({ object , compData}) => ({
      name: "pleat_mosquito_net_global_net_entries_update",
      params: {
        id: object.id,
        pleatMosquitoNetId: compData.pleatMosquitoNet.id
      }
    })
  },
  update: {
    permissions: ["pleat_mosquito_net_global_net_entry_update"],
    route: {
      path: "/pleat_mosquito_net_global_net_entries/:id/update",
      name: "pleat_mosquito_net_global_net_entries_update"
    },
    resource: "pleat_mosquito_net_global_net_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "pleat_mosquito_nets_list" }),
        label: () => "Moskitiery plisowane"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_mosquito_net_global_net_entries_list",
          params: { pleatMosquitoNetId: compData.object.pleatMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `Kolory siatek dla wersji "${compData.object.pleatMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru dla siatki "${compData.object.globalNet.name}" z wersją "${compData.object.pleatMosquitoNet.name}"`
      }
    ],
    formComponent: PleatMosquitoNetGlobalNetEntryForm,
    onCreated: fetchPleatMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "pleat_mosquito_net_global_net_entries_list",
      params: {
        pleatMosquitoNetId: compData.object.pleatMosquitoNet.id
      }
    })
  }
};
