import http from "../../../http";

export default {
  props: {
    url: { type: String },
    resource: { type: String, required: true },
    placeholder: { type: String },
    addForm: { type: Object },
    addFormTitle: { type: String },
    extraProps: { type: String, default: null },
    label: { type: Function, default: item => item.name },
    addDefaultProp: { type: Boolean, default: true },
    excludeId: { type: Number },
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      loading: false,
      list: [],
      showAddForm: false
    };
  },
  watch: {
    excludeId: function() { // watch it
      this.fetchData()
    },
    url() {
      this.fetchData()
    }
  },
  methods: {
    refresh() {
      this.fetchData();
    },
    showForm() {
      this.showAddForm = true;
    },
    closeForm() {
      this.showAddForm = false;
    },
    async fetchData() {
      this.loading = true;
      try {
        const url = this.url || this.resource;
        //upewniamy sie ze nie ma ? na koncu
        let additionalUrl = `${this.addDefaultProp ? "?properties[]=name&properties[]=id" : "?"}${this.extraProps || ""}`;
        additionalUrl = additionalUrl.slice(-1) === "?" ? additionalUrl.slice(0, -1) : additionalUrl;

        let { data } = await http.get(
          `${url}${additionalUrl}`,
          {
            params: {
              pagination: false
            }
          }
        );
        this.list = data["hydra:member"].filter(el=>{
          return el.id !== this.excludeId
        });
        this.$emit("fetched", data["hydra:member"]);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    }
  },
  created() {
    this.fetchData();
  }
};
