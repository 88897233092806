import store from "store";
import http from "../../http";

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    token: store.get("token") || null,
    refresh_token: store.get("refresh_token") || null,
    email: store.get("email") || null,
    type: store.get("type") || [],
    isLoggedIn: !!store.get("token"),
    permissions: store.get("permissions") || [],
    roles: store.get("roles") || [],
    userId: store.get("userId") || null,
    hasToReadReclamationOrBugReport: false
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    email(state) {
      return state.email;
    },
    hasToReadReclamationOrBugReport(state) {
      return state.hasToReadReclamationOrBugReport;
    },
    userId(state) {
      return state.userId;
    },
    isAdmin(state) {
      return state.type === "admin";
    },
    hasSuperAdminRole(state) {
      return state.roles.includes("ROLE_ADMIN");
    },
    isAllowed: (state, getters) => params => {
      if (getters.isAdmin) {
        return true;
      }
      let result = false;
      params.forEach(param => {
        if (param) {
          if (state.permissions.includes(param)) {
            result = true;
          }
        }
      });
      //@TODO change to return result, when permissions will be added
      return true;
    }
  },
  mutations: {
    setHasToReadReclamationOrBugReport(state, data) {
      state.hasToReadReclamationOrBugReport = data;
    },
    setData(state, data) {
      store.set("token", data.token);
      store.set("refresh_token", data.refresh_token);
      store.set("email", data.email);
      store.set("type", data.type);
      store.set("permissions", data.permissions);
      store.set("roles", data.roles);
      store.set("userId", data.userId);
      store.set(
        "hasToReadReclamationOrBugReport",
        data.hasToReadReclamationOrBugReport
      );
      state.token = data.token;
      state.refresh_token = data.refresh_token;
      state.email = data.email;
      state.type = data.type;
      state.permissions = data.permissions;
      state.isLoggedIn = true;
      state.roles = data.roles;
      state.userId = data.userId;
      state.hasToReadReclamationOrBugReport =
        data.hasToReadReclamationOrBugReport;
    },
    setRefreshedTokens(state, data) {
      store.set("token", data.token);
      store.set("refresh_token", data.refresh_token);
      store.set(
          "hasToReadReclamationOrBugReport",
          data.hasToReadReclamationOrBugReport
      );
      state.token = data.token;
      state.refresh_token = data.refresh_token;
      state.hasToReadReclamationOrBugReport =
          data.hasToReadReclamationOrBugReport;
    },
    removeData(state) {
      store.set("token", null);
      store.set("refresh_token", null);
      store.set("email", null);
      store.set("type", null);
      store.set("roles", null);
      state.token = null;
      state.refresh_token = null;
      state.email = null;
      state.type = null;
      state.permissions = null;
      state.isLoggedIn = false;
      state.roles = null;
    },
    resetError(state) {
      state.error = null;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setError(state, error) {
      state.error = error;
    }
  },
  actions: {
    setHasToReadReclamationOrBugReport({ commit }, data) {
      commit("setHasToReadReclamationOrBugReport", data);
    },
    async postLogin({ commit }, params) {
      commit("resetError");
      commit("setLoading", true);
      try {
        let { data } = await http.post("/authentication_token", params);
        if (data.type !== "admin" && data.type !== "admin_worker") {
          throw Error("Not admin!");
        }
        commit("setData", data);
        commit("setLoading", false);
        return data;
      } catch (e) {
        commit("setLoading", false);
        commit("setError", "Niepoprawne logowanie");
        throw e;
      }
    },
    async resetPassword({commit}, params) {
      try {
        await http.post("/reset-password", params);
        commit("resetError");
      } catch (e) {
        throw new Error("Nie udało się zresetować hasła");
      }
    },
    async changePassword({commit}, params) {
      try {
        await http.post(`/reset-password/change/${params.token}`, params);
        commit("removeData");
      } catch (e) {
        throw new Error("Nie udało się zmienić hasła" + e.toString());
      }
    },
    logout({commit}) {
      commit("removeData");
    }
  }
};
