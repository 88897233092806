import GlobalRemoteControlForm from "@/modules/global/components/GlobalRemoteControlForm";
import decimal from "@/helpers/decimal";

export default {
  list: {
    permissions: ["global_remote_control_read"],
    sortable: true,
    actions: [
      {
        label: "Nowy pilot",
        type: "is-primary",
        route: () => ({
          name: "global_remote_controls_create"
        }),
        permissions: ["global_remote_control_create"]
      }
    ],
    route: {
      path: "/global_remote_controls/list",
      name: "global_remote_controls_list"
    },
    breadcrumbs: [
      {
        label: () => "Globalne piloty"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za szt. [netto]",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "global_remote_controls",
    url: () =>
      `/global_remote_controls?properties[]=id&properties[]=name&properties[]=symbol&properties[]=isEnabled&properties[]=pieceSurcharge&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_remote_control_update"]
      },
      {
        action: "remove",
        permissions: ["global_remote_control_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_remote_control_create"],
    route: {
      path: "/global_remote_controls/create",
      name: "global_remote_controls_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_remote_controls_list" }),
        label: () => "Globalne piloty"
      },
      {
        label: () => `Nowy globalny pilot"`
      }
    ],
    formComponent: GlobalRemoteControlForm,
    listRoute: () => ({
      name: "global_remote_controls_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_remote_controls_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_remote_control_update"],
    route: {
      path: "/global_remote_controls/:id/update",
      name: "global_remote_controls_update"
    },
    resource: "global_remote_controls",
    breadcrumbs: [
      {
        route: () => ({ name: "global_remote_controls_list" }),
        label: () => "Globalne piloty"
      },
      {
        label: ({ compData }) =>
          `Edycja globalnego pilota "${compData.object.name}"`
      }
    ],
    formComponent: GlobalRemoteControlForm,
    listRoute: () => ({
      name: "global_remote_controls_list"
    })
  }
};
