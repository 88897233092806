import ProductionWorkerForm from "../components/ProductionWorkerForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    sortable: true,
    hasPagination: true,
    permissions: ["production_worker_read"],
    actions: [
      {
        label: "Nowy pracownik",
        type: "is-primary",
        route: () => ({
          name: "production_workers_create"
        }),
        permissions: ["production_worker_create"]
      }
    ],
    route: {
      path: "/production_workers/list",
      name: "production_workers_list"
    },
    breadcrumbs: [
      {
        label: () => "Pracownicy produkcji"
      }
    ],
    fields: [
      {
        field: "firstName",
        type: "text",
        label: "Imię",
        filter: "text"
      },
      { field: "lastName", type: "text", label: "Nazwisko", filter: "text" },
      {
        field: "initials",
        type: "text",
        label: "Inicjały",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "production_workers",
    url: () =>
      `/production_workers?` +
      generateProperties(
        [],
        ["id", "firstName", "lastName", "initials", "isEnabled", "position", "productTypes"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["company_update"]
      }
    ]
  },
  create: {
    permissions: ["production_worker_create"],
    route: {
      path: "/production_workers/create",
      name: "production_workers_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "production_workers_list" }),
        label: () => "Pracownicy produkcji"
      },
      {
        label: () => `Nowy pracownik`
      }
    ],
    formComponent: ProductionWorkerForm,
    listRoute: () => ({
      name: "production_workers_list"
    }),
    updateRoute: ({ object }) => ({
      name: "production_workers_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["production_worker_update"],
    route: {
      path: "/production_workers/:id/update",
      name: "production_workers_update"
    },
    resource: "production_workers",
    breadcrumbs: [
      {
        route: () => ({ name: "production_workers_list" }),
        label: () => "Pracownicy produkcji"
      },
      {
        label: ({ compData }) =>
          `Edycja pracownika "${compData.object.firstName} ${compData.object.lastName}"`
      }
    ],
    formComponent: ProductionWorkerForm,
    listRoute: () => ({ name: "production_workers_list" })
  }
};
