import crud from "../../crud";
import pleatMosquitoNets from "./crud/pleatMosquitoNets";
import pleatMosquitoNetGlobalAccessoryEntries from "./crud/pleatMosquitoNetGlobalAccessoryEntries";
import pleatMosquitoNetGlobalNetEntries from "./crud/pleatMosquitoNetGlobalNetEntries";
import pleatMosquitoNetPleatMosquitoNetAdditionalEntries from "./crud/pleatMosquitoNetPleatMosquitoNetAdditionalEntries";
import pleatMosquitoNetPriceLists from "./crud/pleatMosquitoNetPriceLists";
import pleatMosquitoNetAdditionals from "./crud/pleatMosquitoNetAdditionals";
import pleatMosquitoNetFrames from "./crud/pleatMosquitoNetFrames";
import pleatMosquitoNetSills from "./crud/pleatMosquitoNetSills";

crud(pleatMosquitoNets);
crud(pleatMosquitoNetGlobalAccessoryEntries);
crud(pleatMosquitoNetGlobalNetEntries);
crud(pleatMosquitoNetPleatMosquitoNetAdditionalEntries);
crud(pleatMosquitoNetPriceLists);
crud(pleatMosquitoNetAdditionals);
crud(pleatMosquitoNetFrames);
crud(pleatMosquitoNetSills);
