<template lang="pug">
  table.table.is-fullwidth.is-bordered
    FabricShadeOrderItemProductionTemplate(:object="order")

</template>

<script>
import decimal from "../../../helpers/decimal";
import moment from "moment";
import FabricShadeOrderItemProductionTemplate from "../../orders/components/FabricShadeOrderItemProductionTemplate.vue";

export default {
  components: {FabricShadeOrderItemProductionTemplate},
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
  methods: {
    prop(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField] && object[extraField][field] !== undefined
            ? object[extraField][field]
            : "Brak";
      }
      return object && object[field] !== undefined ? object[field] : "Brak";
    },

    getDecimalValue(value) {
      return decimal(value);
    },
    getDate(date, format = "DD-MM-YYYY hh:mm:ss") {
      if (!date) {
        return "Nie ustawione";
      }
      return moment(date, "DD-MM-YYYY hh:mm:ss").format(format);
    },
  }
};
</script>
