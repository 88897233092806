<template lang="pug">
  table.table.is-fullwidth.is-bordered
    PleatMosquitoNetOrderItemProductionTemplate(:object="order")

</template>

<script>
import PleatMosquitoNetOrderItemProductionTemplate from "../../orders/components/PleatMosquitoNetOrderItemProductionTemplate";

export default {
  components: {PleatMosquitoNetOrderItemProductionTemplate},
  name: "ProductionOrderPreviewPleatMosquitoNetTable",
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>