import detailColorForm from "../components/DetailColorForm.vue";

export default {
  list: {
    permissions: ["detail_color_read"],
    sortable: true,
    actions: [
      {
        label: "Nowy kolor",
        type: "is-primary",
        route: ({ route }) => ({
          name: "detail_colors_create",

        }),
        permissions: ["detail_color_create"]
      }
    ],
    route: {
      path: "/detail_colors",
      name: "detail_colors_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_colors_list" }),
        label: () => "Kolory detali"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }],
    resource: "detail_colors",
    url: ({ route }) =>
      `/detail_colors`,
    rowActions: [
      {
        action: "edit",
        permissions: ["detail_color_update"]
      },
      {
        action: "remove",
        permissions: ["detail_color_delete"]
      }
    ],
  },
  create: {
    permissions: ["detail_color_create"],
    route: {
      path: "/detail_colors/create",
      name: "detail_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_colors_list" }),
        label: () => "Kolory detali"
      },

      {
        label: ({ compData }) =>
          `Nowy kolor`
      }
    ],
    formComponent: detailColorForm,
    listRoute: ({ compData }) => ({
      name: "detail_colors_list"
    }),
    updateRoute: ({ object }) => ({
      name: "detail_colors_update",
      params: { id: object.id }
    }),
  },
  update: {
    permissions: ["detail_color_update"],
    route: {
      path: "/detail_colors/:id/update",
      name: "detail_colors_update"
    },
    resource: "detail_colors",
    breadcrumbs: [
      {
        route: () => ({ name: "detail_colors_list" }),
        label: () => "Systemy w detalach"
      },
      {
        label: ({ compData }) =>
          `Edycja koloru"${compData.object.name}" `
      }
    ],
    formComponent: detailColorForm,
    listRoute: ({ compData }) => ({
      name: "detail_colors_list"
    })
  }
};
