import ProductTypeForm from "../components/ProductTypeForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["product_type_read"],
    route: {
      path: "/product_types/list",
      name: "product_types_list"
    },
    breadcrumbs: [
      {
        label: () => "Typy produktów"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "code", type: "text", label: "Kod", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "product_types",
    url: () =>
      `/product_types?` +
      generateProperties(
        [],
        ["id", "name", "isEnabled", "code"]
      ) +
      generateProperties(
        ["productionLines"],
        ["id", "name", "number", "lineColor"],
        true
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["product_type_update"]
      }
    ]
  },
  update: {
    permissions: ["product_type_update"],
    route: {
      path: "/product_types/:id/update",
      name: "product_types_update"
    },
    resource: "product_types",
    breadcrumbs: [
      {
        route: () => ({ name: "product_types_list" }),
        label: () => "Typy produktów"
      },
      {
        label: ({ compData }) =>
          `Edycja typu produktu "${compData.object.name}"`
      }
    ],
    formComponent: ProductTypeForm,
    listRoute: () => ({ name: "product_types_list" })
  }
};
