<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="configurations")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ochrona danych")
                  app-form-field(label="Twoje dane są chronione" field="yourDataIsProtected" :errors="errors")
                    app-form-editor(v-model="data.yourDataIsProtected")
                b-tab-item(label="Regulamin")
                    app-form-field(label="Treść regulaminu" field="regulationContent" :errors="errors")
                      app-form-editor(v-model="data.regulationContent")
                b-tab-item(label="Dane Firmy")
                    app-form-field(label="Nazwa firmy" field="ownerCompanyName" :errors="errors")
                        b-input(v-model="data.ownerCompanyName")
                    app-form-field(label="Email firmy" field="ownerCompanyEmail" :errors="errors")
                        b-input(v-model="data.ownerCompanyEmail")
                    app-form-field(label="NIP firmy" field="ownerCompanyTaxId" :errors="errors")
                        b-input(v-model="data.ownerCompanyTaxId")
                b-tab-item(label="Tło")
                  app-form-field(label="Tło przy logowaniu do aplikacji" field="loginPageWallPaper" :errors="errors")
                    app-form-file(v-model="files.loginPageWallPaper" :image="data.loginPageWallPaper" @removed="data.loginPageWallPaper = {}")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      files:{
        loginPageWallPaper:null,
      },
      model: {
        yourDataIsProtected: "",
        regulationContent: "",
        ownerCompanyName: "",
        ownerCompanyEmail: "",
        ownerCompanyTaxId: "",
        loginPageWallPaper: ""
      }
    };
  }
};
</script>
