<template lang="pug">
  div.container.is-fullhd
    template
      table.table.is-bordered.has-cells-centered.is-narrow.is-fullwidth.print-table
        thead
          tr
            th(colspan=22)  Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
          tr
            th(rowspan=2) Lp.
            th(rowspan=2) Grupa
            th(rowspan=2) Wers.
            th(rowspan=2) Tkan.
            th(rowspan=2) Kolor
            th(rowspan=2) Kas.
            th(rowspan=2) Rurka
            th(rowspan=2) Cięż.
            th(rowspan=2) Prow.
            th(rowspan=2) Szer. cięcia
            th(rowspan=2) Wys. cięcia
            th(rowspan=2) L
            th(rowspan=2) Osp.
            th(rowspan=2) Belka
            th(rowspan=2) Str.
            th(rowspan=2) Moc.
            th(rowspan=2) Napęd.
            th(rowspan=2) Obr.
            th(rowspan=2) Prow.
            th(rowspan=2) Dod.
            th(rowspan=2) Miejsce
        tbody
          tr(v-for="(item, index) in orderItems(object)")
            td {{ index + 1 }}
            td {{ prop(item.fabricShadeDayNightGroup, 'position') }}
            td {{ prop(item.fabricShadeDayNight, 'name') }}
            td {{ prop(item.fabricDayNight, 'name') }}
            td {{ prop(item.fabricColorDayNight, 'name') }}
            td {{ item.fabricShadeDayNight.productionCase != null? (item.width + item.fabricShadeDayNight.productionCase) /10 : '' }}
            td {{ item.fabricShadeDayNight.productionPipe != null? (item.width + item.fabricShadeDayNight.productionPipe) / 10 : ''  }}
            td {{ item.fabricShadeDayNight.productionWeight != null? (item.width + item.fabricShadeDayNight.productionWeight) / 10 : ''  }}
            td {{ item.fabricShadeDayNight.productionRunner != null? (item.height + item.fabricShadeDayNight.productionRunner) /10 : ''  }}
            td {{ item.fabricShadeDayNight.productionFabricWidth != null? (item.fabricWidth + item.fabricShadeDayNight.productionFabricWidth) / 10 : ''  }}
            td {{ item.fabricShadeDayNight.productionFabricHeight != null? (item.height + item.fabricShadeDayNight.productionFabricHeight) /10 : ''  }}
            td {{ item.quantity }}
            td {{ prop(item.fabricShadeDayNightGlobalAccessoryEntry, "name", 'globalAccessory') }}
            td {{ prop(item.fabricShadeDayNightGlobalBalkEntry, 'name', 'globalBalk') }}
            td {{ item.driveSide }}
            td {{ prop(item.fabricShadeDayNightGlobalFixingEntry, 'name', 'globalFixing') }}
            td {{ prop(item.globalDrive, 'symbol') }}
            td {{ item.hasTurnableFabric ? 'TAK' : '-' }}
            td {{ prop(item.fabricShadeDayNightGlobalRunnerEntry, "name", 'globalRunner') }}
            td
              p(v-if="!item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries.length") Brak
              p(v-for="(entry, indexProtection) in item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries") {{ prop(entry, "name", "fabricShadeDayNightAdditional") }}
            td {{ item.shortDescription }}

    .columns.is-multiline
      .column(v-if="accessoriesLengthsPerVersionAndAccessory(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Wersja
              th Osprzęt
              th Ilość
              th mb
          tbody
            tr(v-for="item in accessoriesLengthsPerVersionAndAccessory(object)")
              td {{ item.fabricShadeDayNightName }}
              td {{ item.accessoryName }}
              td {{ item.quantity }}
              td {{ item.accessoriesLength /1000 }}

      .column(v-if="runnersLengthPerAccessory(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Prowadnice
              th Osprzęt
              th mb
          tbody
            tr(v-for="item in runnersLengthPerAccessory(object)")
              td {{ item.runnerName }}
              td {{ item.accessoryName }}
              td {{ item.runnersLength /1000 }}
      .column(v-if="surfacePerFabricAndFabricColor(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Tkanina | Kolor
              th m2
          tbody
            tr(v-for="item in surfacePerFabricAndFabricColor(object)")
              td {{ `${item.fabricDayNightName} | ${item.fabricColorDayNightName}` }}
              td {{ getDecimal(item.surface /10000) }}
</template>
<script>

import decimal from "@/helpers/decimal";
import AutomationOrderItem from "./AutomationOrderItem";
import priceFormatter from "../../../helpers/priceFormatter";
import prop from "../../../helpers/propFunc";
import getOrderItems from "../../../helpers/orderItems";

export default {
  props: ['object'],
  methods: {
    runnersLengthPerAccessory(order) {
      const items = this.orderItems(order);
      let runnersLength = [];
      for (let i = 0; i < items.length; i++) {
        let include = false;
        for (let j = 0; j < runnersLength.length; j++) {
          if (include) {
            break;
          }
          if (
              runnersLength[j].runnerName ===
              items[i].fabricShadeDayNightGlobalRunnerEntry.globalRunner.name &&
              runnersLength[j].accessoryName ===
              items[i].fabricShadeDayNightGlobalAccessoryEntry.globalAccessory.name
          ) {
            runnersLength[j].runnersLength +=
                (items[i].height + items[i].fabricShadeDayNight.productionRunner) *
                items[i].quantity *
                2;
            include = true;
          }
        }
        if (
            !include &&
            items[i].fabricShadeDayNightGlobalRunnerEntry &&
            items[i].fabricShadeDayNightGlobalAccessoryEntry
        ) {
          runnersLength.push({
            runnerName: items[i].fabricShadeDayNightGlobalRunnerEntry.globalRunner.name,
            accessoryName:
            items[i].fabricShadeDayNightGlobalAccessoryEntry.globalAccessory.name,
            runnersLength:
                (items[i].height + items[i].fabricShadeDayNight.productionRunner) *
                items[i].quantity *
                2
          });
        }
      }
      return runnersLength;
    },
    surfacePerFabricAndFabricColor(order) {
      const items = this.orderItems(order);
      const surfaces = [];

      for (const item of items) {
        const {fabricDayNight, fabricColorDayNight, fabricShadeDayNight, fabricWidth, height, quantity} = item;

        if (fabricDayNight && fabricColorDayNight && fabricShadeDayNight) {
          const existingSurface = surfaces.find(surface => surface.fabricDayNightName === fabricDayNight.name && surface.fabricColorDayNightName === fabricColorDayNight.name);

          if (existingSurface) {
            existingSurface.surface += (fabricWidth + fabricShadeDayNight.productionFabricWidth) * (height + fabricShadeDayNight.productionFabricHeight) * quantity;
          } else {
            surfaces.push({
              fabricDayNightName: fabricDayNight.name,
              fabricColorDayNightName: fabricColorDayNight.name,
              surface: (fabricWidth + fabricShadeDayNight.productionFabricWidth) * (height + fabricShadeDayNight.productionFabricHeight) * quantity
            });
          }
        }
      }

      return surfaces;
    },
    accessoriesLengthsPerVersionAndAccessory(order) {
      const items = this.orderItems(order);
      let accessoriesLengths = [];
      for (let i = 0; i < items.length; i++) {
        let include = false;
        for (let j = 0; j < accessoriesLengths.length; j++) {
          if (include) {
            break;
          }
          if (
              accessoriesLengths[j].fabricShadeDayNightName ===
              items[i].fabricShadeDayNight.name &&
              accessoriesLengths[j].accessoryName ===
              items[i].fabricShadeDayNightGlobalAccessoryEntry.globalAccessory.name
          ) {
            accessoriesLengths[j].quantity += items[i].quantity;
            accessoriesLengths[j].accessoriesLength +=
                (items[i].width + items[i].fabricShadeDayNight.productionCase) *
                items[i].quantity;
            include = true;
          }
        }
        if (!include && items[i].fabricShadeDayNight && items[i].fabricShadeDayNightGlobalAccessoryEntry) {
          accessoriesLengths.push({
            fabricShadeDayNightName: items[i].fabricShadeDayNight.name,
            accessoryName:
            items[i].fabricShadeDayNightGlobalAccessoryEntry.globalAccessory.name,
            quantity: items[i].quantity,
            accessoriesLength:
                (items[i].width + items[i].fabricShadeDayNight.productionCase) *
                items[i].quantity
          });
        }
      }
      return accessoriesLengths;
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
  }
};
</script>
