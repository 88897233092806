import { render, staticRenderFns } from "./AppTable.vue?vue&type=template&id=dca68e12&lang=pug"
import script from "./AppTable.vue?vue&type=script&lang=js"
export * from "./AppTable.vue?vue&type=script&lang=js"
import style0 from "./AppTable.vue?vue&type=style&index=0&id=dca68e12&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports