import ExternalShutterExternalShutterRunnerEntryForm from "../components/ExternalShutterExternalShutterRunnerEntryForm";
import fetchExternalShutter from "../helpers/fetchExternalShutter";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["external_shutter_external_shutter_runner_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "external_shutter_external_shutter_runner_entries_create",
          params: {
            externalShutterId: route.params.externalShutterId
          }
        }),
        permissions: ["external_shutter_external_shutter_runner_entry_create"]
      }
    ],
    route: {
      path:
        "/external_shutter_external_shutter_runner_entries/:externalShutterId/list",
      name: "external_shutter_external_shutter_runner_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        label: ({ compData }) =>
          `Wiązania prowadnic dla wersji "${compData.externalShutter.name}"`
      }
    ],
    fields: [
      {
        field: "externalShutterRunner.symbol",
        type: "text",
        label: "Symbol prowadnicy",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_external_shutter_runner_entries",
    url: ({ route }) =>
      `/external_shutters/${route.params.externalShutterId}/external_shutter_external_shutter_runner_entries?` +
      generateProperties(
        [],
        ["id", "position", "isEnabled"]
      ) +
      generateProperties(["externalShutterRunner"], ["name", "symbol"], true) +
      generateProperties(["externalShutter"], ["name", "symbol"], true),
    onCreated: fetchExternalShutter,
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_external_shutter_runner_entry_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_external_shutter_runner_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_external_shutter_runner_entry_create"],
    route: {
      path:
        "/external_shutter_external_shutter_runner_entries/:externalShutterId/create",
      name: "external_shutter_external_shutter_runner_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_external_shutter_runner_entries_list",
          params: { externalShutterId: compData.externalShutter.id }
        }),
        label: ({ compData }) =>
          `Wiązania prowadnic dla wersji "${compData.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterExternalShutterRunnerEntryForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_external_shutter_runner_entries_list",
      params: { externalShutterId: compData.externalShutter.id }
    }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_external_shutter_runner_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["external_shutter_external_shutter_runner_entry_update"],
    route: {
      path: "/external_shutter_external_shutter_runner_entries/:id/update",
      name: "external_shutter_external_shutter_runner_entries_update"
    },
    resource: "external_shutter_external_shutter_runner_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_external_shutter_runner_entries_list",
          params: {
            externalShutterId: compData.object.externalShutter.id
          }
        }),
        label: ({ compData }) =>
          `Wiązania prowadnic dla wersji "${compData.object.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania prowadnicy "${compData.object.externalShutterRunner.name}" z wersją "${compData.object.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterExternalShutterRunnerEntryForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_external_shutter_runner_entries_list",
      params: {
        externalShutterId: compData.object.externalShutter.id
      }
    })
  }
};
