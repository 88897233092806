import FabricShadeDayNightGlobalBalkEntryForm from "../components/FabricShadeDayNightGlobalBalkEntryForm";
import decimal from "../../../helpers/decimal";
import fetchFabricShadeDayNight from "@/modules/fabric_shade_day_nights/helpers/fetchFabricShadeDayNight";

export default {
  list: {
    permissions: ["fabric_shade_day_night_global_balk_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_shade_day_night_global_balk_entries_create",
          params: {
            fabricShadeDayNightId: route.params.fabricShadeDayNightId
          }
        }),
        permissions: ["fabric_shade_day_night_global_balk_entry_create"]
      }
    ],
    route: {
      path: "/fabric_shade_day_night_global_balk_entries/:fabricShadeDayNightId/list",
      name: "fabric_shade_day_night_global_balk_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        label: ({ compData }) =>
          `Wiązania belek dolnych dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    fields: [
      {
        field: "globalBalk.symbol",
        type: "text",
        label: "Symbol belki",
        filter: "text"
      },
      {
        field: "globalBalk.name",
        type: "text",
        label: "Nazwa belki",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "heightSurcharge",
        type: "text",
        label: "Dopłata wysokości [netto]",
        format: decimal
      }
    ],
    resource: "fabric_shade_day_night_global_balk_entries",
    url: ({ route }) =>
      `/fabric_shade_day_nights/${route.params.fabricShadeDayNightId}/fabric_shade_day_night_global_balk_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=heightSurcharge&properties[globalBalk][]=name&properties[globalBalk][]=symbol`,
    onCreated: fetchFabricShadeDayNight,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_day_night_global_balk_entry_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_day_night_global_balk_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_day_night_global_balk_entry_create"],
    route: {
      path: "/fabric_shade_day_night_global_balk_entries/:fabricShadeDayNightId/create",
      name: "fabric_shade_day_night_global_balk_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_global_balk_entries_list",
          params: { fabricShadeDayNightId: compData.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Belki dolne dla wersji "${compData.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightGlobalBalkEntryForm,
    onCreated: fetchFabricShadeDayNight,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_global_balk_entries_list",
      params: { fabricShadeDayNightId: compData.fabricShadeDayNight.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_day_night_global_balk_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_day_night_global_balk_entry_update"],
    route: {
      path: "/fabric_shade_day_night_global_balk_entries/:id/update",
      name: "fabric_shade_day_night_global_balk_entries_update"
    },
    resource: "fabric_shade_day_night_global_balk_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_global_balk_entries_list",
          params: { fabricShadeDayNightId: compData.object.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Belki dolne dla wersji "${compData.object.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania belki "${compData.object.globalBalk.name}" z wersją "${compData.object.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightGlobalBalkEntryForm,
    onCreated: fetchFabricShadeDayNight,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_global_balk_entries_list",
      params: {
        fabricShadeDayNightId: compData.object.fabricShadeDayNight.id
      }
    })
  }
};
