<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="app_errors" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Data wystąpienia" field="createdAt" :errors="errors") {{ data.createdAt }}
                            
                            app-form-field(label="Użytkownik" field="user" :errors="errors") {{ userData }}
                            
                            app-form-field(label="Status" field="status" :errors="errors")
                                b-select(
                                    v-model="data.status"
                                )
                                    option(
                                        v-for="item in data.errorStatuses"
                                        :key="item"
                                        :value="item"
                                    ) {{ item }}
                                
                            app-form-field(label="Notatka" field="description" :errors="errors")
                                b-input(v-model="data.description" type="textarea")

                        .column
                            app-form-field(label="Kernel" field="kernel" :errors="errors")
                                ul
                                    li(v-for="value, field in data.kernel" @click="copyData(value)").has-pointer 
                                        b {{ field }}:
                                        div {{ value }}
                                        .divider.is-horizontal
                        .column
                            app-form-field(label="Request" field="request" :errors="errors")
                                ul
                                    li(v-for="value, field in data.request" @click="copyData(value)").has-pointer
                                        b {{ field }}:
                                        div {{ value }}
                                        .divider.is-horizontal
                        .column
                            app-form-field(label="Throwable" field="throwable" :errors="errors")
                                ul
                                    li(v-for="value, field in data.throwable" @click="copyData(value)").has-pointer
                                        b {{ field }}:
                                        div {{ value }}
                                        .divider.is-horizontal


</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import notify from "../../../helpers/notify";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        createdAt: "",
        description: null,
        errorStatuses: [],
        status: "",
        kernel: [],
        request: [],
        throwable: [],
        updatedAt: "",
        user: {},
      }
    };
  },
  computed: {
    userData() {
        if(this.model.user && this.model.user.id) {
            return this.model.user ? ((this.model.user.firstName || "") + " " + (this.model.user.lastName || "") + " (id: "+this.model.user.id+")") : "brak";
        } else {
            return 'Brak';
        }
    }
  },
  methods: {
    fieldIsArray(data) {
        return Array.isArray(data);
    },
    transformToModel(data) {
        return data;
    },
    transformToSubmit(data) {
        return data;
    },
    async copyData(data) {
        try {
            await navigator.clipboard.writeText(data);
            notify('Skopiowano treść komunikatu do schowka');
        } catch($e) {
            notify('Nie udało się skopiować do schowka');
        }
    }
  }
};
</script>
