import crud from "@/crud";
import globalFixings from "@/modules/global/crud/globalFixings";
import globalBalks from "@/modules/global/crud/globalBalks";
import globalRunners from "@/modules/global/crud/globalRunners";
import globalAccessories from "@/modules/global/crud/globalAccessories";
import globalPipes from "@/modules/global/crud/globalPipes";
import globalCases from "@/modules/global/crud/globalCases";
import globalDrives from "@/modules/global/crud/globalDrives";
import globalDriveControls from "@/modules/global/crud/globalDriveControls";
import globalDriveControlTypes from "@/modules/global/crud/globalDriveControlTypes";
import globalDriveTypes from "@/modules/global/crud/globalDriveTypes";
import globalRemoteControls from "@/modules/global/crud/globalRemoteControls";
import manufacturers from "@/modules/global/crud/manufacturers";
import globalDriveSeries from "./crud/globalDriveSeries";
import globalRemoteControlChannels from "./crud/globalRemoteControlChannels";
import globalRemoteControlTypes from "./crud/globalRemoteControlTypes";
import globalClips from "../global/crud/globalClips";
import globalNets from "../global/crud/globalNets";

crud(globalAccessories);
crud(globalBalks);
crud(globalFixings);
crud(globalRunners);
crud(globalPipes);
crud(globalCases);
crud(globalDrives);
crud(globalDriveControls);
crud(globalDriveControlTypes);
crud(globalDriveTypes);
crud(globalRemoteControls);
crud(manufacturers);
crud(globalDriveSeries);
crud(globalRemoteControlChannels);
crud(globalRemoteControlTypes);
crud(globalClips);
crud(globalNets);