<template lang="pug">
  b-datepicker(
    :value="parser(value)"
    @input="input"
    locale="pl-PL"
    :date-formatter="formatter"
    :date-parser="parser"
    :first-day-of-week="1"
  )
</template>
<script>
import moment from "moment";

export default {
  props: {
    value: String
  },
  methods: {
    input(date) {
      this.$emit("input", this.formatter(date));
    },
    formatter(date) {
      return moment(date)
        .set({ hour: 0, minute: 0, second: 0 })
        .format("DD-MM-YYYY H:mm:ss");
    },
    parser(date) {
      if (date) {
        return moment(date, "DD-MM-YYYY H:mm:ss").toDate();
      }
      return null;
    }
  }
};
</script>
