<template lang="pug">
    app-view-update(@to-list="toList" :resource="resource" :resourceProperties="resourceProperties" @fetched="fetched")
        template(#breadcrumb="{ object }")
            app-view-breadcrumbs(v-if="!isEmpty(object)" :breadcrumbs="breadcrumbs" :data="data")

        template(#default="{ object }")
            component(:is="formComponent" :object="object" :toList="toList")

        template(#buttons="{ object }")
            template(v-for="action in actions")
                app-button-remove(
                    v-if="action === 'remove'"
                    :resource="resource"
                    :object="object"
                    @removed="toList"
                )
</template>
<script>
export default {
  props: {
    breadcrumbs: Array,
    formComponent: Object,
    resource: String,
    resourceProperties: String || null,
    listRoute: Function,
    actions: Array
  },
  data() {
    return {
      data: {
        object: {}
      }
    };
  },
  methods: {
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    fetched(object) {
      this.data.object = object;
    },
    toList() {
      this.$router.push(
        this.listRoute({
          compData: this.data,
          route: this.$route,
          router: this.$router
        })
      );
    }
  }
};
</script>
