import ExternalShutterArmorForm from "../components/ExternalShutterArmorForm";
import generateProperties from "../../../helpers/generateProperties";
import surface from "../../../helpers/surface";
import kilogram from "../../../helpers/kilogram";

export default {
  list: {
    permissions: ["external_shutter_armor_read"],
    actions: [
      {
        label: "Nowy pancerz",
        type: "is-primary",
        route: () => ({
          name: "external_shutter_armors_create"
        }),
        permissions: ["external_shutter_armor_create"]
      }
    ],
    route: {
      path: "/external_shutter_armors/list",
      name: "external_shutter_armors_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Pancerze"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "lamelHeight",
        type: "text",
        label: "Wysokość lamela (mm)"
      },
      {
        field: "weight",
        type: "text",
        label: "Masa kg/m^2",
        format: kilogram
      },
      {
        field: "maxWidth",
        type: "text",
        label: "Max. szerokość (mm)"
      },
      {
        field: "maxSurface",
        type: "text",
        label: "Max. powierzchnia (m2)",
        format: surface
      },
      {
        type: "link",
        label: "Kolory Pancerza",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name:
              "external_shutter_armor_external_shutter_armor_color_entries_list",
            params: { externalShutterArmorId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Kolory listw dolnych",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_armor_fabric_shade_color_entries_list",
            params: { externalShutterArmorId: row.id }
          })
        }
      }
    ],
    resource: "external_shutter_armors",
    url: () =>
      `/external_shutter_armors?` +
      generateProperties(
        [],
        [
          "id",
          "name",
          "symbol",
          "maxSurface",
          "weight",
          "maxWidth",
          "lamelHeight"
        ]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_armor_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_armor_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_armor_create"],
    route: {
      path: "/external_shutter_armors/create",
      name: "external_shutter_armors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armors_list" }),
        label: () => "Pancerze"
      },
      {
        label: () => `Nowy pancerz`
      }
    ],
    formComponent: ExternalShutterArmorForm,
    listRoute: () => ({ name: "external_shutter_armors_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_armors_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_armor_update"],
    route: {
      path: "/external_shutter_armors/:id/update",
      name: "external_shutter_armors_update"
    },
    resource: "external_shutter_armors",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "symbol",
        "maxSurface",
        "weight",
        "maxWidth",
        "lamelHeight",
        "picture",
        "sectionPicture"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armors_list" }),
        label: () => "Pancerze"
      },
      {
        label: ({ compData }) => `Edycja pancerza "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterArmorForm,
    listRoute: () => ({ name: "external_shutter_armors_list" })
  }
};
