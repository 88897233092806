import FabricShadeDayNightGlobalFixingEntryForm from "../components/FabricShadeDayNightGlobalFixingEntryForm";
import decimal from "../../../helpers/decimal";
import fetchFabricShadeDayNight from "@/modules/fabric_shade_day_nights/helpers/fetchFabricShadeDayNight";

export default {
  list: {
    permissions: ["fabric_shade_day_night_global_fixing_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_shade_day_night_global_fixing_entries_create",
          params: {
            fabricShadeDayNightId: route.params.fabricShadeDayNightId
          }
        }),
        permissions: ["fabric_shade_day_night_global_fixing_entry_create"]
      }
    ],
    route: {
      path: "/fabric_shade_day_night_global_fixing_entries/:fabricShadeDayNightId/list",
      name: "fabric_shade_day_night_global_fixing_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        label: ({ compData }) =>
          `Wiązania mocowań dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    fields: [
      {
        field: "globalFixing.symbol",
        type: "text",
        label: "Symbol mocowania",
        filter: "text"
      },
      {
        field: "globalFixing.name",
        type: "text",
        label: "Nazwa mocowania",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za sztukę [netto]",
        format: decimal
      }
    ],
    resource: "fabric_shade_day_night_global_fixing_entries",
    url: ({ route }) =>
      `/fabric_shade_day_nights/${route.params.fabricShadeDayNightId}/fabric_shade_day_night_global_fixing_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=pieceSurcharge&properties[globalFixing][]=name&properties[globalFixing][]=symbol`,
    onCreated: fetchFabricShadeDayNight,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_day_night_global_fixing_entry_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_day_night_global_fixing_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_day_night_global_fixing_entry_create"],
    route: {
      path: "/fabric_shade_day_night_global_fixing_entries/:fabricShadeDayNightId/create",
      name: "fabric_shade_day_night_global_fixing_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_global_fixing_entries_list",
          params: { fabricShadeDayNightId: compData.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Mocowania dla wersji "${compData.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightGlobalFixingEntryForm,
    onCreated: fetchFabricShadeDayNight,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_global_fixing_entries_list",
      params: { fabricShadeDayNightId: compData.fabricShadeDayNight.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_day_night_global_fixing_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_day_night_global_fixing_entry_update"],
    route: {
      path: "/fabric_shade_day_night_global_fixing_entries/:id/update",
      name: "fabric_shade_day_night_global_fixing_entries_update"
    },
    resource: "fabric_shade_day_night_global_fixing_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_global_fixing_entries_list",
          params: { fabricShadeDayNightId: compData.object.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Mocowania dla wersji "${compData.object.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania mocowania "${compData.object.globalFixing.name}" z wersją "${compData.object.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightGlobalFixingEntryForm,
    onCreated: fetchFabricShadeDayNight,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_global_fixing_entries_list",
      params: {
        fabricShadeDayNightId: compData.object.fabricShadeDayNight.id
      }
    })
  }
};
