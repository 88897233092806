import FixedOrderActivityForm from "../components/FixedOrderActivityForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    sortable: true,
    hasPagination: true,
    permissions: ["fixed_order_activity_read"],
    actions: [
      {
        label: "Nowa czynność",
        type: "is-primary",
        route: () => ({
          name: "fixed_order_activities_create"
        }),
        permissions: ["fixed_order_activity_create"]
      }
    ],
    route: {
      path: "/fixed_order_activities/list",
      name: "fixed_order_activities_list"
    },
    breadcrumbs: [
      {
        label: () => "Czynności FX"
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Nazwa",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "requireQuantity",
        type: "boolean",
        label: "Wymaga ilości",
        filter: "boolean"
      },
    ],
    resource: "fixed_order_activities",
    url: () =>
      `/fixed_order_activities?` +
      generateProperties(
        [],
        ["id", "name", "isEnabled", 'requireQuantity', 'position']
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["fixed_order_activity_update"]
      },
      {
        action: "remove",
        permissions: ["fixed_order_activity_delete"]
      }
    ]
  },
  create: {
    permissions: ["fixed_order_activity_create"],
    route: {
      path: "/fixed_order_activities/create",
      name: "fixed_order_activities_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fixed_order_activities_list" }),
        label: () => "Czynności FX"
      },
      {
        label: () => `Nowa czynność FX`
      }
    ],
    formComponent: FixedOrderActivityForm,
    listRoute: () => ({
      name: "fixed_order_activities_list"
    }),
    updateRoute: ({ object }) => ({
      name: "fixed_order_activity_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["fixed_order_activity_update"],
    route: {
      path: "/fixed_order_activities/:id/update",
      name: "fixed_order_activities_update"
    },
    resource: "fixed_order_activities",
    breadcrumbs: [
      {
        route: () => ({ name: "fixed_order_activities_list" }),
        label: () => "Czynności FX"
      },
      {
        label: ({ compData }) =>
          `Edycja czynności "${compData.object.name}"`
      }
    ],
    formComponent: FixedOrderActivityForm,
    listRoute: () => ({ name: "fixed_order_activities_list" })
  }
};
