import BugReportForm from "../components/BugReportForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["bug_report_read"],
    route: {
      path: "/bug_reports/list",
      name: "bug_reports_list"
    },
    breadcrumbs: [
      {
        label: () => "Lista zgłoszeń"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "createdAt",
        type: "text",
        label: "Data utworzenia"
      },
      {
        field: "updatedAt",
        type: "text",
        label: "Data ostatniej aktualizacji"
      },
      {
        field: "status",
        type: "text",
        label: "Status",
        filter: "select",
        filterOptions: () => [
          { label: "Wszystkie", value: null },
          { label: "Nowy", value: "new" },
          { label: "Przyjęty", value: "accepted" },
          { label: "Zamknięty", value: "closed" },
          { label: "Przekazany do DGCS.NET", value: "transferred" }
        ],
        format: val => {
          switch (val) {
            case "new":
              return "Nowy";
            case "accepted":
              return "Przyjęty";
            case "closed":
              return "Zamknięty";
            case "transferred":
              return "DGCS.NET";
          }
        }
      },
      {
        field: "isReadByAdmin",
        type: "simplebool",
        label: "Odczytano",
        filter: "boolean"
      }
    ],
    resource: "bug_reports",
    url: () =>
      `/bug_reports?` +
      generateProperties(
        [],
        ["id", "name", "createdAt", "updatedAt", "status", "isReadByAdmin"]
      ),
    rowActions: [
      {
        action: "edit",
        icon: "eye",
        permissions: ["bug_report_update"]
      }
    ]
  },
  update: {
    permissions: ["bug_report_update"],
    route: {
      path: "/bug_reports/:id/update",
      name: "bug_reports_update"
    },
    resource: "bug_reports",
    breadcrumbs: [
      {
        route: () => ({ name: "bug_reports_list" }),
        label: () => "Lista zgłoszeń"
      },
      {
        label: ({ compData }) =>
          `"Edycja zgłoszenia": "${compData.object.name}"`
      }
    ],
    formComponent: BugReportForm,
    listRoute: () => ({ name: "bug_reports_list" })
  }
};
