import Vue from "vue";
import generateProperties from "../../../helpers/generateProperties";

export default async ({ compData, route, $notify, $http }) => {
    Vue.set(compData, "slideMosquitoNet", {});
    try {
        compData.loading = true;
        let { data } = await $http.get(`slide_mosquito_nets/${route.params.slideMosquitoNetId}?` + generateProperties([],['id', 'name', 'code'],false));
        compData.slideMosquitoNet = data;
        compData.loading = false;
    } catch (e) {
        $notify("Nie udało się załadować moskitiery przesuwanej", "danger");
    }
};
