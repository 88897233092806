import crud from "../../crud";
import frameMosquitoNetAdditionals from "./crud/frameMosquitoNetAdditionals";
import frameMosquitoNets from "./crud/frameMosquitoNets";
import frameMosquitoNetFrameMosquitoNetAdditionalEntries from "./crud/frameMosquitoNetFrameMosquitoNetAdditionalEntries";
import frameMosquitoNetGlobalAccessoryEntries from "./crud/frameMosquitoNetGlobalAccessoryEntries";
import frameMosquitoNetGlobalClipEntries from "./crud/frameMosquitoNetGlobalClipEntries";
import frameMosquitoNetGaskets from "./crud/frameMosquitoNetGaskets";
import frameMosquitoNetPriceLists from "./crud/frameMosquitoNetPriceLists";
import frameMosquitoNetGlobalNetEntries from "./crud/frameMosquitoNetGlobalNetEntries";

crud(frameMosquitoNetAdditionals);
crud(frameMosquitoNets);
crud(frameMosquitoNetFrameMosquitoNetAdditionalEntries);
crud(frameMosquitoNetGlobalAccessoryEntries);
crud(frameMosquitoNetGlobalClipEntries);
crud(frameMosquitoNetGaskets);
crud(frameMosquitoNetPriceLists);
crud(frameMosquitoNetGlobalNetEntries);
