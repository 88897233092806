<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="window_frames")
    template(#default="{ data, errors }")
      b-tabs
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywna

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" min=0 @input="data.position = +$event" :value="data.position")

              app-form-field(label="Zdjęcie" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        picture: "",
        position: 0,
        isEnabled: true
      },
      files: {
        picture: {}
      }
    };
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
