import crud from "../../crud";
import details from "./crud/details";
import detailUnits from "./crud/detailUnits";
import detailProducts from "./crud/detailProducts";
import detailSystems from "./crud/detailSystems";
import detailColors from "./crud/detailColors";
import detailCategories from "./crud/detailCategories";

crud(details)
crud(detailUnits)
crud(detailProducts)
crud(detailSystems)
crud(detailColors)
crud(detailCategories)