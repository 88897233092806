import PaymentMethodForm from "../components/PaymentMethodForm";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    sortable: true,
    permissions: ["payment_method_read"],
    actions: [
      {
        label: "Nowa metoda płatności",
        type: "is-primary",
        route: () => ({
          name: "payment_methods_create"
        }),
        permissions: ["payment_method_read"]
      }
    ],
    route: {
      path: "/payment_methods/list",
      name: "payment_methods_list"
    },
    breadcrumbs: [
      {
        label: () => "Metody płatności"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "percentDiscount",
        type: "text",
        format: decimal,
        label: "Rabat [%]",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywna",
        filter: "boolean"
      }
    ],
    resource: "payment_methods",
    url: () => `/payment_methods?properties[]=id&properties[]=name&properties[]=percentDiscount&properties[]=isEnabled`,
    rowActions: [
      {
        action: "edit",
        permissions: ["payment_method_update"]
      },
      {
        action: "remove",
        permissions: ["payment_method_delete"]
      }
    ]
  },
  create: {
    permissions: ["payment_method_create"],
    route: {
      path: "/payment_methods/create",
      name: "payment_methods_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "payment_methods_list" }),
        label: () => "Metody płatności"
      },
      {
        label: () => `Nowa metoda płatności`
      }
    ],
    formComponent: PaymentMethodForm,
    listRoute: () => ({
      name: "payment_methods_list"
    }),
    updateRoute: ({ object }) => ({
      name: "payment_methods_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["payment_method_update"],
    route: {
      path: "/payment_methods/:id/update",
      name: "payment_methods_update"
    },
    resource: "payment_methods",
    breadcrumbs: [
      {
        route: () => ({ name: "payment_methods_list" }),
        label: () => "Metody płatności"
      },
      {
        label: ({ compData }) =>
          `Edycja metody płatności "${compData.object.name}"`
      }
    ],
    formComponent: PaymentMethodForm,
    listRoute: () => ({
      name: "payment_methods_list"
    })
  }
};
