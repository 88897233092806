import OrderForm from "../components/OrderForm";
import moment from "moment";
import generateProperties from "../../../helpers/generateProperties";
import orderNumber from "../../../helpers/orderNumberCreator";
import notify from "../../../helpers/notify";
import http from "../../../http";
import OrderNumberCreator from "../../../helpers/orderNumberCreator";
import productTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import orderCategoryByProductTypeCode from "../../../helpers/orderCategoryByProductTypeCode";

export default {
  list: {
    permissions: ["order_read"],
    route: {
      path: "/orders/list",
      name: "orders_list"
    },
    sortField:"orderStatus",
    sortOrder:'accepted new', // sort by array
    itemsPerPage: 50,
    hasPagination: true,
    countable: true,
    rowColorFunction : function(row) {
      const status = row.status;

      if (status === "new") {
        return "has-background-warning";
      }

      if (status === "accepted") {
        return "has-background-success";
      }

    },
    breadcrumbs: [
      {
        label: () => "Zamówienia"
      }
    ],
    fields: [
      {
        field: "orderDate",
        type: "virtual",
        label: "Data",
        filter: "text",
        filterExtraField: "strictly_after",
        value: (h, row) => {
          const {orderDate, valuationDate, clearedDate, status, createdAt} = row
          if (row['@type'] === "FixedOrder") {
            return moment(createdAt, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
          }
          if (status === 'valuation' && valuationDate) return moment(valuationDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
          if(status === 'cleared' && clearedDate )return moment(clearedDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
          if (orderDate) return moment(orderDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
        },
        format: function(value, row) {
          if(!value) return 'brak'
          return moment(value, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY");
        }
      },
      {
        field: "number",
        type: "text",
        label: "Numer",
        filter: "text",
        format : function(value){
          return orderNumber(value)
        }
      },
      {
        field: "isFixedOrder",
        label: "Zamówienie FX",
        filter: "boolean",
        isOnlyFilter: true
      },
      {
        field: "category",
        type: "virtual",
        label: "Kategoria",
        filter: "select",
        value: (h, row) => {
          const { category } = row;
          let cat = '';

          if (row['@type'] === "FixedOrder") {
            cat = "(FX) ";
          }

          cat += productTypeFullNameByOrderCategory[category] || '';

          return cat;
        },
        format: function(value) {
          return  productTypeFullNameByOrderCategory[value] || '';
        },
        filterOptions: [
          { label: "Wszystkie", value: null },
          { label: "Roletki Tkaninowe", value: orderCategoryByProductTypeCode.RTK },
          { label: "Roletki Dzień-noc", value: orderCategoryByProductTypeCode.RDN },
          { label: "Rolety Zewnętrzne", value: orderCategoryByProductTypeCode.RZW },
          { label: "Moskitiery Ramkowe", value: orderCategoryByProductTypeCode.MSQ },
          { label: "Moskitiery Zwijane", value: orderCategoryByProductTypeCode.MZW },
          { label: "Moskitiery Plisowane", value: orderCategoryByProductTypeCode.MPL },
          { label: "Moskitiery przesuwane", value: orderCategoryByProductTypeCode.MPR },
          { label: "Moskitiery Drzwiowe", value: orderCategoryByProductTypeCode.MDR },
          { label: "Plisy", value: orderCategoryByProductTypeCode.PLI },
          { label: "Detale", value: orderCategoryByProductTypeCode.DET }
        ]
      },
      {
        field: "quantity",
        type: "text",
        label: "Szt."
      },
      {
        field: "company",
        type: "virtual",
        label: "Firma",
        value: (h, row) => {
          let company = row.company;
          if (company) {
            return company.shortName;
          }
          company = row.subCompany && row.subCompany.company;
          if (company) {
            return company.shortName;
          }
        }
      },
      {
        field: "subCompany",
        type: "virtual",
        label: "Pododbiorca",
        value: (h, row) => {
          const company = row.subCompany;
          if (company) {
            return company.shortName;
          }
          return "-";
        }
      },
      {
        field: "deliveryMethod.name",
        type: "text",
        label: "Dostawa"
      },
      {
        field: "productionStatus",
        type: "text",
        label: "Produkcja",
        filter: "select",
        filterOptions: [
          { label: "Wszystkie", value: null },
          { label: "Oczekuje", value: "waiting" },
          { label: "W trakcie", value: "in_progress" },
          { label: "Zakończona", value: "end" }
        ],
        isOnlyFilter: true
      },
      {
        field: "productionDate",
        type: "virtual",
        label: "Status",
        value: (h, row) => {
          const status = row.status;
          const productionStatus = row.productionStatus;
          const productionDate = row.productionDate;
          const productionEndDate = row.productionEndDate;
          const shipmentDate = row.shipmentDate;
          const deliveryMethod = row.deliveryMethod;

          if (status === "new") {
            return "Nowy";
          }

          if (status === "accepted") {
            return "Przyjęte";
          }

          if (status === "valuation") {
            return "Wycena";
          }

          if (status === "cleared") {
            return "Niedokończono";
          }

          if (shipmentDate && deliveryMethod) {
            return `${deliveryMethod.name} ${moment(
              shipmentDate,
              "DD-MM-YYYY hh:mm:ss"
            ).format("DD-MM-YYYY")}`;
          }

          if (productionStatus === "end" && productionEndDate) {
            return `Wyprodukowano ${moment(
              productionEndDate,
              "DD-MM-YYYY hh:mm:ss"
            ).format("DD-MM-YYYY")}`;
          }

          if (status === "production" && productionStatus === "waiting") {
            return "Oczekuje";
          }

          if (
            status === "production" &&
            productionStatus === "in_progress"  &&
            moment(productionDate, "DD-MM-YYYY hh:mm:ss").isSameOrBefore(
              moment(),
              "day"
            )
          ) {
            return `W trakcie ${moment(
              productionDate,
              "DD-MM-YYYY hh:mm:ss"
            ).format("DD-MM-YYYY")}`;
          }

          if (status === "production" && productionDate && productionStatus === "in_progress" ) {
            return `Zaplanowano ${moment(
              productionDate,
              "DD-MM-YYYY hh:mm:ss"
            ).format("DD-MM-YYYY")}`;
          }

          return "-";
        }
      },
      {
        field: "packageNote",
        label: "Opis paczki",
        filter: "text",
        isOnlyFilter: true
      },
      {
        field: "companiesShortNames",
        label: "Firma",
        filter: "text",
        isOnlyFilter: true
      },
      {
        field: "in",
        type: "text",
        label: "Status",
        filter: "select",
        filterOptions: [
          { label: "Zamówienia", value: "new accepted production", selected:true },
          { label: "Wycena", value: "valuation" },
          { label: "Wszystkie", value: "new accepted production valuation" },
          { label: "Nowy", value: "new" },
          { label: "Przyjęte", value: "accepted" },
          { label: "Niedokończone", value: "cleared" }
        ],
        isOnlyFilter: true
      },
      {
        type: "buttons",
        label: "Operacje",
        buttons: [
          {
            label: "Podgląd",
            route: ({ row }) => ({
              name: "order_show",
              params: { id: row.id, printType: "preview" }
            })
          },
          {
            label: "Produkcja",
            route: ({ row }) => ({
              name: "order_show",
              params: { id: row.id, printType: "production" }
            }),
            boolStatement: function({ row }) {
              return "production" === row.status || "accepted" === row.status || "valuation" === row.status;
            }
          },
          {
            label: "Wycofaj",
            boolStatement: function({ row }) {
              return ("valuation" && "cleared") !== row.status;
            },
            isExecuteFunction: true,
            execute: async ({row, reload})=> {
              if (confirm("Czy na pewno chcesz wycofać zamówienie numer: " + orderNumber(row.id) + " ?  \n (powróci ono do wyceny danego klienta) ")) {try {
                    await http.put(`/orders/${row.id}/retraction`, row);
                    notify("Wycofano zamówienie: " + orderNumber(row.id));
                    reload() // refresh list
                  } catch {
                    notify("Nie udało się zmienic statusu zamówienia: " +orderNumber(row.id), "warning");
                  }
              }
            }
          },
          {
            label: "Notatka",
            isExecuteFunction: true,
            boolStatement: function({ row }) {
              return "valuation" !== row.status;
            },
            execute: ({row, showModal})=> {
              const title = "Dodaj notatkę do zamówienia nr: " + OrderNumberCreator(row.id)
              const field = "adminNotes"
              showModal(title, field, row);
            }
          },
          {
            label: "Zmień wartość",
            isExecuteFunction: true,
            boolStatement: function({ row }) {
              return ("valuation" && "cleared") !== row.status;
            },
            execute: ({row, showModal})=> {
              const title = "Zmień wartość do zamówienia nr: " + OrderNumberCreator(row.id)
              const field = "customPurchaseGrossValue"
              showModal(title, field, row);
            }
          },
        ]
      }
    ],
    resource: "orders",
    url: () =>
      `/orders?` +
      generateProperties(
        [],
        [
          "id",
          "orderDate",
          "productionDate",
          "valuationDate",
          "clearedDate",
          "productionStatus",
          "productionEndDate",
          "shipmentDate",
          "status",
          "quantity",
          "category",
          "number",
          "adminNotes",
          "adminNotesUserChanger",
          "shipment",
          "customPurchaseGrossValue",
          "totalPurchaseGrossValue",
          "customPurchaseGrossValueUserChanger",
          "createdAt",
          "isFixedOrder"
        ],
        false
      ) +
      generateProperties(["deliveryMethod"], ["name"], true) +
      generateProperties(["company"], ["shortName"], true) +
      generateProperties(["subCompany"], ["shortName"], true) +
      generateProperties(["subCompany", "company"], ["shortName"], true) +
      generateProperties(["customPurchaseGrossValueUserChanger"], ["firstName", "lastName", "username"], true)

},
  create: {
    permissions: ["order_create"],
    route: {
      path: "/orders/create",
      name: "orders_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "orders_list" }),
        label: () => "Zamówienia"
      },
      {
        label: () => `Nowe zamówienie`
      }
    ],
    formComponent: OrderForm,
    listRoute: () => ({ name: "orders_list" }),
    updateRoute: ({ object }) => ({
      name: "orders_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["order_update"],
    route: {
      path: "/orders/:id/update",
      name: "orders_update"
    },
    resource: "orders",
    breadcrumbs: [
      {
        route: () => ({ name: "orders_list" }),
        label: () => "Zamówienia"
      },
      {
        label: ({ compData }) => `Edycja wersji "${compData.object.name}"`
      }
    ],
    formComponent: OrderForm,
    listRoute: () => ({ name: "orders_list" })
  }
};
