import SlideMosquitoNetAdditionalForm from "../components/SlideMosquitoNetAdditionalForm.vue";

export default {
    list: {
        permissions: ["slide_mosquito_net_additional_read"],
        actions: [
            {
                label: "Nowy dodatek",
                type: "is-primary",
                route: () => ({
                    name: "slide_mosquito_net_additionals_create"
                }),
                permissions: ["slide_mosquito_net_additional_create"]
            }
        ],
        route: {
            path: "/slide_mosquito_net_additionals/list",
            name: "slide_mosquito_net_additionals_list"
        },
        sortable: false,
        breadcrumbs: [
            {
                label: () => `Dodatki`
            }
        ],
        fields: [
            { field: "name", type: "text", label: "Nazwa", filter: "text" }
        ],
        resource: "slide_mosquito_net_additionals",
        url: () => `/slide_mosquito_net_additionals`,
        rowActions: [
            {
                action: "edit",
                permissions: ["slide_mosquito_net_additional_update"]
            },
            {
                action: "remove",
                permissions: ["slide_mosquito_net_additional_delete"]
            }
        ]
    },
    create: {
        permissions: ["slide_mosquito_net_additional_create"],
        route: {
            path: "/slide_mosquito_net_additionals/create",
            name: "slide_mosquito_net_additionals_create"
        },
        breadcrumbs: [
            {
                route: () => ({
                    name: "slide_mosquito_net_additionals_list"
                }),
                label: () => `Dodatki`
            },
            {
                label: () => `Nowy dodatek`
            }
        ],
        formComponent: SlideMosquitoNetAdditionalForm,
        listRoute: () => ({
            name: "slide_mosquito_net_additionals_list"
        }),
        updateRoute: ({ object }) => ({
            name: "slide_mosquito_net_additionals_update",
            params: {
                id: object.id
            }
        })
    },
    update: {
        permissions: ["slide_mosquito_net_additional_update"],
        route: {
            path: "/slide_mosquito_net_additionals/:id/update",
            name: "slide_mosquito_net_additionals_update"
        },
        resource: "slide_mosquito_net_additionals",
        breadcrumbs: [
            {
                route: () => ({
                    name: "slide_mosquito_net_additionals_list"
                }),
                label: () => `Dodatki`
            },
            {
                label: ({ compData }) => `Edycja dodatku "${compData.object.name}"`
            }
        ],
        formComponent: SlideMosquitoNetAdditionalForm,
        listRoute: () => ({
            name: "slide_mosquito_net_additionals_list"
        })
    }
};
