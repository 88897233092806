import Vue from "vue";
import generateProperties from "../../../helpers/generateProperties";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "externalShutterBox", {});
  Vue.set(compData, "externalShutter", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `external_shutter_boxes/${route.params.externalShutterBoxId}?properties[]=id&properties[]=name` +
        generateProperties(["externalShutter"], ["id", "name"], true)
    );
    compData.externalShutterBox = data;
    compData.externalShutter = data.externalShutter;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować skrzynki", "danger");
  }
};
