<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="download_files" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny
            .column
              app-form-field(label="Plik" field="file" :errors="errors")
                app-form-file(v-model="files.file" :image="data.file" @removed="data.file = {}" :downloadable="true" :download-text="data.name")
</template>

<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        isEnabled: true,
        file: null,
        downloadSubsection: `/download_subsections/${this.$route.params.downloadSubsectionId}`
      },
      files: {
        file: {}
      }
    };
  },
  methods: {
    transformToModel(data) {
      if (typeof data.downloadSubsection !== "string") {
        data.downloadSubsection = data.downloadSubsection["@id"];
      }
      return data;
    }
  }
};
</script>
