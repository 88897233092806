<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_box_internal_fabric_shade_color_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 W danej skrzynce ten sam kolor wewnętrzny nie może występować więcej niż 1 raz
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Dopłata za metr" field="lengthSurcharge" :errors="errors")
                app-form-decimal(v-model="data.lengthSurcharge")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(label="Przekrój" field="sectionPicture" :errors="errors")
                app-form-file(v-model="files.sectionPicture" :image="data.sectionPicture" @removed="data.sectionPicture = {}")

            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Kolor" field="fabricShadeColor" :errors="errors")
                resource-select(
                  v-model="data.fabricShadeColor"
                  resource="fabric_shade_colors"
                  url="fabric_shade_colors"
                  placeholder="Wybierz kolor"
                  label="name"
                )

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  data() {
    return {
      files: {
        sectionPicture: {}
      },
    };
  },
  computed: {
    model() {
      return {
        isEnabled: false,
        position: 0,
        lengthSurcharge: 0,
        fabricShadeColor: null,
        externalShutterBox: `/external_shutter_boxes/${this.$route.params.externalShutterBoxId}`,
        sectionPicture: {},
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.externalShutterBox) {
        data.externalShutterBox = data.externalShutterBox["@id"] || data.externalShutterBox;
      }
      if (data.fabricShadeColor) {
        data.fabricShadeColor = data.fabricShadeColor["@id"] || data.fabricShadeColor;
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
