<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="detail_products" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")
                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny
                            app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
                b-tab-item(label="Systemy")
                  app-form-field(label="Systemy" field="detailSystems" :errors="errors")
                    multiple-resource-select(
                      v-model="data.detailSystems"
                      resource="detail_systems"
                      placeholder="Wybierz system"
                    )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect.vue";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      files: {
        picture: {},
      },
      model: {
        name: "",
        isEnabled: null,
        detailSystems:[]
      },
    };
  },
  transformToModel(data) {
    this.model.detailSystems = data.detailSystems.map(
        detailSystems => detailSystems["@id"]
    );

    return data;
  }
};
</script>
