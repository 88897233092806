<template lang="pug">
  table.table.is-fullwidth.is-bordered
    SlideMosquitoNetOrderItemProductionTemplate(:object="order")

</template>

<script>
import SlideMosquitoNetOrderItemProductionTemplate from "../../orders/components/SlideMosquitoNetOrderItemProductionTemplate";

export default {
  components: {SlideMosquitoNetOrderItemProductionTemplate},
  name: "ProductionOrderPreviewSlideMosquitoNetTable",
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>