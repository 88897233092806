<template lang="pug">
    div
        b-checkbox(@input="onSelectAll" v-model="all" :disabled="disabled") {{ selectAllLabel }}
        br
        br
        div(v-for="item in list" :key="item.id")
            b-checkbox(
                :disabled="disabled"
                :native-value="item[nativeValueField]"
                v-model="model"
                ) {{ label(item) }}
        .columns
            .column
                button.button(@click.prevent="refresh" type="button") Odśwież
            .column.is-narrow
                div(v-if="addForm")
                    button.button(@click.prevent="showForm" type="button") Dodaj
                    .modal.is-active(v-show="showAddForm")
                        .modal-background
                        .modal-card
                            header.modal-card-head
                                p.modal-card-title {{ addFormTitle }}
                                button.delete(aria-label='close' type="button" @click="closeForm")
                            section.modal-card-body
                                component(:is="addForm" @success="success")
</template>
<script>
import resourceSelectMixin from "../mixins/resourceSelectMixin";
export default {
  mixins: [resourceSelectMixin],
  props: {
    value: { type: Array, default: () => [] },
    nativeValueField: { type: String, default: '@id' },
  },
  computed: {
    selectAllLabel() {
      if (this.all) {
        return "Odznacz wszystkie";
      } else {
        return "Zaznacz wszystkie";
      }
    },
    all: {
      set() {},
      get() {
        return this.value.length > 0 && this.value.length === this.list.length;
      }
    },
    model: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value.map(i => (i["@id"] ? i["@id"] : i));
      }
    }
  },
  methods: {
    onSelectAll(value) {
      if (value) {
        let values = this.list.map(item => item["@id"]);
        this.$emit("input", values);
      } else {
        this.$emit("input", []);
      }
    },
    async success(data) {
      this.closeForm();
      await this.fetchData();
      let copy = [...this.value, data];
      this.$emit("input", copy);
    }
  },
};
</script>
