import decimal from "../../../helpers/decimal";
import ThreadColorForm from "../components/ThreadColorForm.vue";

export default {
  list: {
    permissions: ["thread_color_read"],
    actions: [
      {
        label: "Nowy kolor nici",
        type: "is-primary",
        route: ({ route }) => ({
          name: "thread_colors_create",
        }),
        permissions: ["thread_color_create"]
      }
    ],
    route: {
      path: "/thread_colors",
      name: "thread_colors_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "thread_colors_list" }),
        label: () => "Kolor nici"
      },
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Kolor nici",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "selectionOrder",
        type: "text",
        label: "Kolejność doboru"
      }
    ],
    resource: "thread_colors",
    url: ({ route }) =>
        `/thread_colors?properties[]=id&properties[]=position&properties[]=name&properties[]=isEnabled&properties[]=selectionOrder`,
    rowActions: [
      {
        action: "edit",
        permissions: ["thread_colors_update"]
      },
      {
        action: "remove",
        permissions: ["thread_colors_delete"]
      }
    ]
  },
  create: {
    permissions: ["thread_color_create"],
    route: {
      path: "/thread_colors/create",
      name: "thread_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "thread_colors_list" }),
        label: () => "Kolory nici"
      },
      {
        route: ({ compData }) => ({
          name: "thread_colors_list",
        }),
      },
      {
        label: ({ compData }) =>
            `Nowy kolor nici `
      }
    ],
    formComponent: ThreadColorForm,
    listRoute: ({ compData }) => ({
      name: "thread_colors_list",
    }),
    updateRoute: ({ object }) => ({
      name: "thread_colors_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["thread_colors_update"],
    route: {
      path: "/thread_colors/:id/update",
      name: "thread_colors_update"
    },
    resource: "thread_colors",
    breadcrumbs: [
      {
        label: ({ compData }) =>
            `Edycja koloru nici "${compData.object.name}"`
      }
    ],
    formComponent: ThreadColorForm,
    listRoute: ({ compData }) => ({
      name: "thread_colors_list",
    })
  }
};
