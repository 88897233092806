import GlobalBalkForm from "../components/GlobalBalkForm";

export default {
  list: {
    permissions: ["global_balk_read"],
    actions: [
      {
        label: "Nowa belka dolna",
        type: "is-primary",
        route: () => ({
          name: "global_balks_create"
        }),
        permissions: ["global_balk_create"]
      }
    ],
    route: {
      path: "/global_balks/list",
      name: "global_balks_list"
    },
    sortable: false,
    sortField:"name",
    sortOrder:'asc',
    breadcrumbs: [
      {
        label: () => `Belki dolne`
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" }
    ],
    resource: "global_balks",
    url: () =>
      `/global_balks?properties[]=id&properties[]=name&properties[]=symbol&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_balk_update"]
      },
      {
        action: "remove",
        permissions: ["global_balk_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_balk_create"],
    route: {
      path: "/global_balks/create",
      name: "global_balks_create"
    },
    breadcrumbs: [
      {
        label: () => `Nowa belka dolna  dla wesji`
      }
    ],
    formComponent: GlobalBalkForm,
    listRoute: () => ({
      name: "global_balks_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_balks_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_balk_update"],
    route: {
      path: "/global_balks/:id/update",
      name: "global_balks_update"
    },
    resource: "global_balks",
    breadcrumbs: [
      {
        route: () => ({ name: "global_balks_list" }),
        label: () => "Belki dolne"
      },
      {
        label: ({ compData }) => `Edycja belki dolnej "${compData.object.name}"`
      }
    ],
    formComponent: GlobalBalkForm,
    listRoute: () => ({
      name: "global_balks_list"
    })
  }
};
