import VatRateForm from "../components/VatRateForm";

export default {
  list: {
    sortable: true,
    permissions: ["vat_rate_read"],
    actions: [
      {
        label: "Nowa stawka",
        type: "is-primary",
        route: () => ({
          name: "vat_rates_create"
        }),
        permissions: ["vat_rate_create"]
      }
    ],
    route: {
      path: "/vat_rates/list",
      name: "vat_rates_list"
    },
    breadcrumbs: [
      {
        label: () => "Stawki VAT"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "value", type: "text", label: "Wartość", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywna",
        filter: "boolean"
      }
    ],
    resource: "vat_rates",
    url: () =>
      `/vat_rates?properties[]=id&properties[]=name&properties[]=value&properties[]=isEnabled`,
    rowActions: [
      {
        action: "edit",
        permissions: ["vat_rate_update"]
      },
      {
        action: "remove",
        permissions: ["vat_rate_delete"]
      }
    ]
  },
  create: {
    permissions: ["vat_rate_create"],
    route: {
      path: "/vat_rates/create",
      name: "vat_rates_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "vat_rates_list" }),
        label: () => "Stawki VAT"
      },
      {
        label: () => `Nowa stawka VAT`
      }
    ],
    formComponent: VatRateForm,
    listRoute: () => ({
      name: "vat_rates_list"
    }),
    updateRoute: ({ object }) => ({
      name: "vat_rates_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["vat_rate_update"],
    route: {
      path: "/vat_rates/:id/update",
      name: "vat_rates_update"
    },
    resource: "vat_rates",
    breadcrumbs: [
      {
        route: () => ({ name: "vat_rates_list" }),
        label: () => "Stawki VAT"
      },
      {
        label: ({ compData }) => `Edycja stawki VAT "${compData.object.name}"`
      }
    ],
    formComponent: VatRateForm,
    listRoute: () => ({ name: "vat_rates_list" })
  }
};
