<template lang="pug">
    b-input(
        :size="size"
        :value="formatted"
        @input="onInput"
        @focus="$event.target.select()"
    )
</template>
<script>
export default {
  props: {
    value: Number,
    size: String
  },
  computed: {
    formatted() {
      return this.value / 60;
    },
    classes() {
      return {
        input: true,
        [`${this.size}`]: true
      };
    }
  },
  methods: {
    onInput(event) {
      const previousNumber = this.value;
      const number = parseFloat(event.replace(",", "."));
      const value = Math.round(number * 60);
      if (isNaN(number)) {
        this.$emit("input", previousNumber);
      } else {
        this.$emit("input", value);
      }
    }
  }
};
</script>
