<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_boxes" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny
              app-form-field(field="hasMosquitoNet" :errors="errors")
                b-checkbox(v-model="data.hasMosquitoNet") Moskitiera
              br
              app-form-field(label="Dopłata za sztukę zł" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")
            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")
              app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                app-form-time-integer(v-model="data.productionTime")
              app-form-field(label="Dopłata długości zł/mb" field="lengthSurcharge" :errors="errors")
                app-form-decimal(v-model="data.lengthSurcharge")
            .columnc
              app-form-field(label="Masa skrzynki kg/mb" field="weight" :errors="errors")
                app-form-weight(v-model="data.weight" unit="kg")
              app-form-field(label="Masa kpl. boków kg" field="sideWeight" :errors="errors")
                app-form-weight(v-model="data.sideWeight" unit="kg")
              app-form-field(label="Dopłata za zwiększenie zł" field="increaseSurcharge" :errors="errors")
                app-form-decimal(v-model="data.increaseSurcharge")
            .column
              app-form-field(label="Szerokość (mm)" field="width" :errors="errors")
                app-form-length(v-model="data.width" unit="mm")
              app-form-field(label="Wysokość (mm)" field="height" :errors="errors")
                app-form-length(v-model="data.height" unit="mm")
              app-form-field(label="Dopłata od długości prowadnic zł/mb" field="runnerHeightSurcharge" :errors="errors")
                app-form-decimal(v-model="data.runnerHeightSurcharge")
          .columns
            .column
              app-form-field(label="Przekrój" field="sectionPicture" :errors="errors")
                app-form-file(v-model="files.sectionPicture" :image="data.sectionPicture" @removed="data.sectionPicture = {}")
        b-tab-item(label="Nawoje")
          table.table
            tbody
              template(v-for="(pipe, pipeIndex) in externalShutterPipes")
                tr(v-if="pipeIndex === 0")
                  template(v-for="(armor, armorIndex) in externalShutterArmors")
                    td(v-if="armorIndex === 0")
                      div Rura\Pancerz
                    td(:key="armor['@id']")
                      div {{ armor.name }}
                tr
                  template(v-for="(armor, armorIndex) in externalShutterArmors")
                    td(v-if="armorIndex === 0" )
                      div {{ pipe.symbol }}
                    td( :key="pipe['@id'] + armor.id")
                      app-form-decimal-integer(
                        size="is-small"
                        :value="getValueFor(pipe, armor)"
                        @input="setValueFor(pipe, armor, $event)"
                      )
        b-tab-item(label="Dokumenty")
          app-form-field(label="Dokumenty" field="instructions" :errors="errors")
            app-form-files(v-model="files.instructions" :image="data.instructions" @removed="(id)=>{data.instructions = afterRemovedFiles(id,data.instructions)}"  :downloadable="true" :multiple='true' accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps')
        b-tab-item(label="Adaptacje")
          app-form-field(v-if="externalShutterId" label="Adaptacje" field="externalShutterAdaptations" :errors="errors")
            multiple-resource-select(
              v-model="data.externalShutterAdaptations"
              resource="external_shutter_adaptations"
              :url="`external_shutters/${externalShutterId}/external_shutter_adaptations`"
              placeholder="Wybierz adaptacje"
              :add-default-prop="false"
              :full-object="true"
            )

</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import generateProperties from "../../../helpers/generateProperties";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: {MultipleResourceSelect},
  data() {
    return {
      files: {
        sectionPicture: {},
        instructions: null
      },
      externalShutterArmors: [],
      externalShutterPipes: [],
      externalShutterId: null
    };
  },
  computed: {
    model() {
      return {
        name: "",
        hasMosquitoNet: false,
        isEnabled: false,
        position: 0,
        weight: 0,
        height: 0,
        width: 0,
        sideWeight: 0,
        lengthSurcharge: 0,
        increaseSurcharge: 0,
        pieceSurcharge: 0,
        runnerHeightSurcharge: 0,
        productionTime: 0,
        externalShutter: `/external_shutters/${this.$route.params.externalShutterId}`,
        sectionPicture: null,
        instructions: null,
        externalShutterPipeExternalShutterArmorEntries: [],
        externalShutterAdaptations: []
      };
    }
  },
  methods: {
    transformToSubmit(data) {
      data.externalShutter = data.externalShutter["@id"] || data.externalShutter;
      data.externalShutterPipeExternalShutterArmorEntries = data.externalShutterPipeExternalShutterArmorEntries.map(entry => {
            entry.globalPipe = entry.globalPipe["@id"] || entry.globalPipe;
            entry.externalShutterArmor = entry.externalShutterArmor["@id"] || entry.externalShutterArmor;
            return entry;
          }
      );


      return data;
    },
    transformToModel(data) {
      if (data.externalShutter) {
        let shutter = data.externalShutter['@id'] || data.externalShutter;
        shutter = shutter.split("/");
        shutter = shutter[shutter.length - 1];
        if (parseInt(shutter)) {
          this.externalShutterId = shutter;
        }
      }


      return data;
    },
    afterRemovedFiles(id, array) {
      if (array.length === 0) return [];
      return array.filter((obj) => obj.id !== id);
    },
    getValueFor(pipe, armor) {
      const item = this.model.externalShutterPipeExternalShutterArmorEntries.find(
          item => item.globalPipe["@id"] === pipe["@id"] && item.externalShutterArmor["@id"] === armor["@id"]
      );
      if (item) {
        return item.value;
      }
      return 0;
    },
    setValueFor(pipe, armor, value) {
      const item = this.model.externalShutterPipeExternalShutterArmorEntries.find(
          item => item.globalPipe["@id"] === pipe["@id"] && item.externalShutterArmor["@id"] === armor["@id"]
      );
      if (item) {
        item.value = value;
      } else {
        this.model.externalShutterPipeExternalShutterArmorEntries.push({
          globalPipe: pipe,
          externalShutterArmor: armor,
          value: value
        });
      }
    }
  },
  async created() {
    //dla tworzenia
    if (this.$route.params.externalShutterId) {
      const shutterPath = `/external_shutters/${this.$route.params.externalShutterId}?` + generateProperties(["globalPipes"], ["id", "symbol", "position"]) + generateProperties(["externalShutterPriceLists", "externalShutterArmor"], ["id", "name"], true);
      let {data} = await http.get(shutterPath);
      this.externalShutterPipes = data.globalPipes;
      this.externalShutterArmors = data.externalShutterPriceLists.map(
          list => list.externalShutterArmor
      );

      this.externalShutterPipes.forEach(pipe => {
        this.externalShutterArmors.forEach(armor => {
          const obj = {
            globalPipe: pipe,
            externalShutterArmor: armor,
            value: 0
          };
          this.model.externalShutterPipeExternalShutterArmorEntries.push(obj);
        });
      });

      this.externalShutterPipes.sort((first, next) => {
        if (first.position - next.position < 0) {
          return -1;
        }
        if (first.position - next.position > 0) {
          return 1;
        }
        return 0;
      });

      this.model.externalShutterPipeExternalShutterArmorEntries.sort(
          (first, next) => {
            if (first.globalPipe.position - next.globalPipe.position < 0) {
              return -1;
            }
            if (first.globalPipe.position - next.globalPipe.position > 0) {
              return 1;
            }
            if (first.externalShutterArmor.id - next.externalShutterArmor.id < 0) {
              return -1;
            }
            if (first.externalShutterArmor.id - next.externalShutterArmor.id > 0) {
              return 1;
            }
            return 0;
          }
      );
    }
    this.model.position = await checkLastPosition(this.$router);
  },
  watch: {
    //dla aktualizacji
    async object() {
      if (this.object && this.object.id) {
        const shutterPath = `/external_shutters/${this.object.externalShutter.id}?` + generateProperties(["globalPipes"], ["id", "symbol", "position"]) + generateProperties(["externalShutterPriceLists", "externalShutterArmor"], ["id", "name"], true);
        let {data} = await http.get(shutterPath);
        this.externalShutterPipes = data.globalPipes;
        this.externalShutterArmors = data.externalShutterPriceLists.map(
            list => list.externalShutterArmor
        );

        this.externalShutterPipes.sort((first, next) => {
          if (first.position - next.position < 0) {
            return -1;
          }
          if (first.position - next.position > 0) {
            return 1;
          }
          return 0;
        });

        this.externalShutterPipes.forEach(pipe => {
          this.externalShutterArmors.forEach(armor => {
            if (this.object.externalShutterPipeExternalShutterArmorEntries.findIndex(
                entry =>
                    (entry.globalPipe["@id"] || entry.globalPipe === pipe["@id"] || pipe) &&
                    (entry.externalShutterArmor["@id"] || entry.externalShutterArmor === armor["@id"] || armor)
            ) < 0
            ) {
              const obj = {
                globalPipe: pipe,
                externalShutterArmor: armor,
                value: 0
              };
              this.object.externalShutterPipeExternalShutterArmorEntries.push(obj);
            }
          });
        });

        this.object.externalShutterPipeExternalShutterArmorEntries.sort(
            (first, next) => {
              if (first.globalPipe.position - next.globalPipe.position < 0) {
                return -1;
              }
              if (first.globalPipe.position - next.globalPipe.position > 0) {
                return 1;
              }
              if (first.externalShutterArmor.id - next.externalShutterArmor.id < 0) {
                return -1;
              }
              if (first.externalShutterArmor.id - next.externalShutterArmor.id > 0) {
                return 1;
              }
              return 0;
            }
        );
      }
    }
  },

};
</script>
