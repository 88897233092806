import GlobalPipeForm from "../components/GlobalPipeForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["global_pipe_read"],
    actions: [
      {
        label: "Nowa globalna rura",
        type: "is-primary",
        route: () => ({ name: "global_pipes_create" }),
        permissions: ["global_pipe_create"]
      }
    ],
    route: {
      path: "/global_pipes/list",
      name: "global_pipes_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => `Globalne rury`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "isEnabledForExternalShutter",
        type: "boolean",
        label: "Aktywny w roletach zewnętrznych",
        filter: "boolean"
      },
      {
        field: "diameter",
        type: "text",
        label: "Średnica [mm]",
        filter: "text"
      }
    ],
    resource: "global_pipes",
    url: () =>
      `/global_pipes?` +
      generateProperties(
        [],
        [
          "id",
          "name",
          "symbol",
          "isEnabled",
          "isEnabledForExternalShutter",
          "diameter",
          "position"
        ]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["global_pipe_update"]
      },
      {
        action: "remove",
        permissions: ["global_pipe_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_pipe_create"],
    route: {
      path: "/global_pipes/create",
      name: "global_pipes_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_pipes_list" }),
        label: () => `Globalne rury `
      },
      {
        label: () => `Nowa globalna rura `
      }
    ],
    formComponent: GlobalPipeForm,
    listRoute: () => ({ name: "global_pipes_list" }),
    updateRoute: ({ object }) => ({
      name: "global_pipes_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_pipe_update"],
    route: {
      path: "/global_pipes/:id/update",
      name: "global_pipes_update"
    },
    resource: "global_pipes",
    breadcrumbs: [
      {
        route: () => ({ name: "global_pipes_list" }),
        label: () => `Globalne rury`
      },
      {
        label: ({ compData }) =>
          `Edycja globalnej rury "${compData.object.name}"`
      }
    ],
    formComponent: GlobalPipeForm,
    listRoute: () => ({ name: "global_pipes_list" })
  }
};
