<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="pleat_additional_strings" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 W danej wersji ten sam naciąg nie może występować więcej niż 1 raz
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column

              app-form-field(label="Naciąg" field="globalRunner" :errors="errors")
                resource-select(
                  v-model="data.globalRunner"
                  resource="global_runners"
                  url="global_runners"
                  placeholder="Wybierz prowadnice"
                  label="name"
                )

              app-form-field(label="Dopłata od sztuki" field="pieceSurcharge" :errors="errors")
                app-form-decimal(v-model="data.pieceSurcharge")

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

            .column
              app-form-field(label="Opis" field="description" :errors="errors")
                b-input(v-model="data.description" type="textarea")

              app-form-field(label="Zdjęcie" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: { ResourceSelect },
  data() {
    return {
      files: {
        picture: {},
      },
    }
  },
  computed: {
    model() {
      return {
        isEnabled: false,
        position: 0,
        pieceSurcharge: 0,
        globalRunner: null,
        description: '',
        picture: {},
        pleat: `/pleats/${this.$route.params.pleatId}`
      };
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.pleat) {
        data.pleat = data.pleat["@id"] || data.pleat;
      }
      if (data.globalRunner) {
        data.globalRunner = data.globalRunner["@id"] || data.globalRunner;
      }

      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
