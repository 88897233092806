<template lang="pug">
  table.table.is-fullwidth.is-bordered
    DetailOrderItemProductionTemplate(:object="order")

</template>

<script>
import DetailOrderItemProductionTemplate from "../../orders/components/DetailOrderItemProductionTemplate";

export default {
  components: {DetailOrderItemProductionTemplate},
  name: "ProductionOrderPreviewDetailTable",
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>