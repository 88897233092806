<template lang="pug">
    b-field(
        :custom-class="customClass"
        :horizontal="horizontal"
        :label="label"
        :type="{ 'is-danger': hasError }"
        :message="message"
        :class="classes"
        :grouped="grouped"
        expanded
    )
        slot
</template>
<script>
export default {
  props: {
    horizontal: Boolean,
    disabled: Boolean,
    grouped: Boolean,
    classes: { type: String },
    label: { type: String },
    customClass: { type: String },
    field: { type: String, required: true },
    errors: { type: Array, default: () => [] },
    help: { type: String, default: "" }
  },
  computed: {
    hasError() {
      return this.errors.find(
        ({ propertyPath }) => propertyPath === this.field
      );
    },
    message() {
      if (this.hasError) {
        return this.hasError.message;
      }
      return this.help;
    }
  }
};
</script>
