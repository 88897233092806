<template lang="pug">
  b-field(:label="label")
    b-input(
      :placeholder="internalPlaceholder"
      type="search"
      icon="magnify"
      @input="update($event)"
      :value="valueChangerToInput(filters[field])"
    )
</template>
<script>
import filterMixin from "../mixins/filterMixin";

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
        args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  mixins: [filterMixin],
  props: {
    placeholder: { type: String },
    time: { type: Number, default: 350 },
    valueChangerToFilter: { type: Function, default: value => value },
    valueChangerToInput: { type: Function, default: value => value }
  },
  computed: {
    update() {
      return debounce(function(value) {
        this.filter({ field: this.field, value: this.valueChangerToFilter(value) });
      }, this.time);
    },
    internalPlaceholder() {
      return this.placeholder || this.label.toLowerCase() + "...";
    }
  }
};
</script>
