import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "pleat", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(`pleats/${route.params.pleatId}`);
    compData.pleat = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować plisy", "danger");
  }
};
