<template lang="pug">
  b-tabs(type="is-boxed" @input="getDataFromApi")
    b-tab-item(label="Ogólne produktów")
      table.table.is-bordered.is-fullwidth.is-narrow
        tbody
          template()
            tr
              th Nazwa produtku
              th Wersje
            tr(v-for="(product,index) in mainStatisticsData")
              td(span="product.versions.length") {{productTypeToName(index) }}
              td
                table.table.is-fullwidth.is-narrow
                  tbody
                    tr
                      th Nazwa
                      th Wyprodukowano
                      th Średni wymiar
                      th Średnia cena netto
                      th Suma ceny netto
                    tr(v-for="version in product.versions")
                      td {{version.name}}
                      td {{version['total'] || 0.0 }}
                      td {{`${Math.round(version['avgFullHeight'])} x ${Math.round(version['avgWidth'])}` }}
                      td {{ formatPriceValue(getDecimal(version['avgNetPurchaseValue']))}}
                      td {{ formatPriceValue(getDecimal(version['sumNetPurchaseValue']))}}

    b-tab-item(label="Globalne podzespoły")
    b-tab-item(label="Rolety zewnętrzne")
    b-tab-item(label="Roletki tkaninowe")
    b-tab-item(label="Roletki dzień noc")


</template>
<script>

import http from "../../../http";
import productTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import priceFormatter from "../../../helpers/priceFormatter";
import decimal from "../../../helpers/decimal";

export default {
  data() {
    return {
      loading: false,
      mainStatisticsData: [],
    };
  },
  mounted() {
    this.getMainStatisticsData()
  },
  methods: {
    getDataFromApi(tabIndex) {
      switch (tabIndex) {
        case 0 :
          this.getMainStatisticsData();
          break;
      }
    },
    async getMainStatisticsData() {
      let {data} = await http.get("/statistics/main");
      this.mainStatisticsData = data

    },
    productTypeToName(productType) {
      return productTypeFullNameByOrderCategory[productType]
    },
    getDecimal(value) {
      return decimal(value)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
  }
};
</script>
