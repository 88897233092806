import DownloadFileForm from "@/modules/other/components/DownloadFileForm";
import fetchDownloadSubsection from "@/modules/other/helpers/fetchDownloadSubsection";

export default {
  list: {
    permissions: ["download_file_read"],
    actions: [
      {
        label: "Nowy plik",
        type: "is-primary",
        route: ({ route }) => ({
          name: "download_files_create",
          params: {
            downloadSubsectionId: route.params.downloadSubsectionId
          }
        }),
        permissions: ["download_file_create"]
      }
    ],
    route: {
      path: "/download_files/:downloadSubsectionId/list",
      name: "download_files_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne"
      },
      {
        route: ({ compData }) => ({
          name: "download_subsections_list",
          params: {
            downloadSectionId: compData.downloadSubsection.downloadSection.id
          }
        }),
        label: ({ compData }) =>
          `Sekcje dodatkowe dla sekcji "${compData.downloadSubsection.downloadSection.name}"`
      },
      {
        label: ({ compData }) =>
          `Pliki do pobrania dla podsekcji "${compData.downloadSubsection.name}"`
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Nazwa",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "download_files",
    url: ({ route }) =>
      `/download_subsections/${route.params.downloadSubsectionId}/download_files?properties[]=id&properties[]=name&properties[]=isEnabled&properties[]=position`,
    onCreated: fetchDownloadSubsection,
    rowActions: [
      {
        action: "edit",
        permissions: ["download_file_update"]
      },
      {
        action: "remove",
        permissions: ["download_file_delete"]
      }
    ]
  },
  create: {
    permissions: ["download_file_create"],
    route: {
      path: "/download_files/:downloadSubsectionId/create",
      name: "download_files_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "download_sections_list" }),
        label: () => "Sekcje główne"
      },
      {
        route: ({ compData }) => ({
          name: "download_subsections_list",
          params: {
            downloadSectionId: compData.downloadSubsection.downloadSection.id
          }
        }),
        label: ({ compData }) =>
          `Podsekcje sekcji "${compData.downloadSubsection.downloadSection.name}"`
      },
      {
        route: ({ compData }) => ({
          name: "download_files_list",
          params: { downloadSubsectionId: compData.downloadSubsection.id }
        }),
        label: ({ compData }) =>
          `Pliki podsekcji "${compData.downloadSubsection.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy plik dla sekcji "${compData.downloadSubsection.name}"`
      }
    ],
    formComponent: DownloadFileForm,
    onCreated: fetchDownloadSubsection,
    listRoute: ({ compData }) => ({
      name: "download_files_list",
      params: { downloadSubsectionId: compData.downloadSubsection.id }
    }),
    updateRoute: ({ object }) => ({
      name: "download_files_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["download_file_update"],
    route: {
      path: "/download_files/:id/update",
      name: "download_files_update"
    },
    resource: "download_files",
    breadcrumbs: [
      {
        route: () => ({ name: "download_files_list" }),
        label: () => "Sekcje główne"
      },
      {
        route: function({ compData }) {
          return {
            name: "download_subsections_list",
            params: { downloadSectionId: compData.object.downloadSection.id }
          };
        },
        label: ({ compData }) =>
          `Podsekcje sekcji "${compData.object.downloadSection.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja podsekcji "${compData.object.name}" dla sekcji "${compData.object.downloadSection.name}"`
      }
    ],
    formComponent: DownloadFileForm,
    onCreated: fetchDownloadSubsection,
    listRoute: ({ compData }) => ({
      name: "download_files_list",
      params: {
        downloadSubsectionId: compData.object.downloadSubsection.id
      }
    })
  }
};
