

export default {
  list: {
    permissions: ["news_read"],
    actions: [],
    route: {
      path: "/news/:id/news_reads/list",
      name: "news_reads_list"
    },
    resource: "news_reads",
    url: (({route}) => `/news/${route.params.id}/news_reads`),
    sortable: true,
    fields: [
      {
        field: "company",
        type: "virtual",
        label: "Firma",
        value: (h, row) => {
          let company = row.company;
          if (company) {
            return company.shortName;
          }
          company = row.subCompany && row.subCompany.company;
          if (company) {
            return company.shortName;
          }
        }
      },
      {
        field: "subCompany",
        type: "virtual",
        label: "Pododbiorca",
        value: (h, row) => {
          const company = row.subCompany;
          if (company) {
            return company.shortName;
          }
          return "-";
        }
      },
      {
        field: "user.firstName",
        type: "virtual",
        label: "Imię",
        value: (h, row) => {
          const user = row.user;
          if (user) {
            return user.firstName;
          }
          return "-";
        }
      },
      {
        field: "user.lastName",
        type: "virtual",
        label: "Nazwisko",
        value: (h, row) => {
          const user = row.user;
          if (user) {
            return user.lastName;
          }
          return "-";
        }
      },
      {
        field: "user.email",
        type: "virtual",
        label: "Email",
        value: (h, row) => {
          const user = row.user;
          if (user) {
            return user.email;
          }
          return "-";
        }
      },
      {
        field: "date",
        type: "text",
        label: "Data odczytu"
      }
    ]
  },

}