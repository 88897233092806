<template lang="pug" functional>
  b-field(:label="props.label")
    b-select(
      @input="props.filter({ field: props.field, value: $event })"
      :value="props.filters[props.field]"
    )
      slot
</template>
<script>
import filterMixin from "../mixins/filterMixin";

export default {
  mixins: [filterMixin]
};
</script>
