import FabricShadeDayNightFabricShadeDayNightAdditionalEntryForm from "../../fabric_shade_day_nights/components/FabricShadeDayNightFabricShadeDayNightAdditionalEntryForm.vue";
import decimal from "../../../helpers/decimal";
import fetchFabricShadeDayNight from "@/modules/fabric_shade_day_nights/helpers/fetchFabricShadeDayNight";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["fabric_shade_fabric_shade_additional_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_create",
          params: {
            fabricShadeDayNightId: route.params.fabricShadeDayNightId
          }
        }),
        permissions: ["fabric_shade_day_night_fabric_shade_day_night_additional_entry_create"]
      }
    ],
    route: {
      path: "/fabric_shade_day_night_fabric_shade_day_night_additional_entries/:fabricShadeDayNightId/list",
      name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        label: ({ compData }) =>
          `Wiązania dodatków dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    fields: [
      {
        field: "fabricShadeDayNightAdditional.name",
        type: "text",
        label: "Nazwa dodatku",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "heightSurcharge",
        type: "text",
        label: "Dopłata wysokości [netto]",
        format: decimal
      },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za sztukę",
        format: decimal
      }
    ],
    resource: "fabric_shade_day_night_fabric_shade_day_night_additional_entries",
    url: ({ route }) =>
      `/fabric_shade_day_nights/${route.params.fabricShadeDayNightId}/fabric_shade_day_night_fabric_shade_day_night_additional_entries?` +
        generateProperties([],['id', 'name', 'position','isEnabled', 'widthSurcharge', 'heightSurcharge', 'pieceSurcharge']) +
        generateProperties(['fabricShadeDayNightAdditional'],['name'],true),
    onCreated: fetchFabricShadeDayNight,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_day_night_fabric_shade_day_night_additional_entry_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_day_night_fabric_shade_day_night_additional_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_day_night_fabric_shade_day_night_additional_entry_create"],
    route: {
      path: "/fabric_shade_day_night_fabric_shade_day_night_additional_entries/:fabricShadeDayNightId/create",
      name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_list",
          params: { fabricShadeDayNightId: compData.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Dodatki dla wersji "${compData.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightFabricShadeDayNightAdditionalEntryForm,
    onCreated: fetchFabricShadeDayNight,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_list",
      params: { fabricShadeDayNightId: compData.fabricShadeDayNight.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_day_night_fabric_shade_day_night_additional_entry_update"],
    route: {
      path: "/fabric_shade_day_night_fabric_shade_day_night_additional_entries/:id/update",
      name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_update"
    },
    resource: "fabric_shade_day_night_fabric_shade_day_night_additional_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shade_day_nights_list" }),
        label: () => "Roletki dzień-noc"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_list",
          params: { fabricShadeDayNightId: compData.object.fabricShadeDayNight.id }
        }),
        label: ({ compData }) =>
          `Dodatki dla wersji "${compData.object.fabricShadeDayNight.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania osprzętu "${compData.object.fabricShadeDayNightAdditional.name}" z wersją "${compData.object.fabricShadeDayNight.name}"`
      }
    ],
    formComponent: FabricShadeDayNightFabricShadeDayNightAdditionalEntryForm,
    onCreated: fetchFabricShadeDayNight,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_day_night_fabric_shade_day_night_additional_entries_list",
      params: {
        fabricShadeDayNightId: compData.object.fabricShadeDayNight.id
      }
    })
  }
};
