<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="fabric_shade_global_accessory_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.heightSurcharge")

                            app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.widthSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Osprzęt" field="globalAccessory" :errors="errors")
                                resource-select(
                                    v-model="data.globalAccessory"
                                    resource="global_accessories"
                                    url="global_accessories"
                                    placeholder="Wybierz osprzęt"
                                    extra-props="&properties[]=symbol&order[name]=asc"
                                    :labels="['symbol']"
                                )
                            app-form-field(v-if="getFabricShadeId && getFabricShadeId > -1" label="Domyślna belka dolna" field="fabricShadeGlobalBalkEntry" :errors="errors")
                                resource-select(
                                  v-model="data.fabricShadeGlobalBalkEntry"
                                  :resource="'fabric_shades/'+getFabricShadeId + '/fabric_shade_global_balk_entries'"
                                  :url="'fabric_shades/'+getFabricShadeId + '/fabric_shade_global_balk_entries'"
                                  placeholder="Wybierz belkę"
                                  extra-props="&properties[globalBalk][]=symbol&properties[globalBalk][]=name&properties[globalBalk][]=id?order[globalBalk.name]=asc"
                                  :labels="[['globalBalk','symbol']]"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
//TO JEST KOLOR
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        skipCalculations: false,
        isEnabled: true,
        fabricShade: `/fabric_shades/${this.$route.params.fabricShadeId}`,
        globalAccessory: null,
        fabricShadeGlobalBalkEntry: null
      },
      fabricShadeId: this.$route.params.fabricShadeId,
      downloadedFabricShadeId: null
    };
  },
  computed: {
    getFabricShadeId() {
      if (!isNaN(this.fabricShadeId) && this.fabricShadeId) {
        return this.fabricShadeId;
      }
      return this.downloadedFabricShadeId;
    }
  },
  methods: {
    transformToModel(data) {
      if (typeof data.fabricShade !== "string") {
        this.model.fabricShadeGlobalBalkEntry =
          this.model.fabricShade["@id"] + this.model.fabricShadeGlobalBalkEntry;
      }
      return data;
    },
    transformToSubmit(data) {
      if (data.fabricShade && data.fabricShade["@id"]) {
        data.fabricShade = data.fabricShade["@id"];
      }
      if (data.globalAccessory && data.globalAccessory["@id"]) {
        data.globalAccessory = data.globalAccessory["@id"];
      }
      if (
        data.fabricShadeGlobalBalkEntry &&
        data.fabricShadeGlobalBalkEntry["@id"]
      ) {
        data.fabricShadeGlobalBalkEntry =
          data.fabricShadeGlobalBalkEntry["@id"];
      }
      const splited = data.fabricShadeGlobalBalkEntry.split("/");
      data.fabricShadeGlobalBalkEntry = `${splited[3]}/${splited[4]}`;
      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.id) {
      const { data } = await http.get(
        `/fabric_shade_global_accessory_entries/${this.$route.params.id}?properties[fabricShade][]=id`
      );
      if (data.fabricShade) {
        this.downloadedFabricShadeId = data.fabricShade.id;
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
