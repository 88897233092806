<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose")
    app-view-card(:title="title")
      template(slot="icon")
        b-button(@click="onClose")
          b-icon(icon="close" size="is-small")
      p(style="font-size: 18px; text-align: center; margin-bottom: 20px;")
        span Czas przekracza 8 godzin w tym dniu. Czy na pewno chcesz kontynuować?
      b-field(style="display: flex; justify-content: center;")
        b-button(type="is-primary" @click="saveData" class="is-large") Tak, zapisz
        b-button(type="outline-primary" @click="onClose" class="is-large") Nie, anuluj
</template>
<script>

import decimal from "../../../helpers/decimal";
import priceFormatter from "../../../helpers/priceFormatter";

export default {
  components: {  },
  props: {
    active: { type: Boolean, default: false, required: true },
    title: { type: String },
    functionToConfirm: {type: Function}
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  methods: {
    saveData(){
      this.functionToConfirm()
      this.onClose()
    },

    onClose() {
      this.isActive = false;
      this.$emit("close", this.order);
    },
    getDecimal(val) {
      return decimal(val);
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
  },
  watch: {
    active(value) {
      this.isActive = value;
    }
  }
};
</script>
