import router from "./../../router";
import Dashboard from "./views/Dashboard";

router.addRoute(
  {
    path: "/",
    name: "dashboard",
    component: Dashboard
  }
);
