import DeliveryMethodForm from "../components/DeliveryMethodForm";

export default {
  list: {
    sortable: true,
    permissions: ["delivery_method_read"],
    actions: [
      {
        label: "Nowa metoda dostawy",
        type: "is-primary",
        route: () => ({
          name: "delivery_methods_create"
        }),
        permissions: ["delivery_method_create"]
      }
    ],
    route: {
      path: "/delivery_methods/list",
      name: "delivery_methods_list"
    },
    breadcrumbs: [
      {
        label: () => "Metody dostawy"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywna",
        filter: "boolean"
      }
    ],
    resource: "delivery_methods",
    url: () =>
      `/delivery_methods?properties[]=id&properties[]=name&properties[]=isEnabled`,
    rowActions: [
      {
        action: "edit",
        permissions: ["delivery_method_update"]
      },
      {
        action: "remove",
        permissions: ["delivery_method_delete"]
      }
    ]
  },
  create: {
    permissions: ["delivery_method_create"],
    route: {
      path: "/delivery_methods/create",
      name: "delivery_methods_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "delivery_methods_list" }),
        label: () => "Metody dostawy"
      },
      {
        label: () => `Nowa metoda dostawy`
      }
    ],
    formComponent: DeliveryMethodForm,
    listRoute: () => ({
      name: "delivery_methods_list"
    }),
    updateRoute: ({ object }) => ({
      name: "delivery_methods_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["delivery_method_update"],
    route: {
      path: "/delivery_methods/:id/update",
      name: "delivery_methods_update"
    },
    resource: "delivery_methods",
    breadcrumbs: [
      {
        route: () => ({ name: "delivery_methods_list" }),
        label: () => "Metody dostawy"
      },
      {
        label: ({ compData }) =>
          `Edycja metody dostawy "${compData.object.name}"`
      }
    ],
    formComponent: DeliveryMethodForm,
    listRoute: () => ({
      name: "delivery_methods_list"
    })
  }
};
