import PleatGlobalRunnerEntryForm from "../components/PleatGlobalRunnerEntryForm";
import decimal from "../../../helpers/decimal";
import fetchPleat from "@/modules/pleats/helpers/fetchPleat";

export default {
  list: {
    permissions: ["pleat_global_runner_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_global_runner_entries_create",
          params: {
            pleatId: route.params.pleatId
          }
        }),
        permissions: ["pleat_global_runner_entry_create"]
      }
    ],
    route: {
      path: "/pleat_global_runner_entries/:pleatId/list",
      name: "pleat_global_runner_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        label: ({ compData }) =>
            `Wiązania prowadnic dla wersji "${compData.pleat.name}"`
      }
    ],
    fields: [
      {
        field: "globalRunner.symbol",
        type: "text",
        label: "Symbol prowadnicy",
        filter: "text"
      },
      {
        field: "globalRunner.name",
        type: "text",
        label: "Nazwa prowadnicy",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata długości [netto]",
        format: decimal
      }
    ],
    resource: "pleat_global_runner_entries",
    url: ({ route }) =>
        `/pleats/${route.params.pleatId}/pleat_global_runner_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=lengthSurcharge&properties[globalRunner][]=name&properties[globalRunner][]=symbol`,
    onCreated: fetchPleat,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_global_runner_entry_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_global_runner_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_global_runner_entry_create"],
    route: {
      path: "/pleat_global_runner_entries/:pleatId/create",
      name: "pleat_global_runner_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_global_runner_entries_list",
          params: { pleatId: compData.pleat.id }
        }),
        label: ({ compData }) =>
            `Prowadnice dla wersji "${compData.pleat.name}"`
      },
      {
        label: ({ compData }) =>
            `Nowe wiązanie dla wersji "${compData.pleat.name}"`
      }
    ],
    formComponent: PleatGlobalRunnerEntryForm,
    onCreated: fetchPleat,
    listRoute: ({ compData }) => ({
      name: "pleat_global_runner_entries_list",
      params: { pleatId: compData.pleat.id }
    }),
    updateRoute: ({ object }) => ({
      name: "pleat_global_runner_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["pleat_global_runner_entry_update"],
    route: {
      path: "/pleat_global_runner_entries/:id/update",
      name: "pleat_global_runner_entries_update"
    },
    resource: "pleat_global_runner_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_global_runner_entries_list",
          params: { pleatId: compData.object.pleat.id }
        }),
        label: ({ compData }) =>
            `Prowadnice dla wersji "${compData.object.pleat.name}"`
      },
      {
        label: ({ compData }) =>
            `Edycja wiązania prowadnicy "${compData.object.globalRunner.name}" z wersją "${compData.object.pleat.name}"`
      }
    ],
    formComponent: PleatGlobalRunnerEntryForm,
    onCreated: fetchPleat,
    listRoute: ({ compData }) => ({
      name: "pleat_global_runner_entries_list",
      params: {
        pleatId: compData.object.pleat.id
      }
    })
  }
};
