import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "fabricShadeGroup", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `fabric_shade_groups/${route.params.fabricShadeGroupId}?properties[]=id&properties[]=name&properties[]=position`
    );
    compData.fabricShadeGroup = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować grupy cenowej", "danger");
  }
};
