import DetailUnitForm from "../components/DetailUnitForm.vue";

export default {
  list: {
    permissions: ["detail_unit_read"],
    sortable: true,

    route: {
      path: "/detail_units",
      name: "detail_units_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_units_list" }),
        label: () => "Jednostki detali"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
    ],
    resource: "detail_units",
    url: ({ route }) =>
      `/detail_units`,
  },
  create: {
    permissions: ["detail_unit_create"],
    route: {
      path: "/detail_units/create",
      name: "detail_units_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_units_list" }),
        label: () => "Jednostki detali"
      },

      {
        label: ({ compData }) =>
          `Nowy detal`
      }
    ],
    formComponent: DetailUnitForm,
    listRoute: ({ compData }) => ({
      name: "detail_units_list"
    }),
    updateRoute: ({ object }) => ({
      name: "detail_units_update",
      params: { id: object.id }
    }),
  },
  update: {
    permissions: ["detail_unit_update"],
    route: {
      path: "/detail_units/:id/update",
      name: "detail_units_update"
    },
    resource: "detail_units",
    breadcrumbs: [
      {
        route: () => ({ name: "detail_units_list" }),
        label: () => "Jednostki w detalach"
      },
      {
        label: ({ compData }) =>
          `Edycja jednostki"${compData.object.name}" `
      }
    ],
    formComponent: DetailUnitForm,
    listRoute: ({ compData }) => ({
      name: "detail_units_list"
    })
  }
};
