<template lang="pug">
  div.container.is-fullhd
    template
      table.table.is-bordered.has-cells-centered.is-narrow.is-fullwidth.print-table
        thead
          tr
            th(colspan=7)  Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
          tr
            th(rowspan=2) Lp.
            th(rowspan=2) Nazwa
            th(rowspan=2) Symbol.
            th(rowspan=2) Jednostka
            th(rowspan=2) Sztuk.
            th(rowspan=2) Ilość op.
            th(rowspan=2) J.m.
        tbody
          tr(v-for="(item, index) in orderItems(object)")
            td(style="width:20px;") {{ index + 1 }}
            // nazwa detalu
            td {{ prop(item.detail, "name") }}
            // symbol
            td {{ prop(item.detail, "symbol") }}
            // jednostka
            td(style="width:20px;") {{ prop(item.detailUnit, "name") }}
            // ilość
            td(style="width:20px;") {{ item.quantity }}
            // ilość sztuk
            td(style="width:20px;") {{calculatePieces(item)}}
            // skrot jm.
            td(style="width:20px;") {{ unitName(item) }}
</template>

<script>
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import decimal from "../../../helpers/decimal";
import unitName from "../../../helpers/unitName";
import calculatePieces from "../../../helpers/calculatePieces";

export default {
  name: "DetailOrderItemProductionTemplate",
  props: ['object'],
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
    unitName: (item) => unitName(item),
    calculatePieces: (item) => calculatePieces(item, 'mm')
  }
}
</script>
