<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="global_pipes" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata" field="externalShutterPieceSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.externalShutterPieceSurcharge")

                            app-form-field(field="externalShutterIsEnabled" :errors="errors")
                                b-checkbox(v-model="data.externalShutterIsEnabled") Aktywny

                            app-form-field(label="Maksymalna Szerokość (mm)" field="externalShutterMaxWidth" :errors="errors")
                                app-form-length(v-model="data.externalShutterMaxWidth")

                            app-form-field(label="Masa kg/mb" field="externalShutterWeight" :errors="errors")
                                app-form-weight(unit="kg" v-model="data.externalShutterWeight" )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        externalShutterIsEnabled: true,
        externalShutterWeight: 0,
        externalShutterMaxWidth: 0,
        externalShutterPieceSurcharge: 0,
      }
    };
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      return data;
    }
  }
};
</script>
