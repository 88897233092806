<template lang="pug">
  table.table.is-fullwidth.is-bordered
    RollMosquitoNetOrderItemProductionTemplate(:object="order")

</template>

<script>
import RollMosquitoNetOrderItemProductionTemplate from "../../orders/components/RollMosquitoNetOrderItemProductionTemplate";

export default {
  components: {RollMosquitoNetOrderItemProductionTemplate},
  name: "ProductionOrderPreviewRollMosquitoNetTable",
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>