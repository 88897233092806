<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="global_fixings" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Magazyn (symbol)" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            app-form-field(label="Numer" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                app-form-time-integer(v-model="data.productionTime")

                            app-form-field(label="Opis" field="description" :errors="errors")
                                b-input(v-model="data.description" type="textarea")

                        .column
                            app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                                b-input(v-model="data.message" type="textarea")

                            app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

                            app-form-field(label="Miniaturka (proporcja 4:3)" field="thumbnail" :errors="errors")
                                app-form-file(v-model="files.thumbnail" :image="data.thumbnail" @removed="data.thumbnail = {}")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        symbol: "",
        name: "",
        description: "",
        picture: "",
        thumbnail: "",
        message: "",
        productionTime: 0
      },
      files: {
        picture: {},
        thumbnail: {}
      },
      messageHelp:
        "Gdy użytkownik wybierze to mocowanie, zostanie mu wyświetlony podany komunikat."
    };
  },
  methods: {
    transformToModel(data) {
      return data;
    }
  }
};
</script>
