<template lang="pug">
    span(title="Kliknij by przełączyć")
        a(
            class="button is-small"
            :class="classes"
            href=""
            @click.prevent="toggle"
        ) {{ value ? "TAK" : "NIE" }}
</template>
<script>
export default {
  props: {
    resource: { type: String, required: true },
    object: { type: Object, required: true },
    field: { type: String, required: true }
  },
  computed: {
    value() {
      return this.object[this.field];
    },
    classes() {
      return { "is-success": this.value, "is-danger": !this.value };
    },
    url() {
      return `${this.resource}/${this.object.id}`;
    }
  },
  methods: {
    async toggle() {
      const value = !this.object[this.field];
      this.object[this.field] = value;
      try {
        let { data } = await this.$http.put(this.url, {
          [this.field]: value
        });
        this.$emit("success", data);
        this.$notify("Przełączono element");
      } catch (e) {
        this.$notify("Nie udało się przełączyć elementu", "danger");
        this.object[this.field] = !this.object[this.field];
      }
    }
  }
};
</script>
