import centimeter from "../../../helpers/centimeter";
import decimal from "../../../helpers/decimal";
import generateProperties from "../../../helpers/generateProperties";
import PleatFabricForm from "../components/PleatFabricForm.vue";
import fetchPleatGroup from "../helpers/fetchPleatGroup";

export default {
  list: {
    permissions: ["pleat_fabric_read"],
    actions: [
      {
        label: "Nowa kolekcja",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_fabrics_create",
          params: {
            pleatGroupId: route.params.pleatGroupId
          }
        }),
        permissions: ["pleat_fabric_create"]
      }
    ],
    route: {
      path: "/pleat_fabrics/:pleatGroupId/list",
      name: "pleat_fabrics_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) =>
            `Kolekcje tkanin dla grupy ${compData.pleatGroup.name}`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      { field: "description", type: "text", label: "Opis", filter: "text" },
      { field: "tannerCorrection", type: "text", label: "Korekta garbików", filter: "text" },
      {
        field: "rollWidth",
        type: "text",
        label: "Szerokość rolki [mm]",
      },
      {
        field: "rollLength",
        type: "text",
        label: "Długość rolki [mm]",
      },
      {
        type: "link",
        label: "Kolory",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "pleat_fabric_colors_list",
            params: { pleatFabricId: row.id }
          })
        }
      }
    ],
    resource: "pleat_fabrics",
    url: ({ route }) =>
        `/pleat_groups/${route.params.pleatGroupId}/pleat_fabrics?${generateProperties(
            ["id", "name", "symbol", "isEnabled", "number", "description", "hasTurnablePattern", "isTurnable", "hasPattern", "rollWidth", "rollLength", "measurementInstructions"],
            [],
            true
        )}`,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_fabrics_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_fabric_delete"]
      }
    ],
    onCreated: fetchPleatGroup
  },
  create: {
    permissions: ["pleat_fabric_create"],
    route: {
      path: "/pleat_fabrics/:pleatGroupId/create",
      name: "pleat_fabrics_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        label: ({ compData }) =>
            `Nowa kolekcja dla grupy "${compData.pleatGroup.name}"`
      }
    ],
    formComponent: PleatFabricForm,
    listRoute: ({ compData }) => ({
      name: "pleat_fabrics_list",
      params: { pleatGroupId: compData.pleatGroup.id }
    }),
    updateRoute: ({ object }) => ({
      name: "pleat_fabrics_update",
      params: { id: object.id }
    }),
    onCreated: fetchPleatGroup
  },
  update: {
    permissions: ["pleat_fabrics_update"],
    route: {
      path: "/pleat_fabrics/:id/update",
      name: "pleat_fabrics_update"
    },
    resource: "pleat_fabrics",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: () => ({ name: "pleat_groups_list" }),
        label: () => "Grupy cenowe"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_fabrics_list",
          params: { pleatGroupId: compData.object.pleatGroup.id }
        }),
        label: ({ compData }) =>
            `Kolekcje tkanin dla grupy "${compData.object.pleatGroup.name}"`
      },
      {
        label: ({ compData }) =>
            `Edycja tkaniny "${compData.object.name}" dla grupy "${compData.object.pleatGroup.name}"`
      }
    ],
    formComponent: PleatFabricForm,
    listRoute: ({ compData }) => ({
      name: "pleat_fabrics_list",
      params: { pleatGroupId: compData.object.pleatGroup.id }
    })
  }
};
