<template lang="pug">
    div
        .has-text-centered.has-font-25 Pamiętaj, jednostka w cennikach to milimetry
        .is-clearfix.has-margin-bottom
            button.button.is-pulled-right(@click.prevent="clear") Wyczyść
        app-form(resource="fabric_shade_day_night_price_lists" v-bind="props" v-on="listeners" :transform-to-submit="transformToSubmit")
            template(#default)
                table.table.price-list-table
                    tbody
                        tr(v-for="(_, row) in rows" :key="row")
                            td(v-for="(_, col) in cols" :key="col" class="has-no-padding-left")
                                div(v-if="row === 0 && col === 0")
                                    b-input(size="is-small" @paste.native.prevent="onPaste" placeholder="Wklej tutaj treść z Excela")
                                div(v-else)
                                    app-form-length(
                                        classes="has-text-weight-bold has-background-gray"
                                        v-if="row === 0 || col === 0"
                                        size="is-small"
                                        :value="getValueFor(row, col)"
                                        @input="setValueFor(row, col, $event)"
                                        unit="mm"
                                    )
                                    app-form-decimal-integer(
                                        classes=""
                                        v-else
                                        size="is-small"
                                        :value="getValueFor(row, col)"
                                        @input="setValueFor(row, col, $event)"
                                    )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import onPaste from "../../../helpers/onPaste";

export default {
  mixins: [form],
  methods: {
    getValueFor(row, col) {
      const item = this.collection.find(
        item => item.row === row && item.col === col
      );
      if (item) {
        return item.value;
      }
      return null;
    },
    setValueFor(row, col, value) {
      const item = this.collection.find(
        item => item.row === row && item.col === col
      );
      if (item) {
        item.value = value;
      } else {
        this.collection.push({
          row,
          col,
          value
        });
      }
    },
    transformToSubmit(data) {
      data.fabricShadeDayNight = data.fabricShadeDayNight["@id"];
      data.fabricShadeDayNightPriceListEntries = data.fabricShadeDayNightPriceListEntries.filter(
        entry =>
          entry.row !== null && entry.col !== null && entry.value !== null
      );
      return data;
    },
    clear() {
      if (confirm("Czy na pewno wyczyścić wszystkie pola?")) {
        this.object.fabricShadeDayNightPriceListEntries = [];
        this.model.fabricShadeDayNightPriceListEntries = [];
      }
    },
    modifyCollection(collection) {
      collection.forEach((item) => {
        if (item.col === 0 || item.row === 0) {
          item.value /= 10;
        }
      });
    },
    onPaste(event) {
      const value = event.clipboardData.getData("text");
      let collection = onPaste(value);
      this.modifyCollection(collection);
      this.object.fabricShadeDayNightPriceListEntries = collection;
      this.model.fabricShadeDayNightPriceListEntries = collection;
    }
  },
  computed: {
    model() {
      return {
        fabricShadeDayNight: {},
        fabricShadeDayNightPriceListEntries: []
      };
    },
    length() {
      return this.fabricShadeDayNight.priceListRows * this.fabricShadeDayNight.priceListCols;
    },
    fabricShadeDayNight() {
      return this.object.fabricShadeDayNight || {};
    },
    rows() {
      return this.fabricShadeDayNight.priceListRows;
    },
    cols() {
      return this.fabricShadeDayNight.priceListCols;
    },
    collection() {
      return this.object.fabricShadeDayNightPriceListEntries;
    }
  }
};
</script>
