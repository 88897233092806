<template lang="pug">
    .columns.is-gapless
        .column
            b-select(
                :loading="loading"
                :value="internalValue"
                @input="onInput"
                :placeholder="placeholder"
                :disabled="disabled"
            )
                option(
                  v-if="nullable"
                  :value="null"
                ) Brak
                option(
                    v-for="item in filteredList"
                    :key="item.id"
                    :disabled="showOnlyEnabled && !item.isEnabled"
                    :value="`/${resource}/${item.id}`"
                ) {{ getLabel(item)}}
        .column.is-narrow
            button.button(@click.prevent="refresh" type="button") Odśwież
            span(v-if="addForm")
                button.button(@click.prevent="showForm" type="button") Dodaj
                .modal.is-active(v-show="showAddForm")
                    .modal-background
                    .modal-card
                        header.modal-card-head
                            p.modal-card-title {{ addFormTitle }}
                            button.delete(aria-label='close' type="button" @click="closeForm")
                        section.modal-card-body
                            component(:is="addForm" @success="success")

</template>
<script>
import resourceSelectMixin from "../mixins/resourceSelectMixin";

export default {
  mixins: [resourceSelectMixin],
  props: {
    value: { default: null },
    label: { type: String, default: "name" },
    nullable: { type: Boolean, default: false },
    labels: { type: Array, default: null },
    separator: { type: String, default: " - " },
    fullObject: { type: Boolean, default: false },
    showOnlyEnabled: { type: Boolean, default: false },
  },
  computed: {
    /**
     * Returns list of items that should be displayed in the select
     * showOnlyEnabled - if true, only enabled items will be displayed and elements that are already selected
     * @returns {*[]}
     */
    filteredList() {
      if (this.showOnlyEnabled) {
        if(this.value && this.value["@id"]) {
          return this.list.filter(item => item.isEnabled || this.value['@id'] === item["@id"]);
        }
      }
      return this.list;
    },
    internalValue() {
      if (this.value && this.value["@id"]) {
        return this.value["@id"];
      }
      return this.value;
    }
  },
  methods: {
    onInput(event) {
      if (!this.fullObject) {
        this.$emit("input", event);
      } else {
        const data = this.list.find(obj => obj["@id"] === event);
        this.$emit("input", data || event);
      }
    },
    async success(item, data) {
      this.closeForm();
      await this.fetchData();
      this.$emit("input", data);
    },
    getLabel(item) {
      let output = "";
      if (this.labels) {
        this.labels.forEach((label, idx) => {
          if (Array.isArray(label)) {
            let nestedLabel = null;
            for (let subLabel of label) {
              if (nestedLabel === null) {
                nestedLabel = item[subLabel];
              } else {
                nestedLabel = nestedLabel[subLabel];
              }
              if (!nestedLabel) {
                break;
              }
            }
            if (nestedLabel) {
              output += nestedLabel;
            }
          } else if (item[label]) {
            if (idx !== 0) {
              output += this.separator;
            }
            output += item[label];
          }
        });
      } else if (this.label) {
        output += item[this.label];
      }
      return output;
    }
  }
};
</script>
