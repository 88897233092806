<template lang="pug">
  tr
    // liczba porządkowa
    td
      div
        .th-in {{ (index + 1 ) }}
    //nazwa wersji
    td {{ prop(item.externalShutter, "name") }}
    // profil pancerza
    td {{ prop(item.externalShutterPriceList, "name",  "externalShutterArmor") }}
    // kolor pancerza
    td {{ prop(item.externalShutterArmorExternalShutterArmorColorEntry, "name",  "externalShutterArmorColor") }}
    // kolor skrzynki zewnetrzny
    td {{ prop(item.temporaryExternalBoxColor, "name",  "fabricShadeColor") }}
    // kolor skrzynki wewnetrzny
    td {{ prop(item.temporaryInternalBoxColor, "name",  "fabricShadeColor") }}
    // kolor prowadnic
    td {{ prop(item.externalShutterRunnerFabricShadeColorEntry, "name",  "fabricShadeColor") }}
    // kolor listwy dolnej
    td {{ prop(item.externalShutterArmorFabricShadeColorEntry, "name",  "fabricShadeColor") }}

</template>
<script>
import property from "../../../helpers/propFunc";

export default {
  props: {
    index: Number,
    item: Object,
  },
  methods: {
    prop: (object, field, extraField) => property(object, field, extraField),
  },

};
</script>
