import { render, staticRenderFns } from "./PleatOrderItemPreviewTemplate.vue?vue&type=template&id=ebbc7fca&scoped=true&lang=pug"
import script from "./PleatOrderItemPreviewTemplate.vue?vue&type=script&lang=js"
export * from "./PleatOrderItemPreviewTemplate.vue?vue&type=script&lang=js"
import style0 from "./PleatOrderItemPreviewTemplate.vue?vue&type=style&index=0&id=ebbc7fca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebbc7fca",
  null
  
)

export default component.exports