<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="download_sections" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")
              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny
            .column
</template>

<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        instruction: "",
        isEnabled: true
      },
      files: {
        picture: {},
      },
    };
  },
  methods: {
    transformToModel(data) {
      return data;
    }
  }
};
</script>
