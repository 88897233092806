<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose")
    app-view-card(:title=`title`)
      template(slot="icon")
        b-button(
          @click="onClose"
        )
          b-icon(icon="close" size="is-small")
      b-field(label="Notatka" v-if="order && field === 'adminNotes' ")
          b-input(v-model="order.adminNotes" type="textarea")
      app-form-field(v-if='field === "customPurchaseGrossValue"' :label="`Wartość (Policzona brutto: `+ formatPriceValue(getDecimal(order.totalPurchaseGrossValue) +`)`)" field="percentDiscountAutomation")
          app-form-decimal(v-model="order.customPurchaseGrossValue" style="width: 25%")
      b-button(type="is-primary" @click="saveData") Zapisz
</template>

<script>

import http from "../../../http";
import simpleClone from "../../../helpers/simpleClone";
import notify from "../../../helpers/notify";
import OrderNumberCreator from "../../../helpers/orderNumberCreator";
import decimal from "../../../helpers/decimal";
import priceFormatter from "../../../helpers/priceFormatter";

export default {
  components: {  },
  props: {
    active: { type: Boolean, default: false, required: true },
    title: { type: String },
    field: { type: String },
    order: { default: null, required: false }
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  methods: {
    updateNotes(){
      const order = simpleClone(this.order)
      const data = {adminNotes: this.order.adminNotes}

      if(this.order.adminNotes === null)return;

      http.put(`/orders/${order.id}`, data).then(() => {
        notify(`Dodano notatkę do zamówienia nr: ${OrderNumberCreator(order.id)}`);
      }).catch(() =>{
        notify(`Nie udało się dodać notatki zamówienia nr: ${OrderNumberCreator(order.id)}`, "danger");
      })

    },
    updateCustomPurchaseGrossValue(){
      const order = simpleClone(this.order)
      const data = {customPurchaseGrossValue: this.order.customPurchaseGrossValue}


      if(this.order.customPurchaseGrossValue === null) return;

      http.put(`/orders/${order.id}`, data).then(() => {
        notify(`Zmieniono cenę zamówienia nr: ${OrderNumberCreator(order.id)}`);
      }).catch(() =>{
        notify(`Nie udało się zmienić ceny zamówienia nr: ${OrderNumberCreator(order.id)}`, "danger");
      })

    },
    saveData(){
      if(this.field === 'customPurchaseGrossValue' )this.updateCustomPurchaseGrossValue()
      else if(this.field === 'adminNotes')this.updateNotes()

      this.onClose()
    },

    onClose() {
      this.isActive = false;
      this.$emit("close", this.order);
    },
    getDecimal(val) {
      return decimal(val);
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
  },
  watch: {
    active(value) {
      this.isActive = value;
    }
  }
};
</script>
