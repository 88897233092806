<template lang="pug">
.table-container.mt-2
  table.table.is-bordered.has-cells-centered.is-narrow.long-table.is-fullwidth
    thead
      tr
        th(colspan=19) Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
      tr
        th(rowspan=2) Lp.
        th(rowspan=2) Grupa
        th(rowspan=2) Wersja
        th(rowspan=2) Tkanina
        th(rowspan=2) Kolor
        th(colspan=2) Szer. [cm]
        th(rowspan=2) Wys. [cm]
        th(rowspan=2) Szt.
        th(rowspan=2) Osprzęt
        th(rowspan=2) Belka
        th(rowspan=2) Str.
        th(rowspan=2) Moc.
        th(rowspan=2) Ster.
        th(rowspan=2) Napęd
        th(rowspan=2) Obr.
        th(rowspan=2) Prow.
        th(rowspan=2) Dod.
        th(rowspan=2) Netto
      tr
        th rol.
        th mat.
    tbody
      tr(v-for="(item, index) in orderItems(object)")
        td {{ index + 1 }}
        td {{ prop(item.fabricShadeDayNightGroup, 'position') }}
        td {{ prop(item.fabricShadeDayNight, 'name') }}
        td {{ prop(item.fabricDayNight, 'name') }}
        td {{ prop(item.fabricColorDayNight, 'name') }}
        td {{ item.width / 10 }}
        td {{ item.fabricWidth / 10 }}
        td {{ item.height / 10 }}
        td {{ item.quantity }}
        td {{ prop(item.fabricShadeDayNightGlobalAccessoryEntry, 'name', 'globalAccessory') }}
        td {{ prop(item.fabricShadeDayNightGlobalBalkEntry, 'name', 'globalBalk') }}
        td {{ item.driveSide }}
        td {{ prop(item.fabricShadeDayNightGlobalFixingEntry, 'name', 'globalFixing') }}
        td {{ prop(item.globalDriveControl, 'symbol') }}
        td {{ prop(item.globalDrive, 'symbol') }}
        td {{ item.hasTurnableFabric ? 'TAK' : 'NIE' }}
        td {{ prop(item.fabricShadeDayNightGlobalRunnerEntry, 'name', 'globalRunner') }}
        td
          p(v-if="!item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries.length") Brak
          p(v-for="(entry, indexProtection) in item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries") {{ prop(entry, "name", "fabricShadeDayNightAdditional") }}
        td {{ formatPriceValue(getDecimal(item.netPurchaseValue)) }}
    tfoot
      tr
        td.has-text-right(colspan=8) Razem ilość:
        td {{ object.quantity }}
        td.has-text-right(colspan=9) Wartość razem netto:
        td {{ formatPriceValue(getDecimal(object.netPurchaseValueBeforeDiscount)) }}

  automation-order-items(:items='object.automationOrderItems')

</template>
<script>

import decimal from "@/helpers/decimal";
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import AutomationOrderItems from "./AutomationOrderItems";

export default {
  props: ['object'],
  components:{AutomationOrderItems},
  methods: {

    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
  }
};
</script>
