<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="slide_mosquito_net_global_net_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata za sztukę" field="pieceSurcharge" :errors="errors")
                               app-form-decimal(v-model="data.pieceSurcharge")

                            app-form-field(label="Dopłata długości zł / m" field="lengthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.lengthSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                        .column
                            app-form-field(label="Maksymalna szerokość (mm)" field="maxWidth" :errors="errors")
                                app-form-length(v-model="data.maxWidth" unit="mm")

                            app-form-field(label="Maksymalna wysokość (mm)" field="maxHeight" :errors="errors")
                                app-form-length(v-model="data.maxHeight" unit="mm")

                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Siatki" field="globalNet" :errors="errors")
                                resource-select(
                                    v-model="data.globalNet"
                                    resource="global_nets"
                                    url="global_nets"
                                    placeholder="Wybierz siatkę"
                                    extra-props="&properties[]=symbol&order[name]=asc"
                                    :labels="['symbol']"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        skipCalculations: false,
        isEnabled: true,
        pieceSurcharge: 0,
        lengthSurcharge: 0,
        maxWidth: 0,
        maxHeight: 0,
        slideMosquitoNet: `/slide_mosquito_nets/${this.$route.params.slideMosquitoNetId}`,
        globalNet: null,
      },
      slideMosquitoNetId: this.$route.params.slideMosquitoNetId,
    };
  },
  methods: {
    transformToModel(data) {
      if (data.slideMosquitoNet && data.slideMosquitoNet["@id"]) {
        data.slideMosquitoNet = data.slideMosquitoNet["@id"];
      }
      if (data.globalNet && data.globalNet["@id"]) {
        data.globalNet = data.globalNet["@id"];
      }
      return data;
    },
    transformToSubmit(data) {
      if (data.slideMosquitoNet && data.slideMosquitoNet["@id"]) {
        data.slideMosquitoNet = data.slideMosquitoNet["@id"];
      }
      if (data.globalNet && data.globalNet["@id"]) {
        data.globalNet = data.globalNet["@id"];
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
