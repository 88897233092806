import Vue from "vue";
import generateProperties from "../../../helpers/generateProperties";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "subCompany", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(
      `sub_companies/${route.params.subCompanyId}?` +
        generateProperties([],["receptionDays","hasInvoicingByProducer", "hasShipmentToSubCompany", "name", "id"]) +
        generateProperties(['company'],['id', 'name'],true)
    );
    compData.subCompany = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować pododbiorcy", "danger");
  }
};
