import detailCategoryForm from "../components/DetailCategoryForm.vue";

export default {
  list: {
    permissions: ["detail_category_read"],
    sortable: true,
    actions: [
      {
        label: "Nowa kategoria",
        type: "is-primary",
        route: ({ route }) => ({
          name: "detail_categories_create",

        }),
        permissions: ["detail_category_create"]
      }
    ],
    route: {
      path: "/detail_categories",
      name: "detail_categories_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_categories_list" }),
        label: () => "Kategorie detali"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }],
    resource: "detail_categories",
    url: ({ route }) =>
      `/detail_categories`,
    rowActions: [
      {
        action: "edit",
        permissions: ["detail_category_update"]
      },
      {
        action: "remove",
        permissions: ["detail_category_delete"]
      }
    ],
  },
  create: {
    permissions: ["detail_category_create"],
    route: {
      path: "/detail_categories/create",
      name: "detail_categories_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_categories_list" }),
        label: () => "Kategorie detali"
      },

      {
        label: ({ compData }) =>
          `Nowa kategoria`
      }
    ],
    formComponent: detailCategoryForm,
    listRoute: ({ compData }) => ({
      name: "detail_categories_list"
    }),
    updateRoute: ({ object }) => ({
      name: "detail_categories_update",
      params: { id: object.id }
    }),
  },
  update: {
    permissions: ["detail_category_update"],
    route: {
      path: "/detail_categories/:id/update",
      name: "detail_categories_update"
    },
    resource: "detail_categories",
    breadcrumbs: [
      {
        route: () => ({ name: "detail_categories_list" }),
        label: () => "Kategorie w detalach"
      },
      {
        label: ({ compData }) =>
          `Edycja kotegorii"${compData.object.name}" `
      }
    ],
    formComponent: detailCategoryForm,
    listRoute: ({ compData }) => ({
      name: "detail_categories_list"
    })
  }
};
