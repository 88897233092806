import DetailForm from "../components/DetailForm.vue";
import decimal from "../../../helpers/decimal";
import http from "../../../http";

export default {
  list: {
    permissions: ["detail_read"],
    sortable: true,
    actions: [
      {
        label: "Nowy detal",
        type: "is-primary",
        route: ({ route }) => ({
          name: "details_create",
          params: {
            detailId: route.params.detailId
          }
        }),
        permissions: ["detail_create"]
      },
      {
        label: "Export",
        type: "is-info",
        route: () => ({
          name: "details_export"
        }),
        permissions: ["details_export"]
      },
      {
        label: "Import",
        type: "is-info",
        route: () => ({
          name: "details_import"
        }),
        permissions: ["details_import"]
      }
    ],
    route: {
      path: "/details",
      name: "details_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "details_list" }),
        label: () => "Detale"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "netWholesalePriceForUnit", type: "text", label: "Cena j.", format: decimal },
      { field: "netWholesalePriceForPackage", type: "text", label: "Cena za opk.", format: decimal },
      {
        field: "detailSystems.detailProducts.name",
        type: "virtual-hide",
        disabledHead: true,
        label: "Produkt",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/detail_products")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        },
        value: (h, row) => {
          return ''
        },
      },{
        field: "detailSystems.name",
        type: "virtual",
        label: "System",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/detail_systems")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        },
        value: (h, row) => {
          return row.detailSystems.length
        },
      },
      {
        field: "detailCategories.name",
        type: "virtual",
        label: "Kategoria",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/detail_categories")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        },
        value: (h, row) => {
          return row.detailCategories.length
        },
      },
      {
        field: "detailColors.name",
        type: "virtual",
        label: "Kolor",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/detail_colors")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        },
        value: (h, row) => {
          return row.detailColors.length
        },
      },

      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },

    ],
    resource: "details",
    url: ({ route }) =>
      `/details`,
    rowActions: [
      {
        action: "edit",
        permissions: ["detail_update"]
      },
      {
        action: "remove",
        permissions: ["detail_delete"]
      }
    ],
  },
  create: {
    permissions: ["detail_create"],
    route: {
      path: "/details/create",
      name: "details_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "details_list" }),
        label: () => "Detale"
      },

      {
        label: ({ compData }) =>
          `Nowy detal`
      }
    ],
    formComponent: DetailForm,
    listRoute: ({ compData }) => ({
      name: "details_list"
    }),
    updateRoute: ({ object }) => ({
      name: "details_update",
      params: { id: object.id }
    }),
  },
  update: {
    permissions: ["detail_update"],
    route: {
      path: "/details/:id/update",
      name: "details_update"
    },
    resource: "details",
    breadcrumbs: [
      {
        route: () => ({ name: "details_list" }),
        label: () => "Detale"
      },
      {
        label: ({ compData }) =>
          `Edycja detalu "${compData.object.name}" `
      }
    ],
    formComponent: DetailForm,
    listRoute: ({ compData }) => ({
      name: "details_list"
    })
  },
  export: {
    permissions: ["details_export"],
    route: {
      path: "/details/export",
      name: "details_export"
    },
  },
  import: {
    permissions: ["details_import"],
    route: {
      path: "/details/import",
      name: "details_import"
    },
  },
};
