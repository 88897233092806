import TraitColorForm from "../components/TraitColorForm";

export default {
  list: {
    sortable: true,
    permissions: ["trait_color_read"],
    actions: [
      {
        label: "Nowy kolor",
        type: "is-primary",
        route: () => ({
          name: "trait_colors_create"
        }),
        permissions: ["trait_color_create"]
      }
    ],
    route: {
      path: "/trait_colors/list",
      name: "trait_colors_list"
    },
    breadcrumbs: [
      {
        label: () => "Kolory"
      }
    ],
    fields: [{ field: "name", type: "text", label: "Nazwa", filter: "text" }],
    resource: "trait_colors",
    url: () => `/trait_colors?properties[]=id&properties[]=name`,
    rowActions: [
      {
        action: "edit",
        permissions: ["trait_color_update"]
      },
      {
        action: "remove",
        permissions: ["trait_color_delete"]
      }
    ]
  },
  create: {
    permissions: ["trait_color_create"],
    route: {
      path: "/trait_colors/create",
      name: "trait_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "trait_colors_list" }),
        label: () => "Kolory"
      },
      {
        label: () => `Nowy kolor`
      }
    ],
    formComponent: TraitColorForm,
    listRoute: () => ({
      name: "trait_colors_list"
    }),
    updateRoute: ({ object }) => ({
      name: "trait_colors_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["trait_color_update"],
    route: {
      path: "/trait_colors/:id/update",
      name: "trait_colors_update"
    },
    resource: "trait_colors",
    breadcrumbs: [
      {
        route: () => ({ name: "trait_colors_list" }),
        label: () => "Kolory"
      },
      {
        label: ({ compData }) => `Edycja koloru "${compData.object.name}"`
      }
    ],
    formComponent: TraitColorForm,
    listRoute: () => ({ name: "trait_colors_list" })
  }
};
