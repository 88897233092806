import CompaniesForm from "../components/CompanyForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
    list: {
        permissions: ["company_read"],
        sortField: "shortName",
        sortOrder: "asc",
        rowColorFunction: function (row) {
            // highlight new companies
            if (row.firstLoginDateTime == null) return "has-background-warning";

        },
        actions: [
            {
                label: "Nowy odbiorca",
                type: "is-primary",
                route: () => ({
                    name: "companies_create"
                }),
                permissions: ["company_create"]
            }
        ],
        route: {
            path: "/companies/list",
            name: "companies_list"
        },
        breadcrumbs: [
            {
                label: () => "Odbiorcy"
            }
        ],
        fields: [
            {
                field: "shortName",
                type: "text",
                label: "Nazwa skrócona",
                filter: "text"
            },
            {field: "taxId", type: "text", label: "NIP", filter: "text"},
            {
                field: "streetAndNumber",
                type: "text",
                label: "Ulica",
                filter: "text"
            },
            {field: "city", type: "text", label: "Miejscowość", filter: "text"},
            {field: "zipCode", type: "text", label: "Kod", filter: "text"},
            {field: "email", type: "text", label: "Email"},
            {field: "mobilePhone", type: "text", label: "Telefon 2"},
            {
                field: "isEnabled",
                type: "boolean",
                label: "Aktywny",
                filter: "boolean"
            },
            {
                field: "subCompanies",
                filter: "exists",
                type: "link",
                label: "Pododbiorcy",
                link: {
                    label: "Pokaż",
                    route: ({row}) => ({
                        name: "sub_companies_list",
                        params: {companyId: row.id}
                    })
                }
            },
            {
                field: "company_workers",
                filter: "none",
                type: "link",
                label: "Pracownicy",
                link: {
                    label: "Pokaż",
                    route: ({row}) => ({
                        name: "company_workers_list",
                        params: {companyId: row.id}
                    })
                }
            },
        ],
        resource: "companies",
        url: () =>
            `/companies?`
            + generateProperties(
                [],
                ['id', 'shortName', 'taxId', 'streetAndNumber', 'city', 'zipCode', 'mobilePhone', 'isEnabled', 'email', 'statistic', 'firstLoginDateTime', 'isVisible', 'needSupportWithAddLogo']
            )
        ,
        rowActions: [
            {
                action: "custom",
                icon: "account",
                title: "Zamówienia",
                permissions: ["order_read"],
                route: ({row}) => ({
                    name: "orders_list",
                    query: {
                        in: "new accepted production",
                        companiesShortNames: row.shortName
                    },
                })
            },
            {
                action: "custom",
                icon: "chart-bar",
                title: "Statystyka",
                permissions: ["statistic_read"],
                route: ({row}) => ({
                    name: "statistics_list",
                    params: {
                        id: row.statistic?.id,
                        company: row
                    },
                    query: {
                        shortName: row.shortName
                    }
                })
            },
            {
                action: "edit",
                permissions: ["company_update"]
            },
            {
                action: "remove",
                permissions: ["company_delete"]
            }
        ]
    },
    create: {
        permissions: ["company_create"],
        route: {
            path: "/companies/create",
            name: "companies_create"
        },
        breadcrumbs: [
            {
                route: () => ({name: "companies_list"}),
                label: () => "Odbiorcy"
            },
            {
                label: () => `Nowy odbiorca`
            }
        ],
        formComponent: CompaniesForm,
        listRoute: () => ({
            name: "companies_list"
        }),
        updateRoute: ({object}) => ({
            name: "companies_update",
            params: {id: object.id}
        })
    },
    update: {
        permissions: ["company_update"],
        route: {
            path: "/companies/:id/update",
            name: "companies_update"
        },
        resource: "companies",
        breadcrumbs: [
            {
                route: () => ({name: "companies_list"}),
                label: () => "Odbiorcy"
            },
            {
                label: ({compData}) => `Edycja odbiorcy "${compData.object.name}"`
            }
        ],
        formComponent: CompaniesForm,
        listRoute: () => ({name: "companies_list"})
    }
};
