<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="fabric_shade_global_runner_entries" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata długości zł / m" field="lengthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.lengthSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Prowadnica" field="globalRunner" :errors="errors")
                                resource-select(
                                    v-model="data.globalRunner"
                                    resource="global_runners"
                                    url="global_runners"
                                    placeholder="Wybierz prowadnice"
                                    extra-props="&properties[]=symbol&order[name]=asc"
                                    :labels="['symbol']"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        lengthSurcharge: 0,
        skipCalculations: false,
        isEnabled: true,
        fabricShade: `/fabric_shades/${this.$route.params.fabricShadeId}`,
        globalRunner: null
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      if (data.fabricShade["@id"]) {
        data.fabricShade = data.fabricShade["@id"];
      }
      if (data.globalRunner["@id"]) {
        data.globalRunner = data.globalRunner["@id"];
      }
      return data;
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
