<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-narrow
      thead
        tr
          th L.p
          th Element
          th Ilość
          th Cena
        tr

      tbody
        template(v-for="(item, index) in items")
          automation-order-item(
            :item="item"
            :index="index"
          )
      tr
        td(colspan=1 style="border: 0;")
        td Suma
        td {{ sumParameterInAutomationOrderItems("quantity") }}
        td {{ getDecimalValue(sumParameterInAutomationOrderItems("netPurchaseValue"))}}

</template>
<script>

import decimal from "@/helpers/decimal";
import AutomationOrderItem from "./AutomationOrderItem";
import priceFormatter from "../../../helpers/priceFormatter";

export default {
  components: { AutomationOrderItem },
  props: ['items'],
  computed: {
    // ...mapGetters(["hideWholeSalePrice"]),
    // ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    // ...mapGetters("externalShutters", ["order"]),
    // ...mapGetters("common", ["formatWeight"])
  },
  methods: {
    sumParameterInAutomationOrderItems: function(parameter)  {
      return this.items.reduce((total, automationOrderItem) => automationOrderItem[parameter] + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
