<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="order_reclamations" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel" :custom-validation="validation")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(:label="'Ogólne'")
          .columns
            .column.is-6
              .column
                app-form-field(:label="'Temat zgłoszenia'" field="name" :errors="errors" required)
                  b-input(v-model="data.name" :disabled="!disabled(data.status)" )
              .column
                app-form-field(v-if="!data.orderNumberFromOutside" :label="'Numer zamówienia'" field="order" :errors="errors" )
                  b-input(v-model="data.order" :disabled="!disabled(data.status)" @change.native="checkExistOrder(data, errors)")
                app-form-field(v-else :label="'Numer zamówienia'" field="orderNumber" :errors="errors" )
                  b-input(v-model="data.orderNumber" :disabled="!disabled(data.status)" )
                router-link(
                  v-if="!data.orderNumberFromOutside && currentOrderExist && orderData && orderData['@type'] !== 'FixedOrder'"
                  :to="{name: 'order_show', params: { id: data.order, printType: 'production'}}"
                ) Link do zamówienia
                p(v-if="!data.orderNumberFromOutside && currentOrderExist && orderData && orderData['@type'] === 'FixedOrder'") FX {{ orderData && orderData['number'] ? 'nr: '+ orderData['number'] : ''  }} (brak podglądu)
              .column
                app-form-field(field="orderNumberFromOutside" :errors="errors")
                  b-checkbox(v-model="data.orderNumberFromOutside") Numer zamówienia z zewnątrz

              .column
                app-form-field(:label="'Odbiorca'" field="company" :errors="errors" required)
                  b-autocomplete(
                    v-model="searchCustomerInput"
                    :data="customers"
                    placeholder="Wybierz klienta"
                    field="shortName"
                    :loading="isFetching"
                    @typing="getAsyncData"
                    @select="option => {onCustomerChanged(option, data);}"
                  )
                    template(slot-scope="props") {{ props.option.shortName }}
              .column
                app-form-field(:label="'Kategoria'" field="productCode" :errors="errors")
                  b-input(v-model="data.productCode" disabled="true" )
              .column
                router-link( :to="routePath" class="button" :disabled="!currentOrderExist || !data.createdAt" ) Podglad reklamacyjny
            .column.is-6
              .columns.is-multiline
                .column
                  app-form-field(label="Status" field="status" :errors="errors")
                    b-select(v-model="data.status")
                      option(value="new") Nowy
                      option(value="accepted") Przyjęty
                      option(value="closed") Zamknięty
                .column
                  app-form-field(label="Decyzja" field="decision" :errors="errors")
                    b-select(v-model="data.decision")
                      option(value="accepted") Uznana
                      option(value="rejected") Odrzucona
                .column
                  app-form-field(label="Miesiąc" field="month" :errors="errors")
                    b-select(v-model="data.month")
                      option(value="0") Styczeń
                      option(value="1") Luty
                      option(value="2") Marzec
                      option(value="3") Kwiecień
                      option(value="4") Maj
                      option(value="5") Czerwiec
                      option(value="6") Lipiec
                      option(value="7") Sierpień
                      option(value="8") Wrzesień
                      option(value="9") Październik
                      option(value="10") Listopad
                      option(value="11") Grudzień
                .column
                  app-form-field(label="Zgłoszono przez" field="reportedBy" :errors="errors")
                    b-select(v-model="data.reportedBy" :disabled="data.reportedBy === 'SWIZ'")
                      option(value="SWIZ" disabled) SWIZ
                      option(value="mail") E-mail
                      option(value="telephone") Telefonicznie
                      option(value="inperson") Osobiście
                .column
                  app-form-field(label="Przyczyna" field="reason" :errors="errors")
                    b-select(v-model="data.reason" )
                      option(value="neglect") Zaniedbanie
                      option(value="material_defect") Wada materiałów
                      option(value="logistic") Transport
                      option(value="client_fault") Wina klienta
                      option(value="reclamation_provider") Reklamacja dostawcy
                      option(value="reclamation_service") Reklamacja serwisu
                .column
                  app-form-field(label="Koszt" field="cost" :errors="errors")
                    app-form-decimal(v-model="data.cost")

                .column.is-12
                  app-form-field(label="Krótki opis – pole tekstowe dla operatora" field="shortDescription" :errors="errors")
                    b-input(v-model="data.shortDescription" type="textarea")
                .column.is-12
                  app-form-field(label="Postępowanie" field="procedureDescription" :errors="errors")
                    b-input(v-model="data.procedureDescription" type="textarea")
                .column.is-12
                  app-form-field(field="consideredReclamation" label="Reklamację rozpatrzył" :errors="errors")
                    resource-select(
                      v-if="data"
                      v-model="data.consideredReclamation"
                      resource="admin_workers"
                      url="admin_workers"
                      show-only-enabled
                      placeholder="Wybierz pracownika"
                      :labels=`['firstName', 'lastName' ]`
                      separator=" "
                      extraProps="&properties[]=firstName&properties[]=lastName&properties[]=isEnabled"
                    )
                .column.is-12
                  app-form-field(label="Osoby odpowiedzialne (można dodać kilka)" field="responsiblePersons" :errors="errors")
                    b-autocomplete(
                      :disabled="data.reason !== 'neglect'"
                      :data="production_workers"
                      clear-on-select
                      placeholder="Wybierz pracowników"
                      :loading="isFetching"
                      :custom-formatter="function (option) { return option.firstName + ' ' + option.lastName; }"
                      @typing="getAsyncDataProductionWorkers"
                      @select="option => {onProductionWorkerChanged(option, data);}"
                    )
                  ul
                    li.has-padding-top-small(v-for="person in data.responsiblePersons")
                      span {{ person.firstName }} {{ person.lastName }}
                      span(v-if="!person.isEnabled").has-margin-left-3.has-text-danger (nieaktywny)
                      b-button.has-margin-left-3(size="is-small" @click.prevent="data.responsiblePersons = data.responsiblePersons.filter(p => p.id !== person.id)") Usuń
        b-tab-item(:label="'Korespondencja'")
          .columns(v-if=" object && object.orderReclamationMessages && object.orderReclamationMessages.length > 0")
            .divider
            .column
              .column(v-if="data.orderReclamationMessages.length === 0")
                b-notification(:closable="false") Brak wiadomości
              .columns(v-for="report in data.orderReclamationMessages")
                .column
                  .is-right(v-if="report.author.id !== userId")
                    b-message(
                      type="is-success"
                      :title="`${report.author.firstName} ${report.author.lastName}  ${report.createdAt}`"
                      :closable="false"
                    )
                      div(v-html="report.message")

                .column
                  .is-left(v-if="report.author.id === userId")
                    b-message(
                      type="is-info"
                      :title="`${report.author.firstName} ${report.author.lastName} ${report.createdAt}`"
                      :closable="false"
                    )
                      div(v-html="report.message")
            .divider
          .columns
            .column
              app-form-field(:label="'Wiadomość'" field="newMessage" :errors="errors" )
                app-form-editor(v-model="data.newMessage")
        b-tab-item(:label="'Pliki'")
          .column
            span Pliki przesłane przez klienta
          .columns.is-multiline
            .column(v-if="data.files.length === 0")
              b-notification(:closable="false") Brak plików
            .column(v-for="file in data.files" v-if="file.contentUrl").is-one-fifth
              a(
                class="button is-primary"
                :href="file.contentUrl"
                target="_blank"
                download
              ) Plik użytkownika
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import {mapGetters} from "vuex";
import simpleClone from "../../../helpers/simpleClone";
import http from "../../../http";
import orderNumberFlatter from "../../../helpers/orderNumberFlatter";
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import generateProperties from "../../../helpers/generateProperties";
import multipleResourceSelect from "../../common/components/MultipleResourceSelect";
import resourceSelect from "../../common/components/ResourceSelect";
import productTypeCodesByOrderCategory from "../../../helpers/productTypeCodesByOrderCategory";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  mixins: [form],
  components: {resourceSelect, multipleResourceSelect},
  data() {
    return {
      model: {
        name: "",
        orderReclamationMessages: [],
        orderNumberFromOutside: false,
        order: null,
        orderNumber: null,
        month: null,
        reportedBy: null,
        company: null,
        decision: null,
        shortDescription: "",
        procedureDescription: "",
        consideredReclamation: null,
        responsiblePersons: [],
        productCode: null,
        status: "new", //invisible
        files: [],
        reason: "",
        cost: 0,
        newMessage: "",
        createdAt: "", //not procesible
        updatedAt: "" //not procesible
      },
      currentOrderExist: false,
      searchCustomerInput: "",
      orderData: {},
      isFetching: false,
      customers: [],
      production_workers: [],
      files: {
        file: {}
      },
      routePath: {},
    };
  },
  methods: {
    disabled(id) {
      return id === null || isNaN(id);
    },
    removeError(field, errors) {
      errors = errors.splice(errors.findIndex(item => item.propertyPath !== field), 1)
    },
    checkExistOrder: debounce(async function (dataForm, errors) {
          const {data} = await http.get(`/orders?properties[]=id&properties[]=number&properties[]=category&number=${orderNumberFlatter(dataForm.order)}`);
          if (data["hydra:member"].length > 0) {
            this.removeError("order", errors)
            const firstOrder = data["hydra:member"][0];
            this.orderData = firstOrder;
            dataForm.order = orderNumberCreator(firstOrder.id);
            dataForm.productCode = firstOrder.category ? productTypeCodesByOrderCategory[firstOrder.category] : null;
            this.currentOrderExist = true
          } else {
            this.currentOrderExist = false
            errors.push({
              propertyPath: "order",
              message: "Zamówienie nie istnieje"
            });
          }

        }, 500
    ),
    getAsyncDataProductionWorkers: debounce(function (name) {
      if (!name.length) {
        this.production_workers = [];
        return;
      }
      this.isFetching = true;
      http
          .get(
              `/production_workers?` +
              generateProperties(
                  [],
                  [
                    "id",
                    "firstName",
                    "lastName",
                    "isEnabled"
                  ]
              ) +
              `&search=${name}&isEnabled=1`
          )
          .then(({data}) => {
            this.production_workers = [];
            this.production_workers = data["hydra:member"];
          })
          .catch(error => {
            this.production_workers = [];
            throw error;
          })
          .finally(() => {
            this.isFetching = false;
          });
    }, 500),
    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.customers = [];
        return;
      }
      this.isFetching = true;
      http
          .get(
              `/companies?` +
              generateProperties(
                  [],
                  [
                    "id",
                    "shortName",
                    "name",
                  ]
              ) +
              `&search=${name}&isEnabled=1`
          )
          .then(({data}) => {
            this.customers = [];
            this.customers = data["hydra:member"];
          })
          .catch(error => {
            this.customers = [];
            throw error;
          })
          .finally(() => {
            this.isFetching = false;
          });
    }, 500),
    onCustomerChanged(option, data) {
      if (!option) return;
      data.company = option

    },
    onProductionWorkerChanged(option, data) {
      if (!option) return;
      data.responsiblePersons.push(option)
    },

    validation(data, errors) {
      if (data.orderNumber) {
        const pattern = /^\d+$/;
        const isValid = pattern.test(data.orderNumber);

        if (!isValid) {
          errors.push({
            propertyPath: "orderNumber",
            message: "Niepoprawny format, proszę wprowadzić numer bez znaków specjalnych"
          });
        }
      }
    },

    transformToSubmit(formData) {
      delete formData.createdAt;
      delete formData.updatedAt;

      delete formData.files

      delete formData.productCode

      if(formData.orderNumberFromOutside)delete formData.order

      if (formData.order) formData.order = `/orders/${orderNumberFlatter(formData.order)}`;

      if(formData.responsiblePersons) formData.responsiblePersons = formData.responsiblePersons.map(person => `/production_workers/${person.id}`)

      return formData;
    },
    transformToModel(data) {
      if (this.$route.name.includes("update")) {
        let toSend = simpleClone(data);
        delete toSend.author;
        delete toSend.company;
        delete toSend.orderReclamationMessages;
        delete toSend.files;
        delete toSend.order;
        delete toSend.consideredReclamation;
        delete toSend.responsiblePersons;
        http.put(`/order_reclamations/${toSend.id}/read`, toSend);
      }
      data.newMessage = "";

      this.searchCustomerInput = data.company && data.company.shortName
      this.checkExistOrder(data, [])
      data.productCode = data.order.category ? productTypeCodesByOrderCategory[data.order.category] : null

      this.routePath = ({
        name: "order_reclamations_show",
        params: {id: data.order.id, printType: "production", shortDescription: data.shortDescription}
      })
      data.order = orderNumberCreator(data.order.number);

      return data;
    }
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
  }
};
</script>
