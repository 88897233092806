<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="fabric_shade_day_night_global_accessory_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.heightSurcharge")

                            app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.widthSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Osprzęt" field="globalAccessory" :errors="errors")
                                resource-select(
                                    v-model="data.globalAccessory"
                                    resource="global_accessories"
                                    url="global_accessories"
                                    placeholder="Wybierz osprzęt"
                                    extra-props="&properties[]=symbol&order[symbol]=asc"
                                    :labels="['symbol']"
                                )
                            app-form-field(v-if="getFabricShadeDayNightId && getFabricShadeDayNightId > -1" label="Domyślna belka dolna" field="fabricShadeDayNightGlobalBalkEntry" :errors="errors")
                                resource-select(
                                  v-model="data.fabricShadeDayNightGlobalBalkEntry"
                                  :resource="'fabric_shade_day_nights/'+getFabricShadeDayNightId + '/fabric_shade_day_night_global_balk_entries'"
                                  :url="'fabric_shade_day_nights/'+getFabricShadeDayNightId + '/fabric_shade_day_night_global_balk_entries'"
                                  placeholder="Wybierz belkę"
                                  extra-props="&properties[globalBalk][]=symbol&properties[globalBalk][]=name&properties[globalBalk][]=id&order[name]=asc"
                                  :labels="[['globalBalk','symbol']]"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
//TO JEST KOLOR
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        skipCalculations: false,
        isEnabled: true,
        fabricShadeDayNight: `/fabric_shade_day_nights/${this.$route.params.fabricShadeDayNightId}`,
        globalAccessory: null,
        fabricShadeDayNightGlobalBalkEntry: null
      },
      fabricShadeDayNightId: this.$route.params.fabricShadeDayNightId,
      downloadedFabricShadeDayNightId: null
    };
  },
  computed: {
    getFabricShadeDayNightId() {
      if (!isNaN(this.fabricShadeDayNightId) && this.fabricShadeDayNightId) {
        return this.fabricShadeDayNightId;
      }
      return this.downloadedFabricShadeDayNightId;
    }
  },
  methods: {
    transformToModel(data) {
      if (typeof data.fabricShadeDayNight !== "string") {
        this.model.fabricShadeDayNightGlobalBalkEntry =
          this.model.fabricShadeDayNight["@id"] + this.model.fabricShadeDayNightGlobalBalkEntry;
      }
      return data;
    },
    transformToSubmit(data) {
      if (data.fabricShadeDayNight && data.fabricShadeDayNight["@id"]) {
        data.fabricShadeDayNight = data.fabricShadeDayNight["@id"];
      }
      if (data.globalAccessory && data.globalAccessory["@id"]) {
        data.globalAccessory = data.globalAccessory["@id"];
      }
      if (
        data.fabricShadeDayNightGlobalBalkEntry &&
        data.fabricShadeDayNightGlobalBalkEntry["@id"]
      ) {
        data.fabricShadeDayNightGlobalBalkEntry =
          data.fabricShaDayNightdeGlobalBalkEntry["@id"];
      }
      const splited = data.fabricShadeDayNightGlobalBalkEntry.split("/");
      data.fabricShadeDayNightGlobalBalkEntry = `${splited[3]}/${splited[4]}`;
      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.id) {
      const { data } = await http.get(
        `/fabric_shade_day_night_global_accessory_entries/${this.$route.params.id}?properties[fabricShadeDayNight][]=id`
      );
      if (data.fabricShadeDayNight) {
        this.downloadedFabricShadeDayNightId = data.fabricShadeDayNight.id;
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
