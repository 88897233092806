import PleatMosquitoNetGlobalAccessoryEntryForm from "../../pleat_mosquito_nets/components/PleatMosquitoNetGlobalAccessoryEntryForm.vue";
import decimal from "../../../helpers/decimal";
import fetchPleatMosquitoNet from "../helpers/fetchPleatMosquitoNet";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["global_accessories_pleat_mosquito_net_global_accessory_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_mosquito_net_global_accessory_entries_create",
          params: {
            pleatMosquitoNetId: route.params.pleatMosquitoNetId
          }
        }),
        permissions: ["pleat_mosquito_net_global_accessory_create"]
      }
    ],
    route: {
      path: "/pleat_mosquito_net_global_accessory_entries/:pleatMosquitoNetId/list",
      name: "pleat_mosquito_net_global_accessory_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleat_mosquito_nets_list" }),
        label: () => "Moskitiery plisowane"
      },
      {
        label: ({ compData }) =>
          `Wiązania osprzętów dla wersji "${compData.pleatMosquitoNet.name}"`
      }
    ],
    fields: [
      {
        field: "globalAccessory.name",
        type: "text",
        label: "Nazwa osprzętu",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "heightSurcharge",
        type: "text",
        label: "Dopłata wysokości [netto]",
        format: decimal
      },
    ],
    resource: "pleat_mosquito_net_global_accessory_entries",
    url: ({ route }) =>
      `/pleat_mosquito_nets/${route.params.pleatMosquitoNetId}/pleat_mosquito_net_global_accessory_entries?` +
        generateProperties([],['id', 'name', 'position','isEnabled', 'widthSurcharge', 'heightSurcharge']) +
        generateProperties(['globalAccessory'],['id','name'],true),
    onCreated: fetchPleatMosquitoNet,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_mosquito_net_global_accessory_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_mosquito_net_global_accessory_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_mosquito_net_global_accessory_create"],
    route: {
      path: "/pleat_mosquito_net_global_accessory_entries/:pleatMosquitoNetId/create",
      name: "pleat_mosquito_net_global_accessory_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleat_mosquito_nets_list" }),
        label: () => "Moskitiery plisowane"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_mosquito_net_global_accessory_entries_list",
          params: { pleatMosquitoNetId: compData.pleatMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `Osprzęt dla wersji "${compData.pleatMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.pleatMosquitoNet.name}"`
      }
    ],
    formComponent: PleatMosquitoNetGlobalAccessoryEntryForm,
    onCreated: fetchPleatMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "pleat_mosquito_net_global_accessory_entries_list",
      params: { pleatMosquitoNetId: compData.pleatMosquitoNet.id }
    }),
    updateRoute: ({ object , compData}) => ({
      name: "pleat_mosquito_net_global_accessory_entries_update",
      params: {
        id: object.id,
        pleatMosquitoNetId: compData.pleatMosquitoNet.id
      }
    })
  },
  update: {
    permissions: ["pleat_mosquito_net_global_accessory_entry_update"],
    route: {
      path: "/pleat_mosquito_net_global_accessory_entries/:id/update",
      name: "pleat_mosquito_net_global_accessory_entries_update"
    },
    resource: "pleat_mosquito_net_global_accessory_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "pleat_mosquito_nets_list" }),
        label: () => "Moskitiery plisowane"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_mosquito_net_global_accessory_entries_list",
          params: { pleatMosquitoNetId: compData.object.pleatMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `Osprzęt dla wersji "${compData.object.pleatMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania osprzętu "${compData.object.globalAccessory.name}" z wersją "${compData.object.pleatMosquitoNet.name}"`
      }
    ],
    formComponent: PleatMosquitoNetGlobalAccessoryEntryForm,
    onCreated: fetchPleatMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "pleat_mosquito_net_global_accessory_entries_list",
      params: {
        pleatMosquitoNetId: compData.object.pleatMosquitoNet.id
      }
    })
  }
};
