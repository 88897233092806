import DoorMosquitoNetForm from "../components/DoorMosquitoNetForm.vue";
import generateProperties from "../../../helpers/generateProperties";
import centimeter from "../../../helpers/centimeter";

export default {
    list: {
        permissions: ["door_mosquito_net_read"],
        actions: [
            {
                label: "Nowa wersja",
                type: "is-primary",
                route: () => ({
                    name: "door_mosquito_nets_create"
                }),
                permissions: ["door_mosquito_net_create"]
            }
        ],
        route: {
            path: "/door_mosquito_nets/list",
            name: "door_mosquito_nets_list"
        },
        sortable: true,
        breadcrumbs: [
            {
                label: () => "Moskitiery drzwiowe"
            }
        ],
        fields: [
            { field: "symbol", type: "text", label: "Symbol", filter: "text" },
            { field: "name", type: "text", label: "Nazwa", filter: "text" },
            {
                field: "isEnabled",
                type: "boolean",
                label: "Widoczny",
                filter: "boolean"
            },
            {
                field: "priceListType",
                type: "text",
                label: "Rodzaj cennika",
                filter: "select",
                filterOptions: [
                    { label: "Wszystkie", value: null },
                    { label: "Tabela", value: "tabela" },
                    { label: "Cena za m2", value: "m2" }
                ]
            },
            {
                field: "minWidth",
                type: "text",
                label: "Minimalna szerokość (cm)",
                format: centimeter
            },
            {
                field: "maxWidth",
                type: "text",
                label: "Maksymalna szerokość (cm)",
                format: centimeter
            },
            {
                type: "link",
                label: "Osprzęt",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "door_mosquito_net_global_accessory_entries_list",
                        params: { doorMosquitoNetId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Dodatki",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "door_mosquito_net_door_mosquito_net_additional_entries_list",
                        params: { doorMosquitoNetId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Cenniki",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "door_mosquito_net_price_lists_list",
                        params: { doorMosquitoNetId: row.id }
                    })
                }
            },
            {
                type: "link",
                label: "Kolory siatek",
                link: {
                    label: "Pokaż",
                    route: ({ row }) => ({
                        name: "door_mosquito_net_global_net_entries_list",
                        params: { doorMosquitoNetId: row.id }
                    })
                }
            },
        ],
        resource: "door_mosquito_nets",
        url: () =>
            `/door_mosquito_nets?` + generateProperties([],['id', 'name', 'position', 'symbol', 'isEnabled', 'priceListType', 'minWidth', 'maxWidth']),
        rowActions: [
            {
                action: "edit",
                permissions: ["door_mosquito_net_update"]
            },
            {
                action: "remove",
                permissions: ["door_mosquito_net_delete"]
            }
        ]
    },
    create: {
        permissions: ["door_mosquito_net_create"],
        route: {
            path: "/door_mosquito_nets/create",
            name: "door_mosquito_nets_create"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "door_mosquito_nets_list" }),
                label: () => "Moskitiery drzwiowe"
            },
            {
                label: () => `Nowa wersja`
            }
        ],
        formComponent: DoorMosquitoNetForm,
        listRoute: () => ({ name: "door_mosquito_nets_list" }),
        updateRoute: ({ object }) => ({
            name: "door_mosquito_nets_update",
            params: { id: object.id }
        })
    },
    update: {
        permissions: ["door_mosquito_net_update"],
        route: {
            path: "/door_mosquito_nets/:id/update",
            name: "door_mosquito_nets_update"
        },
        resource: "door_mosquito_nets",
        resourceProperties: generateProperties(
            [],
            [
                "id",
                "name",
                "priceListType",
                "position",
                "symbol",
                "isEnabled",
                "isEnabledForShop",
                "productionTime",
                "canBeSentInParts",
                "assemblySurcharge",
                "productionHeightFrameCut",
                "productionWidthFrameCut",
                "measurementInstruction",
                "message",
                "hasCrossbar",
                "crossbarMessage",
                "defaultInstallationValue",
                "minWidth",
                "maxWidth",
                "minHeight",
                "maxHeight",
                "priceListCols",
                "priceListRows",
                "picture",
                "surveyPicture",
                "mechanismPicture",
                "thumbnailForPriceList",
                "profilePictureForShop",
                "heightFieldPictureForShop",
                "widthFieldPictureForShop",
                "companyInstallationValues",
                "notesForPriceListPL",
                "notesForPriceListCZ",
                "notesForPriceListEN",
                "notesForPriceListDE",
                "notesForPriceListFR",
                "notesForPriceListSE",
                "notesForPriceListIT",
                "notesForPriceListSI",
                "notesForPriceListRU",
                "notesForPriceListES",
                "notesForPriceListNL",
                "surveyDescriptionPL",
                "surveyDescriptionCZ",
                "surveyDescriptionEN",
                "surveyDescriptionDE",
                "surveyDescriptionFR",
                "surveyDescriptionSE",
                "surveyDescriptionIT",
                "surveyDescriptionSI",
                "surveyDescriptionRU",
                "surveyDescriptionES",
                "surveyDescriptionNL",
                "extendedTitlePL",
                "extendedTitleCZ",
                "extendedTitleEN",
                "extendedTitleDE",
                "extendedTitleFR",
                "extendedTitleSE",
                "extendedTitleIT",
                "extendedTitleSI",
                "extendedTitleRU",
                "extendedTitleES",
                "extendedTitleNL",
                "descriptionForShopPL",
                "descriptionForShopCZ",
                "descriptionForShopEN",
                "descriptionForShopDE",
                "descriptionForShopFR",
                "descriptionForShopSE",
                "descriptionForShopIT",
                "descriptionForShopSI",
                "descriptionForShopRU",
                "descriptionForShopES",
                "descriptionForShopNL",
            ],
            false
        ),
        breadcrumbs: [
            {
                route: () => ({ name: "door_mosquito_nets_list" }),
                label: () => "Moskitiery drzwiowe"
            },
            {
                label: ({ compData }) => `Edycja wersji "${compData.object.name}"`
            }
        ],
        formComponent: DoorMosquitoNetForm,
        listRoute: () => ({ name: "door_mosquito_nets_list" })
    }
};
