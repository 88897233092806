export default function (status) {

    const map = {
        "new": "Nowy",
        "accepted": "Przyjęty",
        "production" : 'Produkcja',
        "closed": "Zamknięty",
        "valuation": "Wycena",
        "complete": "Zakończony",
        "draft": "Roboczy",
    };

    return map[status] || status;
}