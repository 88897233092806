import fetchRollMosquitoNet from "../helpers/fetchRollMosquitoNet";
import RollMosquitoNetPriceListForm from "../components/RollMosquitoNetPriceListForm.vue";
import generateProperties from "../../../helpers/generateProperties";

export default {
    list: {
        permissions: ["roll_mosquito_net_price_list_read"],
        route: {
            path: "/roll_mosquito_net_price_lists/:rollMosquitoNetId/list",
            name: "roll_mosquito_net_price_lists_list"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "roll_mosquito_nets_list" }),
                label: () => "Moskitiery zwijane"
            },
            {
                route: ({ compData }) =>  compData && compData.object && compData.object.rollMosquitoNet ? {
                    name: "roll_mosquito_net_price_lists_list",
                    params: { rollMosquitoNetId: compData.object.rollMosquitoNet.id }
                } : {
                    name: "roll_mosquito_net_price_lists_list",
                    params: { rollMosquitoNetId: 1}
                },
                label: ({ compData }) =>
                    compData && compData.object && compData.object.rollMosquitoNet ? `Cennik bazowy dla wersji "${compData.object.rollMosquitoNet.name}"` : ''
            },
        ],
        fields: [
            {
                field: "rollMosquitoNet.name",
                type: "text",
                label: "Nazwa moskitiery",
            },
            {
                field: "rollMosquitoNet.symbol",
                type: "text",
                label: "Kod moskitiety",
            }
        ],
        resource: "roll_mosquito_net_price_lists",
        url: ({ route }) =>
            `/roll_mosquito_nets/${route.params.rollMosquitoNetId}/roll_mosquito_net_price_lists?`
        +generateProperties([],['id'],false)
        +generateProperties(['rollMosquitoNet'],['id', 'name', 'symbol'],true)
        ,
        onCreated: fetchRollMosquitoNet,
        rowActions: [
            {
                action: "edit",
                permissions: ["roll_mosquito_net_price_list_update"]
            }
        ]
    },
    update: {
        permissions: ["roll_mosquito_net_price_list_update"],
        route: {
            path: "/roll_mosquito_net_price_lists/:id/update",
            name: "roll_mosquito_net_price_lists_update"
        },
        resource: "roll_mosquito_net_price_lists",
        breadcrumbs: [
            {
                route: () => ({ name: "roll_mosquito_nets_list" }),
                label: () => "Moskitiery Zwijane"
            },
            {
                route: ({ compData }) => ({
                    name: "roll_mosquito_net_price_lists_list",
                    params: { rollMosquitoNetId: compData.object.rollMosquitoNet.id }
                }),
                label: ({ compData }) =>
                    `Cennik bazowy dla wersji "${compData.object.rollMosquitoNet.name}"`
            },
        ],
        onCreated: fetchRollMosquitoNet,
        formComponent: RollMosquitoNetPriceListForm,
        listRoute: ({ compData }) => ({
            name: "roll_mosquito_net_price_lists_list",
            params: {
                rollMosquitoNetId: compData.object.rollMosquitoNet.id
            }
        })
    }
};
