import ExternalShutterRunnerForm from "../components/ExternalShutterRunnerForm";
import generateProperties from "../../../helpers/generateProperties";
import kilogram from "../../../helpers/kilogram";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_runner_read"],
    actions: [
      {
        label: "Nowa prowadnica",
        type: "is-primary",
        route: () => ({
          name: "external_shutter_runners_create"
        }),
        permissions: ["external_shutter_runner_create"]
      }
    ],
    route: {
      path: "/external_shutter_runners/list",
      name: "external_shutter_runners_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Prowadnice"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata za mb",
        format: decimal
      },
      {
        field: "weight",
        type: "text",
        label: "Masa kg/mb",
        format: kilogram
      },
      {
        field: "isRunnerUnderMosquitoNet",
        type: "boolean",
        label: "Prowadnica pod moskitiere",
        filter: "boolean"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Kolory",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_runner_fabric_shade_color_entries_list",
            params: { externalShutterRunnerId: row.id }
          })
        }
      }
    ],
    resource: "external_shutter_runners",
    url: () =>
      `/external_shutter_runners?` +
      generateProperties(
        [],
        [
          "id",
          "name",
          "symbol",
          "position",
          "isEnabled",
          "lengthSurcharge",
          "weight",
          "isRunnerUnderMosquitoNet",
          "externalShutterArmors",
          "externalShutterDrillHoles"
        ]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_runner_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_runner_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_runner_create"],
    route: {
      path: "/external_shutter_runners/create",
      name: "external_shutter_runners_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_runners_list" }),
        label: () => "Prowadnice"
      },
      {
        label: () => `Nowa prowadnica`
      }
    ],
    formComponent: ExternalShutterRunnerForm,
    listRoute: () => ({ name: "external_shutter_runners_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_runners_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_runner_update"],
    route: {
      path: "/external_shutter_runners/:id/update",
      name: "external_shutter_runners_update"
    },
    resource: "external_shutter_runners",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "symbol",
        "position",
        "isEnabled",
        "lengthSurcharge",
        "weight",
        "isRunnerUnderMosquitoNet",
        "externalShutterArmors",
        "externalShutterDrillHoles",
        "sectionLeftPicture",
        "sectionRightPicture"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_runners_list" }),
        label: () => "Prowadnice"
      },
      {
        label: ({ compData }) => `Edycja prowadnicy "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterRunnerForm,
    listRoute: () => ({ name: "external_shutter_runners_list" })
  }
};
