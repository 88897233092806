import crud from "../../crud";

import pleats from "./crud/pleats";
import pleatFixingTypes from "./crud/pleatFixingTypes";
import pleatPleatFixingTypeEntries from "./crud/pleatPleatFixingTypeEntries";
import pleatRemoteControlTypes from "./crud/pleatRemoteControlTypes";
import pleatPleatRemoteControlTypeEntries from "./crud/pleatPleatRemoteControlTypeEntries";
import pleatAdditionalStrings from "./crud/pleatAdditionalStrings";
import pleatGlobalAccessoryEntries from "./crud/pleatGlobalAccessoryEntries";
import pleatGlobalRunnerEntries from "./crud/pleatGlobalRunnerEntries";
import pleatPriceLists from "./crud/pleatPriceLists";
import pleatGroups from "./crud/pleatGroups";
import pleatFabrics from "./crud/pleatFabrics";
import pleatFabricColors from "./crud/pleatFabricColors";
import threadColors from "./crud/threadColors";
import pleatPleatAdditionalEntries from "./crud/pleatPleatAdditionalEntries";
import pleatAdditionals from "./crud/pleatAdditionals";

crud(pleats);
crud(pleatFixingTypes);
crud(pleatPleatFixingTypeEntries);
crud(pleatRemoteControlTypes);
crud(pleatPleatRemoteControlTypeEntries);
crud(pleatAdditionalStrings);
crud(pleatGlobalAccessoryEntries);
crud(pleatGlobalRunnerEntries)
crud(pleatPleatAdditionalEntries)
crud(pleatPriceLists)
crud(pleatGroups)
crud(pleatFabrics)
crud(pleatFabricColors)
crud(threadColors)
crud(pleatAdditionals)