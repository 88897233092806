export default {
  RTK: "fabric_shade",
  RZW: "external_shutter",
  RDN: "fabric_shade_day_night",
  AUT: "automation",
  PLI: "pleat",
  DET: "detail",
  MSQ: "frame_mosquito_net",
  MZW: "roll_mosquito_net",
  MPL: "pleat_mosquito_net",
  MDR: "door_mosquito_net",
  MPR: "slide_mosquito_net",
};