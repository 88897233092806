import GlobalAccessoryForm from "../components/GlobalAccessoryForm";

export default {
  list: {
    permissions: ["global_accessory_read"],
    actions: [
      {
        label: "Nowy osprzęt",
        type: "is-primary",
        route: () => ({
          name: "global_accessories_create"
        }),
        permissions: ["global_accessory_create"]
      }
    ],
    route: {
      path: "/global_accessories/list",
      name: "global_accessories_list"
    },
    sortable: false,
    sortField:"name",
    sortOrder:'asc',
    breadcrumbs: [
      {
        label: () => `Osprzęt`
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" }
    ],
    resource: "global_accessories",
    url: () =>
      `/global_accessories?properties[]=id&properties[]=name&properties[]=symbol&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_accessory_update"]
      },
      {
        action: "remove",
        permissions: ["global_accessory_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_accessory_create"],
    route: {
      path: "/global_accessories/create",
      name: "global_accessories_create"
    },
    breadcrumbs: [
      {
        label: () => `Nowy osprzęt  dla wesji`
      }
    ],
    formComponent: GlobalAccessoryForm,
    listRoute: () => ({
      name: "global_accessories_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_accessories_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_accessory_update"],
    route: {
      path: "/global_accessories/:id/update",
      name: "global_accessories_update"
    },
    resource: "global_accessories",
    breadcrumbs: [
      {
        route: () => ({ name: "global_accessories_list" }),
        label: () => "Osprzęt"
      },
      {
        label: ({ compData }) => `Edycja osprzętu "${compData.object.name}"`
      }
    ],
    formComponent: GlobalAccessoryForm,
    listRoute: () => ({
      name: "global_accessories_list"
    })
  }
};
