export default {
  fabric_shade: "RTK",
  external_shutter: "RZW",
  fabric_shade_day_night: "RDN",
  pleat: "PLI",
  automation: "AUT",
  detail: "DET",
  frame_mosquito_net: "MSQ",
  roll_mosquito_net: "MZW",
  slide_mosquito_net: "MPR",
  door_mosquito_net: "MDR",
  pleat_mosquito_net: "MPL"
};
