import ExternalShutterDrillHoleForm from "../components/ExternalShutterDrillHoleForm";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_drill_hole_read"],
    actions: [
      {
        label: "Nowy nawiert",
        type: "is-primary",
        route: () => ({
          name: "external_shutter_drill_holes_create"
        }),
        permissions: ["external_shutter_drill_hole_create"]
      }
    ],
    route: {
      path: "/external_shutter_drill_holes/list",
      name: "external_shutter_drill_holes_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Nawierty"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za sztukę prowadnicy",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_drill_holes",
    url: () =>
      `/external_shutter_drill_holes?` +
      generateProperties(
        [],
        ["id", "name", "isEnabled", "pieceSurcharge", "position"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_drill_hole_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_drill_hole_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_drill_hole_create"],
    route: {
      path: "/external_shutter_drill_holes/create",
      name: "external_shutter_drill_holes_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_drill_holes_list" }),
        label: () => "Nawierty"
      },
      {
        label: () => `Nowy nawiert`
      }
    ],
    formComponent: ExternalShutterDrillHoleForm,
    listRoute: () => ({ name: "external_shutter_drill_holes_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_drill_holes_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_drill_hole_update"],
    route: {
      path: "/external_shutter_drill_holes/:id/update",
      name: "external_shutter_drill_holes_update"
    },
    resource: "external_shutter_drill_holes",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "isEnabled",
        "pieceSurcharge",
        "position",
        "sectionLeftPicture",
        "sectionRightPicture"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_drill_holes_list" }),
        label: () => "Nawierty"
      },
      {
        label: ({ compData }) => `Edycja nawiertu "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterDrillHoleForm,
    listRoute: () => ({ name: "external_shutter_drill_holes_list" })
  }
};
