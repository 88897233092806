<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="production_lines" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 Aby dodać linię produkcyjną lub zedytować typ produktu przejdź do zakładki <router-link to="/product_types/list">Zarządzanie -> Typy produktów</router-link>
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
                app-form-field(label="Typ produkut" field="productType.name" :errors="errors")
                  b-input(v-model="data.productType.name" disabled)

                app-form-field(label="Linia" field="name" :errors="errors")
                  b-input(v-model="data.name" disabled)
            .column
                .columns
                  .column.is-one-fifth
                    app-form-field(label="Kolor" field="lineColor" :errors="errors" )
                      b-input(type="color" v-model="data.lineColor")
                  .column

                app-form-field(label="Monterzy" field="fitterProductionWorkers" :errors="errors" )
                  multiple-resource-select(
                        v-model="data.fitterProductionWorkers"
                        resource="production_workers"
                        url="production_workers"
                        placeholder="Wybierz pracowników"
                        :extra-props="getFittersQuery()"
                        :label="item => `${item.firstName} ${item.lastName}`"
                      )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect.vue";

export default {
  mixins: [form],
  components: { MultipleResourceSelect },
  data() {
    return {
      model: {
        name: "",
        lineColor: '#000000',
        productType: {
          name: ''
        },
        fitterProductionWorkers: []
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      delete data['productType'];
      delete data['name'];

      return data;
    },
    transformToModel(data) {
      return data;
    },
    getFittersQuery() {
      return `&properties[]=id&properties[]=firstName&properties[]=lastName&isEnabled=true`
    },
  }
};
</script>
