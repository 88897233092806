import Vue from "vue";
import generateProperties from "../../../helpers/generateProperties";

export default async ({ compData, route, $notify, $http }) => {
    Vue.set(compData, "rollMosquitoNet", {});
    try {
        compData.loading = true;
        let { data } = await $http.get(`roll_mosquito_nets/${route.params.rollMosquitoNetId}?` + generateProperties([],['id', 'name', 'code'],false));
        compData.rollMosquitoNet = data;
        compData.loading = false;
    } catch (e) {
        $notify("Nie udało się załadować moskitiery zwijanej", "danger");
    }
};
