import TraitFabricForm from "../components/TraitFabricForm";

export default {
  list: {
    sortable: true,
    permissions: ["trait_fabric_read"],
    actions: [
      {
        label: "Nowa cecha tkaniny",
        type: "is-primary",
        route: () => ({
          name: "trait_fabrics_create"
        }),
        permissions: ["trait_fabric_create"]
      }
    ],
    route: {
      path: "/trait_fabrics/list",
      name: "trait_fabrics_list"
    },
    breadcrumbs: [
      {
        label: () => "Cechy tkanin"
      }
    ],
    fields: [{ field: "name", type: "text", label: "Nazwa", filter: "text" }],
    resource: "trait_fabrics",
    url: () => `/trait_fabrics?properties[]=id&properties[]=name`,
    rowActions: [
      {
        action: "edit",
        permissions: ["trait_fabric_update"]
      },
      {
        action: "remove",
        permissions: ["trait_fabric_delete"]
      }
    ]
  },
  create: {
    permissions: ["trait_fabric_create"],
    route: {
      path: "/trait_fabrics/create",
      name: "trait_fabrics_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "trait_fabrics_list" }),
        label: () => "Cechy tkanin"
      },
      {
        label: () => `Nowa cecha tkaniny`
      }
    ],
    formComponent: TraitFabricForm,
    listRoute: () => ({
      name: "trait_fabrics_list"
    }),
    updateRoute: ({ object }) => ({
      name: "trait_fabrics_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["trait_fabric_update"],
    route: {
      path: "/trait_fabrics/:id/update",
      name: "trait_fabrics_update"
    },
    resource: "trait_fabrics",
    breadcrumbs: [
      {
        route: () => ({ name: "trait_fabrics_list" }),
        label: () => "Cechy tkanin"
      },
      {
        label: ({ compData }) => `Edycja cechy tkaniny "${compData.object.name}"`
      }
    ],
    formComponent: TraitFabricForm,
    listRoute: () => ({ name: "trait_fabrics_list" })
  }
};
