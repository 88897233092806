<template lang="pug">
  div.mt-2
    template
      .table-container
        table.table.is-bordered.has-cells-centered.is-narrow
          thead
            tr
              th(colspan=24)  Pozycje {{object.status === 'valuation'? "wyceny" : "zamówienia"}}
            tr
              th(rowspan=2) Lp.
              th(rowspan=2) Wer.
              th(rowspan=2) Mos.
              th(rowspan=2) Kom.
              th(rowspan=3) Szer. [mm]
              th(colspan=3).has-text-centered Wys.
              th(rowspan=2) Skrz. [mm]
              th(rowspan=2) Rura. [mm]
              th(rowspan=2) Panc  [mm]
              th(rowspan=2) Lam.
              th(rowspan=2) Masa [kg]
              th(rowspan=2) Rura
              th(rowspan=2) Napęd
              th(rowspan=2) Str. nap.
              th(rowspan=2) Prow. L
              th(rowspan=2) Prow. P
              th(rowspan=2) Naw. L
              th(rowspan=2) Naw. P
              th(rowspan=2) Adap.
              th(rowspan=2) Zab.
              th(rowspan=2) Szt
              th(rowspan=2) Miej. mon.

            tr
              th Skrz. [mm]
              th Prow. [mm]
              th Całk. [mm]

          tbody
            template(v-for="(item, index) in orderItems(object)")
              tr(v-for="(itemCombination, indexCombination) in item.externalShutterOrderItemCombinations")
                // liczba porządkowa
                td
                  div
                    .th-in {{ (index + 1 + iteratorAlphabet(item)[indexCombination]) }}
                //nazwa wersji (z drugiej tabelki)
                td {{ checkVirtualVersionNumberAfterSum(orderAfterSum(object), item) +1 }}
                // Moskitiera
                td {{ trueFalseToText(prop(itemCombination.hasMosquitoNet)) }}
                // wybrana kombinacja
                td {{ (item.selectedCombination === "single") ? "-" : item.selectedCombination   }}
                //szerokość kombinacji
                td {{ prop(itemCombination, "width") }}
                // nazwa skrzynki
                td {{ prop(itemCombination.externalShutterBox, "width") }}
                // wysokość prowadnic
                td {{ prop(itemCombination, "runnersHeight") }}
                // wysokość całkowita
                td {{ prop(itemCombination, "fullHeight") }}
                // Korekta skrzynki
                td(v-if="indexCombination === 0" :rowspan="item.externalShutterOrderItemCombinationsCount") {{ item.externalShutterOrderItemCombinations.reduce((sum, it)=>sum + it.width, 0) + prop(item.externalShutter, "productionBox") }}
                // Korekta rury
                td {{ prop(itemCombination, "width") + prop(item.externalShutter, "productionPipe") + prop(itemCombination,  "externalShutterGlobalDriveEntries", "globalDrive")[0].pipeCutCorrection }}
                // Korekta pancerza
                td(v-if="item.externalShutterOrderItemCombinationsCount === 1") {{ prop(itemCombination, "runnersHeight") + prop(item.externalShutter, "productionArmor1") }}
                td(v-if="item.externalShutterOrderItemCombinationsCount === 2 && indexCombination === 0") {{ prop(itemCombination, "runnersHeight") + prop(item.externalShutter, "productionArmor1") }}
                td(v-if="item.externalShutterOrderItemCombinationsCount === 2 && indexCombination === 1") {{ prop(itemCombination, "runnersHeight") + prop(item.externalShutter, "productionArmor2") }}
                td(v-if="item.externalShutterOrderItemCombinationsCount === 3 && indexCombination === 0") {{ prop(itemCombination, "runnersHeight") + prop(item.externalShutter, "productionArmor1") }}
                td(v-if="item.externalShutterOrderItemCombinationsCount === 3 && indexCombination === 1") {{ prop(itemCombination, "runnersHeight") + prop(item.externalShutter, "productionArmor3") }}
                td(v-if="item.externalShutterOrderItemCombinationsCount === 3 && indexCombination === 2") {{ prop(itemCombination, "runnersHeight") + prop(item.externalShutter, "productionArmor2") }}
                // lamele -  ilość piór w pancerzu, liczone tymczasowo wg wzoru Wysokość prowadnic + 1/2 wysokości skrzynki / wysokość piór
                td {{ Math.ceil((prop(itemCombination, "runnersHeight") + (0.5 * prop(itemCombination.externalShutterBox, "width"))) / (prop(item.externalShutterPriceList, "lamelHeight", "externalShutterArmor") /100))}}
                // masa zestawu
                td {{ formatWeight(itemCombination.weight) }}
                // rura
                td {{ prop(itemCombination.globalPipe, "symbol") }}
                // napęd
                td {{ prop(itemCombination.globalDrive, "symbol") }}
                // strona napędu
                td {{ prop(itemCombination.externalShutterDriveExit, "name") }}
                // Prowadnica lewa
                td {{ prop(itemCombination.externalShutterExternalShutterRunnerEntryLeft, "name",  "externalShutterRunner") }}
                // Prowadnica prawa
                td {{ prop(itemCombination.externalShutterExternalShutterRunnerEntryRight, "name",  "externalShutterRunner") }}
                // Nawiert lewy
                td {{ prop(itemCombination.externalShutterDrillHoleLeft, "name") }}
                // Nawiert prawy
                td {{ prop(itemCombination.externalShutterDrillHoleRight, "name") }}
                //Adaptacja
                td {{ prop(itemCombination.externalShutterAdaptation, "name") }}
                // Zabezpieczenia
                td
                  p(v-if="!itemCombination.externalShutterExternalShutterProtectionEntries.length") Brak
                  p(v-for="(itemProtection, indexProtection) in itemCombination.externalShutterExternalShutterProtectionEntries") {{ prop(itemProtection, "name", "externalShutterProtection") }}

                // ilość szt. przy kombinacji
                td( v-if="indexCombination === 0" :rowspan="item.externalShutterOrderItemCombinationsCount") {{ item.quantity }}
                td {{prop(item, "shortDescription") }}
          tfoot
            tr
              td(colspan=4).has-text-right Razem :
              td.has-text-weight-bold {{getDecimal(sumParameterInExternalShutterOrderItems(orderItems(object), 'width', true) / 10) }} m
              td(colspan=5)
              //td.has-text-weight-bold {{sumParameterInExternalShutterOrderItems(orderItems(object), 'sumSurfacesAllCombinations') / 1000000}}
              td(colspan="2") Razem kg:
              td.has-text-weight-bold {{ formatWeight(object.sumWeight) }}
              td(colspan=8)
              td(colspan=1) Razem ilość:
              td.has-text-weight-bold {{ sumParameterInExternalShutterOrderItems(orderItems(object), "quantity") }}
              td

    .columns.is-multiline
      .column(v-if="externalShutterArmorPerVersion(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Pancerz
              th mb
              th m2
          tbody
            tr(v-for="item in externalShutterArmorPerVersion(object)")
              td {{ item.externalShutterArmor }} {{item.externalShutterArmorColor}}
              td {{ getDecimal(item.externalShutterArmorLengths /10 ) }}
              td {{ getDecimal(item.externalShutterArmorSurfaces /10000 ) }}
      .column(v-if="externalShutterBoxPerVersion(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Skrzynka
              th mb
          tbody
            tr(v-for="item in externalShutterBoxPerVersion(object)")
              td {{ item.externalShutterBox }} {{item.temporaryExternalBoxColor}} {{item.temporaryInternalBoxColor}}
              td {{ getDecimal(item.externalShutterBoxLengths /10) }}

      .column(v-if="externalShutterRunnerPerVersion(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Prowadnica
              th mb
          tbody
            tr(v-for="item in externalShutterRunnerPerVersion(object)")
              td {{ item.externalShutterRunner }} {{item.fabricShadeColor}}
              td {{ getDecimal(item.externalShutterRunnerLengths /10 )}}
      .column(v-if="externalShutterArmorFabricShadeColorEntryPerVersion(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Listwa dolna
              th mb
          tbody
            tr(v-for="item in externalShutterArmorFabricShadeColorEntryPerVersion(object)")
              td {{ item.externalShutterArmorFabricShadeColorEntry}}
              td {{ getDecimal(item.externalShutterArmorFabricShadeColorEntryLengths /10) }}
      .column(v-if="globalPipePerVersion(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Rura
              th mb
          tbody
            tr(v-for="item in globalPipePerVersion(object)")
              td {{ item.globalPipe}}
              td {{ getDecimal(item.globalPipeLengths /10 ) }}
      .column(v-if="globalDrivePerVersion(object).length > 0").is-narrow
        table.table.is-bordered.has-margin-bottom
          thead
            tr
              th Napęd
              th szt.
          tbody
            tr(v-for="item in globalDrivePerVersion(object)")
              td {{ item.globalDrive}}
              td {{ item.quantity }}
</template>
<script>

import decimal from "@/helpers/decimal";
import priceFormatter from "../../../helpers/priceFormatter";
import getOrderItems from "../../../helpers/orderItems";
import prop from "../../../helpers/propFunc";
import AutomationOrderItems from "./AutomationOrderItems";
import ExternalShutterOrderItemsAfterSum from "./ExternalShutterOrderItemsAfterSum";
import simpleClone from "../../../helpers/simpleClone";
import weight from "../../../helpers/weight";

export default {
  props: ['object'],
  components: {AutomationOrderItems, ExternalShutterOrderItemsAfterSum},
  computed: {
    formatWeight: () => grams => weight(grams),
    sumParameterInExternalShutterOrderItems: () => (items, parameter, combination = false) => {
      if (combination) return items.reduce((total, externalShutterOrderItem) => {
        let combinationTotal = 0;
        externalShutterOrderItem.externalShutterOrderItemCombinations.forEach(combination => {
          combinationTotal += combination[parameter]
        })
        return combinationTotal + total
      }, 0)

      return items.reduce((total, externalShutterOrderItem) => (externalShutterOrderItem[parameter]) + total, 0)
    },
    iteratorAlphabet: () => item => {
      if (item.externalShutterOrderItemCombinationsCount === 1) return " "
      return ["a", "b", "c"]
    },
  },
  methods: {
    checkVirtualVersionNumberAfterSum: function (orderAfterSum, item) {
      return orderAfterSum.findIndex((p) => (
          p.externalShutter['id'] === item.externalShutter['id'] &&
          p.externalShutterArmorFabricShadeColorEntry['id'] === item.externalShutterArmorFabricShadeColorEntry['id'] &&
          p.temporaryExternalBoxColor['id'] === item.temporaryExternalBoxColor['id'] &&
          p.temporaryInternalBoxColor['id'] === item.temporaryInternalBoxColor['id'] &&
          p.externalShutterArmorExternalShutterArmorColorEntry['id'] === item.externalShutterArmorExternalShutterArmorColorEntry['id'] &&
          p.externalShutterPriceList["externalShutterArmor"]['id'] === item.externalShutterPriceList["externalShutterArmor"]['id'] &&
          p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id']

      ))
    },
    orderAfterSum: function (items) {
      const orderItems = simpleClone(this.orderItems(items))
      return Object.values(orderItems).filter((item, index, selfArray) =>
              index === selfArray.findIndex((p) => (
                  p.externalShutter['id'] === item.externalShutter['id'] &&
                  p.externalShutterArmorFabricShadeColorEntry['id'] === item.externalShutterArmorFabricShadeColorEntry['id'] &&
                  p.temporaryExternalBoxColor['id'] === item.temporaryExternalBoxColor['id'] &&
                  p.temporaryInternalBoxColor['id'] === item.temporaryInternalBoxColor['id'] &&
                  p.externalShutterArmorExternalShutterArmorColorEntry['id'] === item.externalShutterArmorExternalShutterArmorColorEntry['id'] &&
                  p.externalShutterPriceList["externalShutterArmor"]['id'] === item.externalShutterPriceList["externalShutterArmor"]['id'] &&
                  p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id']

              ))
      );
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimal(value) {
      return decimal(value);
    },
    trueFalseToText(value) {
      return (value) ? 'Tak' : 'Nie'
    },
    orderItems: (order) => getOrderItems(order),
    prop: (object, field, extraField) => prop(object, field, extraField),
    externalShutterArmorPerVersion(order) {
      const items = this.orderItems(order);
      let externalShutterArmorCollection = [];

      for (let i = 0; i < items.length; i++) {
        let include = false;
        for (let j = 0; j < externalShutterArmorCollection.length; j++) {
          if (include) {
            break;
          }
          if (
              externalShutterArmorCollection[j].externalShutterArmor ===
              items[i].externalShutterPriceList.externalShutterArmor.name &&
              externalShutterArmorCollection[j].externalShutterArmorColor ===
              items[i].externalShutterArmorExternalShutterArmorColorEntry.externalShutterArmorColor.name
          ) {
            externalShutterArmorCollection[j].quantity += items[i].quantity;
            externalShutterArmorCollection[j].externalShutterArmorSurfaces += items[i].sumSurfacesAllCombinations
            items[i].externalShutterOrderItemCombinations.forEach((it) => {
              externalShutterArmorCollection[j].externalShutterArmorLengths +=
                  (it.runnersHeight * items[i].quantity);
            })

            include = true;
          }
        }
        if (!include && items[i].externalShutterPriceList && items[i].externalShutterPriceList.externalShutterArmor) {
          let calculateLengthInAllCombinations = 0;
          items[i].externalShutterOrderItemCombinations.forEach((it) => {
            calculateLengthInAllCombinations += (it.runnersHeight * items[i].quantity);
          }),
              externalShutterArmorCollection.push({
                externalShutterArmor: items[i].externalShutterPriceList.externalShutterArmor.name,
                externalShutterArmorColor: items[i].externalShutterArmorExternalShutterArmorColorEntry.externalShutterArmorColor.name,
                quantity: items[i].quantity,
                externalShutterArmorLengths: calculateLengthInAllCombinations,
                externalShutterArmorSurfaces: items[i].sumSurfacesAllCombinations
              });
        }
      }
      return externalShutterArmorCollection;
    },
    externalShutterBoxPerVersion(order) {
      const items = this.orderItems(order)
      const itemsCombinations = items.map(it => {
        return it.externalShutterOrderItemCombinations.map(el =>
            ({
              ...el, quantity: it.quantity, temporaryExternalBoxColor: it.temporaryExternalBoxColor.fabricShadeColor,
              temporaryInternalBoxColor: it.temporaryInternalBoxColor.fabricShadeColor
            })
        )
      }).flat()
      let externalShutterBoxCollection = [];

      for (let i = 0; i < itemsCombinations.length; i++) {
        let include = false;

        for (let j = 0; j < externalShutterBoxCollection.length; j++) {
          if (include) {
            break;
          }
          if (
              externalShutterBoxCollection[j].externalShutterBox === itemsCombinations[i].externalShutterBox.name &&
              externalShutterBoxCollection[j].temporaryInternalBoxColor === itemsCombinations[i].temporaryInternalBoxColor.name &&
              externalShutterBoxCollection[j].temporaryExternalBoxColor === itemsCombinations[i].temporaryExternalBoxColor.name
          ) {
            externalShutterBoxCollection[j].externalShutterBoxLengths += itemsCombinations[i].width * itemsCombinations[i].quantity
            include = true;
          }

        }
        if (!include && itemsCombinations[i].width) {
          externalShutterBoxCollection.push({
            externalShutterBox: itemsCombinations[i].externalShutterBox.name,
            temporaryInternalBoxColor: itemsCombinations[i].temporaryInternalBoxColor.name,
            temporaryExternalBoxColor: itemsCombinations[i].temporaryExternalBoxColor.name,
            externalShutterBoxLengths: itemsCombinations[i].width * itemsCombinations[i].quantity,
          });
        }
      }
      return externalShutterBoxCollection;
    },

    externalShutterRunnerPerVersion(order) {
      const items = this.orderItems(order)
      const itemsCombinations = items.map(it => {
        return it.externalShutterOrderItemCombinations.map(el =>
            ({
              ...el,
              fabricShadeColor: it.externalShutterRunnerFabricShadeColorEntry.fabricShadeColor,
              quantity: it.quantity
            })
        )
      }).flat()
      let externalShutterRunnersCollection = [];

      for (let i = 0; i < itemsCombinations.length; i++) {
        let includeLeft = false;
        let includeRight = false;
        for (let j = 0; j < externalShutterRunnersCollection.length; j++) {
          // if (includeLeft && includeRight) {
          //   break;
          // }
          if (
              externalShutterRunnersCollection[j].externalShutterRunner ===
              itemsCombinations[i].externalShutterExternalShutterRunnerEntryLeft.externalShutterRunner.name &&
              externalShutterRunnersCollection[j].fabricShadeColor === itemsCombinations[i].fabricShadeColor.name
          ) {
            externalShutterRunnersCollection[j].externalShutterRunnerLengths += itemsCombinations[i].runnersHeight * itemsCombinations[i].quantity
            includeLeft = true;
          }

          if (
              externalShutterRunnersCollection[j].externalShutterRunner ===
              itemsCombinations[i].externalShutterExternalShutterRunnerEntryRight.externalShutterRunner.name &&
              externalShutterRunnersCollection[j].fabricShadeColor === itemsCombinations[i].fabricShadeColor.name
          ) {
            externalShutterRunnersCollection[j].externalShutterRunnerLengths += itemsCombinations[i].runnersHeight * itemsCombinations[i].quantity
            includeRight = true;
          }

        }
        if ((!includeLeft || !includeRight) && itemsCombinations[i].runnersHeight) {
          externalShutterRunnersCollection.push({
            externalShutterRunner:
                (!includeLeft ? itemsCombinations[i].externalShutterExternalShutterRunnerEntryLeft.externalShutterRunner.name :
                    itemsCombinations[i].externalShutterExternalShutterRunnerEntryRight.externalShutterRunner.name),
            externalShutterRunnerLengths: ((!includeRight && !includeLeft) ? itemsCombinations[i].runnersHeight * itemsCombinations[i].quantity * 2
                : itemsCombinations[i].runnersHeight * itemsCombinations[i].quantity),
            fabricShadeColor: itemsCombinations[i].fabricShadeColor.name
          });
        }
      }
      return externalShutterRunnersCollection;
    },
    externalShutterArmorFabricShadeColorEntryPerVersion(order) {
      const items = this.orderItems(order)
      const itemsCombinations = items.map(it => {
        return it.externalShutterOrderItemCombinations.map(el =>
            ({
              ...el,
              externalShutterArmorFabricShadeColorEntry: it.externalShutterArmorFabricShadeColorEntry,
              quantity: it.quantity
            })
        )
      }).flat()
      let externalShutterArmorFabricShadeColorEntryCollection = [];

      for (let i = 0; i < itemsCombinations.length; i++) {
        let include = false;
        for (let j = 0; j < externalShutterArmorFabricShadeColorEntryCollection.length; j++) {
          if (include) {
            break;
          }
          if (
              externalShutterArmorFabricShadeColorEntryCollection[j].externalShutterArmorFabricShadeColorEntry ===
              itemsCombinations[i].externalShutterArmorFabricShadeColorEntry.fabricShadeColor.name
          ) {
            externalShutterArmorFabricShadeColorEntryCollection[j].externalShutterArmorFabricShadeColorEntryLengths += itemsCombinations[i].width * itemsCombinations[i].quantity
            include = true;
          }
        }
        if (!include && itemsCombinations[i].width) {
          externalShutterArmorFabricShadeColorEntryCollection.push({
            externalShutterArmorFabricShadeColorEntry: itemsCombinations[i].externalShutterArmorFabricShadeColorEntry.fabricShadeColor.name,
            externalShutterArmorFabricShadeColorEntryLengths: itemsCombinations[i].width * itemsCombinations[i].quantity,
          });
        }
      }
      return externalShutterArmorFabricShadeColorEntryCollection;
    },

    globalPipePerVersion(order) {
      const items = this.orderItems(order)
      const itemsCombinations = items.map(it => {
        return it.externalShutterOrderItemCombinations.map(el =>
            ({...el, quantity: it.quantity})
        )
      }).flat()
      let globalPipeCollection = [];

      for (let i = 0; i < itemsCombinations.length; i++) {
        let include = false;
        for (let j = 0; j < globalPipeCollection.length; j++) {
          if (include) {
            break;
          }
          if (
              globalPipeCollection[j].globalPipe ===
              itemsCombinations[i].globalPipe.symbol
          ) {
            globalPipeCollection[j].globalPipeLengths += itemsCombinations[i].width * itemsCombinations[i].quantity
            include = true;
          }
        }
        if (!include && itemsCombinations[i].width) {
          globalPipeCollection.push({
            globalPipe: itemsCombinations[i].globalPipe.symbol,
            globalPipeLengths: itemsCombinations[i].width * itemsCombinations[i].quantity,
          });
        }
      }
      return globalPipeCollection;
    },
    globalDrivePerVersion(order) {
      const items = this.orderItems(order)
      const itemsCombinations = items.map(it => {
        return it.externalShutterOrderItemCombinations.map(el =>
            ({...el, quantity: it.quantity})
        )
      }).flat()
      let globalDriveCollection = [];

      for (let i = 0; i < itemsCombinations.length; i++) {
        let include = false;
        for (let j = 0; j < globalDriveCollection.length; j++) {
          if (include) {
            break;
          }
          if (
              globalDriveCollection[j].globalDrive ===
              itemsCombinations[i].globalDrive.symbol
          ) {
            globalDriveCollection[j].quantity += itemsCombinations[i].quantity
            include = true;
          }
        }
        if (!include && itemsCombinations[i].quantity) {
          globalDriveCollection.push({
            globalDrive: itemsCombinations[i].globalDrive.symbol,
            quantity: itemsCombinations[i].quantity,
          });
        }
      }
      return globalDriveCollection;
    },

  }
};
</script>
