<template lang="pug">
    b-button(
        title="Usuń"
        :disabled="active"
        :loading="active"
        :size="size"
        icon-left="delete"
        type="is-danger"
        @click.prevent="remove"
    )
</template>
<script>
export default {
  props: {
    object: { type: Object, required: true },
    resource: { type: String, required: true },
    size: { type: String }
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    url() {
      return `${this.resource}/${this.object.id}`;
    }
  },
  methods: {
    async remove() {
      if (confirm("Czy na pewno?")) {
        try {
          this.active = true;
          await this.$http.delete(this.url);
          this.$notify("Usunięto element");
          this.active = false;
          this.$emit("removed");
        } catch (e) {
          this.active = false;
          this.$notify(e.message, "danger");
        }
      }
    }
  }
};
</script>
