import crud from "../../crud";

import fabricShades from "./crud/fabricShades";
import fabricShadeGroups from "./crud/fabricShadeGroups";
import fabrics from "./crud/fabrics";
import fabricColors from "./crud/fabricColors";
import fabricShadePriceLists from "./crud/fabricShadePriceLists";
import fabricShadeColors from "./crud/fabricShadeColors";
import windowFrames from "@/modules/fabric_shades/crud/windowFrames";
import fabricShadeGlobalBalkEntries from "./crud/fabricShadeGlobalBalkEntries";
import fabricShadeGlobalFixingEntries from "./crud/fabricShadeGlobalFixingEntries";
import fabricShadeGlobalRunnerEntries from "./crud/fabricShadeGlobalRunnerEntries";
import fabricShadeGlobalAccessoryEntries from "./crud/fabricShadeGlobalAccessoryEntries";
import fabricShadeAdditionals from "./crud/fabricShadeAdditionals";
import fabricShadeFabricShadeAdditionalEntries from "./crud/fabricShadeFabricShadeAdditionalEntries";

crud(fabricShades);
crud(fabricShadeGroups);
crud(fabrics);
crud(fabricColors);
crud(fabricShadePriceLists);
crud(fabricShadeColors);
crud(windowFrames);
crud(fabricShadeGlobalBalkEntries);
crud(fabricShadeGlobalFixingEntries);
crud(fabricShadeGlobalRunnerEntries);
crud(fabricShadeGlobalAccessoryEntries);
crud(fabricShadeAdditionals);
crud(fabricShadeFabricShadeAdditionalEntries);
