<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="fabrics" @object-changed="change" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Symbol" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")
    
                            app-form-field(label="Opis" field="description" :errors="errors")
                                b-input(v-model="data.description" type="textarea")
    
                            app-form-field(label="Szerokość rolki (cm)" field="rollWidth" :errors="errors")
                                app-form-decimal(v-model="data.rollWidth")

                            app-form-field(label="Długość rolki (cm)" field="rollLength" :errors="errors")
                                app-form-decimal(v-model="data.rollLength")

                            app-form-field(field="hasPattern" :errors="errors")
                                b-checkbox(v-model="data.hasPattern") Występuje wzór
    
                            app-form-field(field="isTurnable" :errors="errors")
                                b-checkbox(v-model="data.isTurnable") Możliwość obracania
    
                        .column
                            app-form-field(label="Grupa" field="group" :errors="errors")
                                resource-select(
                                    v-model="data.group"
                                    resource="fabric_shade_groups"
                                    url="fabric_shade_groups"
                                    placeholder="Wybierz grupę"
                                    )
    
                            app-form-field(label="Numer" field="number" :errors="errors")
                                b-input(v-model="data.number")
    
                            app-form-field(label="Faktura" field="texture" :errors="errors")
                                b-select(v-model="data.texture")
                                    option(v-for="({label, value}) in textures" :key="label" :value="value") {{ label }}
    
                            app-form-field(label="Zaciemnienie" field="darknessType" :errors="errors")
                                b-select(v-model="data.darknessType")
                                    option(v-for="{label, value} in darknessTypes" :key="label" :value="value") {{ label }}
    
                            app-form-field(field="isEnabledForShop" :errors="errors")
                                b-checkbox(v-model="data.isEnabledForShop") Dostępne dla sklepu
    
                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                    p.subtitle Maksymalne wysokości dla wersji
                    .is-relative(:class="{ 'is-taller': loading }")
                        b-loading(:active="loading" :is-full-page="false")
                    div(v-show="!loading")
                        app-form-field(
                            v-for="(height, i) in data.fabricHeights"
                            :key="height.fabricShade.name"
                            horizontal
                            :label="height.fabricShade.name"
                            field="field"
                            :errors="errors"
                            )
                            app-form-length(v-model="height.height" unit="cm")

</template>
<script>
import { mapGetters } from "vuex";
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      loading: false,
      fabricShades: [],
      model: {
        name: "",
        symbol: "",
        description: "",
        rollWidth: 0,
        rollLength: 0,
        hasPattern: false,
        isTurnable: false,
        number: "",
        texture: "gladka",
        darknessType: "firana",
        isEnabledForShop: false,
        isEnabled: true,
        fabricHeights: [],
        fabricColors: [],
        group: `/fabric_shade_groups/${this.$route.params.fabricShadeGroupId}`
      }
    };
  },
  computed: {
    ...mapGetters(["textures", "darknessTypes"])
  },
  methods: {
    transformToSubmit(data) {
      data.group = data.group["@id"];
      data.fabricHeights.map(fabricHeight => {
        fabricHeight.fabricShade = fabricHeight.fabricShade["@id"];
        return fabricHeight;
      });
      return data;
    },
    change(formData) {
      this.fabricShades.forEach(
        this.addFabricHeightFor(formData.fabricHeights)
      );
    },
    addFabricHeightFor(collection) {
      return fabricShade => {
        const found = collection.find(fh => {
          return fh.fabricShade.id === fabricShade.id;
        });
        if (!found) {
          collection.push({
            fabricShade: {
              id: fabricShade.id,
              ["@id"]: fabricShade["@id"],
              name: fabricShade.name
            },
            height: 0
          });
        }
      };
    }
  },
  async created() {
    try {
      this.loading = true;
      let { data } = await this.$http.get("fabric_shades", {
        params: {
          pagination: false,
          properties: ["id", "name"]
        }
      });
      this.fabricShades = data["hydra:member"];
      this.fabricShades.forEach(
        this.addFabricHeightFor(this.model.fabricHeights)
      );
    } catch ({ message, response }) {
      if (!response) {
        throw new Error(message);
      }
      if (response.status !== 401) {
        this.$notify(message, "danger");
      }
    }
    this.loading = false;
  }
};
</script>
