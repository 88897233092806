<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="global_pipes" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Symbol" field="symbol" :errors="errors")
                                b-input(v-model="data.symbol")

                            app-form-field(label="Średnica [mm]" field="diameter" :errors="errors")
                                b-input(type="number" :value="data.diameter" @input="val => data.diameter = +val")

                            app-form-field(label="Cena" field="price" :errors="errors")
                                app-form-decimal(v-model="data.price")

                            app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                                app-form-time-integer(v-model="data.productionTime")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                            app-form-field(field="isEnabledForExternalShutter" :errors="errors")
                                b-checkbox(v-model="data.isEnabledForExternalShutter") Widoczny w sekcji rolet zewnętrznych

                            app-form-field(field="skipCalculations" :errors="errors")
                                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

                            app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
                        .column
                            app-form-field(label="Napędy kompatybilne z tą rurą" field="globalDrives" :errors="errors")
                                multiple-resource-select(
                                  v-model="data.globalDrives"
                                  resource="global_drives"
                                  url="global_drives"
                                  extraProps="&properties[]=symbol"
                                  :label="item => item.symbol"
                                  placeholder="Wybierz napędy"
                                )


</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        symbol: "",
        price: 0,
        diameter: null,
        picture: "",
        isEnabled: true,
        isEnabledForExternalShutter: false,
        skipCalculations: true,
        productionTime: 0,
        globalDrives: []
      },
      files: {
        picture: {}
      }
    };
  },
  methods: {
    transformToModel(data) {
      // this.model.globalDrives = data.globalDrives.map(drive => drive["@id"]);
      return data;
    }
  }
};
</script>
