<template lang="pug">
    app-form(v-on="listeners" v-bind="props" resource="fabric_shade_fabric_shade_additional_entries" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
        template(#default="{ data, errors }")
            b-tabs(type="is-boxed")
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.heightSurcharge")

                            app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                                app-form-decimal(v-model="data.widthSurcharge")
                                
                            app-form-field(label="Dopłata za sztukę" field="pieceSurcharge" :errors="errors")
                              app-form-decimal(v-model="data.pieceSurcharge")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny

                        .column
                            app-form-field(label="Kolejność" field="position" :errors="errors")
                                b-input(type="number" @input="data.position = +$event" :value="data.position")

                            app-form-field(label="Dodatek" field="fabricShadeAdditional" :errors="errors")
                                resource-select(
                                    v-model="data.fabricShadeAdditional"
                                    resource="fabric_shade_additionals"
                                    url="fabric_shade_additionals"
                                    placeholder="Wybierz dodatek"
                                    extra-props="&order[name]=asc"
                                    :labels="['name']"
                                )

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        pieceSurcharge: 0,
        isEnabled: true,
        fabricShade: `/fabric_shades/${this.$route.params.fabricShadeId}`,
        fabricShadeAdditional: null,
      },
      fabricShadeId: this.$route.params.fabricShadeId,
      downloadedFabricShadeId: null
    };
  },
  computed: {
    getFabricShadeId() {
      if (!isNaN(this.fabricShadeId) && this.fabricShadeId) {
        return this.fabricShadeId;
      }
      return this.downloadedFabricShadeId;
    }
  },
  methods: {
    transformToModel(data) {
      return data;
    },
    transformToSubmit(data) {
      if (data.fabricShade && data.fabricShade["@id"]) {
        data.fabricShade = data.fabricShade["@id"];
      }
      if (data.fabricShadeAdditional && data.fabricShadeAdditional["@id"]) {
        data.fabricShadeAdditional = data.fabricShadeAdditional["@id"];
      }

      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.id) {
      const { data } = await http.get(
        `/fabric_shade_fabric_shade_additional_entries/${this.$route.params.id}?properties[fabricShade][]=id`
      );
      if (data.fabricShade) {
        this.downloadedFabricShadeId = data.fabricShade.id;
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
