<template>
  <div class="has-border-bottom has-margin-bottom">
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" to="/">
          SWIZ-ADMIN
          <div class="has-big-red-star" v-if="hasToReadReclamationOrBugReport">
            *
          </div>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>

      <template slot="end">
        <b-navbar-item tag="div">
          <div v-if="isLoggedIn" class="buttons">
            <span class="button is-primary" title="Zalogowany użytkownik">
              <strong>{{ email }}</strong>
            </span>
            <a class="button is-light" @click.prevent="logout" title="Wyloguj">
              <b-icon icon="logout" />
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import http from "../http";

export default {
  computed: {
    ...mapGetters("auth", [
      "email",
      "isLoggedIn",
      "hasToReadReclamationOrBugReport"
    ])
  },
  methods: {
    ...mapActions("auth", ["setHasToReadReclamationOrBugReport"]),
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({
          name: "login"
        });
      });
    }
  },
  watch: {
    async $route() {
      let { data } = await http.get("/api/bugs/reclamations/read");
      this.setHasToReadReclamationOrBugReport(data.hasNoReadBugReports);
    }
  }
};
</script>
