<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="trait_fabrics")
    template(#default="{ data, errors }")
      b-tabs
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Zdjęcie (proporcja 4:3)" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")
            .column
              app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                b-input(v-model="data.message" type="textarea")

</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
export default {
  mixins: [form],
  data() {
    return {
      files: {
        picture: {}
      },
      model: {
        name: "",
        message: ""
      },
      messageHelp:
        "Gdy użytkownik wybierze tą cechę, zostanie mu wyświetlony podany komunikat."
    };
  }
};
</script>
