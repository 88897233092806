import GlobalFixingForm from "@/modules/global/components/GlobalFixingForm";

export default {
  list: {
    permissions: ["global_fixing_read"],
    actions: [
      {
        label: "Nowe mocowanie",
        type: "is-primary",
        route: () => ({
          name: "global_fixings_create"
        }),
        permissions: ["global_fixing_create"]
      }
    ],
    route: {
      path: "/global_fixings/list",
      name: "global_fixings_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Mocowania"
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "position", type: "text", label: "Numer", filter: "text" }
    ],
    resource: "global_fixings",
    url: () =>
      `/global_fixings?properties[]=id&properties[]=name&properties[]=symbol&properties[]=number&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_fixing_update"]
      },
      {
        action: "remove",
        permissions: ["global_fixing_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_fixing_create"],
    route: {
      path: "/global_fixings/create",
      name: "global_fixings_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "global_fixings_list" }),
        label: () => "Mocowania"
      },
      {
        label: () => `Nowe mocowanie"`
      }
    ],
    formComponent: GlobalFixingForm,
    listRoute: () => ({
      name: "global_fixings_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_fixings_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_fixing_update"],
    route: {
      path: "/global_fixings/:id/update",
      name: "global_fixings_update"
    },
    resource: "global_fixings",
    breadcrumbs: [
      {
        route: () => ({ name: "global_fixings_list" }),
        label: () => "Mocowania"
      },
      {
        label: ({ compData }) => `Edycja mocowania "${compData.object.name}"`
      }
    ],
    formComponent: GlobalFixingForm,
    listRoute: () => ({
      name: "global_fixings_list"
    })
  }
};
