<template lang="pug" functional>
  th(:class="{ sortable: props.sortable }" @click.prevent="props.sort(props)" v-if="!props.disabledHead") {{ props.label }}
    template(v-show="props.sortable && props.isSorted(props.field)")
      span(class="icon is-small" v-show="props.sortable && props.isDesc")
        i(class="mdi mdi-arrow-down")
      span(class="icon is-small" v-show="props.sortable && props.isAsc")
        i(class="mdi mdi-arrow-up")
</template>
<script>
export default {
  props: {
    label: { type: String, required: true },
    disabledHead: Boolean,
    field: { type: String },
    isSorted: { type: Function, required: true },
    sort: { type: Function, required: true },
    sortable: Boolean,
    isDesc: Boolean,
    isAsc: Boolean
  }
};
</script>
