import notify from "../../helpers/notify";

export const onResponseError = error => {
  if (error.response && error.response.status === 403) {
    notify(
        `Brak dostępu do wybranego zasobu bądź operacji`,
        "danger"
    );
  }
  if (error.response && error.response.status === 422) {
    notify(
      `Nie można usunąć obiektu bez uprzedniego usunięcia podobiektów`,
      "danger"
    );
  }
  if (error.response && error.response.status === 404) {
    notify(
      `Wystąpił błąd, nieodnaleziono obiektu pod adresem ${error.response.request.responseURL}`,
      "danger"
    );
  }
  if (error.response && error.response.status === 500) {
    notify(
      `Wystąpił nieoczekiwany błąd aplikacji, administrator został już o nim powiadomiony`,
      "danger"
    );
  }
  return Promise.reject(error);
};

// export const onRequest = config => {
//   config.params["XDEBUG_SESSION_START"] = "PHPSTORM";
//   return config;
// };
