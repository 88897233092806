import PleatAdditionalForm from "../../pleats/components/PleatAdditionalForm.vue";
import decimal from "../../../helpers/decimal";
import http from "@/http";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["pleat_additional_read"],
    hasPagination: true,
    sortable: false,
    actions: [
      {
        label: "Nowy Dodatek",
        type: "is-primary",
        route: () => ({
          name: "pleat_additionals_create"
        }),
        permissions: ["pleat_additional_create"]
      }
    ],
    route: {
      path: "/pleat_additionals/list",
      name: "pleat_additionals_list"
    },
    breadcrumbs: [
      {
        label: () => `Dodatki dla roletek tkaninowych`
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
    ],
    resource: "pleat_additionals",
    url: () =>
      `/pleat_additionals?` +
        generateProperties(
        [],
        ["id", "name"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_additional_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_additional_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_additional_create"],
    route: {
      path: "/pleat_additionals/create",
      name: "pleat_additionals_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "pleat_additionals_list"
        }),
        label: () => `Dodatki dla plis`
      },
      {
        label: () => `Nowy dodatek`
      }
    ],
    formComponent: PleatAdditionalForm,
    listRoute: () => ({
      name: "pleat_additionals_list"
    }),
    updateRoute: ({ object }) => ({
      name: "pleat_additionals_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["pleat_additional_update"],
    route: {
      path: "/pleat_additionals/:id/update",
      name: "pleat_additionals_update"
    },
    resource: "pleat_additionals",
    breadcrumbs: [
      {
        route: () => ({
          name: "pleat_additionals_list"
        }),
        label: ({ compData }) => `Dodatki dla plis`
      },
      {
        label: ({ compData }) =>
          `Edycja dodatku "${compData.object.name}"`
      }
    ],
    formComponent: PleatAdditionalForm,
    listRoute: () => ({
      name: "pleat_additionals_list"
    })
  }
};
