import orderCategoryByProductTypeCode from "./orderCategoryByProductTypeCode";

export default function getOrderItemFieldNameByCategory(category){
    switch (category) {
        case orderCategoryByProductTypeCode.RTK:
            return "fabricShadeOrderItems";
        case orderCategoryByProductTypeCode.RZW:
            return "externalShutterOrderItems";
        case orderCategoryByProductTypeCode.RDN:
            return "fabricShadeDayNightOrderItems";
        case orderCategoryByProductTypeCode.PLI:
            return "pleatOrderItems";
        case orderCategoryByProductTypeCode.DET:
            return "detailOrderItems";
        case orderCategoryByProductTypeCode.MSQ:
            return "frameMosquitoNetOrderItems";
        case orderCategoryByProductTypeCode.MPL:
            return "pleatMosquitoNetOrderItems";
        case orderCategoryByProductTypeCode.MDR:
            return "doorMosquitoNetOrderItems";
        case orderCategoryByProductTypeCode.MPR:
            return "slideMosquitoNetOrderItems";
        case orderCategoryByProductTypeCode.MZW:
            return "rollMosquitoNetOrderItems";
        default:
            return "fabricShadeOrderItems";
    }
};