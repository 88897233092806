<template lang="pug">
  div
    .has-text-centered.has-font-25 Pamiętaj, jednostka w cennikach to milimetry
    .is-clearfix.has-margin-bottom
      button.button.is-pulled-right(@click.prevent="clear") Wyczyść
    app-form(resource="frame_mosquito_net_price_lists" v-bind="props" v-on="listeners" :transform-to-submit="transformToSubmit")
      template(#default)
        table.table.price-list-table
          tbody
            tr(v-for="(_, row) in rows" :key="row")
              td(v-for="(_, col) in cols" :key="col" class="has-no-padding-left")
                div(v-if="row === 0 && col === 0")
                  b-input(size="is-small" @paste.native.prevent="onPaste" placeholder="Wklej tutaj treść z Excela")
                div(v-else)
                  app-form-length(
                    classes="has-text-weight-bold has-background-gray"
                    v-if="row === 0 || col === 0"
                    size="is-small"
                    :value="getValueFor(row, col)"
                    @input="setValueFor(row, col, $event)"
                    unit="mm"
                  )
                  app-form-decimal-integer(
                    classes=""
                    v-else
                    size="is-small"
                    :value="getValueFor(row, col)"
                    @input="setValueFor(row, col, $event)"
                  )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import onPaste from "../../../helpers/onPaste";

export default {
  mixins: [form],
  methods: {
    getValueFor(row, col) {
      const item = this.collection.find(
          item => item.row === row && item.col === col
      );
      if (item) {
        return item.value;
      }
      return null;
    },
    setValueFor(row, col, value) {
      const item = this.collection.find(
          item => item.row === row && item.col === col
      );

      if (item) {
        item.value = value;
      } else {
        this.collection.push({
          row,
          col,
          value
        });
      }
    },
    transformToSubmit(data) {
      data.frameMosquitoNet = data.frameMosquitoNet["@id"];
      data.frameMosquitoNetPriceListEntries = data.frameMosquitoNetPriceListEntries.filter(
          entry =>
              entry.row !== null && entry.col !== null && entry.value !== null
      );
      return data;
    },
    clear() {
      if (confirm("Czy na pewno wyczyścić wszystkie pola?")) {
        this.object.frameMosquitoNetPriceListEntries = [];
        this.model.frameMosquitoNetPriceListEntries = [];
      }
    },
    modifyCollection(collection) {
      collection.forEach((item) => {
        if (item.col === 0 || item.row === 0) {
          item.value /= 10;
        }
      });
    },
    onPaste(event) {
      const value = event.clipboardData.getData("text");
      const collection = onPaste(value);
      this.modifyCollection(collection);
      this.object.frameMosquitoNetPriceListEntries = collection;
      this.model.frameMosquitoNetPriceListEntries = collection;
    }
  },
  computed: {
    model() {
      return {
        frameMosquitoNet: {},
        frameMosquitoNetPriceListEntries: []
      };
    },
    length() {
      return this.frameMosquitoNet.priceListRows * this.frameMosquitoNet.priceListCols;
    },
    frameMosquitoNet() {
      return this.object.frameMosquitoNet || {};
    },
    rows() {
      return this.frameMosquitoNet.priceListRows;
    },
    cols() {
      return this.frameMosquitoNet.priceListCols;
    },
    collection() {
      return this.object.frameMosquitoNetPriceListEntries;
    }
  }
};
</script>
