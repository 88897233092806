import FabricShadeAdditionalForm from "../../fabric_shades/components/FabricShadeAdditionalForm.vue";
import decimal from "../../../helpers/decimal";
import http from "@/http";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["fabric_shade_additional_read"],
    hasPagination: true,
    sortable: false,
    actions: [
      {
        label: "Nowy Dodatek",
        type: "is-primary",
        route: () => ({
          name: "fabric_shade_additionals_create"
        }),
        permissions: ["fabric_shade_additional_create"]
      }
    ],
    route: {
      path: "/fabric_shade_additionals/list",
      name: "fabric_shade_additionals_list"
    },
    breadcrumbs: [
      {
        label: () => `Dodatki dla roletek tkaninowych`
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
    ],
    resource: "fabric_shade_additionals",
    url: () =>
      `/fabric_shade_additionals?` +
        generateProperties(
        [],
        ["id", "name"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_additional_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_additional_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_additional_create"],
    route: {
      path: "/fabric_shade_additionals/create",
      name: "fabric_shade_additionals_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "fabric_shade_additionals_list"
        }),
        label: () => `Dodatki dla roletek tkaninowych`
      },
      {
        label: () => `Nowy dodatek`
      }
    ],
    formComponent: FabricShadeAdditionalForm,
    listRoute: () => ({
      name: "fabric_shade_additionals_list"
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_additionals_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_additional_update"],
    route: {
      path: "/fabric_shade_additionals/:id/update",
      name: "fabric_shade_additionals_update"
    },
    resource: "fabric_shade_additionals",
    breadcrumbs: [
      {
        route: () => ({
          name: "fabric_shade_additionals_list"
        }),
        label: ({ compData }) => `Dodatki dla roletek tkaninowych`
      },
      {
        label: ({ compData }) =>
          `Edycja dodatku "${compData.object.name}"`
      }
    ],
    formComponent: FabricShadeAdditionalForm,
    listRoute: () => ({
      name: "fabric_shade_additionals_list"
    })
  }
};
