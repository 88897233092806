<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose")
    app-view-card(title="Rezerwacja czasu produkcji")
      template(slot="icon")
        b-button(
          @click="onClose"
        )
          b-icon(icon="close" size="is-small")
      template
        app-form(v-bind="props" @close="onClose" resource="production_time_bookings", :showSave="false" :transformToSubmit="transformToSubmit" :transformToModel="transformToModel" )
          template(#default="{ data, errors }")
            .columns
              .column 
                app-form-field(label="Notatka" field="description" :errors="errors")
                  b-input(v-model="data.description" type="textarea")
              .column
                app-form-field(label="Data produkcji" field="productionDate" :errors="errors")
                  date-picker(
                      v-model="data.productionDate"
                      type="date"
                      value-type="format"
                      format="DD-MM-YYYY"
                      :disabled-date="(date) => !date.getDay()"
                    )
                app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                  app-form-time-integer(v-model="data.productionTime")
                app-form-field(label="Typ produktu" field="productType" :errors="errors")
                  resource-select(
                    v-if="data && isActive"
                    v-model="data.productType"
                    resource="product_types"
                    url="product_types"
                    placeholder="Wybierz typ produktu"
                    :extra-props="productTypeResourceSelectProps"
                    label="name"
                    @input="onProductTypeChange(data, $event)"
                    full-object
                  )
                app-form-field(label="Linia produkcyjna" field="productionLine" :errors="errors")
                  resource-select(
                    v-if="data && isActive && data.productType"
                    v-model="data.productionLine"
                    resource="production_lines"
                    :url="productionLineResourceSelectProps"
                    placeholder="Wybierz linię produkcyjną"
                    label="name"
                    :addDefaultProp="false"
                  )
                app-form-field(label="Odbiorca" field="company" :errors="errors")
                  resource-select(
                    v-if="data && isActive"
                    v-model="data.company"
                    resource="companies"
                    :url="companyResourceSelectProps"
                    placeholder="Wybierz odbiorcę"
                    label="shortName"
                    :addDefaultProp="false"
                    nullable
                  )
</template>

<script>
import form from "@/plugins/app/form/mixins/form";
import ResourceSelect from "./ResourceSelect";
import AppForm from '../../../plugins/app/form/components/AppForm.vue';
import generateProperties from "../../../helpers/generateProperties";
import DatePicker from "vue2-datepicker";
import http from "../../../http";

export default {
  mixins: [form],
  components: { ResourceSelect, AppForm, DatePicker },
  props: {
    active: { type: Boolean, default: false, required: true },
    booking: { default: null, required: true },
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  methods: {
    onProductTypeChange(data) {
      data.productionLine = null;
    },
    onClose() {
      this.isActive = false;
      this.$emit("close", this.order);
    },
    transformToSubmit(data) {

      if(data.productType && data.productType['@id']) {
        data.productType = data.productType['@id'];
      }
      if(data.productionLine && data.productionLine['@id']) {
        data.productionLine = data.productionLine['@id'];
      }
      if(data.company && data.company['@id']) {
        data.company = data.company['@id'];
      }

      return data;
    },
    transformToModel(data) {
      return data;
    },
    // async validation(formData, errors) {
    //   let validationData = new FormData();
    //   validationData.append('productionTime', formData.productionTime);
    //   validationData.append('productType', parseInt(formData.productType.split('/')[2]));
    //   validationData.append('productionLine', parseInt(formData.productionLine.split('/')[2]));
    //   validationData.append('productionDate', formData.productionDate);
    //
    //   let { data } = await http.post(
    //     '/api/validate/production/time',
    //     validationData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         "Accept": "application/ld+json"
    //       }
    //     }
    //   );
    //
    //   if(!data.status) {
    //     if(data.errors) {
    //       for(let i = 0; i < data.errors.length; i++) {
    //         errors.push({
    //           propertyPath: data.errors[i].propertyPath,
    //           message: data.errors[i].message
    //         });
    //       }
    //     }
    //   }
    // }
  },
  computed: {
    model() {
      if(!this.booking) {
        return {
          description: "",
          productionTime: 0,
          productionDate: null,
          productionLine: null,
          productType: null,
          company: null,
        }
      }
      return this.booking;
    },
    productionLineResourceSelectProps() {
      if(Object.prototype.hasOwnProperty.call(this.props.model, 'productType') && Object.prototype.hasOwnProperty.call(this.props.model.productType, 'id')) {
        return 'production_lines?' + generateProperties([],['id', 'name']) + `&productType.id[]=${this.props.model.productType.id}`;
      } else {
        return 'production_lines?' + generateProperties([],['id', 'name']);
      }
    },
    companyResourceSelectProps() {
      return `companies?` + generateProperties([],['id', 'shortName']) + '&isEnabled=true';
    },
    productTypeResourceSelectProps() {
      return generateProperties([],['id', 'name', 'code'],true);
    },
    url() {
      let address = `production_time_bookings/${this.booking.id}`;
      return address;
    }
  },
  watch: {
    async active(value) {
      if(!value) {
        this.props.model = {
          description: "",
          productionTime: 0,
          productionDate: null,
          productionLine: null,
          productType: null,
          company: null,
        };
      }
      this.isActive = value;
      if(value && this.booking && this.booking.id) {
        try {
          let { data } = await this.$http.get(this.url);
          // this.booking = data;
          this.props.model = data;
          this.$emit("fetched", data);
        } catch (e) {
          this.$notify(
            `Nie udało się pobrać obiektu pod adresem ${this.url}`,
            "danger"
          );
        }
      }

    }
  },
};
</script>
