import ExternalShutterBoxForm from "../components/ExternalShutterBoxForm";
import fetchExternalShutter from "../helpers/fetchExternalShutter";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";
import weight from "../../../helpers/weight";

export default {
  list: {
    permissions: ["external_shutter_box_read"],
    actions: [
      {
        label: "Nowa skrzynka",
        type: "is-primary",
        route: ({ route }) => ({
          name: "external_shutter_boxes_create",
          params: {
            externalShutterId: route.params.externalShutterId
          }
        }),
        permissions: ["external_shutter_box_create"]
      }
    ],
    route: {
      path: "/external_shutter_boxes/:externalShutterId/list",
      name: "external_shutter_boxes_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        label: ({ compData }) =>
          `Skrzynki dla wersji "${compData.externalShutter.name}"`
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Nazwa",
        filter: "text"
      },
      {
        field: "width",
        type: "text",
        label: "Szerokość (mm)"
      },
      {
        field: "height",
        type: "text",
        label: "Wysokość (mm)"
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata za mb.",
        format: decimal
      },
      {
        field: "increaseSurcharge",
        type: "text",
        label: "Dopłata za zwiększenie",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "hasMosquitoNet",
        type: "boolean",
        label: "Moskitiera",
        filter: "boolean"
      },
      {
        type: "link",
        label: "Kolory zewn.",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_box_external_fabric_shade_color_entries_list",
            params: { externalShutterBoxId: row.id }
          })
        }
      },
      {
        type: "link",
        label: "Kolory wewn.",
        link: {
          label: "Pokaż",
          route: ({ row }) => ({
            name: "external_shutter_box_internal_fabric_shade_color_entries_list",
            params: { externalShutterBoxId: row.id }
          })
        }
      }
    ],
    resource: "external_shutter_boxes",
    url: ({ route }) =>
      `/external_shutters/${route.params.externalShutterId}/external_shutter_boxes?` +
      generateProperties(
        [],
        [
          "id",
          "name",
          "position",
          "isEnabled",
          "width",
          "height",
          "hasMosquitoNet",
          "lengthSurcharge",
          "increaseSurcharge",
          "instructions",
          "sectionPicture",
          "sideWeight",
          "weight"
        ]
      ) +
      generateProperties(["externalShutter"], ["id", "name", "symbol"], true),
    onCreated: fetchExternalShutter,
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_box_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_box_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_box_create"],
    route: {
      path: "/external_shutter_boxes/:externalShutterId/create",
      name: "external_shutter_boxes_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_boxes_list",
          params: { externalShutterId: compData.externalShutter.id }
        }),
        label: ({ compData }) =>
          `Skrzynki dla wersji "${compData.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowa skrzynka dla wersji "${compData.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterBoxForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_boxes_list",
      params: { externalShutterId: compData.externalShutter.id }
    }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_boxes_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["external_shutter_box_update"],
    route: {
      path: "/external_shutter_boxes/:id/update",
      name: "external_shutter_boxes_update"
    },
    resource: "external_shutter_boxes",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_boxes_list",
          params: {
            externalShutterId: compData.object.externalShutter.id
          }
        }),
        label: ({ compData }) =>
          `Skrzynki dla wersji "${compData.object.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja skrzynki "${compData.object.name}" dla wersji "${compData.object.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterBoxForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_boxes_list",
      params: {
        externalShutterId: compData.object.externalShutter.id
      }
    })
  }
};
