import ExternalShutterArmorColorForm from "../components/ExternalShutterArmorColorForm";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["external_shutter_armor_color_read"],
    actions: [
      {
        label: "Nowy kolor pancerzy",
        type: "is-primary",
        route: () => ({
          name: "external_shutter_armor_colors_create"
        }),
        permissions: ["external_shutter_armor_color_create"]
      }
    ],
    route: {
      path: "/external_shutter_armor_colors/list",
      name: "external_shutter_armor_colors_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => "Kolory pancerzy"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
    ],
    resource: "external_shutter_armor_colors",
    url: () =>
      `/external_shutter_armor_colors?` +
      generateProperties(
        [],
        ["id", "name", "position"]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_armor_color_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_armor_color_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_armor_color_create"],
    route: {
      path: "/external_shutter_armor_colors/create",
      name: "external_shutter_armor_colors_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armor_colors_list" }),
        label: () => "Kolory pancerzy"
      },
      {
        label: () => `Nowy kolor pancerzy`
      }
    ],
    formComponent: ExternalShutterArmorColorForm,
    listRoute: () => ({ name: "external_shutter_armor_colors_list" }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_armor_colors_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["external_shutter_armor_color_update"],
    route: {
      path: "/external_shutter_armor_colors/:id/update",
      name: "external_shutter_armor_colors_update"
    },
    resource: "external_shutter_armor_colors",
    resourceProperties: generateProperties(
      [],
      ["id", "name", "position", "picture", "thumbnail"]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_armor_colors_list" }),
        label: () => "Nawierty"
      },
      {
        label: ({ compData }) => `Edycja koloru pancerzy "${compData.object.name}"`
      }
    ],
    formComponent: ExternalShutterArmorColorForm,
    listRoute: () => ({ name: "external_shutter_armor_colors_list" })
  }
};
