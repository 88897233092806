<template lang="pug">
  b-field
    b-upload(:value="value" @input="$emit('input', $event)" :multiple="multiple" :accept="accept" )
      a(class="button is-primary")
        b-icon(icon="upload")
        span Wskaż plik

    .columns.is-multiline
      .column.is-12
        span(class="file-name" v-if="multiple" v-for="file in value").is-4 {{ file.name }}
      .column(class="" v-for="img in image" v-if="image.length > 0").is-6
        .card
          .card-image
            figure.image(v-if="!downloadable")
              img(:src="img.contentUrl")
          .card-content(v-if="downloadable")
            span {{ img.contentUrl && img.contentUrl.substring(img.contentUrl.lastIndexOf('/')+1).substring(0,40)+"..." }}
          .card-footer
            p.card-footer-item
              b-button(
                type="is-danger"
                icon-left="mdi mdi-delete"
                @click.prevent="()=>remove(img.id)"
                :loading="removing"
              ) Usuń
            p.card-footer-item(v-if="downloadable")
              a(
                class="button is-primary"
                :href="img.contentUrl"
                target="_blank"
                download
              ) Pobierz/Wydrukuj plik

</template>
<script>
export default {
  props: {
    value: {type: [File, Object, Array]},
    image: {type: [Object, String, Array]},
    multiple: Boolean,
    accept: {type: String, default: '*'},
    downloadable: {type: Boolean, default: false},
    downloadText: {type: String, default: "Instrukcja montażu"}
  },
  data() {
    return {
      removing: false
    };
  },
  methods: {
    async remove(imageID) {
      try {
        this.removing = true;
        await this.$http.delete(`media_objects/${imageID}`);
        // this.$emit("input", []);
        this.$emit("removed", imageID, this.image);
        this.removing = false;
      } catch (e) {
        this.removing = false;
      }
    }
  }
};
</script>
