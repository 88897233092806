import crud from "../../crud";

import fabricShadeDayNights from "./crud/fabricShadeDayNights";
import fabricShadeDayNightGroups from "./crud/fabricShadeDayNightGroups";
import fabricDayNights from "./crud/fabricDayNights";
import fabricColorDayNights from "./crud/fabricColorDayNights";
import fabricShadeDayNightPriceLists from "./crud/fabricShadeDayNightPriceLists";
import windowFramesDayNights from "@/modules/fabric_shade_day_nights/crud/windowFrameDayNights";
import fabricShadeDayNightGlobalBalkEntries from "./crud/fabricShadeDayNightGlobalBalkEntries";
import fabricShadeDayNightGlobalFixingEntries from "./crud/fabricShadeDayNightGlobalFixingEntries";
import fabricShadeDayNightGlobalRunnerEntries from "./crud/fabricShadeDayNightGlobalRunnerEntries";
import fabricShadeDayNightGlobalAccessoryEntries from "./crud/fabricShadeDayNightGlobalAccessoryEntries";
import fabricShadeDayNightAdditionals from "./crud/fabricShadeDayNightAdditionals";
import fabricShadeDayNightFabricShadeDayNightAdditionalEntries from "./crud/fabricShadeDayNightFabricShadeDayNightAdditionalEntries";

crud(fabricShadeDayNights);
crud(fabricShadeDayNightGroups);
crud(fabricDayNights);
crud(fabricColorDayNights);
crud(fabricShadeDayNightPriceLists);
crud(windowFramesDayNights);
crud(fabricShadeDayNightGlobalBalkEntries);
crud(fabricShadeDayNightGlobalFixingEntries);
crud(fabricShadeDayNightGlobalRunnerEntries);
crud(fabricShadeDayNightGlobalAccessoryEntries);
crud(fabricShadeDayNightAdditionals);
crud(fabricShadeDayNightFabricShadeDayNightAdditionalEntries);
