import CountryForm from "../components/CountryForm";

export default {
  list: {
    sortable: true,
    permissions: ["country_read"],
    actions: [
      {
        label: "Nowy kraj",
        type: "is-primary",
        route: () => ({
          name: "countries_create"
        }),
        permissions: ["country_create"]
      }
    ],
    route: {
      path: "/countries/list",
      name: "countries_list"
    },
    breadcrumbs: [
      {
        label: () => "Kraje"
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "countries",
    url: () =>
      `/countries?properties[]=id&properties[]=name&properties[]=isEnabled`,
    rowActions: [
      {
        action: "edit",
        permissions: ["country_update"]
      },
      {
        action: "remove",
        permissions: ["country_delete"]
      }
    ]
  },
  create: {
    permissions: ["country_create"],
    route: {
      path: "/countries/create",
      name: "countries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "countries_list" }),
        label: () => "Kraje"
      },
      {
        label: () => `Nowy kraj`
      }
    ],
    formComponent: CountryForm,
    listRoute: () => ({
      name: "countries_list"
    }),
    updateRoute: ({ object }) => ({
      name: "countries_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["country_update"],
    route: {
      path: "/countries/:id/update",
      name: "countries_update"
    },
    resource: "countries",
    breadcrumbs: [
      {
        route: () => ({ name: "countries_list" }),
        label: () => "Kraje"
      },
      {
        label: ({ compData }) => `Edycja kraju "${compData.object.name}"`
      }
    ],
    formComponent: CountryForm,
    listRoute: () => ({ name: "countries_list" })
  }
};
