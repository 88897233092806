import ExternalShutterGlobalDriveEntryForm from "../components/ExternalShutterGlobalDriveEntryForm";
import fetchExternalShutter from "../helpers/fetchExternalShutter";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";
import http from "../../../http";

export default {
  list: {
    permissions: ["external_shutter_global_drive_entry_read"],
    route: {
      path: "/external_shutter_global_drive_entries/:externalShutterId/list",
      name: "external_shutter_global_drive_entries_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        label: ({ compData }) =>
          `Wiązania rur dla wersji "${compData.externalShutter.name}"`
      }
    ],
    fields: [
      {
        field: "globalDrive.globalDriveType.name",
        type: "text",
        label: "Typ",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/global_drive_types?properties[]=name")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        }
      },
      {
        field: "globalDrive.globalDriveSeries.name",
        type: "text",
        label: "Seria",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/global_drive_series?properties[]=name")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        }
      },
      { field: "globalDrive.symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktyw",
        filter: "boolean"
      },

    ],
    resource: "external_shutter_global_drive_entries",
    url: ({ route }) =>
      `/external_shutters/${route.params.externalShutterId}/external_shutter_global_drive_entries?` +
      generateProperties(
        [],
        ["id", "isEnabled", "pipeCutCorrection", "groupingSurcharge"]
      ) +
      generateProperties(["globalDrive"], ["name", "symbol"], true) +
      generateProperties(["globalDrive", "manufacturer"], ["name"], true) +
      generateProperties(["globalDrive", "globalDriveSeries"], ["name"], true) +
      generateProperties(["globalDrive", "globalDriveType"], ["name"], true) +
      generateProperties(["externalShutter"], ["id", "name", "symbol"], true),
    onCreated: fetchExternalShutter,
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_global_drive_entry_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_global_drive_entry_delete"]
      }
    ]
  },
  update: {
    permissions: ["external_shutter_global_drive_entry_update"],
    route: {
      path: "/external_shutter_global_drive_entries/:id/update",
      name: "external_shutter_global_drive_entries_update"
    },
    resource: "external_shutter_global_drive_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutters_list" }),
        label: () => "Wersje"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_global_drive_entries_list",
          params: {
            externalShutterId: compData.object.externalShutter.id
          }
        }),
        label: ({ compData }) =>
          `Wiązania wapędów dla wersji "${compData.object.externalShutter.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania napędu "${compData.object.globalDrive.name}" z wersją "${compData.object.externalShutter.name}"`
      }
    ],
    formComponent: ExternalShutterGlobalDriveEntryForm,
    onCreated: fetchExternalShutter,
    listRoute: ({ compData }) => ({
      name: "external_shutter_global_drive_entries_list",
      params: {
        externalShutterId: compData.object.externalShutter.id
      }
    })
  }
};
