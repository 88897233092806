import PleatPleatAdditionalEntryForm from "../../pleats/components/PleatPleatAdditionalEntryForm.vue";
import decimal from "../../../helpers/decimal";
import fetchPleat from "@/modules/pleats/helpers/fetchPleat";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["pleat_pleat_additional_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "pleat_pleat_additional_entries_create",
          params: {
            pleatId: route.params.pleatId
          }
        }),
        permissions: ["pleat_pleat_additional_entry_create"]
      }
    ],
    route: {
      path: "/pleat_pleat_additional_entries/:pleatId/list",
      name: "pleat_pleat_additional_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        label: ({ compData }) =>
          `Wiązania dodatków dla wersji "${compData.pleat.name}"`
      }
    ],
    fields: [
      {
        field: "pleatAdditional.name",
        type: "text",
        label: "Nazwa dodatku",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "heightSurcharge",
        type: "text",
        label: "Dopłata wysokości [netto]",
        format: decimal
      },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za sztukę",
        format: decimal
      }
    ],
    resource: "pleat_pleat_additional_entries",
    url: ({ route }) =>
      `/pleats/${route.params.pleatId}/pleat_pleat_additional_entries?` +
        generateProperties([],['id', 'name', 'position','isEnabled', 'widthSurcharge', 'heightSurcharge', 'pieceSurcharge']) +
        generateProperties(['pleatAdditional'],['name'],true),
    onCreated: fetchPleat,
    rowActions: [
      {
        action: "edit",
        permissions: ["pleat_pleat_additional_entry_update"]
      },
      {
        action: "remove",
        permissions: ["pleat_pleat_additional_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["pleat_pleat_additional_entry_create"],
    route: {
      path: "/pleat_pleat_additional_entries/:pleatId/create",
      name: "pleat_pleat_additional_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_pleat_additional_entries_list",
          params: { pleatId: compData.pleat.id }
        }),
        label: ({ compData }) =>
          `Dodatki dla wersji "${compData.pleat.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.pleat.name}"`
      }
    ],
    formComponent: PleatPleatAdditionalEntryForm,
    onCreated: fetchPleat,
    listRoute: ({ compData }) => ({
      name: "pleat_pleat_additional_entries_list",
      params: { pleatId: compData.pleat.id }
    }),
    updateRoute: ({ object }) => ({
      name: "pleat_pleat_additional_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["pleat_pleat_additional_entry_update"],
    route: {
      path: "/pleat_pleat_additional_entries/:id/update",
      name: "pleat_pleat_additional_entries_update"
    },
    resource: "pleat_pleat_additional_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "pleats_list" }),
        label: () => "Plisy"
      },
      {
        route: ({ compData }) => ({
          name: "pleat_pleat_additional_entries_list",
          params: { pleatId: compData.object.pleat.id }
        }),
        label: ({ compData }) =>
          `Dodatki dla wersji "${compData.object.pleat.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania osprzętu "${compData.object.pleatAdditional.name}" z wersją "${compData.object.pleat.name}"`
      }
    ],
    formComponent: PleatPleatAdditionalEntryForm,
    onCreated: fetchPleat,
    listRoute: ({ compData }) => ({
      name: "pleat_pleat_additional_entries_list",
      params: {
        pleatId: compData.object.pleat.id
      }
    })
  }
};
