<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="pleats" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Symbol" field="symbol" :errors="errors")
                b-input(v-model="data.symbol")

              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Rodzaj cennika" field="name" :errors="errors")
                b-select(placeholder="Wybierz rodzaj cennika" v-model="data.priceListType" :disabled="true")
                  option(value="tabela")  Tabela - ceny w zależności od rozmiarów

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Widoczny

              .columns
                .column
                  app-form-field(label="Liczba KOLUMN cennika" field="priceListCols" :errors="errors")
                    b-input(type="number" v-model.number="data.priceListCols")
                .column
                  app-form-field(label="Liczba WIERSZY cennika" field="priceListRows" :errors="errors")
                    b-input(type="number" v-model.number="data.priceListRows")

              .columns
                .column
                  app-form-field(label="Minimalna szerokość (mm)" field="minWidth" :errors="errors")
                    app-form-length(v-model="data.minWidth" unit="mm")
                .column
                  app-form-field(label="Maksymalna szerokość (mm)" field="maxWidth" :errors="errors")
                    app-form-length(v-model="data.maxWidth" unit="mm")

              .columns
                .column
                  app-form-field(label="Minimalna wysokość (mm)" field="minHeight" :errors="errors")
                    app-form-length(v-model="data.minHeight" unit="mm")
                .column
                  app-form-field(label="Maksymalna wysokość (mm)" field="maxHeight" :errors="errors")
                    app-form-length(v-model="data.maxHeight" unit="mm")

              .columns
                .column
                  app-form-field(label="Domyślna cena montażu" field="defaultInstallationValue" :errors="errors")
                    app-form-decimal(v-model="data.defaultInstallationValue")
                .column

            .column
              app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                b-input(v-model="data.message" type="textarea")

        b-tab-item(label="Produkcja")
          .columns
            .column
              app-form-field(label="Ilość tkanin" field="fabricCount" :errors="errors")
                b-input(type="number" v-model.number="data.fabricCount")

              app-form-field(label="Ilość belek" field="balkCount" :errors="errors")
                b-input(type="number" v-model.number="data.balkCount")

              app-form-field(label="Szerokość belek" field="balkWidth" :errors="errors")
                app-form-length(v-model="data.balkWidth" unit="mm")

              app-form-field(label="Prowadnica" field="productionRunner" :errors="errors")
                app-form-length(v-model="data.productionRunner" unit="mm")

            .column
              app-form-field(label="Czas produkcji (minuty)" field="productionTime" :errors="errors")
                app-form-time-integer(v-model="data.productionTime")

              app-form-field(label="Plisa XL od (mm)" field="largePleatMargin" :errors="errors")
                app-form-length(v-model="data.largePleatMargin" unit="mm")

              app-form-field(label="Taśma maskująca (mm)" field="maskingTape" :errors="errors")
                app-form-length(v-model="data.maskingTape" unit="mm")

              app-form-field(field="isRequiredAdditionalStrain" :errors="errors")
                b-checkbox(v-model="data.isRequiredAdditionalStrain") Wymagany dodatkowy naciąg (wybranie opcji powoduje pojawienie się naciągu po prawej stronie w podglądzie)

          app-form-field(label="Korekta garbików" field="pleatTannerCorrections" :errors="errors")
            .columns
              .column.is-full
                b-button(
                  @click="addPleatTannerCorrection(data)"
                  type="is-primary"
                ) Dodaj korekte
                table.table.is-bordered
                  thead
                    tr
                      th Margines wysokości (mm)
                      th Korekta ilości garbików (szt)
                      th Akcje
                  tbody
                    tr(v-for="(pleatTannerCorrection, idx) in data.pleatTannerCorrections")
                      td
                        b-input(type="number" v-model.number="pleatTannerCorrection.heightMargin")
                      td
                        b-input(type="number" v-model.number="pleatTannerCorrection.tannerQuantityCorrection")
                      td
                        b-button(@click="removePleatTannerCorrection(data, idx)" type="is-danger") Usuń

          br
          br
          app-form-field(label="Długości linek: x * szerokość plisy + y * wysokość plisy + z, gdzie: x i y to liczby całkowite, z to zapas sznurka" field="pleatWireLengths" :errors="errors")
            .columns
              .column.is-full
                b-button(
                  @click="addPleatWireLength(data)"
                  type="is-primary"
                ) Dodaj długość
                table.table.is-bordered
                  thead
                    tr
                      th Margines szerokości (mm)
                      th Współczynnik szerokości (x)
                      th Współczynnik wysokości (y)
                      th Stała (z)
                      th Akcje
                  tbody
                    tr(v-for="(pleatWireLength, idx) in data.pleatWireLengths")
                      td
                        b-input(type="number" v-model.number="pleatWireLength.pleatWidthMargin")
                      td
                        b-input(type="number" v-model.number="pleatWireLength.pleatWidthRatio")
                      td
                        b-input(type="number" v-model.number="pleatWireLength.pleatHeightRatio")
                      td
                        b-input(type="number" v-model.number="pleatWireLength.constantValue")
                      td
                        b-button(@click="removePleatWireLength(data, idx)" type="is-danger") Usuń
        b-tab-item(label="Uwagi do cennika")
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Uwagi do cennika (${value})`" :field="`notesForPriceList${value}`" :errors="errors")
                app-form-editor(v-model="data[`notesForPriceList${value}`]")

        b-tab-item(label="Zdjęcia")
          app-form-field(label="Zdjęcie" field="picture" :errors="errors")
            app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")

          app-form-field(label="Zdjęcie pomiaru" field="surveyPicture" :errors="errors")
            app-form-file(v-model="files.surveyPicture" :image="data.surveyPicture" @removed="data.surveyPicture = {}")

          app-form-field(label="Zdjęcie wersji bok profil dla sklepu" field="profilePictureForShop" :errors="errors")
            app-form-file(v-model="files.profilePictureForShop" :image="data.profilePictureForShop" @removed="data.profilePictureForShop = {}")


        b-tab-item(label="Opisy pomiarów")
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Krótki opis pomiaru (${value})`" :field="`surveyDescription${value}`" :errors="errors")
                app-form-editor(v-model="data[`surveyDescription${value}`]")

        b-tab-item(label="Dla sklepu")
          b-tabs(type="is-boxed")
            b-tab-item(v-for="{ label, value } in languages" :label="label" :key="value")
              app-form-field(:label="`Rozszerzony tytuł (${value})`" :field="`extendedTitle${value}`" :errors="errors")
                b-input(v-model="data[`extendedTitle${value}`]")
              app-form-field(:label="`Opis wersji dla sklepu (${value})`" :field="`descriptionForShop${value}`" :errors="errors")
                app-form-editor(v-model="data[`descriptionForShop${value}`]")

        b-tab-item(label="Dokumenty")
          app-form-field(label="Plik instrukcja montażu" field="measurementInstruction" :errors="errors")
            app-form-file(v-model="files.measurementInstruction" :image="data.measurementInstruction" @removed="data.measurementInstruction = {}" :downloadable="true")

</template>
<script>
import { mapGetters } from "vuex";
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import simpleClone from "../../../helpers/simpleClone";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      files: {
        profilePictureForShop: {},
        picture: {},
        surveyPicture: {},
        measurementInstruction: {},
      },
      messageHelp:
          "Gdy użytkownik wybierze tą wersję, zostanie mu wyświetlony podany komunikat."
    };
  },
  computed: {
    ...mapGetters(["languages"]),
    model() {
      return {
        symbol: "",
        position: 0,
        name: "",
        priceListType: "tabela",
        isEnabled: true,
        message: "",
        priceListCols: 0,
        priceListRows: 0,
        minWidth: 0,
        maxWidth: 0,
        minHeight: 0,
        maxHeight: 0,
        defaultInstallationValue: 0,
        pieceSurcharge: 0,
        //produkcja
        fabricCount: 0,
        balkCount: 0,
        balkWidth: 0,
        productionRunner: 0,
        largePleatMargin: 0,
        maskingTape: 0,
        isRequiredAdditionalStrain: false,
        productionTime: 0,
        pleatTannerCorrections: [],
        pleatWireLengths: [],
        //docsy i zdjecia
        profilePictureForShop: null,
        picture: null,
        surveyPicture: null,
        measurementInstruction: null,
        //opisy
        notesForPriceListPL: "",
        notesForPriceListCZ: "",
        notesForPriceListEN: "",
        notesForPriceListDE: "",
        notesForPriceListFR: "",
        notesForPriceListSE: "",
        notesForPriceListIT: "",
        notesForPriceListSI: "",
        notesForPriceListRU: "",
        notesForPriceListES: "",
        notesForPriceListNL: "",
        surveyDescriptionPL: "",
        surveyDescriptionCZ: "",
        surveyDescriptionEN: "",
        surveyDescriptionDE: "",
        surveyDescriptionFR: "",
        surveyDescriptionSE: "",
        surveyDescriptionIT: "",
        surveyDescriptionSI: "",
        surveyDescriptionRU: "",
        surveyDescriptionES: "",
        surveyDescriptionNL: "",
        extendedTitlePL: "",
        descriptionForShopPL: "",
        extendedTitleCZ: "",
        descriptionForShopCZ: "",
        extendedTitleEN: "",
        descriptionForShopEN: "",
        extendedTitleDE: "",
        descriptionForShopDE: "",
        extendedTitleFR: "",
        descriptionForShopFR: "",
        extendedTitleSE: "",
        descriptionForShopSE: "",
        extendedTitleIT: "",
        descriptionForShopIT: "",
        extendedTitleSI: "",
        descriptionForShopSI: "",
        extendedTitleRU: "",
        descriptionForShopRU: "",
        extendedTitleES: "",
        descriptionForShopES: "",
        extendedTitleNL: "",
        descriptionForShopNL: "",
      };
    },
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(data) {
      return data;
    },
    getPleatTannerCorrectionsTemplate() {
      const obj = {
        heightMargin: 0,
        tannerQuantityCorrection: 0,
      };

      return simpleClone(obj);
    },
    addPleatTannerCorrection(data) {
      data.pleatTannerCorrections.push(this.getPleatTannerCorrectionsTemplate());
    },
    removePleatTannerCorrection(data, idx) {
      data.pleatTannerCorrections.splice(idx, 1);
    },
    getPleatWireLengthsTemplate() {
      const obj = {
        pleatWidthMargin: 0,
        pleatWidthRatio: 0,
        pleatHeightRatio: 0,
        constantValue: 0
      };

      return simpleClone(obj);
    },
    addPleatWireLength(data) {
      data.pleatWireLengths.push(this.getPleatWireLengthsTemplate());
    },
    removePleatWireLength(data, idx) {
      data.pleatWireLengths.splice(idx, 1);
    },
  },

};
</script>
