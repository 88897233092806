import GlobalRunnerForm from "../components/GlobalRunnerForm";

export default {
  list: {
    permissions: ["global_runner_read"],
    actions: [
      {
        label: "Nowa prowadnica",
        type: "is-primary",
        route: () => ({
          name: "global_runners_create"
        }),
        permissions: ["global_runner_create"]
      }
    ],
    route: {
      path: "/global_runners/list",
      name: "global_runners_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        label: () => `Prowadnice`
      }
    ],
    fields: [
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "position", type: "text", label: "Numer", filter: "text" }
    ],
    resource: "global_runners",
    url: () =>
      `/global_runners?properties[]=id&properties[]=symbol&properties[]=name&properties[]=isEnabled&properties[]=number&properties[]=position`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_runner_update"]
      },
      {
        action: "remove",
        permissions: ["global_runner_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_runner_create"],
    route: {
      path: "/global_runners/create",
      name: "global_runners_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "global_runners_list"
        }),
        label: () => `Prowadnice`
      },
      {
        label: () => `Nowa prowadnica`
      }
    ],
    formComponent: GlobalRunnerForm,
    listRoute: () => ({
      name: "global_runners_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_runners_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_runner_update"],
    route: {
      path: "/global_runners/:id/update",
      name: "global_runners_update"
    },
    resource: "global_runners",
    breadcrumbs: [
      {
        route: () => ({
          name: "global_runners_list"
        }),
        label: () => `Prowadnice`
      },
      {
        label: ({ compData }) => `Edycja prowadnicy "${compData.object.name}"`
      }
    ],
    formComponent: GlobalRunnerForm,
    listRoute: () => ({
      name: "global_runners_list"
    })
  }
};
