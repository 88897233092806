<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="production_workers" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-card(title="Dane firmy")

                app-form-field(label="Imię" field="firstName" :errors="errors")
                  b-input(v-model="data.firstName")

                app-form-field(label="Nazwisko" field="lastName" :errors="errors")
                  b-input(v-model="data.lastName")

                app-form-field(label="Inicjały" field="initials" :errors="errors")
                  b-input(v-model="data.initials")

                app-form-field(field="isEnabled" :errors="errors")
                  b-checkbox(v-model="data.isEnabled") Aktywny

                app-form-field(label="Kolejność" field="position" :errors="errors")
                  b-input(type="number" @input="data.position = +$event" :value="data.position")

            .column
              app-card(title="Monter")
                app-form-field(label="Typy produktów" field="fitterProductTypes" :errors="errors")
                  multiple-resource-select(
                    v-model="data.fitterProductTypes"
                    resource="product_types"
                    url="product_types"
                    placeholder="Wybierz domyślne produkty"
                  )
            .column
              app-card(title="Pilarz")
                app-form-field(label="Typy produktów" field="sawyerProductTypes" :errors="errors")
                  multiple-resource-select(
                    v-model="data.sawyerProductTypes"
                    resource="product_types"
                    url="product_types"
                    placeholder="Wybierz domyślne produkty"
                  )
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        initials: "",
        isEnabled: true,
        sawyerProductTypes: [],
        fitterProductTypes: [],
        position: 1
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(object) {
      this.model.sawyerProductTypes = object.sawyerProductTypes.map(
        type => (type && type["@id"]) || type
      );
      this.model.fitterProductTypes = object.fitterProductTypes.map(
        type => (type && type["@id"]) || type
      );
      return object;
    }
  }
};
</script>
