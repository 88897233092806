import SubCompanyForm from "../components/SubCompanyForm";
import fetchCompany from "@/modules/management/helpers/fetchCompany";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["sub_company_read"],
    sortField: "shortName",
    sortOrder: "asc",
    route: {
      path: "/sub_companies/:companyId/list",
      name: "sub_companies_list"
    },
    breadcrumbs: [
      {
        route: () => ({name: "companies_list"}),
        label: () => "Odbiorcy"
      },
      {
        label: ({compData}) =>
          `Pododbiorcy dla odbiorcy: "${compData.company.name}"`
      }
    ],
    fields: [
      {
        field: "shortName",
        type: "text",
        label: "Nazwa skrócona",
        filter: "text"
      },
      {field: "taxId", type: "text", label: "NIP", filter: "text"},
      {
        field: "streetAndNumber",
        type: "text",
        label: "Ulica",
        filter: "text"
      },
      {field: "city", type: "text", label: "Miejscowość", filter: "text"},
      {field: "zipCode", type: "text", label: "Kod", filter: "text"},
      {field: "phone", type: "text", label: "Telefon", filter: "text"},
      {
        field: "hasInvoicingByProducer",
        type: "virtual",
        label: "Fakturowanie przez producenta",
        value: (h, row) => (row.hasInvoicingByProducer ? "Tak" : "Nie")
      },
      {
        field: "hasShipmentToSubCompany",
        type: "virtual",
        label: "Wysyłka bezpośrednia",
        value: (h, row) => (row.hasShipmentToSubCompany ? "Tak" : "Nie")
      },
      {
        field: "companyOwner.isEnabled",
        type: "virtual",
        label: "Aktywny (admin)",
        value: (h, row) => (row.companyOwner.isEnabled ? "Tak" : "Nie")
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny (app)",
        filter: "boolean"
      },
      {
        field: 'isVisible',
        type: 'boolean',
        label: 'Widoczny',
      }
    ],
    resource: "sub_companies",
    url: ({route}) =>
      '/companies/' + route.params.companyId + '/sub_companies?' +
      generateProperties([], ["id", "shortName", "taxId", "streetAndNumber", "city", "zipCode", "phone", "isEnabled", "isVisible", "hasInvoicingByProducer", "hasShipmentToSubCompany", 'needSupportWithAddLogo'], true)+
      generateProperties(["companyOwner"], ["isEnabled"], true),
    onCreated: fetchCompany,
    rowActions: [
      {
        action: "edit",
        permissions: ["sub_company_update"]
      },
      {
        action: "custom",
        icon: "view-list",
        title: "Lista zamówień",
        permissions: ["sub_companies_read"],
        route: ({row}) => ({
          name: "sub_company_orders",
          params: {
            subCompanyId: row.id,
          },
        })
      },
    ]
  },
  update: {
    permissions: ["sub_company_update"],
    route: {
      path: "/sub_companies/:id/update",
      name: "sub_companies_update"
    },
    resource: "sub_companies",
    breadcrumbs: [
      {
        route: () => ({name: "companies"}),
        label: () => "Odbiorcy"
      },
      {
        route: ({compData}) => ({
          name: "sub_companies_list",
          params: {companyId: compData.object.company.id}
        }),
        label: ({compData}) =>
          `Pododbiorcy dla odbiorcy "${compData.object.company.name}"`
      },
      {
        label: ({compData}) => `Edycja pododbiorcy "${compData.object.name}"`
      }
    ],
    formComponent: SubCompanyForm,
    onCreated: fetchCompany,
    listRoute: ({compData}) => ({
      name: "sub_companies_list",
      params: {
        companyId: compData.object.company.id
      }
    })
  }
};
