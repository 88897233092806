<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="admin_workers")
    template(#default="{ data, errors }")
      b-tabs
        b-tab-item(label="Ogólne")
          .subtitle Pracownik administracyjny
          .columns
            .column
              app-form-field(label="Imię" field="firstName" :errors="errors")
                b-input(v-model="data.firstName")

              app-form-field(label="Nazwisko" field="lastName" :errors="errors")
                b-input(v-model="data.lastName")

              app-form-field(label="E-mail" field="email" :errors="errors")
                b-input(v-model="data.email")
            .column
              app-form-field(label="Login" field="username" :errors="errors")
                b-input(v-model="data.username" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off")

              app-form-field(label="Hasło" field="plainPassword" :errors="errors")
                b-input(v-model="data.plainPassword" type="password" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off")

              .columns
                .column.is-6
                  app-form-field(label="Aktywny" field="isEnabled" :errors="errors")
                    b-checkbox(v-model="data.isEnabled")
                .column.is-6
                  div.has-text-right
                    b-button(type="is-danger" :loading="loading" @click="sendResetPassword" ) Wymuś zmianę hasła
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      loading: false,
      isReadOnly: true,
      error: null,
      model: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        plainPassword: "",
        isEnabled: true
      }
    };
  },
  methods: {
    async sendResetPassword(e) {
      e.preventDefault() // prevent submit

      this.loading = true;
      try {
        await this.$store.dispatch("auth/resetPassword", {email: this.model.email});
        this.$notify("Wysłaliśmy na podany e-mail z instrukcjami resetowania hasła.");
      } catch (e) {
        this.error = e.toString();
      }
      this.loading = false;
    }
  }
};
</script>
