<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="product_types" :custom-validation="validation" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Nazwa" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(label="Kod" field="code" :errors="errors")
                                b-input(v-model="data.code" :disabled="isDisabled")

                            app-form-field(field="isEnabled" :errors="errors")
                                b-checkbox(v-model="data.isEnabled") Aktywny
                        .column
                            app-form-field(label="Linie produkcyjne" field="productionLines" :errors="errors" )
                                .columns.is-multiline
                                    .column.is-12
                                        .columns
                                            .column
                                                app-form-field(label="Nazwa" field="lineName" :errors="errors" )
                                                    b-input(v-model="productionLineModel.name")
                                            .column
                                                app-form-field(label="Numer" field="lineNumber" :errors="errors" )
                                                    b-input(v-model="productionLineModel.number" type="number" min="0")
                                            .column
                                                app-form-field(label="Kolor" field="lineColor" :errors="errors" )
                                                    b-input(type="color" v-model="productionLineModel.lineColor")
                                            .column
                                                b-button(type="is-primary" class="has-margin-top-27" @click="addProductionLine(data, errors)") Dodaj
                                    .column.is-12.is-paddingless
                                        .divider.is-horizontal
                                    .column.is-12(v-for="(line, idx) in data.productionLines")
                                        .columns
                                            .column
                                                app-form-field(label="Nazwa" :field="'lineName' + idx" :errors="errors" )
                                                    b-input(v-model="line.name")
                                            .column
                                                app-form-field(label="Numer" :field="'lineNumber' + idx" :errors="errors" )
                                                  b-input(v-model="line.number" type="number" min="0")
                                            .column
                                                app-form-field(label="Kolor" :field="'lineColor' + idx" :errors="errors" )
                                                    b-input(type="color" v-model="line.lineColor")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import simpleClone from "../../../helpers/simpleClone";

export default {
  mixins: [form],
  data() {
    return {
      productionLineModel: {
        name: null,
        number: null,
        lineColor: "#000000"
      },
      model: {
        name: "",
        code: "",
        isEnabled: true,
        productionLines: []
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      for (const idx in data.productionLines) {
        data.productionLines[idx].number = parseInt(data.productionLines[idx].number);
      }
      return data;
    },
    addProductionLine(data, errors) {
      let foundError = false;
      if (!this.productionLineModel.name || !this.productionLineModel.name.trim().length) {
        errors.push({
          propertyPath: "lineName",
          message: "Ta wartość nie może być pusta."
        });
        foundError = true;
      } else {
        errors.splice(
          errors.findIndex(({ propertyPath }) => propertyPath === "lineName"),
          1
        );
      }
      if (!this.productionLineModel.number || !this.productionLineModel.number.trim().length || isNaN(this.productionLineModel.number)) {
        errors.push({
          propertyPath: "lineNumber",
          message: "Ta wartość nie jest liczbą."
        });
        foundError = true;
      } else {
        errors.splice(
          errors.findIndex(({ propertyPath }) => propertyPath === "lineNumber"),
          1
        );
      }
      if (!foundError) {
        data.productionLines.push(simpleClone(this.productionLineModel));
        this.productionLineModel.name = "";
        this.productionLineModel.number = null;
        this.productionLineModel.lineColor = "#000000";
      }
    },
    validation(data, errors) {
      const lines = data.productionLines;
      for (let idx in lines) {
        const line = lines[idx];
        if (!line.name || !line.name.trim().length) {
          errors.push({
            propertyPath: "lineName" + idx,
            message: "Ta wartość nie może być pusta."
          });
        } else {
          errors.splice(
            errors.findIndex(
              ({ propertyPath }) => propertyPath === "lineName" + idx
            ),
            1
          );
        }
        if (!line.number || isNaN(line.number)) {
          errors.push({
            propertyPath: "lineNumber" + idx,
            message: "Ta wartość nie jest liczbą."
          });
        } else {
          errors.splice(
            errors.findIndex(
              ({ propertyPath }) => propertyPath === "lineNumber" + idx
            ),
            1
          );
        }
      }
    }
  },
  computed: {
    isDisabled() {
      return this.$router.currentRoute.path.includes("update");
    }
  }
};
</script>
