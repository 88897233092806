<template lang="pug">
    form(@submit.prevent="save" class="is-relative" autocomplete="off")
        b-loading(:is-full-page="false" :active="submitting")
        slot(:errors="errors" :data="formData")
        b-field(class="has-margin-bottom")
          .buttons
            b-button(v-if="showSave" native-type="submit" type="is-primary" :loading="submitting") Zapisz
            b-button(v-if="showSaveAndClose" native-type="button" type="is-default" :loading="submitting" @click="saveAndClose") Zapisz i zamknij
</template>
<script>
import { uploadMedia } from "@/modules/media_objects/helpers";
import simpleClone from "../../../../helpers/simpleClone";
import flatter from "../../../../helpers/flatter";

export default {
  props: {
    resource: { type: String, required: true },
    transformToSubmit: { type: Function },
    model: { type: Object, required: true },
    object: { type: Object },
    files: {type: [Object, Array]},
    decimals: { type: Array },
    transformToModel: { type: Function },
    customValidation: { type: Function },
    customAsyncValidation: { type: Function },
    formDataShouldBeObject: { type: Boolean, default: false },
    showSave: {Type: Boolean, default: true},
    showSaveAndClose: {Type: Boolean, default: true}
  },
  data() {
    return {
      submitting: false,
      errors: [],
      formData: this.formDataShouldBeObject ? this.object : this.model
    };
  },
  methods: {
    replaceObject(obj) {
      this.formData.id = obj.id;
      for (let field in this.model) {
        this.formData[field] = obj[field];
      }
      if (this.transformToModel) {
       this.formData = this.transformToModel(this.formData);
      }
      this.$emit("object-changed", this.formData);
    },
    flat(formData) {
      return flatter(formData);
    },
    async submit(callback) {
      this.submitting = true;
      this.errors = [];
      let formData = this.transformToSubmit
        ? this.transformToSubmit(simpleClone(this.formData))
        : simpleClone(this.formData);
      if (this.customValidation) {
        await this.customValidation(formData, this.errors);
        if (this.errors.length > 0) {
          this.submitting = false;
          this.$notify("Formularz zawiera błędy", "danger");
          return;
        }
      }
      for (let field in this.files) {
        delete formData[field];
      }
      // flat relations
      // this.flat(formData); //  not used, becouse crash armors


      if (this.files) for (const [key, value] of Object.entries(this.files)) {
        if (value instanceof Array) await Promise.all(uploadMedia(value, key, formData, true));
        else await Promise.all(uploadMedia(value, key, formData, false));
      }

      try {
        let { data } = await this.$http[this.method](this.url, formData);
        this.submitting = false;
        this.$notify("Zapisano");
        callback(data);
        this.replaceObject(data);
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.errors = e.response.data.violations;
          this.$notify("Formularz zawiera błędy", "danger");
        }
        this.submitting = false;
        throw e;
      }
    },
    async saveAndClose() {
      await this.submit(data => {
        this.$emit("close", data);
      });
    },
    async save() {
      await this.submit(data => {
        this.$emit("success", data);
      });
    }
  },
  computed: {
    method() {
      return this.isUpdate ? "put" : "post";
    },
    isUpdate() {
      return !!this.formData.id;
    },
    url() {
      let url = `${this.resource}`;
      if (this.isUpdate) {
        url += `/${this.formData.id}`;
      }
      return url;
    }
  },
  watch: {
    object(val) {
      this.replaceObject(val);
    }
  }
};
</script>
