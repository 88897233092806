import WholesalePriceListForm from "../components/WholesalePriceListForm";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["wholesale_price_list_read"],
    actions: [
      {
        label: "Nowy cennik hurtowy",
        type: "is-primary",
        route: () => ({
          name: "wholesale_price_lists_create"
        }),
        permissions: ["wholesale_price_list_create"]
      }
    ],
    route: {
      path: "/wholesale_price_lists/list",
      name: "wholesale_price_lists_list"
    },
    breadcrumbs: [
      {
        label: () => "Cenniki hurtowe"
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: "Nazwa",
        filter: "text"
      },
      {
        field: "percentMargin",
        type: "text",
        label: "Marża procentowa [%]",
        format: decimal
      },
      {
        field: "amountMargin",
        type: "text",
        label: "Marża kwotowa [pln]",
        format: decimal
      },
      {
        field: "percentDiscount",
        type: "text",
        label: "Rabat procentowy [%]",
        format: decimal
      },
      {
        field: "amountDiscount",
        type: "text",
        label: "Rabat kwotowy [pln]",
        format: decimal
      }
    ],
    resource: "wholesale_price_lists",
    url: () =>
      `/wholesale_price_lists?properties[]=id&properties[]=name&properties[]=percentMargin&properties[]=amountMargin&properties[]=percentDiscount&properties[]=amountDiscount`,
    rowActions: [
      {
        action: "edit",
        permissions: ["wholesale_price_list_update"]
      },
      {
        action: "remove",
        permissions: ["wholesale_price_list_delete"]
      }
    ]
  },
  create: {
    permissions: ["wholesale_price_list_create"],
    route: {
      path: "/wholesale_price_lists/create",
      name: "wholesale_price_lists_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "wholesale_price_lists_list" }),
        label: () => "Cenniki hurtowe"
      },
      {
        label: () => `Nowy cennik hurtowy`
      }
    ],
    formComponent: WholesalePriceListForm,
    listRoute: () => ({
      name: "wholesale_price_lists_list"
    }),
    updateRoute: ({ object }) => ({
      name: "wholesale_price_lists_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["wholesale_price_list_update"],
    route: {
      path: "/wholesale_price_lists/:id/update",
      name: "wholesale_price_lists_update"
    },
    resource: "wholesale_price_lists",
    breadcrumbs: [
      {
        route: () => ({ name: "wholesale_price_lists_list" }),
        label: () => "Cenniki hurtowe"
      },
      {
        label: ({ compData }) =>
          `Edycja cennika hurtowego dla "${compData.object.name}"`
      }
    ],
    formComponent: WholesalePriceListForm,
    listRoute: () => ({ name: "wholesale_price_lists_list" })
  }
};
