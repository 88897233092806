import CurrencyForm from "../components/CurrencyForm";

export default {
  list: {
    permissions: ["currency_read"],
    actions: [
      {
        label: "Nowa waluta",
        type: "is-primary",
        route: () => ({
          name: "currencies_create"
        }),
        permissions: ["currency_create"]
      }
    ],
    route: {
      path: "/currencies/list",
      name: "currencies_list"
    },
    breadcrumbs: [
      {
        label: () => "Waluty"
      }
    ],
    fields: [{ field: "code", type: "text", label: "Kod", filter: "text" }],
    resource: "currencies",
    url: () => `/currencies?properties[]=id&properties[]=code`,
    rowActions: [
      {
        action: "edit",
        permissions: ["currency_update"]
      },
      {
        action: "remove",
        permissions: ["currency_delete"]
      }
    ]
  },
  create: {
    permissions: ["currency_create"],
    route: {
      path: "/currencies/create",
      name: "currencies_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "currencies_list" }),
        label: () => "Waluty"
      },
      {
        label: () => `Nowa waluta`
      }
    ],
    formComponent: CurrencyForm,
    listRoute: () => ({
      name: "currencies_list"
    }),
    updateRoute: ({ object }) => ({
      name: "currencies_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["currency_update"],
    route: {
      path: "/currencies/:id/update",
      name: "currencies_update"
    },
    resource: "currencies",
    breadcrumbs: [
      {
        route: () => ({ name: "currencies_list" }),
        label: () => "Waluty"
      },
      {
        label: ({ compData }) => `Edycja kraju "${compData.object.name}"`
      }
    ],
    formComponent: CurrencyForm,
    listRoute: () => ({ name: "currencies_list" })
  }
};
