import crud from "../../crud";
import doorMosquitoNets from "./crud/doorMosquitoNets";
import doorMosquitoNetGlobalAccessoryEntries from "./crud/doorMosquitoNetGlobalAccessoryEntries";
import doorMosquitoNetGlobalNetEntries from "./crud/doorMosquitoNetGlobalNetEntries";
import doorMosquitoNetDoorMosquitoNetAdditionalEntries from "./crud/doorMosquitoNetDoorMosquitoNetAdditionalEntries";
import doorMosquitoNetPriceLists from "./crud/doorMosquitoNetPriceLists";
import doorMosquitoNetAdditionals from "./crud/doorMosquitoNetAdditionals";
import doorMosquitoNetFrames from "./crud/doorMosquitoNetFrames";
import doorMosquitoNetBottomRails from "./crud/doorMosquitoNetBottomRails";

crud(doorMosquitoNets);
crud(doorMosquitoNetGlobalAccessoryEntries);
crud(doorMosquitoNetGlobalNetEntries);
crud(doorMosquitoNetDoorMosquitoNetAdditionalEntries);
crud(doorMosquitoNetPriceLists);
crud(doorMosquitoNetAdditionals);
crud(doorMosquitoNetFrames);
crud(doorMosquitoNetBottomRails);
