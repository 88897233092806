<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="production_free_days" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
    template(#default="{ data, errors }")
      .has-text-centered.has-font-25 Pamiętaj o przeniesieniu zamówień po ustaleniu dnia wolnego
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
                app-form-field(label="Nazwa robocza" field="name" :errors="errors")
                  b-input(v-model="data.name")

                app-form-field(label="Data" field="date" :errors="errors")
                  DatePicker(v-model="data.date" type="day" value-type="format" format="DD-MM-YYYY" :clearable="false")
            .column

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import DatePicker from "vue2-datepicker";

export default {
  mixins: [form],
  components: {
    DatePicker
  },
  data() {
    return {
      model: {
        name: "Dzień wolny",
        date: null,
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(data) {
      return data;
    }
  }
};
</script>
