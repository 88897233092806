<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="pleat_global_accessory_entries" :transform-to-submit="transformToSubmit" )
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Dopłata szerokości zł / m" field="widthSurcharge" :errors="errors")
                app-form-decimal(v-model="data.widthSurcharge")

              app-form-field(label="Dopłata wysokości zł / m" field="heightSurcharge" :errors="errors")
                app-form-decimal(v-model="data.heightSurcharge")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny

              app-form-field(field="skipCalculations" :errors="errors")
                b-checkbox(v-model="data.skipCalculations") Pomiń kalkulacje cennikowe

            .column
              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(label="Osprzęt" field="globalAccessory" :errors="errors")
                resource-select(
                  v-model="data.globalAccessory"
                  resource="global_accessories"
                  url="global_accessories"
                  placeholder="Wybierz osprzęt"
                  extra-props="&properties[]=symbol&order[name]=asc"
                  :labels="['symbol']"
                )

              app-form-field(label="Kolor" field="picture" :errors="errors")
                app-form-file(v-model="files.picture" :image="data.picture" @removed="data.picture = {}")



</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect.vue";
import http from "@/http";
import checkLastPosition from "../../../helpers/checkLastPosition";
//TO JEST KOLOR
export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      model: {
        position: null,
        widthSurcharge: 0,
        heightSurcharge: 0,
        skipCalculations: false,
        isEnabled: true,
        picture: "",
        pleat: `/pleats/${this.$route.params.pleatId}`, // Update resource name
        globalAccessory: null,
        pleatGlobalBalkEntry: null // Update field name
      },
      pleatId: this.$route.params.pleatId,
      downloadedPleatId: null, // Update variable name
      files: {
        picture: {},
        thumbnail: {}
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      if (data.pleat && data.pleat["@id"]) { // Update resource name
        data.pleat = data.pleat["@id"]; // Update field name
      }
      if (data.globalAccessory && data.globalAccessory["@id"]) {
        data.globalAccessory = data.globalAccessory["@id"];
      }
      return data;
    }
  },
  async beforeCreate() {
    if (this.$route.params.id) {
      const { data } = await http.get(
          `/pleat_global_accessory_entries/${this.$route.params.id}?properties[pleat][]=id` // Update resource name
      );
      if (data.pleat) { // Update field name
        this.downloadedPleatId = data.pleat.id; // Update variable name
      }
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
