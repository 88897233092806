<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-narrow
      thead
        tr
            th Lp.
            th Wersja
            th Profil pancerze
            th Kolor pancerza
            th Kolor zewn. skrzynki
            th Kolor wewn. skrzynki
            th Kolor prowadnic
            th Kolor listwy dolnej
        tr
      tbody
        template(v-for="(item, index) in items")
          external-shutter-order-item-after-sum(
            :item="item"
            :index="index"
          )
</template>
<script>

import ExternalShutterOrderItemAfterSum from "./ExternalShutterOrderItemAfterSum";
import simpleClone from "../../../helpers/simpleClone";

export default {
  props: ['items'],
  components: {ExternalShutterOrderItemAfterSum },
};
</script>
