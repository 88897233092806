<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="bug_reports" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(:label="'Ogólne'")
          .columns
            .column
              app-form-field(:label="'Nazwa'" field="name" :errors="errors" required)
                b-input(v-model="data.name" disabled)
            .column
              app-form-field(:label="'Platforma'" field="platform" :errors="errors" required)
                b-input(v-model="data.platform" disabled)
            .column
              app-form-field(label="Czy urządzenie mobilne?" field="isMobile" :errors="errors")
                b-checkbox(v-model="data.isMobile") TAK/NIE
            .column
              app-form-field(label="Status" field="status" :errors="errors")
                b-select(v-model="data.status")
                  option(value="new") Nowy
                  option(value="accepted") Przyjęty
                  option(value="transferred") Przekazany do DGCS.NET
                  option(value="closed") Zamknięty

          .columns
            .column
              app-form-field(label="UserAgent" field="userAgent" :errors="errors")
                b-input(v-model="data.userAgent" disabled)
          .column(v-if="data.file && data.file.contentUrl")
            .column.is-one-fifth
              a(
                class="button is-primary"
                :href="data.file.contentUrl"
                target="_blank"
                download
              ) Plik użytkownika
          .columns(v-if=" object && object.bugReportMessages && object.bugReportMessages.length > 0")
            .divider
            .column
              .columns(v-for="report in data.bugReportMessages")
                .column
                  .is-right(v-if="report.author.id !== userId")
                    b-message(
                      type="is-success"
                      :title="`${report.author.firstName} ${report.author.lastName}  ${report.createdAt}`"
                      :closable="false"
                    )
                      div(v-html="report.message")

                .column
                  .is-left(v-if="report.author.id === userId")
                    b-message(
                      type="is-info"
                      :title="`${report.author.firstName} ${report.author.lastName} ${report.createdAt}`"
                      :closable="false"
                    )
                      div(v-html="report.message")
            .divider
          .columns
            .column
              app-form-field(:label="'Wiadomość'" field="newMessage" :errors="errors" )
                app-form-editor(v-model="data.newMessage")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import { mapGetters } from "vuex";
import http from "../../../http";
import simpleClone from "../../../helpers/simpleClone";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        bugReportMessages: [],
        status: "new", //invisible
        file: null,
        isMobile: navigator.userAgentData.mobile, //invisible
        userAgent: navigator.userAgent, //invisible
        platform: navigator.platform, //invisible
        newMessage: "",
        createdAt: "", //not procesible
        updatedAt: "" //not procesible
      },
      files: {
        file: {}
      }
    };
  },
  methods: {
    transformToSubmit(formData) {
      delete formData.createdAt;
      delete formData.updatedAt;

      return formData;
    },
    transformToModel(data) {
      data.newMessage = "";
      if (this.$route.name.includes("update")) {
        let toSend = simpleClone(data);
        delete toSend.author;
        delete toSend.bugReportMessages;
        delete toSend.file;
        http.put(`/bug_reports/${toSend.id}/read`, toSend);
      }

      return data;
    }
  },
  computed: {
    ...mapGetters("auth", ["userId"])
  }
};
</script>
