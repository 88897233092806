<template lang="pug">
    b-input(
        :custom-class="classes",
        v-model="formatted"
        @focus="$event.target.select()"
        :size="size"
    )
</template>
<script>
export default {
  props: {
    value: Number,
    unit: { type: String, default: "mm" },
    size: { type: String, default: "" },
    classes: String
  },
  computed: {
    factor() {
      if (this.unit === "mm") {
        return 1;
      }
      if (this.unit === "cm") {
        return 10;
      }
      if (this.unit === "m") {
        return 1000;
      }
      throw new Error("Unrecognized unit type");
    },
    formatted: {
      set(value) {
        let lastElement = value.substring(value.length - 1);
        const isLastPeriod = lastElement === "," || lastElement === ".";
        if (isLastPeriod) {
          return;
        }
        value = value.replace(",", ".");
        const notANumber = value === "" || isNaN(value);
        const newValue = notANumber ? null : parseFloat(value) * this.factor;
        this.$emit("input", newValue);
      },
      get() {
        return this.value === null
          ? this.value
          : parseFloat(this.value / this.factor)
              .toString()
              .replace(".", ",");
      }
    }
  }
};
</script>
