import FabricShadeGlobalFixingEntryForm from "../components/FabricShadeGlobalFixingEntryForm";
import decimal from "../../../helpers/decimal";
import fetchFabricShade from "@/modules/fabric_shades/helpers/fetchFabricShade";

export default {
  list: {
    permissions: ["fabric_shade_global_fixing_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "fabric_shade_global_fixing_entries_create",
          params: {
            fabricShadeId: route.params.fabricShadeId
          }
        }),
        permissions: ["fabric_shade_global_fixing_entry_create"]
      }
    ],
    route: {
      path: "/fabric_shade_global_fixing_entries/:fabricShadeId/list",
      name: "fabric_shade_global_fixing_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        label: ({ compData }) =>
          `Wiązania mocowań dla wersji "${compData.fabricShade.name}"`
      }
    ],
    fields: [
      {
        field: "globalFixing.symbol",
        type: "text",
        label: "Symbol mocowania",
        filter: "text"
      },
      {
        field: "globalFixing.name",
        type: "text",
        label: "Nazwa mocowania",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "widthSurcharge",
        type: "text",
        label: "Dopłata szerokości [netto]",
        format: decimal
      },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopłata za sztukę [netto]",
        format: decimal
      }
    ],
    resource: "fabric_shade_global_fixing_entries",
    url: ({ route }) =>
      `/fabric_shades/${route.params.fabricShadeId}/fabric_shade_global_fixing_entries?properties[]=id&properties[]=position&properties[]=widthSurcharge&properties[]=isEnabled&properties[]=pieceSurcharge&properties[globalFixing][]=name&properties[globalFixing][]=symbol`,
    onCreated: fetchFabricShade,
    rowActions: [
      {
        action: "edit",
        permissions: ["fabric_shade_global_fixing_entry_update"]
      },
      {
        action: "remove",
        permissions: ["fabric_shade_global_fixing_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["fabric_shade_global_fixing_entry_create"],
    route: {
      path: "/fabric_shade_global_fixing_entries/:fabricShadeId/create",
      name: "fabric_shade_global_fixing_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_global_fixing_entries_list",
          params: { fabricShadeId: compData.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Mocowania dla wersji "${compData.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla wersji "${compData.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadeGlobalFixingEntryForm,
    onCreated: fetchFabricShade,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_global_fixing_entries_list",
      params: { fabricShadeId: compData.fabricShade.id }
    }),
    updateRoute: ({ object }) => ({
      name: "fabric_shade_global_fixing_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["fabric_shade_global_fixing_entry_update"],
    route: {
      path: "/fabric_shade_global_fixing_entries/:id/update",
      name: "fabric_shade_global_fixing_entries_update"
    },
    resource: "fabric_shade_global_fixing_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Roletki tkaninowe"
      },
      {
        route: ({ compData }) => ({
          name: "fabric_shade_global_fixing_entries_list",
          params: { fabricShadeId: compData.object.fabricShade.id }
        }),
        label: ({ compData }) =>
          `Mocowania dla wersji "${compData.object.fabricShade.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania mocowania "${compData.object.globalFixing.name}" z wersją "${compData.object.fabricShade.name}"`
      }
    ],
    formComponent: FabricShadeGlobalFixingEntryForm,
    onCreated: fetchFabricShade,
    listRoute: ({ compData }) => ({
      name: "fabric_shade_global_fixing_entries_list",
      params: {
        fabricShadeId: compData.object.fabricShade.id
      }
    })
  }
};
