<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="currencies")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .columns
                        .column
                            app-form-field(label="Kod" field="code" :errors="errors")
                                b-input(v-model="data.code")
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";

export default {
  mixins: [form],
  components: {},
  data() {
    return {
      model: {
        code: ""
      }
    };
  }
};
</script>
