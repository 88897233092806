<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="pleat_groups")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              app-form-field(label="Nazwa" field="name" :errors="errors")
                b-input(v-model="data.name")

              app-form-field(label="Kolejność" field="position" :errors="errors")
                b-input(type="number" @input="data.position = +$event" :value="data.position")

              app-form-field(field="isEnabled" :errors="errors")
                b-checkbox(v-model="data.isEnabled") Aktywny
            .column
              app-form-field(label="Komunikat" field="message" :errors="errors" :help="messageHelp")
                b-input(v-model="data.message" type="textarea")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import checkLastPosition from "../../../helpers/checkLastPosition";

export default {
  mixins: [form],
  data() {
    return {
      messageHelp:
          "Gdy użytkownik wybierze tą grupę, zostanie mu wyświetlony podany komunikat."
    };
  },
  computed: {
    model() {
      return {
        position: 0,
        name: "",
        message: "",
        isEnabled: true
      };
    }
  },
  async created() {
    this.model.position = await checkLastPosition(this.$router);
  }
};
</script>
