import ExternalShutterRunnerFabricShadeColorEntryForm from "../components/ExternalShutterRunnerFabricShadeColorEntryForm";
import fetchExternalShutterRunner from "../helpers/fetchExternalShutterRunner";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_runner_fabric_shade_color_entry_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "external_shutter_runner_fabric_shade_color_entries_create",
          params: {
            externalShutterRunnerId: route.params.externalShutterRunnerId
          }
        }),
        permissions: ["external_shutter_runner_fabric_shade_color_entry_create"]
      }
    ],
    route: {
      path: "/external_shutter_runner_fabric_shade_color_entries/:externalShutterRunnerId/list",
      name: "external_shutter_runner_fabric_shade_color_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_runners_list" }),
        label: () => "Prowadnice"
      },
      {
        label: ({ compData }) =>
          `Wiązania kolorów z prowadnicą "${compData.externalShutterRunner.name}"`
      }
    ],
    fields: [
      {
        field: "fabricShadeColor.name",
        type: "text",
        label: "Kolor prowadnicy",
        filter: "text"
      },
      {
        field: "lengthSurcharge",
        type: "text",
        label: "Dopłata za za mb",
        format: decimal
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "external_shutter_runner_fabric_shade_color_entries",
    url: ({ route }) =>
      `/external_shutter_runners/${route.params.externalShutterRunnerId}/external_shutter_runner_fabric_shade_color_entries?` +
      generateProperties([], ["id", "position", "isEnabled", "lengthSurcharge"]) +
      generateProperties(["externalShutterRunner"], ["id", "name"], true) +
      generateProperties(["fabricShadeColor"], ["name"], true),
    onCreated: fetchExternalShutterRunner,
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_runner_fabric_shade_color_entry_update"]
      },
      {
        action: "remove",
        permissions: ["external_shutter_runner_fabric_shade_color_entry_delete"]
      }
    ]
  },
  create: {
    permissions: ["external_shutter_runner_fabric_shade_color_entry_create"],
    route: {
      path: "/external_shutter_runner_fabric_shade_color_entries/:externalShutterRunnerId/create",
      name: "external_shutter_runner_fabric_shade_color_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "fabric_shades_list" }),
        label: () => "Prowadnice"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_runner_fabric_shade_color_entries_list",
          params: { externalShutterRunnerId: compData.externalShutterRunner.id }
        }),
        label: ({ compData }) =>
          `Kolory prowadnic "${compData.externalShutterRunner.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowe wiązanie dla prowadnicy "${compData.externalShutterRunner.name}"`
      }
    ],
    formComponent: ExternalShutterRunnerFabricShadeColorEntryForm,
    onCreated: fetchExternalShutterRunner,
    listRoute: ({ compData }) => ({
      name: "external_shutter_runner_fabric_shade_color_entries_list",
      params: { externalShutterRunnerId: compData.externalShutterRunner.id }
    }),
    updateRoute: ({ object }) => ({
      name: "external_shutter_runner_fabric_shade_color_entries_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["external_shutter_runner_fabric_shade_color_entry_update"],
    route: {
      path: "/external_shutter_runner_fabric_shade_color_entries/:id/update",
      name: "external_shutter_runner_fabric_shade_color_entries_update"
    },
    resource: "external_shutter_runner_fabric_shade_color_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_runners_list" }),
        label: () => "Prowadnice"
      },
      {
        route: ({ compData }) => ({
          name: "external_shutter_runner_fabric_shade_color_entries_list",
          params: { externalShutterRunnerId: compData.object.externalShutterRunner.id }
        }),
        label: ({ compData }) =>
          `Kolory dla prowadnicy "${compData.object.externalShutterRunner.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja wiązania koloru "${compData.object.fabricShadeColor.name}" z prowadnicą "${compData.object.externalShutterRunner.name}"`
      }
    ],
    formComponent: ExternalShutterRunnerFabricShadeColorEntryForm,
    onCreated: fetchExternalShutterRunner,
    listRoute: ({ compData }) => ({
      name: "external_shutter_runner_fabric_shade_color_entries_list",
      params: {
        externalShutterRunnerId: compData.object.externalShutterRunner.id
      }
    })
  }
};
