import http from "../http";
import notify from "./notify";

export default async function checkLastPosition(router) {
    const isUpdate = router.currentRoute.path.includes("update");

    if (isUpdate) {
        return null;
    }

    const {
        externalShutterId = "",
        externalShutterBoxId = "",
        externalShutterArmorId = "",
        externalShutterRunnerId = "",
        fabricShadeId = "",
        fabricShadeDayNightId = "",
        fabricShadeGroupId = "",
        fabricShadeDayNightGroupId = "",
        pleatId = "",
        pleatGroupId = "",
        threadColorId = "",
        frameMosquitoNetId = "",
    } = router.currentRoute.params;

    const pathParams = [
        externalShutterId,
        externalShutterBoxId,
        externalShutterArmorId,
        externalShutterRunnerId,
        fabricShadeId,
        fabricShadeDayNightId,
        fabricShadeGroupId,
        fabricShadeDayNightGroupId,
        pleatId,
        pleatGroupId,
        threadColorId,
        frameMosquitoNetId
    ].filter((param) => !!param);

    const baseUrl = router.currentRoute.path.split("/")[1];
    const path = pathParams.length ? `/${pathParams.join("/")}` : "";

    try {
        const { data } = await http.get(`${baseUrl}${path}/check/position`);

        if (!data['maxPosition']) {
            return 0;
        }

        return parseInt(data['maxPosition']) + 1; // next number returned
    } catch (error) {
        notify("Nie udało się pobrać ostatniej pozycji", "danger");
        return null;
    }
}
