<template lang="pug">
    app-view-card(title="Ustawienia użytkownika")
        .columns
            .column.is-2
                app-view-card.has-max-width-small(title="Kolor tła aplikacji")
                    app-form-field(:label="'Wybierz kolor'" field="null")
                        b-field
                            input(type="color" :value="this.themeBackgroundColor" @input="setThemeBackgroundColor($event.target.value)" class="color-picker")
                    app-form-field(:label="null" field="null")
                        b-field
                            b-button(label="Zapisz" type="is-primary" @click="saveColor" :loading="loading")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import http from "@/http";
import notify from "@/helpers/notify";

export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    ...mapGetters(["themeBackgroundColor"])
  },
  methods: {
    ...mapActions(["setThemeBackgroundColor"]),
    async saveColor() {
      this.loading = true;
      const bgColor = this.themeBackgroundColor;
      try {
        await http.put(`/users/${this.userId}?properties[]=backgroundColor`, {
          backgroundColor: bgColor
        });
        notify("Pomyślnie zaktualizowano kolor tła");
      } catch {
        notify(
          "Nie udało się zaktualizować koloru tła aplikacji, obecny kolor pozostanie tłem do czasu opuszczenia strony",
          "danger"
        );
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
