<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="external_shutter_armors" :transform-to-submit="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(label="Ogólne")
          .columns
            .column
              .columns
                .column
                  app-form-field(label="Nazwa" field="name" :errors="errors")
                    b-input(v-model="data.name")
                .column
                  app-form-field(label="Symbol" field="symbol" :errors="errors")
                    b-input(v-model="data.symbol")
              .columns
                .column
                  app-form-field(label="Maksymalna szerokość (mm)" field="maxWidth" :errors="errors")
                    app-form-length(v-model="data.maxWidth" unit="mm")
                .column
                  app-form-field(label="Maksymalna powierzchnia (m^2)" field="maxSurface" :errors="errors")
                    app-form-surface(v-model="data.maxSurface" unit="m")
              .columns
                .column
                  app-form-field(label="Wysokość lameli (mm)" field="lamelHeight" :errors="errors")
                    app-form-decimal(v-model="data.lamelHeight")
                .column
                  app-form-field(label="Masa kg/m^2" field="weight" :errors="errors")
                    app-form-weight(v-model="data.weight" unit="kg")
            .column
              app-form-field(label="Przekrój" field="sectionPicture" :errors="errors")
                app-form-file(v-model="files.sectionPicture" :image="data.sectionPicture" @removed="data.sectionPicture = {}")
</template>

<script>
import form from "../../../plugins/app/form/mixins/form";
export default {
  mixins: [form],
  data() {
    return {
      files: {
        sectionPicture: {}
      }
    };
  },
  computed: {
    model() {
      return {
        name: "",
        symbol: "",
        maxSurface: 0,
        maxWidth: 0,
        weight: 0,
        //wartość w mm/100 - wyjatek na zyczenie klienta
        lamelHeight: 0,
        sectionPicture: null,
        isEnabled: false
      };
    }
  },
  methods: {
    transformToSubmit(data) {
      return data;
    },
    transformToModel(data) {
      return data;
    }
  }
};
</script>
