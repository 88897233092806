export default function (item) {
    const {detailUnit} = item;

    if (!detailUnit || !detailUnit.symbol) {
        return;
    }

    switch (detailUnit.symbol) {
        case 'rozpak':
            return 'szt.';
        case 'opak':
            return 'szt.';
        case 'mb':
            return item.lengthUnit || 'mm';
        default:
            return null;
    }
}