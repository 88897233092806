import GlobalCaseForm from "../components/GlobalCaseForm";

export default {
  list: {
    permissions: ["global_case_read"],
    actions: [
      {
        label: "Nowa kasetka",
        type: "is-primary",
        route: () => ({
          name: "global_cases_create"
        }),
        permissions: ["global_case_create"]
      }
    ],
    route: {
      path: "/global_cases/list",
      name: "global_cases_list"
    },
    sortable: false,
    breadcrumbs: [
      {
        label: () => `Globalne kasetki`
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      { field: "name", type: "text", label: "Nazwa", filter: "text" }
    ],
    resource: "global_cases",
    url: () => `/global_cases`,
    rowActions: [
      {
        action: "edit",
        permissions: ["global_case_update"]
      },
      {
        action: "remove",
        permissions: ["global_case_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_case_create"],
    route: {
      path: "/global_cases/create",
      name: "global_cases_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "global_cases_list"
        }),
        label: () => `Globalne kasetki`
      },
      {
        label: () => `Nowa kasetka`
      }
    ],
    formComponent: GlobalCaseForm,
    listRoute: () => ({
      name: "global_cases_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_cases_update",
      params: {
        id: object.id
      }
    })
  },
  update: {
    permissions: ["global_case_update"],
    route: {
      path: "/global_cases/:id/update",
      name: "global_cases_update"
    },
    resource: "global_cases",
    breadcrumbs: [
      {
        route: () => ({
          name: "global_cases_list"
        }),
        label: () => `Globalne kasetki`
      },
      {
        label: ({ compData }) => `Edycja kasetki "${compData.object.name}"`
      }
    ],
    formComponent: GlobalCaseForm,
    listRoute: () => ({
      name: "global_cases_list"
    })
  }
};
