import PleatAdditionalStringForm from "../components/PleatAdditionalStringForm";
import fetchPleat from "../helpers/fetchPleat";
import generateProperties from "../../../helpers/generateProperties";
import decimal from "../../../helpers/decimal";

export default {
    list: {
        permissions: ["pleat_additional_string_read"],
        actions: [
            {
                label: "Nowy dodatkowy naciąg",
                type: "is-primary",
                route: ({ route }) => ({
                    name: "pleat_additional_strings_create",
                    params: {
                        pleatId: route.params.pleatId
                    }
                }),
                permissions: ["pleat_additional_string_create"]
            }
        ],
        route: {
            path:
                "/pleat_additional_strings/:pleatId/list",
            name: "pleat_additional_strings_list"
        },
        sortable: true,
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Wersje"
            },
            {
                label: ({ compData }) =>
                    `Dodatkowe naciągi dla wersji "${compData.pleat.name}"`
            }
        ],
        fields: [
            {
                field: "globalRunner.name",
                type: "text",
                label: "Naciąg",
                filter: "text"
            },
            {
                field: "pieceSurcharge",
                type: "text",
                label: "Dopłata od sztuki",
                format: decimal
            },
            {
                field: "isEnabled",
                type: "boolean",
                label: "Aktywny",
                filter: "boolean"
            },
        ],
        resource: "pleat_additional_strings",
        url: ({ route }) =>
            `/pleats/${route.params.pleatId}/pleat_additional_strings?` +
            generateProperties(
                [],
                ["id", "position", "isEnabled", "pieceSurcharge"]
            ) +
            generateProperties(["globalRunner"], ["name"], true)
        ,
        onCreated: fetchPleat,
        rowActions: [
            {
                action: "edit",
                permissions: ["pleat_additional_string_update"]
            },
            {
                action: "remove",
                permissions: ["pleat_additional_string_delete"]
            }
        ]
    },
    create: {
        permissions: ["pleat_additional_string_create"],
        route: {
            path:
                "/pleat_additional_strings/:pleatId/create",
            name: "pleat_additional_strings_create"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Wersje"
            },
            {
                route: ({ compData }) => ({
                    name: "pleat_additional_strings_list",
                    params: { pleatId: compData.pleat.id }
                }),
                label: ({ compData }) =>
                    `Dodatkowe naciągi dla wersji "${compData.pleat.name}"`
            },
            {
                label: ({ compData }) =>
                    `Nowy dodatkowy naciąg dla wersji "${compData.pleat.name}"`
            }
        ],
        formComponent: PleatAdditionalStringForm,
        onCreated: fetchPleat,
        listRoute: ({ compData }) => ({
            name: "pleat_additional_strings_list",
            params: { pleatId: compData.pleat.id }
        }),
        updateRoute: ({ object }) => ({
            name: "pleat_additional_strings_update",
            params: {
                id: object.id
            }
        })
    },
    update: {
        permissions: ["pleat_additional_string_update"],
        route: {
            path: "/pleat_additional_strings/:id/update",
            name: "pleat_additional_strings_update"
        },
        resource: "pleat_additional_strings",
        breadcrumbs: [
            {
                route: () => ({ name: "pleats_list" }),
                label: () => "Wersje"
            },
            {
                route: ({ compData }) => ({
                    name: "pleat_additional_strings_list",
                    params: {
                        pleatId: compData.object.pleat.id
                    }
                }),
                label: ({ compData }) =>
                    `Dodatkowe naciągi dla wersji "${compData.object.pleat.name}"`
            },
            {
                label: ({ compData }) =>
                    `Edycja dodatkowego naciągu - prowadnicy "${compData.object.globalRunner.name}" z wersją "${compData.object.pleat.name}"`
            }
        ],
        formComponent: PleatAdditionalStringForm,
        onCreated: fetchPleat,
        listRoute: ({ compData }) => ({
            name: "pleat_additional_strings_list",
            params: {
                pleatId: compData.object.pleat.id
            }
        })
    }
};
