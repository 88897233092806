import http from "../http";
import router from "../router";

export default function createListStore(url, properties) {
  return {
    namespaced: true,
    state: {
      sortField: "position",
      sortOrder: "asc",
      total: 0,
      list: [],
      perPage: 30,
      loading: false
    },
    getters: {
      loading: state => state.loading,
      list: state => state.list,
      isSorted: () => field => {
        const queryField = router.currentRoute.query.sortField;
        return queryField && queryField === field;
      },
      params: state => () => {
        const query = { ...router.currentRoute.query };
        let sortField = `order[${query.sortField || state.sortField}]`;
        return {
          ...query,
          [sortField]: query.sortOrder || state.sortOrder,
          page: query.page,
          properties: properties || undefined
        };
      },
      filters: () => () => {
        let query = { ...router.currentRoute.query };
        delete query.page;
        delete query.sortField;
        delete query.sortOrder;
        return query;
      },
      classes: state => ({
        "is-taller": state.loading
      }),
      isEmpty: state => state.list.length === 0,
      isAsc: (state,getters,rootState) => rootState.route.query.sortOrder === "asc",
      isDesc: (state,getters,rootState) => rootState.route.query.sortOrder === "desc",
      currentPage: (state,getters,rootState) => parseInt(rootState.route.query.page) || 1,
      hasPrevPage: (state, getters) => getters.currentPage > 1 ,
      hasNextPage: (state, getters) => {
        const lastPage = Math.ceil(state.total / state.perPage);
        return getters.currentPage < lastPage;
      }
    },
    mutations: {
      setList(state, list) {
        state.list = list;
      },
      setTotal(state, total) {
        state.total = total;
      },
      startLoading(state) {
        state.loading = true;
      },
      endLoading(state) {
        state.loading = false;
      }
    },
    actions: {
      async fetch({ commit, getters }) {
        // this.loading = true;
        let { data } = await http.get(url, {
          params: getters.params()
        });
        commit("setList", data["hydra:member"]);
        commit("setTotal", data["hydra:totalItems"]);
        // this.loading = false;
      },
      sort(context, { sortable, field }) {
        if (sortable) {
          router.push({
            query: {
              ...router.currentRoute.query,
              sortField: field,
              sortOrder:
                router.currentRoute.query.sortOrder === "desc" ? "asc" : "desc"
            }
          });
        }
      },
      clearFilters() {
        const query = { ...router.currentRoute.query };
        router.push({
          query: {
            sortField: query.sortField || undefined,
            sortOrder: query.sortOrder || undefined
          }
        });
      },
      filter(context, { field, value }) {
        router.push({
          query: {
            ...router.currentRoute.query,
            [field]: value || undefined
          }
        });
      },
      next({ getters }) {
        if (getters.hasNextPage) {
          router.push({
            query: {
              ...router.currentRoute.query,
              page: getters.currentPage + 1
            }
          });
        }
      },
      prev({ getters }) {
        if (getters.hasPrevPage) {
          router.push({
            query: {
              ...router.currentRoute.query,
              page: getters.currentPage - 1
            }
          });
        }
      }
    }
  };
}
