import ExternalShutterGlobalPipeForm from "../components/ExternalShutterGlobalPipeForm";
import generateProperties from "../../../helpers/generateProperties";
import weight from "../../../helpers/weight";
import decimal from "../../../helpers/decimal";

export default {
  list: {
    permissions: ["external_shutter_global_pipe_read"],
    route: {
      path: "/external_shutter_global_pipes/list",
      name: "external_shutter_global_pipes_list"
    },
    breadcrumbs: [
      {
        label: () => `Globalne rury dla rolet zewnętrznych`
      }
    ],
    fields: [
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      {
        field: "externalShutterMaxWidth",
        type: "text",
        label: "Max szer. (mm)"
      },
      {
        field: "externalShutterWeight",
        type: "text",
        label: "Masa (kg/mb)",
        format: weight
      },
      {
        field: "externalShutterPieceSurcharge",
        type: "text",
        label: "Dopłata",
        format: decimal
      },
      {
        field: "externalShutterIsEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "global_pipes",
    url: () =>
      `/global_pipes?isEnabled=true&isEnabledForExternalShutter=true` +
      generateProperties(
        [],
        [
          "id",
          "name",
          "symbol",
          "externalShutterIsEnabled",
          "externalShutterMaxWidth",
          "externalShutterWeight",
          "externalShutterPieceSurcharge"
        ],
        true
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["external_shutter_global_pipe_update"],
        pathName: "external_shutter_global_pipes_update"
      }
    ]
  },
  update: {
    permissions: ["external_shutter_global_pipe_update"],
    route: {
      path: "/external_shutter_global_pipes/:id/update",
      name: "external_shutter_global_pipes_update"
    },
    resource: "global_pipes",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "name",
        "externalShutterIsEnabled",
        "externalShutterWeight",
        "externalShutterMaxWidth",
        "externalShutterPieceSurcharge"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "external_shutter_global_pipes_list" }),
        label: () => `Globalne rury dla rolet zewnętrznych`
      },
      {
        label: ({ compData }) =>
          `Edycja globalnej rury "${compData.object.name}" dla rolet zewnętrznych`
      }
    ],
    formComponent: ExternalShutterGlobalPipeForm,
    listRoute: () => ({ name: "external_shutter_global_pipes_list" })
  }
};
