import FrameMosquitoNetGlobalNetEntryForm from "../../frame_mosquito_nets/components/FrameMosquitoNetGlobalNetEntryForm.vue";
import fetchFrameMosquitoNet from "../helpers/fetchFrameMosquitoNet";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["frame_mosquito_net_global_net_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "frame_mosquito_net_global_net_entries_create",
          params: {
            frameMosquitoNetId: route.params.frameMosquitoNetId
          }
        }),
        permissions: ["frame_mosquito_net_global_net_create"]
      }
    ],
    route: {
      path: "/frame_mosquito_net_global_net_entries/:frameMosquitoNetId/list",
      name: "frame_mosquito_net_global_net_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "frame_mosquito_nets_list" }),
        label: () => "Moskitiery ramkowe"
      },
      {
        label: ({ compData }) =>
          `Wiązania kolorów siatek dla wersji "${compData.frameMosquitoNet.name}"`
      }
    ],
    fields: [
      {
        field: "globalNet.name",
        type: "text",
        label: "Nazwa siatki",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "skipCalculations",
        type: "boolean",
        label: "Pomiń kalkulacje cennikowe",
        filter: "boolean"
      },
    ],
    resource: "frame_mosquito_net_global_net_entries",
    url: ({ route }) =>
      `/frame_mosquito_nets/${route.params.frameMosquitoNetId}/frame_mosquito_net_global_net_entries?` +
        generateProperties([],['id', 'position', 'isEnabled', 'skipCalculations']) +
        generateProperties(['frameMosquitoNet'],['id', 'name', 'symbol'], true) +
        generateProperties(['globalNet'],['id','name','symbol'],true),
    onCreated: fetchFrameMosquitoNet,
    rowActions: [
      {
        action: "edit",
        permissions: ["frame_mosquito_net_global_net_update"]
      },
      {
        action: "remove",
        permissions: ["frame_mosquito_net_global_net_delete"]
      }
    ]
  },
  create: {
    permissions: ["frame_mosquito_net_global_net_create"],
    route: {
      path: "/frame_mosquito_net_global_net_entries/:frameMosquitoNetId/create",
      name: "frame_mosquito_net_global_net_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "frame_mosquito_nets_list" }),
        label: () => "Moskitiery ramkowe"
      },
      {
        route: ({ compData }) => ({
          name: "frame_mosquito_net_global_net_entries_list",
          params: { frameMosquitoNetId: compData.frameMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `kolory siatek dla wersji "${compData.frameMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy kolor siatki dla wersji "${compData.frameMosquitoNet.name}"`
      }
    ],
    formComponent: FrameMosquitoNetGlobalNetEntryForm,
    onCreated: fetchFrameMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "frame_mosquito_net_global_net_entries_list",
      params: { frameMosquitoNetId: compData.frameMosquitoNet.id }
    }),
    updateRoute: ({ object , compData}) => ({
      name: "frame_mosquito_net_global_net_entries_update",
      params: {
        id: object.id,
        frameMosquitoNetId: compData.frameMosquitoNet.id
      }
    })
  },
  update: {
    permissions: ["frame_mosquito_net_global_net_entry_update"],
    route: {
      path: "/frame_mosquito_net_global_net_entries/:id/update",
      name: "frame_mosquito_net_global_net_entries_update"
    },
    resource: "frame_mosquito_net_global_net_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "frame_mosquito_nets_list" }),
        label: () => "Moskitiery ramkowe"
      },
      {
        route: ({ compData }) => ({
          name: "frame_mosquito_net_global_net_entries_list",
          params: { frameMosquitoNetId: compData.object.frameMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `Kolory siatek dla wersji "${compData.object.frameMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru dla siatki "${compData.object.globalNet.name}" z wersją "${compData.object.frameMosquitoNet.name}"`
      }
    ],
    formComponent: FrameMosquitoNetGlobalNetEntryForm,
    onCreated: fetchFrameMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "frame_mosquito_net_global_net_entries_list",
      params: {
        frameMosquitoNetId: compData.object.frameMosquitoNet.id
      }
    })
  }
};
