import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "fabricDayNight", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(`fabric_day_nights/${route.params.fabricDayNightId}`);
    compData.fabricDayNight = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować tkaniny", "danger");
  }
};
