import detailSystemForm from "../components/DetailSystemForm.vue";

export default {
  list: {
    permissions: ["detail_system_read"],
    sortable: true,
    actions: [
      {
        label: "Nowy system",
        type: "is-primary",
        route: ({ route }) => ({
          name: "detail_systems_create",

        }),
        permissions: ["detail_system_create"]
      }
    ],
    route: {
      path: "/detail_systems",
      name: "detail_systems_list"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_systems_list" }),
        label: () => "Systemy detali"
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      { field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Widoczny",
        filter: "boolean"
      }],
    resource: "detail_systems",
    url: ({ route }) =>
      `/detail_systems`,
    rowActions: [
      {
        action: "edit",
        permissions: ["detail_system_update"]
      },
      {
        action: "remove",
        permissions: ["detail_system_delete"]
      }
    ],
  },
  create: {
    permissions: ["detail_system_create"],
    route: {
      path: "/detail_systems/create",
      name: "detail_systems_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "detail_systems_list" }),
        label: () => "Systemy detali"
      },

      {
        label: ({ compData }) =>
          `Nowy system`
      }
    ],
    formComponent: detailSystemForm,
    listRoute: ({ compData }) => ({
      name: "detail_systems_list"
    }),
    updateRoute: ({ object }) => ({
      name: "detail_systems_update",
      params: { id: object.id }
    }),
  },
  update: {
    permissions: ["detail_system_update"],
    route: {
      path: "/detail_systems/:id/update",
      name: "detail_systems_update"
    },
    resource: "detail_systems",
    breadcrumbs: [
      {
        route: () => ({ name: "detail_systems_list" }),
        label: () => "Systemy w detalach"
      },
      {
        label: ({ compData }) =>
          `Edycja systemu"${compData.object.name}" `
      }
    ],
    formComponent: detailSystemForm,
    listRoute: ({ compData }) => ({
      name: "detail_systems_list"
    })
  }
};
