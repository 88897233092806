<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <form @submit.prevent="submit" class="box">
              <b-message v-show="error" type="is-danger">
                {{ error }}
              </b-message>

              <div class="field">
                <label for="username" class="label">Login</label>
                <div class="control has-icons-left">
                  <input
                    id="username"
                    v-model="data.username"
                    type="text"
                    placeholder="login"
                    class="input"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <label for="password" class="label">Hasło</label>
                <div class="control has-icons-left">
                  <input
                    id="password"
                    v-model="data.password"
                    type="password"
                    placeholder="*******"
                    class="input"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <div class="field has-text-centered">
                <b-button
                  native-type="submit"
                  type="is-primary"
                  :loading="loading"
                >
                  Zaloguj
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {
      data: {
        username: null,
        password: null
      }
    };
  },
  computed: {
    ...mapState("auth", ["error", "loading", "userId"])
  },
  methods: {
    ...mapActions(["init"]),
    submit() {
      this.$store.dispatch("auth/postLogin", this.data).then(() => {
        this.init(this.userId);
        this.$router.push({
          name: "orders_list",
          query: {
            in: "new accepted production",
          },
        });
      });
    }
  }
};
</script>
