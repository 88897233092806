import GlobalDriveForm from "../components/GlobalDriveForm";
import decimal from "../../../helpers/decimal";
import http from "@/http";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["global_drive_read"],
    //hasPagination: true,
    sortable: true,
    actions: [
      {
        label: "Nowy napęd",
        type: "is-primary",
        route: () => ({
          name: "global_drives_create"
        }),
        permissions: ["global_drive_create"]
      },
      {
        label: "Export",
        type: "is-info",
        route: () => ({
          name: "global_drives_export"
        }),
        permissions: ["global_drives_export"]
      },
      {
        label: "Import",
        type: "is-info",
        route: () => ({
          name: "global_drives_import"
        }),
        permissions: ["global_drives_import"]
      }
    ],
    route: {
      path: "/global_drives/list",
      name: "global_drives_list"
    },
    breadcrumbs: [
      {
        label: () => `Napędy globalne`
      }
    ],
    fields: [
      { field: "position", type: "text", label: "Poz." },
      {
        field: "globalDriveType.name",
        type: "text",
        label: "Typ",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/global_drive_types?properties[]=name")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        }
      },
      {
        field: "globalDriveSeries.name",
        type: "text",
        label: "Seria",
        filter: "select",
        filterOptions: () => {
          let types = [];
          types.push({ label: "Wszystkie", value: null });
          http
              .get("/global_drive_series?properties[]=name")
              .then(response => {
                if (response.data["hydra:member"]) {
                  response.data["hydra:member"].forEach(type => {
                    types.push({
                      label: type.name,
                      value: type.name
                    });
                  });
                }
              })
              .catch(() => {
                types = [{ label: "Wszystkie", value: null }];
              });
          return types;
        }
      },
      { field: "symbol", type: "text", label: "Symbol", filter: "text" },
      //      { field: "position", type: "text", label: "Poz." },
      //{ field: "name", type: "text", label: "Nazwa", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktyw",
        filter: "boolean"
      },
      {
        field: "pieceSurcharge",
        type: "text",
        label: "Dopł",
        format: decimal
      },
      {
        field: "minWidth",
        type: "text",
        label: "Min"
      },
      {
        field: "maxWidth",
        type: "text",
        label: "Max"
      }
    ],
    resource: "global_drives",
    url: () =>
      `/global_drives?` +generateProperties(
        [],
        ["id", "name", "symbol", "isEnabled", "pieceSurcharge", "position", "minWidth", "maxWidth"]
      ) +generateProperties(["globalDriveType"], ["name"], true) +
      generateProperties(["globalDriveSeries"], ["name"], true),
    rowActions: [
      {
        action: "edit",
        permissions: ["global_drive_update"]
      },
      {
        action: "remove",
        permissions: ["global_drive_delete"]
      }
    ]
  },
  create: {
    permissions: ["global_drive_create"],
    route: {
      path: "/global_drives/create",
      name: "global_drives_create"
    },
    breadcrumbs: [
      {
        route: () => ({
          name: "global_drives_list"
        }),
        label: () => `Napędy globalne`
      },
      {
        label: () => `Nowy napęd globalny`
      }
    ],
    formComponent: GlobalDriveForm,
    listRoute: () => ({
      name: "global_drives_list"
    }),
    updateRoute: ({ object }) => ({
      name: "global_drives_update",
      params: {
        id: object.id
      }
    })
  },
  export: {
    permissions: ["global_drives_export"],
    route: {
      path: "/global_drives/export",
      name: "global_drives_export"
    },
  },
  import: {
    permissions: ["global_drives_import"],
    route: {
      path: "/global_drives/import",
      name: "global_drives_import"
    },
  },
  update: {
    permissions: ["global_drive_update"],
    route: {
      path: "/global_drives/:id/update",
      name: "global_drives_update"
    },
    resource: "global_drives",
    breadcrumbs: [
      {
        route: () => ({
          name: "global_drives_list"
        }),
        label: ({ compData }) => `Napędy globalne`
      },
      {
        label: ({ compData }) =>
          `Edycja globalnego napędu "${compData.object.name}"`
      }
    ],
    formComponent: GlobalDriveForm,
    listRoute: () => ({
      name: "global_drives_list"
    })
  }
};
