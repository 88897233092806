import Vue from "vue";

export default async ({ compData, route, $notify, $http }) => {
  Vue.set(compData, "fabric", {});
  try {
    compData.loading = true;
    let { data } = await $http.get(`pleat_fabrics/${route.params.pleatFabricId}`);
    compData.pleatFabric = data;
    compData.loading = false;
  } catch (e) {
    $notify("Nie udało się załadować tkaniny", "danger");
  }
};
