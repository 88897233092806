<template lang="pug" functional>
  b-button(
    :title="props.title"
    type="is-light"
    size="is-small"
    tag="router-link"
    :to="props.to"
    :icon-left="props.icon"
    focused
  )
</template>
<script>
export default {
  props: {
    to: {type: [Object, String], required: true},
    icon: {type: String, default: "pencil"},
    title: {type: String, default: ''}
  }
};
</script>
